import FlexSearch from 'flexsearch';

import client from './feathers';

const index = {};

// function add(id, cat, content) {
//   (index[cat] || (index[cat] = new FlexSearch())).add(id, content);
// }

function addAll(id, field, cat, content) {
  (
    index[cat] ||
    (index[cat] = new FlexSearch({
      doc: {
        id,
        field,
      },
    }))
  ).add(content);
}

function search(cat, query) {
  return index[cat] ? index[cat].search(query) : [];
}

const cacheConfig = {
  assets: {
    service: 'reference/assets',
    query: { $limit: -1 },
    indexConfig: {
      id: 'asset_id',
      field: ['asset_id', 'name'],
      category: 'assets',
    },
  },
  providers: {
    service: 'providers',
    query: {
      visible: true,
      $eager: '[endpoints]',
      $modifyEager: {
        endpoints: {
          visible: 1,
        },
      },
      $limit: -1,
    },
    indexConfig: {
      id: 'providerId',
      field: ['name'],
      category: 'providers',
    },
  },
  endpoints: {
    service: 'endpoints',
    query: {
      visible: true,
      $eager: '[provider, parameters.enums]',
      $limit: -1,
    },
    indexConfig: {
      id: 'endpointId',
      field: ['name'],
      category: 'endpoints',
    },
  },
};

async function loadService({ service, query, indexConfig, raw }) {
  const { id, field, category } = indexConfig;

  let data = [];
  if (raw) {
    data = raw;
  } else {
    data = await client.service(service).find({ query });
  }

  return addAll(id, field, category, data);
}

async function cacheSetup() {
  try {
    const providerCache = loadService(cacheConfig.providers);
    //   const endpointCache = loadService(cacheConfig.endpoints);
    //   const assetsCache = loadService(cacheConfig.assets);

    const results = [await providerCache];

    return results;
  } catch (e) {
    // Do nothing
    console.log(e);
  }
}

export { index, search, cacheSetup };
