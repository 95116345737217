/* eslint-disable react/display-name */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Stats } from 'react-instantsearch-dom';
import { AppstoreOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { searchIndices } from './config';
import SortBy from './SortBy';
import CustomHits from './SearchHits';
import CustomPagination from '../../components/widget/components/AlgoliaPagination';

const ButtonGroup = Button.Group;

const Content = React.memo(
  ({ collapsed, setCollapsed, hitsPerPage, onShowSizeChange }) => {
    const { activeKey, view } = useSelector((state) => state.browse);
    const dispatch = useDispatch();

    const foundIndex = searchIndices.find((index) => index.key === activeKey);

    if (_.isEmpty(foundIndex)) {
      return <div>Loading...</div>;
    }

    const { defaultRefinement, items } = foundIndex;

    const sortByProps = { defaultRefinement, items };

    return (
      <div className="contentWrapper isoAlgoliaContentWrapper">
        <div className="topbarWrapper isoAlgoliaTopBar">
          <Stats />
          <SortBy {...sortByProps} />
          <ButtonGroup>
            <Button
              type={view === 'grid' ? 'primary' : ''}
              icon={<AppstoreOutlined />}
              onClick={() => dispatch({ type: 'CHANGE_VIEW', view: 'grid' })}
            />
            <Button
              type={view === 'grid' ? '' : 'primary'}
              icon={<UnorderedListOutlined />}
              onClick={() => dispatch({ type: 'CHANGE_VIEW', view: 'list' })}
            />
          </ButtonGroup>
        </div>

        <CustomHits />
        <br />
        <CustomPagination
          hitsPerPage={hitsPerPage}
          onShowSizeChange={onShowSizeChange}
        />
      </div>
    );
  }
);

export default Content;
