import client from '@cryptosheets/feathers-client';

/**
 * Google is REQUIRED to have either:
 * 1. Rest as the client, or
 * 2. Stringify the response before sending
 *
 * Reason: Google translates non-stringified data dates as dates, and therefore can't
 * send to sheet properly.
 */
const FEATHERS_TRANSPORT = process.env.REACT_APP_FEATHERS_TRANSPORT || 'socket';
export default client('localStorage', FEATHERS_TRANSPORT);
