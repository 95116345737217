function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  .scenario-total-results {\n    font-size: 14px;\n    font-weight: 500;\n    color: #323332;\n    opacity: 0.6;\n    font-stretch: normal;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
export var ScenarioSearchWrapper = styled.div(_templateObject());