import React from 'react';
import _ from 'lodash';
import { Breadcrumb } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import './breadcrumb.css';

const Breadcrumbs = (props) => {
  const { loc } = props;
  const pathSnippets = loc.pathname.split('/').filter(Boolean);
  const extraBreadcrumbItems = [];
  pathSnippets.forEach((path, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    extraBreadcrumbItems.push(
      <Breadcrumb.Item key={url}>
        <Link to={url}>{_.startCase(path)}</Link>
      </Breadcrumb.Item>
    );
  });
  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/">
        <HomeOutlined />
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);
  return (
    <div>
      <Breadcrumb style={{ margin: '16px 0' }}>{breadcrumbItems}</Breadcrumb>
    </div>
  );
};

export default Breadcrumbs;
