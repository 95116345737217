/* eslint-disable import/prefer-default-export */
export const actions = {
  CHANGE_VIEW: 'CHANGE_VIEW',
  BROWSE_SET_SEARCH: 'BROWSE_SET_SEARCH',
  BROWSE_SET_HITS_PER_PAGE: 'BROWSE_SET_HITS_PER_PAGE',
  UPDATE_SEARCH_STATE: 'UPDATE_SEARCH_STATE',
  UPDATE_ENDPOINTS_SEARCH_STATE: 'UPDATE_ENDPOINTS_SEARCH_STATE',
  UPDATE_PROVIDERS_SEARCH_STATE: 'UPDATE_PROVIDERS_SEARCH_STATE',
  UPDATE_ACTIVE_TAB: 'UPDATE_ACTIVE_TAB',
  changeView: (view) => ({
    type: actions.CHANGE_VIEW,
    view,
  }),
  setSearch: (searchValue) => (dispatch) =>
    dispatch({
      type: actions.BROWSE_SET_SEARCH,
      searchValue,
    }),
  setNumberOfHits: (hitsPerPage) => (dispatch) =>
    dispatch({
      type: actions.BROWSE_SET_HITS_PER_PAGE,
      hitsPerPage,
    }),
  updateSearchState: (searchState) => (dispatch) =>
    dispatch({
      type: actions.UPDATE_SEARCH_STATE,
      searchState,
    }),
  updateActiveTab: (key) => (dispatch) => {
    dispatch({
      type: actions.UPDATE_ACTIVE_TAB,
      key,
    });
  },
};
