import React from 'react';
import _ from 'lodash';
import { RefinementList } from 'react-instantsearch-dom';

export default function CustomRefinementList({
  displayName,
  attributeName,
  searchable,
  placeholder,
}) {
  return (
    <div key={displayName} className="isoAlgoliaSidebarItem">
      <h3 className="isoAlgoliaSidebarTitle">{_.startCase(displayName)}</h3>
      <RefinementList
        searchable={searchable}
        translations={{ placeholder }}
        attribute={attributeName}
        transformItems={(items) =>
          items.map((item) => {
            if (attributeName === 'keywords') {
              return {
                ...item,
                label: 'test',
                _highlightResult: {
                  label: {
                    value: 'testhigh',
                  },
                },
              };
            }
            return {
              ...item,
              label: _.startCase(item.label),
            };
          })
        }
        // showMore
      />
    </div>
  );
}
