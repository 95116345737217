import React from 'react';
import { Result } from 'antd';

export default function EmptyPage({ img, title, subTitle, extra }) {
  return (
    <Result
      icon={<img src={img} alt="Empty page walkthrough" />}
      title={title}
      subTitle={subTitle}
      extra={extra}
    />
  );
}
