import algoliasearch from 'algoliasearch';
import { AlgoliaSearchConfig } from '../config';

const { appId, apiKey } = AlgoliaSearchConfig;

export const useAlgolia = (indexName, config = {}) => {
  const client = algoliasearch(appId, apiKey);
  const index = client.initIndex(indexName);

  return index.search('price', config).then(({ hits }) => hits);
};

export const fetchHits = (indexName, value = '', config = {}) => {
  const client = algoliasearch(appId, apiKey);
  const index = client.initIndex(indexName);

  return index.search(value, config).then(({ hits }) => hits);
};
