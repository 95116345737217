import React from 'react';
import { Button } from 'antd';
import { view as EmptyPage } from '../../../components/emptyPage';

const images = {
  mobile:
    'https://res.cloudinary.com/cryptosheets/image/upload/v1578690024/tutorials/workspace_tutorial_mobile_focs7u.gif',
  default:
    'https://res.cloudinary.com/cryptosheets/image/upload/v1578686707/tutorials/workspace_tutorial_rxh3mv.gif',
};

export default function EmptyWorkspace({ toggleFirstTime }) {
  const emptyPageProps = {
    title: 'Workspace',
    subTitle:
      'A customizable dashboard canvas for using Cryptosheets workspace widget panels.',
    extra: [
      <Button type="primary" onClick={toggleFirstTime}>
        Get Started
      </Button>,
      <Button
        type="link"
        href="https://intercom.help/cryptosheets/en/articles/3592597-cryptosheets-workspace-dashboards"
        target="_blank"
        rel="noopener noreferrer"
      >
        Docs
      </Button>,
    ],
    img: images.mobile,
  };
  return <EmptyPage {...emptyPageProps} />;
}
