/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from 'react';
import { Avatar, PageHeader, Tag } from 'antd';
import _ from 'lodash';
import Interweave from 'interweave';

function TemplateSummary({ widgetData, widgetWidth }) {
  const {
    description = 'A piece of content from Cryptosheets.',
    thumbnailUrl = 'https://res.cloudinary.com/cryptosheets/image/upload/v1568293448/logos/CryptosheetsLogoAppsource_w55w2y.png',
    keywords = 'content',
  } = widgetData;

  const displayName = widgetData.title
    ? _.startCase(widgetData.title)
    : widgetData.name
    ? _.startCase(widgetData.name)
    : 'Content Name';

  return (
    <div>
      <PageHeader
        title={displayName}
        subTitle={<Interweave content={description} />}
      >
        <Avatar
          style={{
            boxShadow: 'rgba(0,0,0,.25) 0 10px 10px 0px',
            marginLeft: '5px',
          }}
          shape="square"
          src={thumbnailUrl}
          size={widgetWidth - 50}
        />
        <br />
        <br />

        {[
          <div>
            {_.isString(keywords)
              ? keywords.split(',').map((keyword) => <Tag>{keyword}</Tag>)
              : null}
          </div>,
        ]}
      </PageHeader>
    </div>
  );
}

TemplateSummary.defaultProps = {
  widgetData: {
    name: 'No name provided',
    description: 'A piece of content from Cryptosheets.',
    thumbnailUrl:
      'https://res.cloudinary.com/cryptosheets/image/upload/v1568293448/logos/CryptosheetsLogoAppsource_w55w2y.png',
    keywords: 'content',
  },
};

export default TemplateSummary;
