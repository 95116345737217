export function handleMenuClick(e, actions, data) {
  const findAction = actions.find((action) => action.key === e.key);

  try {
    return findAction.action(data);
  } catch (error) {
    console.log(error);
    //   message.error(`Could not complete action. Reason: ${error.message}`);
  }
}
