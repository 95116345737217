const similarity = (arr, values) => arr.filter((v) => values.includes(v));

const arrayAndHasLength = (array) => {
  if (array && array.length) {
    // array and array.length are truthy
    // ⇒ probably OK to process array
    return true;
  }
  return false;
};

const emptyOrDoesNotExist = (array) => {
  if (!Array.isArray(array) || !array.length) {
    // array does not exist, is not an array, or is empty
    // ⇒ do not attempt to process array
    return true;
  }
  return false;
};

const reducedFilter = (data, keys, fn) =>
  data.filter(fn).map((el) =>
    keys.reduce((acc, key) => {
      acc[key] = el[key];
      return acc;
    }, {})
  );

export default {
  arrayAndHasLength,
  similarity,
  emptyOrDoesNotExist,
  reducedFilter,
};
