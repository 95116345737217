/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { message } from 'antd';
import feathers from '../../feathers';
import { API_BASE_URL, STREAMS_URL } from '../../config';
import { actions as authActions } from '../../account/actions';
import { actions as loadingActions } from '../../components/loading/index';

export const actions = {
  ACCOUNT_TOGGLE_MODAL_VISIBLE: 'ACCOUNT_TOGGLE_MODAL_VISIBLE',
  ACCOUNT_CURRENT_PLAN: 'ACCOUNT_CURRENT_PLAN',
  LOAD_PROFILE_REQUEST: 'index/App/LOAD_PROFILE',
  LOAD_PROFILE_SUCCESS: 'index/App/LOAD_PROFILE_SUCCESS',
  LOAD_PROFILE_ERROR: 'index/App/LOAD_PROFILE_ERROR',
  UPDATE_PROFILE_REQUEST: 'index/App/UPDATE_PROFILE',
  UPDATE_PROFILE_SUCCESS: 'index/App/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_ERROR: 'index/App/UPDATE_PROFILE_ERROR',
  DOWNGRADE_PROFILE_REQUEST: 'index/App/DOWNGRADE_PROFILE_REQUEST',
  DOWNGRADE_PROFILE_SUCCESS: 'index/App/DOWNGRADE_PROFILE_SUCCESS',
  DOWNGRADE_PROFILE_ERROR: 'index/App/DOWNGRADE_PROFILE_ERROR',
  ADD_KEY_REQUEST: 'index/App/ADD_KEY_REQUEST',
  ADD_KEY_SUCCESS: 'index/App/ADD_KEY_SUCCESS',
  ADD_KEY_ERROR: 'index/App/ADD_KEY_ERROR',
  DELETE_KEY_REQUEST: 'index/App/DELETE_KEY_REQUEST',
  DELETE_KEY_SUCCESS: 'index/App/DELETE_KEY_SUCCESS',
  DELETE_KEY_ERROR: 'index/App/DELETE_KEY_ERROR',
  UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_ERROR: 'UPDATE_PASSWORD_ERROR',
  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_ERROR: 'FORGOT_PASSWORD_ERROR',
  LOAD_STRIPE_PLANS_REQUEST: 'LOAD_STRIPE_PLANS_REQUEST',
  LOAD_STRIPE_PLANS_SUCCESS: 'LOAD_STRIPE_PLANS_SUCCESS',
  LOAD_STRIPE_PLANS_ERROR: 'LOAD_STRIPE_PLANS_ERROR',
  CREATE_STRIPE_REQUEST: 'CREATE_STRIPE_REQUEST',
  CREATE_STRIPE_SUCCESS: 'CREATE_STRIPE_SUCCESS',
  CREATE_STRIPE_ERROR: 'CREATE_STRIPE_ERROR',
  UPDATE_STRIPE_REQUEST: 'UPDATE_STRIPE_REQUEST',
  UPDATE_STRIPE_SUCCESS: 'UPDATE_STRIPE_SUCCESS',
  UPDATE_STRIPE_ERROR: 'UPDATE_STRIPE_ERROR',
  RESET_INITIAL_STATE: 'RESET_INITIAL_STATE',
  loadProfileRequest: () => ({
    type: actions.LOAD_PROFILE_REQUEST,
  }),
  profileLoaded: (profile) => ({
    type: actions.LOAD_PROFILE_SUCCESS,
    profile,
  }),
  profileLoadingError: (error) => ({
    type: actions.LOAD_PROFILE_ERROR,
    error,
  }),

  updateProfileRequest: () => ({
    type: actions.UPDATE_PROFILE_REQUEST,
  }),
  profileUpdated: (profile) => ({
    type: actions.UPDATE_PROFILE_SUCCESS,
    profile,
  }),
  profileUpdateError: (error) => ({
    type: actions.UPDATE_PROFILE_ERROR,
    error,
  }),

  downgradeProfileRequest: () => ({
    type: actions.DOWNGRADE_PROFILE_REQUEST,
  }),
  downgradeProfileSuccess: (profile) => ({
    type: actions.DOWNGRADE_PROFILE_SUCCESS,
    profile,
  }),
  downgradeProfileError: (error) => ({
    type: actions.DOWNGRADE_PROFILE_ERROR,
    error,
  }),

  addKeyRequest: () => ({
    type: actions.ADD_KEY_REQUEST,
  }),
  addKeySuccess: (profile) => ({
    type: actions.ADD_KEY_SUCCESS,
    profile,
  }),
  addKeyError: (error) => ({
    type: actions.ADD_KEY_ERROR,
    error,
  }),

  deleteKeyRequest: () => ({
    type: actions.DELETE_KEY_REQUEST,
  }),
  deleteKeySuccess: (profile) => ({
    type: actions.DELETE_KEY_SUCCESS,
    profile,
  }),
  deleteKeyError: (error) => ({
    type: actions.DELETE_KEY_ERROR,
    error,
  }),
  forgotPasswordRequest: () => ({
    type: actions.FORGOT_PASSWORD_REQUEST,
  }),
  forgotPasswordSuccess: (payload) => ({
    type: actions.FORGOT_PASSWORD_SUCCESS,
    payload,
  }),
  forgotPasswordError: (error) => ({
    type: actions.FORGOT_PASSWORD_ERROR,
    error,
  }),
  updatePasswordRequest: () => ({
    type: actions.UPDATE_PASSWORD_REQUEST,
  }),
  updatePasswordSuccess: (msg) => ({
    type: actions.UPDATE_PASSWORD_SUCCESS,
    msg,
  }),
  updatePasswordError: (error) => ({
    type: actions.UPDATE_PASSWORD_ERROR,
    error,
  }),
  updateProfile: (values) => (dispatch, getState) => {
    const user = getState().users.data;
    return feathers
      .service('users')
      .patch(user._id, values)
      .then((res) => dispatch(authActions.authSuccess({ ...res })))
      .catch((e) => message.error(e.message));
  },
  forgotPassword: (values) => (dispatch) => {
    dispatch(actions.forgotPasswordRequest());
    const { email } = values;
    const config = {
      method: 'post',
      url: `${API_BASE_URL}/forgot`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        email,
      }),
    };
    return axios(config)
      .then((response) => {
        dispatch(actions.forgotPasswordSuccess(response.data));
      })
      .catch((err) => {
        dispatch(actions.forgotPasswordError(err));
      });
  },
  updatePassword: (values) => (dispatch, getState) => {
    dispatch(actions.updatePasswordRequest());
    const { email } = getState().users.data;
    const { currentPassword, password } = values;
    const config = {
      method: 'post',
      url: `${STREAMS_URL}/authManagement`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        action: 'passwordChange',
        value: {
          user: { email },
          oldPassword: currentPassword,
          password,
        },
      },
    };
    return axios(config)
      .then((response) => {
        message.success('Successfully updated password');
        dispatch(actions.updatePasswordSuccess(response.data));
      })
      .catch((error) => {
        message.error('Unable to update password');
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          dispatch(actions.updatePasswordError(error.response.data.message));
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          dispatch(
            actions.updatePasswordError(
              'No response received. Please try again.'
            )
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          dispatch(
            actions.updatePasswordError(
              'Something happened in setting up the request.'
            )
          );
        }
      });
  },
  downgradeProfile: (dailyPlanId, stripeCustomerId) => (dispatch) => {
    dispatch(actions.downgradeProfileRequest());
    const config = {
      method: 'post',
      url: `${API_BASE_URL}/payments/downgrade`,
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.authToken}`,
      },
      data: JSON.stringify({
        dailyPlanId,
        stripeCustomerId,
      }),
    };
    return axios(config)
      .then((response) => {
        dispatch(actions.downgradeProfileSuccess(response.data));
        dispatch(actions.loadProfile());
      })
      .catch((err) => {
        dispatch(actions.downgradeProfileError(err));
      });
  },

  addKey: (keys) => (dispatch) => {
    dispatch(actions.addKeyRequest());
    const config = {
      method: 'put',
      url: `${API_BASE_URL}/users/profile/keys`,
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.authToken}`,
      },
      data: JSON.stringify({
        keys,
      }),
    };
    return axios(config)
      .then((response) => {
        dispatch(actions.addKeySuccess(response.data));
        // dispatch(loadProfile());
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          dispatch(actions.addKeyError(error.response.data.message));
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          dispatch(
            actions.addKeyError('No response received. Please try again.')
          );
        } else {
          // Something happened in setting up the request that triggered an Error
          dispatch(
            actions.addKeyError('Something happened in setting up the request.')
          );
        }
      });
  },

  deleteKey: (keys) => (dispatch) => {
    dispatch(actions.deleteKeyRequest());
    const config = {
      method: 'delete',
      url: `${API_BASE_URL}/users/profile/keys`,
      headers: {
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.authToken}`,
      },
      data: JSON.stringify({
        keys,
      }),
    };
    return axios(config)
      .then((response) => {
        dispatch(actions.deleteKeySuccess(response.data));
        // dispatch(loadProfile());
      })
      .catch((err) => {
        dispatch(actions.deleteKeyError(err));
      });
  },
  loadStripePlansRequest: () => ({
    type: actions.LOAD_STRIPE_PLANS_REQUEST,
  }),
  loadStripePlansSuccess: (payload) => ({
    type: actions.LOAD_STRIPE_PLANS_SUCCESS,
    payload,
  }),
  loadStripePlansError: (error) => ({
    type: actions.LOAD_STRIPE_PLANS_ERROR,
    error,
  }),
  loadStripePlans: () => (dispatch, getState) => {
    dispatch(loadingActions.showLoading());
    dispatch(actions.loadStripePlansRequest());
    return axios({
      url: `${API_BASE_URL}/payments/plans`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.authToken}`,
      },
    })
      .then((response) => {
        const { data } = response;
        dispatch(loadingActions.hideLoading());
        dispatch(actions.loadStripePlansSuccess(data));
      })
      .catch((err) => {
        dispatch(loadingActions.hideLoading());
        dispatch(actions.loadStripePlansError(err));
      });
  },
  createStripeRequest: () => ({
    type: actions.CREATE_STRIPE_REQUEST,
  }),
  createStripeSuccess: (payload) => ({
    type: actions.CREATE_STRIPE_SUCCESS,
    payload,
  }),
  createStripeError: (error) => ({
    type: actions.CREATE_STRIPE_ERROR,
    error,
  }),
  createStripe: (dailyPlanId, payload) => (dispatch) => {
    dispatch(loadingActions.showLoading());
    dispatch(actions.createStripeRequest());
    console.log(payload);
    const config = {
      method: 'post',
      url: `${API_BASE_URL}/payments/createCustomer`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.authToken}`,
      },
      data: JSON.stringify({
        stripeToken: payload.token.id,
      }),
    };
    return axios(config)
      .then((response) => {
        console.log('response after create ', response);
        const { billingInfo } = response.data;
        const { stripeCustomerId, card } = billingInfo;
        const config = {
          method: 'post',
          url: `${API_BASE_URL}/payments/subscribe`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.authToken}`,
          },
          data: JSON.stringify({
            stripeCustomerId,
            dailyPlanId,
            card,
          }),
        };

        return axios(config);
      })
      .then((response) => {
        const { data } = response;
        console.log(data);
        dispatch(loadingActions.hideLoading());
        dispatch(actions.createStripeSuccess(data));
        dispatch(actions.loadProfile());
      })
      .catch((err) => {
        console.log(err);
        dispatch(loadingActions.hideLoading());
        dispatch(actions.createStripeError('error in create'));
      });
  },
  updateStripeRequest: () => ({
    type: actions.UPDATE_STRIPE_REQUEST,
  }),
  updateStripeSuccess: (payload) => ({
    type: actions.UPDATE_STRIPE_SUCCESS,
    payload,
  }),
  updateStripeError: (error) => ({
    type: actions.UPDATE_STRIPE_ERROR,
    error,
  }),
  updateStripe: (payload) => (dispatch) => {
    dispatch(loadingActions.showLoading());
    dispatch(actions.updateStripeRequest());
    const config = {
      method: 'post',
      url: `${API_BASE_URL}/payments/changePlan`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.authToken}`,
      },
      data: JSON.stringify({
        ...payload,
      }),
    };
    return axios(config)
      .then((response) => {
        const { data } = response;
        dispatch(loadingActions.hideLoading());
        dispatch(actions.updateStripeSuccess(data));
        dispatch(actions.loadProfile());
      })
      .catch((err) => {
        dispatch(loadingActions.hideLoading());
        dispatch(actions.updateStripeError('something went wrong'));
      });
  },
  resetInitialState: () => ({
    type: actions.RESET_INITIAL_STATE,
  }),
};
