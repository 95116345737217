/* eslint-disable react/display-name */
import React from 'react';
import _ from 'lodash';
import URI from 'urijs';
import { Link } from 'react-router-dom';
import { fetchHits } from '../../../hooks/use-algolia';

function extractHash(url) {
  try {
    const parts = URI.parse(url);

    return _.isNil(parts) ? '/' : parts.fragment;
  } catch (e) {
    return '/';
  }
}

function createMarkup(markup) {
  return { __html: markup };
}

function createLink(hit) {
  const { objectUrl } = hit;

  try {
    const { hostname } = URI.parse(objectUrl);

    if (hostname === 'app.cryptosheets.com') {
      // Link is internal
      const hash = extractHash(objectUrl);
      return (
        <Link to={hash}>
          <div
            id="hit-result"
            dangerouslySetInnerHTML={createMarkup(
              hit._highlightResult.title.value
            )}
          />
        </Link>
      );
    }

    return (
      <a href={objectUrl} target="_blank" rel="noopener noreferrer">
        <div
          id="hit-result"
          dangerouslySetInnerHTML={createMarkup(
            hit._highlightResult.title.value
          )}
        />
      </a>
    );
  } catch (e) {
    return '/';
  }
}

function renderTitle(title) {
  const displayedTitle = _.startCase(title);
  return <span>{displayedTitle}</span>;
}

function renderHit(key, hit) {
  return {
    label: createLink(hit),
    value: hit.title,
  };
}

async function renderHits({ key, index, value, algoliaConfig }) {
  const hits = await fetchHits(index, value, algoliaConfig);

  return _.isEmpty(hits)
    ? [{ label: `No results returned.`, value: `${key}_empty` }]
    : hits.map((hit) => renderHit(key, hit));
}

export const options = async (value) => {
  const endpointHits = renderHits({
    key: 'endpoints',
    index: 'CRYPTOSHEETS_SEARCH',
    value,
    algoliaConfig: {
      hitsPerPage: 3,
      facetFilters: [`objectSubtype:endpoint`],
    },
  });

  const providerHits = renderHits({
    key: 'providers',
    index: 'CRYPTOSHEETS_SEARCH',
    value,
    algoliaConfig: {
      hitsPerPage: 3,
      facetFilters: [`objectSubtype:provider`],
    },
  });

  const templateHits = renderHits({
    key: 'templates',
    index: 'CRYPTOSHEETS_SEARCH',
    value,
    algoliaConfig: {
      hitsPerPage: 3,
      facetFilters: [`objectType:template`],
    },
  });

  const chartHits = renderHits({
    key: 'charts',
    index: 'CRYPTOSHEETS_SEARCH',
    value,
    algoliaConfig: {
      hitsPerPage: 3,
      facetFilters: [`objectType:widget`],
    },
  });

  const clusterHits = renderHits({
    key: 'scenarios',
    index: 'CRYPTOSHEETS_SEARCH',
    value,
    algoliaConfig: {
      hitsPerPage: 3,
      facetFilters: [`objectType:scenario`],
    },
  });

  const contentHits = renderHits({
    key: 'content',
    index: 'CRYPTOSHEETS_SEARCH',
    value,
    algoliaConfig: {
      hitsPerPage: 3,
      facetFilters: [`objectType:content`],
    },
  });

  const [
    endpointOptions,
    providerOptions,
    templateOptions,
    chartOptions,
    clusterOptions,
    contentOptions,
  ] = [
    await endpointHits,
    await providerHits,
    await templateHits,
    await chartHits,
    await clusterHits,
    await contentHits,
  ];

  return [
    {
      key: 1,
      label: renderTitle('Endpoints'),
      options: endpointOptions,
    },
    {
      key: 2,
      label: renderTitle('Providers'),
      options: providerOptions,
    },
    {
      key: 3,
      label: renderTitle('Templates'),
      options: templateOptions,
    },
    {
      key: 4,
      label: renderTitle('Charts'),
      options: chartOptions,
    },
    {
      key: 5,
      label: renderTitle('Scenario Clusters'),
      options: clusterOptions,
    },
    {
      key: 6,
      label: renderTitle('Content'),
      options: contentOptions,
    },
    {
      label: renderTitle('View All Results'),
      options: [
        {
          value: 'Endpoints',
          label: (
            <Link to="/browse" style={{ color: 'gray' }}>
              View all Endpoints
            </Link>
          ),
        },
        {
          value: 'Providers',
          label: (
            <Link to="/browse" style={{ color: 'gray' }}>
              View all Providers
            </Link>
          ),
        },
        {
          value: 'Templates',
          label: (
            <Link to="/browse" style={{ color: 'gray' }}>
              View all Templates
            </Link>
          ),
        },
        {
          value: 'Charts',
          label: (
            <Link to="/content/charts" style={{ color: 'gray' }}>
              View all Charts
            </Link>
          ),
        },
        {
          value: 'Scenario Clusters',
          label: (
            <Link to="/content/scenarios" style={{ color: 'gray' }}>
              View all Scenario Clusters
            </Link>
          ),
        },
        {
          value: 'Help Center',
          label: (
            <Link to="/resources" style={{ color: 'gray' }}>
              Visit the Help Center
            </Link>
          ),
        },
      ],
    },
  ];
};
