function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background-color: white;\n  height: 600px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  .contents {\n    /* width: 500px; */\n    height: 400px;\n  }\n\n  .contents .mode {\n    padding: 10px 0;\n  }\n\n  .contents .code {\n    background: #f5f5f5;\n    overflow: auto;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
export var ConsoleWrapper = styled.div(_templateObject());
export var EditorWrapper = styled.div(_templateObject2());