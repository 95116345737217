// These actions are App specific, such as media queries and environments
/* eslint-disable import/prefer-default-export */
import parse from '../util/parse';
import setData from '../util/excel';

export const actions = {
  INITIAL_GLOBAL_SEARCH_REQUEST: 'INITIAL_GLOBAL_SEARCH_REQUEST',
  INITIAL_GLOBAL_SEARCH_SUCCESS: 'INITIAL_GLOBAL_SEARCH_SUCCESS',
  INITIAL_GLOBAL_SEARCH_ERROR: 'INITIAL_GLOBAL_SEARCH_ERROR',
  UPDATE_GLOBAL_SEARCH: 'UPDATE_GLOBAL_SEARCH',
  SCREEN_RESIZE: 'SCREEN_RESIZE',
  SEND_TO_SHEET_REQUEST: 'SEND_TO_SHEET_REQUEST',
  SEND_TO_SHEET_SUCCESS: 'SEND_TO_SHEET_SUCCESS',
  SEND_TO_SHEET_ERROR: 'SEND_TO_SHEET_ERROR',
  RESET_INITIAL_STATE: 'RESET_INITIAL_STATE',
  IS_CACHE_FINISHED: 'IS_CACHE_FINISHED',
  isCacheFinished: (payload) => ({
    type: actions.IS_CACHE_FINISHED,
    payload,
  }),
  initialGlobalSearchRequest: () => ({
    type: actions.INITIAL_GLOBAL_SEARCH_REQUEST,
  }),
  initialGlobalSearchSuccess: (globalSearch) => ({
    type: actions.INITIAL_GLOBAL_SEARCH_SUCCESS,
    globalSearch,
  }),
  initialGlobalSearchError: (error) => ({
    type: actions.INITIAL_GLOBAL_SEARCH_ERROR,
    error,
  }),
  initialGlobalSearch: () => (dispatch) => {},
  updateGlobalSearch: (value, providers, endpoints) => ({
    type: actions.UPDATE_GLOBAL_SEARCH,
    globalSearchValue: value,
    globalSearch: [
      {
        title: 'Providers',
        children: providers,
      },
      {
        title: 'Endpoints',
        children: endpoints,
      },
    ],
  }),
  screenResize: (width) => ({
    type: actions.SCREEN_RESIZE,
    screenWidth: width,
  }),
  sendToSheetRequest: () => ({
    type: actions.SEND_TO_SHEET_REQUEST,
  }),
  sendToSheetSuccess: (data) => ({
    type: actions.SEND_TO_SHEET_SUCCESS,
    data,
  }),
  sendToSheetError: (error) => ({
    type: actions.SEND_TO_SHEET_ERROR,
    error,
  }),
  sendToSheet: (data, host) => (dispatch) => {
    dispatch(actions.sendToSheetRequest());
    const parsed = parse(data);
    if (host === 'sheets') {
      return window.google.script.run
        .withSuccessHandler((sheetData) => console.log('success', sheetData))
        .withFailureHandler((sheetError) =>
          window.google.script.run.failedData()
        )
        .setData(parsed)
        .then(() => dispatch(actions.sendToSheetSuccess()))
        .catch((err) => dispatch(actions.sendToSheetError(err)));
    }
    if (host === 'excel') {
      return setData(parsed);
    }
    return data;
  },
  resetInitialState: () => ({
    type: actions.RESET_INITIAL_STATE,
  }),
};
