import React from 'react';
import Stream from '../../../components/crud/Stream';
import Enums from '../enums';

export default function ParametersTable({ parameters }) {
  const parameterProps = {
    identifier: 'parameterId',
    service: 'parameters',
    data: parameters.map((param) => ({
      ...param,
      key: param.parameterId,
    })),
    expandable: {
      expandedRowRender: ({ parameterId }) => (
        <Enums parameterId={parameterId} />
      ),
      rowExpandable: (record) => record.name !== 'Not Expandable',
    },
    columns: [
      {
        title: 'parameterId',
        dataIndex: 'parameterId',
        key: 'parameterId',
        width: 100,
        fixed: 'left',
        expandable: true,
        expandComponent: <div>testing expand component</div>,
      },
      {
        title: 'name',
        dataIndex: 'name',
        key: 'name',
        width: 100,
        fixed: 'left',
      },
      {
        title: 'description',
        dataIndex: 'description',
        key: 'description',
        width: 150,
      },
      {
        title: 'in',
        dataIndex: 'in',
        key: 'in',
        width: 150,
      },
      {
        title: 'default',
        dataIndex: 'default',
        key: 'default',
        width: 150,
      },
      {
        title: 'required',
        dataIndex: 'required',
        key: 'required',
        width: 150,
      },
      {
        title: 'type',
        dataIndex: 'type',
        key: 'type',
        width: 150,
      },
    ],
  };
  return <Stream {...parameterProps} />;
}
