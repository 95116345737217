import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown, Button, Menu, Tooltip, Typography } from 'antd';

const { Text } = Typography;

const DropOption = ({
  onMenuClick,
  menuOptions = [],
  buttonStyle,
  dropdownProps,
  host,
}) => {
  const menu = menuOptions.map((item) => {
    const disabled = item.excludeHosts && item.excludeHosts.includes(host);
    const tooltip = disabled
      ? `This action cannot be used in the ${host} environment.`
      : item.name;
    return (
      <Menu.Item key={item.key} disabled={disabled}>
        <Tooltip title={tooltip}>
          {item.icon ? (
            <Text disabled={disabled}>
              {item.icon}
              {item.name}
            </Text>
          ) : (
            item.name
          )}
        </Tooltip>
      </Menu.Item>
    );
  });
  return (
    <Dropdown
      overlay={<Menu onClick={onMenuClick}>{menu}</Menu>}
      {...dropdownProps}
    >
      <Button {...buttonStyle}>{buttonStyle.buttonText}</Button>
    </Dropdown>
  );
};

DropOption.propTypes = {
  onMenuClick: PropTypes.func,
  menuOptions: PropTypes.array.isRequired,
  buttonStyle: PropTypes.object,
  dropdownProps: PropTypes.object,
};

const mapStateToProps = (state, props) => ({
  host: state.auth.environment,
  isAdmin: state.users.data && state.users.data.role === 'admin',
});

export default connect(mapStateToProps)(DropOption);
