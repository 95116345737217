import React, { Component } from 'react';
import { PageHeader, Typography } from 'antd';
import ExcelChart from '../../components/widget/components/excelChart';

const { Paragraph } = Typography;

const InsideContent = (props) => (
  <div className="provider-insideContent">
    <Paragraph ellipsis={{ rows: 4, expandable: true }}>
      <b>Quick Start Templates</b> are a great way to get familiar with
      Cryptosheets functions and increase your productivity. They allow you to
      do amazing things with Excel even if you only have a basic understanding
      of spreadsheets. <br />
      <br />
      You can use these templates created by us to help you get started
      significantly faster vs. building and maintaining your own.
    </Paragraph>
  </div>
);

export class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitUrl: '',
    };
  }

  callback = (key) => {
    console.log(key);
  };

  render() {
    return (
      <>
        <PageHeader title="Templates" style={{ marginBottom: 24 }}>
          <div className="provider-wrap">
            <div className="provider-content">
              <InsideContent {...this.props} />
            </div>
          </div>
        </PageHeader>
        <ExcelChart />
      </>
    );
  }
}

export default Template;
