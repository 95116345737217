import React from 'react';
import { Avatar, Typography } from 'antd';
import ApiKey from './ApiKey';
import Http from './Http';
import Multi from './Multi';

const { Paragraph, Title } = Typography;

function DisplayStep(props) {
  const { scheme } = props;

  const { type } = scheme;

  switch (type) {
    case 'apiKey':
      return <ApiKey {...props} />;
    case 'multi':
      return <Multi {...props} />;
    case 'http':
      return <Http {...props} />;
    default:
      return <div>Provider has no auth setup.</div>;
  }
}

export default function Add(props) {
  const { provider } = props;

  const { name, image } = provider;

  return (
    <div>
      <Typography>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <div style={{ paddingRight: '1rem' }}>
            <Avatar src={image} size="large" />{' '}
          </div>
          <Paragraph>
            Connect your add-in to <br />
            <Title level={4}>{name}</Title>
          </Paragraph>
        </div>
        <DisplayStep {...props} />
      </Typography>
    </div>
  );
}
