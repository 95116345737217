import React from 'react';
import { useSelector } from 'react-redux';
import { Input, Typography, Button, Form, message } from 'antd';

const { Paragraph } = Typography;
const { TextArea } = Input;

const key = 'contactStatus';

const displayMessage = ({ isSaving, isFinished, isError }) => {
  if (isSaving) {
    message.loading({
      key,
      content: 'Hang tight, we are sending your message...',
    });
  }

  if (isFinished) {
    message.success({
      key,
      content: 'Thanks for your message. We will contact you shortly.',
    });
  }

  if (isError) {
    message.error({
      key,
      content:
        'We had trouble sending your request. We have been notified of this error.',
    });
  }
};

export function Contact({ sendForm }) {
  const { isSaving, isFinished, isError } = useSelector(
    (state) => state.contact
  );

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue({
      fromEmail: '',
      fromName: '',
      message: '',
    });
    displayMessage({ isSaving, isFinished, isError });
  }, [form, isSaving, isFinished, isError]);

  const onFinish = (values) => {
    const data = {
      action: 'sendContactForm',
      body: values,
    };
    return sendForm(data);
  };

  return (
    <Form form={form} onFinish={onFinish} className="login-form">
      <h3>Contact Us</h3>
      <Paragraph>We would love to hear from you.</Paragraph>
      <Form.Item
        label="E-mail"
        name="fromEmail"
        rules={[
          {
            type: 'email',
            message: 'The input is not a valid E-mail.',
          },
          {
            required: true,
            message: 'Please input your E-mail',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Name"
        name="fromName"
        rules={[
          {
            required: true,
            message: 'Please input your name.',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Message"
        name="message"
        rules={[
          {
            required: true,
            message: 'Please input your message.',
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Send Message
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Contact;
