/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Input, Table } from 'antd';
import { sortingComparators } from '@cryptosheets/util';
import { useRequest } from '@umijs/hooks';
import DropOption from './DropOption';
import feathers from '../../../feathers';
import {
  insertTemplateIntoWorkbookById,
  openNewWorkbookByTemplateId,
} from '../actions';

const fetchTemplates = async () => {
  const $sort = {};

  const query = {
    $sort,
    $limit: 1000,
    $select: [
      'name',
      'created_at',
      'updated_at',
      'version',
      'author',
      'description',
      'category',
      'templateId',
      'type',
      'tags',
    ],
  };
  const res = await feathers.service('templates').find({
    query,
  });

  return res.data;
};

const handleMenuClick = (record, e) => {
  if (e.key === '1') {
    insertTemplateIntoWorkbookById(record.templateId);
  } else if (e.key === '2') {
    openNewWorkbookByTemplateId(record.templateId);
  }
};

function FormTable() {
  const { data, error, loading } = useRequest(fetchTemplates);

  const [dataSource, setDataSource] = useState();
  const [value, setValue] = useState('');

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  if (error) {
    return <div>Error :(</div>;
  }

  const FilterByNameInput = (
    <Input
      placeholder="Search Name"
      value={value}
      onChange={(e) => {
        const currValue = _.toUpper(e.target.value);
        setValue(currValue);
        const filteredData = data.filter((entry) =>
          _.toUpper(entry.name).includes(currValue)
        );
        setDataSource(filteredData);
      }}
    />
  );

  const columns = [
    {
      title: FilterByNameInput,
      dataIndex: 'name',
      key: 'name',
      sorter: sortingComparators('name').string,
    },
    {
      title: 'TemplateId',
      dataIndex: 'templateId',
      key: 'templateId',
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
      key: 'updated_at',
    },
    {
      title: 'Operation',
      key: 'operation',
      fixed: 'right',
      render: (text, record) => (
        <DropOption
          onMenuClick={(e) => handleMenuClick(record, e)}
          menuOptions={[
            { key: '1', name: 'Insert into Current' },
            { key: '2', name: 'New Workbook' },
          ]}
        />
      ),
    },
  ];
  return (
    <div>
      <Table
        simple
        bordered
        scroll={{ x: 1200 }}
        columns={columns}
        loading={loading}
        dataSource={dataSource}
        rowKey={(record) => record.templateId}
        pagination={{ position: ['topLeft', 'bottomRight'], pageSize: 20 }}
      />
    </div>
  );
}

export default FormTable;
