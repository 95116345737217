export const config = {
  charts: {
    indexProps: {
      searchParameters: {
        filters: 'objectType:widget',
      },
      indexName: 'CRYPTOSHEETS_SEARCH',
    },
    pageProps: {
      title: 'Charts',
      description:
        'Learn how to leverage the Cryptosheets Charting Engine to visualize complex data.',
    },
  },
  scenarios: {
    indexProps: {
      searchParameters: {
        filters: 'objectType:scenario',
      },
      indexName: 'CRYPTOSHEETS_SEARCH',
    },
    pageProps: {
      title: 'Scenario Clusters',
      description: 'Crypto market scenarios & data clusters.',
    },
  },
  default: {
    indexProps: {
      searchParameters: {},
      indexName: 'CRYPTOSHEETS_SEARCH',
    },
    pageProps: {
      title: 'Content',
      description:
        'Search all available content from the Cryptosheets database.',
    },
  },
};
