/* eslint-disable no-nested-ternary */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Avatar, List } from 'antd';
import { EllipsisOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import DropOption from './DropOption';
import Highlight from './AlgoliaHighlight';
import { openInfoWidget } from './api';

const Description = ({ item }) => (
  <>
    <span className="object-description">
      Type: {_.capitalize(item.objectType)} >{' '}
      {/* Type: {_.capitalize(item.objectType)} | Category:{' '} */}
      {_.capitalize(item.objectSubtype)} <br /> Source:{' '}
      {_.capitalize(item.source)}
    </span>
    <br />
  </>
);

function AlgoliaRow(props) {
  // Item is all of the data coming from the hit
  const { item, widgetOptions } = props;
  console.log(widgetOptions);
  const dispatch = useDispatch();

  const ADD_ITEM = (item) => {
    const data = {
      ...item,
      widgetType: widgetOptions.infoComponent || 'widget',
      widgetData: item,
    };
    return dispatch({ type: 'WORKSPACE_ADD_ITEM', item: data });
  };
  const UPDATE_ITEM = (item, data) => {
    const newItem = _.merge({}, item, data);
    return dispatch({ type: 'WORKSPACE_UPDATE_ITEM', item: newItem });
  };

  const image = item.thumbnailUrl || item.image || null;
  const title = !_.isNil(item.title)
    ? 'title'
    : !_.isNil(item.parameter)
    ? 'parameter'
    : 'name';

  // Needs to be widget type data, not hit data
  const menuClickProps = {
    item,
    addItem: ADD_ITEM,
    updateItem: UPDATE_ITEM,
    ...props,
  };
  const defaultActions = {
    key: 'noAction',
    name: 'No Actions Available',
    icon: <QuestionCircleOutlined />,
    action: () => {},
  };
  const actions = _.get(widgetOptions, 'actions', [defaultActions]);
  const dropOptionProps = {
    onMenuClick: (e) => openInfoWidget(e, actions, menuClickProps),
    menuOptions: [...actions],
    buttonStyle: {
      buttonText: '',
      icon: <EllipsisOutlined />,
      type: 'link',
    },
  };
  return (
    <List.Item actions={[<DropOption {...dropOptionProps} />]}>
      <List.Item.Meta
        avatar={<Avatar src={image} shape="square" border="red" />}
        title={<Highlight hit={item} attribute={title} />}
        description={<Description {...props} />}
      />
    </List.Item>
  );
}

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return _.isEqual(prevProps.item.objectID, nextProps.item.objectID);
}

// export default AlgoliaRow;
export default React.memo(AlgoliaRow, areEqual);
