import { unicornCase } from '@cryptosheets/util';
import { message } from 'antd';

const messageConfig = {
  info: {
    key: 'createIntegration',
    content: 'Hang tight, we are creating your integration...',
  },
  success: {
    key: 'createIntegration',
    content: "Success! Check the 'Your Integrations' tab.",
  },
  error: {
    key: 'createIntegration',
    content:
      'We had trouble creating your integration. Contact support@cryptosheets.com for help.',
  },
};

function createKey(services, data) {
  return services.keys.create(data);
}

export async function createIntegration({
  next,
  services,
  provider,
  validateStep,
  isDeepIntegration,
  submitData,
}) {
  validateStep(true);

  if (!isDeepIntegration) {
    await createKey(services, submitData);
    validateStep(false);
    return next();
  }

  const { name } = provider;
  const sanitizedProvider = unicornCase(name);

  const service = services[`integrations/${sanitizedProvider}/setup`];

  if (!service) {
    message.error('Provider is not yet integrated.');
    return null;
  }

  return service
    .create({
      ...submitData,
      provider: name,
    })
    .then((res) => {
      message.success(messageConfig.success);
      validateStep(false);
      next();
      return res;
    })
    .catch((e) => {
      message.error(messageConfig.error);
      return null;
    });
}
