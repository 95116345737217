/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import URI from 'urijs';
import { Typography } from 'antd';
import { connectHighlight } from 'react-instantsearch-dom';
import styled from 'styled-components';

import './AlgoliaHits.less';

const { Text } = Typography;

function extractHash(url) {
  try {
    const parts = URI.parse(url);

    return _.isNil(parts) ? '/' : parts.fragment;
  } catch (e) {
    return '/';
  }
}

function LinkComponent({ appLink, hit, children }) {
  if (_.isNil(appLink)) {
    // We were unable to extract an internal link, let's try and external link
    if (_.isNil(hit.objectUrl)) {
      // No link of any sort exists, just default back to home
      return <Link to="/">{children}</Link>;
    }
    // We have an objectUrl, let's jump off to the external link
    return (
      <a href={hit.objectUrl} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    );
  }
  // We have a proper app link
  return <Link to={appLink}>{children}</Link>;
}

const Highlight = ({ highlight, attribute, hit }) => {
  // Turning attributes into human-readable before parse
  hit.name = _.startCase(hit.name);
  hit.title = _.startCase(hit.title);

  const appLink = hit.appLink || extractHash(hit.objectUrl) || null;

  const linkComponentProps = {
    appLink,
    hit,
  };

  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  return (
    <LinkComponent {...linkComponentProps}>
      {parsedHit.map((part, index) =>
        part.isHighlighted ? (
          // <mark key={index}>{part.value}</mark>
          <Text mark key={index}>
            {part.value}
          </Text>
        ) : (
          <span key={index}>{part.value}</span>
        )
      )}
    </LinkComponent>
  );
};

const CustomHighlight = connectHighlight(Highlight);

const WrappedCustomHighlight = styled(CustomHighlight)`
  color: rgba(0, 0, 0, 0.65);
  font-size: 0.85em;
  padding: 0;
`;

export default WrappedCustomHighlight;
