/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import _ from 'lodash';
import { connect, useSelector, useDispatch } from 'react-redux';
import { CloseOutlined, SettingOutlined } from '@ant-design/icons';
import { Tooltip, Popover } from 'antd';
import CustomSearchBox from './AlgoliaSearch';
import WidgetSelect from '../../../pages/workspace/components/WidgetSelect';
import { getPlan } from '../../../pages/account/reducer';

const WidgetPanel = (props) => {
  const { i } = props;
  const { currentBreakpoint, items } = useSelector((state) => state.workspace);
  const currentItems = items[currentBreakpoint];
  const widget = _.find(currentItems, (item) => item.i === i);
  const dispatch = useDispatch();

  const REMOVE_COMPONENT = () => dispatch({ type: 'WORKSPACE_REMOVE_ITEM', i });

  const widgetSelectProps = {
    item: widget,
    searchLocation: 'widgetPanel',
  };
  return (
    <div
      className="drag-handle"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CustomSearchBox />
      <Popover
        title="Settings"
        trigger="click"
        content={[<WidgetSelect {...widgetSelectProps} />]}
      >
        <SettingOutlined style={{ zIndex: 1 }} />
      </Popover>
      <Tooltip title="Close">
        <CloseOutlined onClick={REMOVE_COMPONENT} />
      </Tooltip>
    </div>
  );
};

const mapStateToProps = (state) => {
  const planId = state.account.dailyPlanId;
  return {
    host: state.auth.environment,
    currentPlan: getPlan(state, planId),
  };
};

export default connect(mapStateToProps)(WidgetPanel);
