/* eslint-disable react/prefer-stateless-function */
import React, { useState } from 'react';
import { useDebounceFn } from '@umijs/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { connectSearchBox } from 'react-instantsearch-dom';

function SearchBox({ refine, delay }) {
  const { searchValue } = useSelector((state) => state.browse);
  const dispatch = useDispatch();

  const [value, setValue] = useState(searchValue);

  useDebounceFn(
    () => {
      dispatch({ type: 'BROWSE_SET_SEARCH', searchValue: value });
      refine(value);
    },
    [value],
    500
  );

  return (
    <Input
      allowClear
      prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
      value={value}
      placeholder="Search"
      size="large"
      onChange={(e) => setValue(e.currentTarget.value)}
      style={{ maxWidth: 340 }}
    />
  );
}

export default connectSearchBox(SearchBox);
