import algoliasearch from 'algoliasearch';

// export const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;
export const API_BASE_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://data.cryptosheets.com/api'
    : 'http://localhost:8080/api';

// export const API_URL = process.env.REACT_APP_API_BASE_ENDPOINT;
export const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://data.cryptosheets.com'
    : 'http://localhost:8080';

export const DOCS_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://docs.cryptosheets.com'
    : 'http://localhost:3001';

export const STREAMS_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://cryptosheets-stream-prod.azurewebsites.net'
    : 'http://localhost:9001';

export const FUNCTIONS_API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://data.cryptosheets.com'
    : 'http://localhost:8080';

export const FUNCTIONS_STREAMS_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://stream-prod.herokuapp.com'
    : 'http://localhost:9001';

export const INTERCOM_APP_ID = 'w1ppxcjb';

export const MOESIF_APP_ID =
  'eyJhcHAiOiI1NjU6MjQzIiwidmVyIjoiMi4wIiwib3JnIjoiMTc3OjMxOSIsImlhdCI6MTU4MTM3OTIwMH0.NKRNNxZM5aaQi9imYHWmHC9WdX_3U0YrknTuk94pOv0';

export const GA_TRACKING_ID = 'UA-116965527-2';

export const STRIPE_PUBLISHABLE =
  process.env.NODE_ENV === 'production'
    ? 'pk_live_PwWSsAoKjGRnrtu2ArsuxG0a'
    : 'pk_test_rZZED0eRRQ8vwfPI6lRZpw6c';

export const AlgoliaSearchConfig = {
  appId: 'D10YB09DLL',
  apiKey: '677642b4bf739d9efc7e46d7750243de',
};

export const ParadigmAlgoliaSearchConfig = {
  appId: 'D10YB09DLL',
  apiKey: '677642b4bf739d9efc7e46d7750243de',
  indexName: 'ENDPOINTS',
};

export const ALGOLIA_CLIENT = algoliasearch(
  ParadigmAlgoliaSearchConfig.appId,
  ParadigmAlgoliaSearchConfig.apiKey
);

export const ALGOLIA_INDICES = [
  'PROVIDERS',
  'ENDPOINTS',
  'CRYPTOSHEETS_SEARCH',
];

// Logos and assets
export const LOGO_BLACK =
  'https://res.cloudinary.com/cryptosheets/image/upload/v1580322699/icons/cryptosheets/Logomark_-_White_j53x7b.svg';

export const LOGO_WHITE =
  'https://res.cloudinary.com/cryptosheets/image/upload/v1564412059/logo_white.svg';

export const LOGOMARK_WHITE =
  'https://res.cloudinary.com/cryptosheets/image/upload/v1564412059/logomark_white.svg';

export const LOGO_ALTERNATE_WHITE =
  'https://res.cloudinary.com/cryptosheets/image/upload/v1564412059/logo_alternate_white.svg';

export const LOGO_GREEN = `https://res.cloudinary.com/cryptosheets/image/upload/v1578279804/cryptosheets-website/logos/cryptosheets_logo_green_e5132x.svg`;

export const CLIENT_TO_STREAM = process.env.REACT_APP_CLIENT_TO_STREAM;
export const IS_LOCKED_DEFAULT = process.env.REACT_APP_IS_LOCKED_DEFAULT;
