/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Markdown } from '@cryptosheets/components';
import AppMarkdown from './paradigm.md';

export class Template extends Component {
  constructor() {
    super();
    this.state = { markdown: '' };
  }

  componentDidMount() {
    // Get the contents from the Markdown file and put them in the React state, so we can reference it in render() below.
    fetch(AppMarkdown)
      .then((res) => res.text())
      .then((text) => this.setState({ markdown: text }));
  }

  render() {
    const { markdown } = this.state;
    return (
      <>
        <Markdown markdown={markdown} />
      </>
    );
  }
}

export default connect()(Template);
