import React from 'react';
import { Statistic, Row, Col, Typography } from 'antd';
import { useRequest } from '@umijs/hooks';
import { useSelector } from 'react-redux';
import moment from 'moment';
import helpers from './_helpers';

const { Title, Text } = Typography;

function QuotaItem({ item, totalQuota }) {
  const { quota, quotaRemaining, periodStart, periodEnd } = item;

  const prettyPeriodStart = moment(periodStart).format('MMMM Do YYYY');
  const prettyPeriodEnd = moment(periodEnd).format('MMMM Do YYYY');

  return (
    <>
      <Col xxl={4} xl={5} lg={6} md={24} sm={24} xs={24}>
        <Statistic title="Requests This Period" value={quota} />
      </Col>
      <Col xxl={4} xl={5} lg={6} md={24} sm={24} xs={24}>
        <Statistic title="Requests Remaining" value={quotaRemaining} />
      </Col>
      <Col xxl={4} xl={5} lg={6} md={24} sm={24} xs={24}>
        <Statistic title="Start Date" value={prettyPeriodStart} />
      </Col>
      <Col xxl={4} xl={5} lg={6} md={24} sm={24} xs={24}>
        <Statistic title="End Date" value={prettyPeriodEnd} />
      </Col>
    </>
  );
}

function Error() {
  return (
    <>
      <Title level={4}>Your Usage</Title>
      <Row gutter={16}>
        <Text type="secondary">You have not made any requests yet.</Text>
      </Row>
    </>
  );
}

export default function Quota() {
  const { fetchQuota } = helpers();

  const { data: currentUser } = useSelector((state) => state.users);
  const { data: quota, isError } = useSelector((state) => state.quota);

  const { loading } = useRequest(() => fetchQuota(currentUser._id));

  if (loading) {
    return <div>loading</div>;
  }

  if (isError && isError.code === 404) {
    return <Error />;
  }

  if (isError) {
    return <Error />;
  }

  if (!quota) {
    return <Error />;
  }
  return (
    <>
      <Title level={4}>Your Usage</Title>
      <Row gutter={16}>
        {quota.counters.map((item) => (
          <QuotaItem item={item} totalQuota={item.quotaTotal} error={isError} />
        ))}
      </Row>
    </>
  );
}
