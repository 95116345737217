import React, { Component } from 'react';
import { Button } from 'antd';

export default class LongText extends Component {
  constructor(props) {
    super(props);
    this.state = { showAll: false };
  }

  showMore() {
    this.setState({ showAll: true });
  }

  showLess() {
    this.setState({ showAll: false });
  }

  render() {
    const { content, limit } = this.props;
    const { showAll } = this.state;
    if (content.length <= limit) {
      // there is nothing more to show
      return <div className="text-sm">{content}</div>;
    }
    if (showAll) {
      // We show the extended text and a link to reduce it
      return (
        <div className="text-sm">
          {content}
          <Button type="link" onClick={() => this.showLess()}>
            Read less
          </Button>
        </div>
      );
    }
    // In the final case, we show a text with ellipsis and a `Read more` button
    const toShow = `${content.substring(0, limit)}...`;
    return (
      <div className="text-sm">
        {toShow}
        <Button type="link" onClick={() => this.showMore()}>
          Read more
        </Button>
      </div>
    );
  }
}

LongText.defaultProps = {
  content: '',
};
