import React from 'react';
import { Empty } from 'antd';
import WidgetSelect from './widgetSelect';

export default function EmptyWidget(props) {
  return (
    <Empty
      image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
      imageStyle={{
        height: 60,
      }}
      description={<span>Choose widget:</span>}
    >
      <WidgetSelect {...props} />
    </Empty>
  );
}
