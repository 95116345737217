export const CF_LOGS_ROOT = 'cf_logs#';

export const loadAuthToken = () => localStorage.getItem('authToken');
export const loadRefreshInterval = () =>
  localStorage.getItem('refreshInterval');

export const saveAuthToken = (authToken) => {
  try {
    localStorage.setItem('authToken', authToken);
  } catch (e) {
    console.log(e);
  }
};

export const saveRefreshInterval = (refreshInterval) => {
  try {
    localStorage.setItem('refreshInterval', refreshInterval);
  } catch (e) {
    console.log(e);
  }
};

export const clearAuthToken = () => {
  try {
    localStorage.removeItem('authToken');
  } catch (e) {
    console.log(e);
  }
};

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // Ignore write errors.
  }
};

export function getAllLocalStorageKeys() {
  const keys = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key) {
      keys.push(key);
    }
  }
}

export function getAllLocalStorageKeysWithRoot(root) {
  return getAllLocalStorageKeys().filter((key) => key.startsWith(root));
}

export function getAllItemsWithRoot(root) {
  return getAllLocalStorageKeysWithRoot(root)
    .map((key) => localStorage.getItem(key))
    .map((item) => JSON.parse(item));
}

export function removeAllItemsWithRoot(root) {
  getAllLocalStorageKeysWithRoot(root).forEach((key) =>
    localStorage.removeItem(key)
  );
}

export const getCustomFunctionsFromLocalStorage = () => {
  const logs = getAllItemsWithRoot(CF_LOGS_ROOT);
  removeAllItemsWithRoot(CF_LOGS_ROOT);
  return logs;
};
