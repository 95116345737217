import { useSelector } from 'react-redux';
import _ from 'lodash';

export const useIsAdmin = () => {
  const user = useSelector((state) => state.users.data);

  const role = _.get(user, ['role'], null);

  return role === 'admin';
};
