import React from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import Humanize from 'humanize-plus';
import { Elements } from 'react-stripe-elements';
import { useSelector } from 'react-redux';
import CardForm from './CardForm';

const currentDate = moment().format('dddd, MMMM Do YYYY, h:mm:ss a');

export default function UpgradeModal({
  plan,
  visible,
  setVisible,
  setNewPlan,
}) {
  const screenWidth = useSelector((state) => state.app.screenWidth);
  const currentSubscription = useSelector(
    (state) => state.stripeSubscription.data
  );
  const currentSubscriptionItem = useSelector(
    (state) => state.stripeSubscriptionItem.data
  );

  const { id, nickname, amount } = plan;

  const INTERVAL = nickname.includes('YEARLY') ? 'year' : 'month';

  return (
    <Modal
      title="Upgrade Account"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={null}
    >
      <div className="Checkout">
        <h1>Upgrade Plan</h1>
        <p style={{ marginBottom: 10 }}>
          Your first payment will be charged today, {currentDate}.{' '}
          <span style={{ fontWeight: 'bold' }}>
            You are upgrading to the {nickname} plan for $
            {Humanize.formatNumber(amount / 100)}/{INTERVAL}.
          </span>{' '}
        </p>
        <Elements>
          <CardForm
            fontSize={screenWidth < 450 ? '14px' : '18px'}
            newPlanId={id}
            setVisible={setVisible}
            setNewPlan={setNewPlan}
            currentSubscription={currentSubscription}
            currentSubscriptionItem={currentSubscriptionItem}
          />
        </Elements>
      </div>
    </Modal>
  );
}
