import React from 'react';
import { Markdown } from '@cryptosheets/components';
import { Typography } from 'antd';
import ApiKeyInput from './ApiKeyInput';

const { Paragraph } = Typography;

export default function Multi(props) {
  const { submitData } = props;
  const { data } = props.scheme;

  const { description } = data[0];

  const globalSubmit = props.handleSubmitData;

  const addItem = (datum) => {
    const globalSubmitData = {
      ...props.submitData,
      name: 'multi',
      in: 'query',
    };
    const { multiValue } = globalSubmitData;

    const isExists = multiValue.find((d) => d.name === datum.name);

    if (!isExists) {
      const newGlobalSubmitData = {
        ...globalSubmitData,
        multiValue: [...multiValue, datum],
      };
      return globalSubmit(newGlobalSubmitData);
    }
    // Exists, replace
    const filteredMulti = multiValue.filter((d) => d.name !== datum.name);
    const newGlobalSubmitData = {
      ...globalSubmitData,
      multiValue: [...filteredMulti, datum],
    };
    return globalSubmit(newGlobalSubmitData);
  };

  return (
    <>
      <Paragraph>
        <Markdown markdown={description} />
      </Paragraph>
      <br />
      <Paragraph>
        {data.map((schemeDetails) => {
          const inputProps = {
            ...props,
            submitData,
            handleSubmitData: addItem,
            scheme: schemeDetails,
          };
          return <ApiKeyInput {...inputProps} />;
        })}
      </Paragraph>
    </>
  );
}
