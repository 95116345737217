import React from 'react';
import _ from 'lodash';
import { useRequest } from '@umijs/hooks';
import { LoadingOutlined } from '@ant-design/icons';
import { Alert, Result, Typography } from 'antd';
import { useServices } from '../../../../../hooks/use-services';
import { createIntegration } from './_helpers';

import './Test.css';

const { Text } = Typography;

export default function Test(props) {
  const services = useServices();

  const { provider, scheme, validateStep, submitData, next, isMobile } = props;

  const isDeep = _.reduce(
    scheme.data,
    (result, value) => {
      const { isDeepIntegration } = value;

      return isDeepIntegration === 1;
    },
    false
  );

  const { data, loading, error } = useRequest(() => {
    const config = {
      next,
      services,
      provider,
      submitData,
      validateStep,
      isDeepIntegration: isDeep,
    };

    return createIntegration(config);
  });

  if (loading) {
    return (
      <div className="">
        <Typography>
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
            }}
          />
          <Result
            icon={<LoadingOutlined />}
            title="Hang Tight, we are testing your integration..."
            extra={<Text>This should only take a few moments...</Text>}
          />
        </Typography>
      </div>
    );
  }

  if (error || _.isNil(data)) {
    return (
      <div className={`integration-test ${isMobile ? 'is-mobile' : null}`}>
        <Result
          status="warning"
          title="Integration Failed"
          subTitle={
            <Alert
              message="Error"
              description={`${provider.name} did not accept your API key credentials. You can check your token at ${provider.link}`}
              type="error"
              showIcon
            />
          }
        />
      </div>
    );
  }

  return <div>success</div>;
}
