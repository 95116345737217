function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background: transparent;\n\n  .ant-collapse-item {\n    transition: all 0.5s ease;\n    margin-bottom: 5px;\n  }\n\n  .ant-collapse-item:hover {\n    /* box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);\n    -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);\n    border-color: transparent;\n    position: relative;\n    z-index: 10; */\n  }\n  .ant-collapse-item .ant-collapse-header:hover {\n    background-color: #d3eadc;\n  }\n\n  .ant-collapse-item > .ant-collapse-header {\n    background-color: white;\n    position: relative;\n    padding: 4px 16px;\n    padding-left: 40px;\n    color: rgba(0, 0, 0, 0.85);\n    line-height: 1.5715;\n    cursor: pointer;\n    -webkit-transition: all 0.3s;\n    transition: all 0.3s;\n  }\n  .ant-collapse-content {\n    background-color: transparent;\n  }\n  .ant-collapse-content > .ant-collapse-content-box {\n    padding: 0px;\n  }\n\n  #console-collapse-custom .ant-collapse-content-box {\n    padding: 0;\n  }\n\n  .ant-collapse-extra {\n    display: flex;\n    align-items: center;\n    fle\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  flex-grow: 1;\n  flex-shrink: 0;\n  font-size: 13px;\n  color: #4b4b4b;\n  white-space: nowrap;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import { Collapse } from 'antd';
import styled from 'styled-components';
export var ParametersWrapper = styled.div(_templateObject());
export var PanelTitle = styled.span(_templateObject2());
export var StyledCollapse = styled(Collapse)(_templateObject3());