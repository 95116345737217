/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Input } from 'antd';
import { options } from './config';

import './header-search.less';

class HeaderSearch extends Component {
  static defaultProps = {
    defaultActiveFirstOption: false,
    className: '',
    placeholder: '',
    defaultOpen: false,
    options: [],
    onPressEnter: () => {},
    onSearch: () => {},
    onChange: () => {},
    onVisibleChange: () => {},
  };

  static getDerivedStateFromProps(props) {
    if ('open' in props) {
      return {
        searchMode: props.open,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      searchMode: props.defaultOpen,
      value: this.props.currentRefinement,
      options: props.options,
    };
    this.debouncePressEnter = this.debouncePressEnter(
      this.debouncePressEnter,
      500,
      {
        leading: true,
        trailing: false,
      }
    );
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown, true);

    // return this.fetchOptions().then((options) => this.setState({ options }));
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  fetchOptions = async (value = '') => {
    const newOptions = await options(value);

    return newOptions;
  };

  onKeyDown = (e) => {
    if (e.keyCode === 191 && e.target.tagName.toUpperCase() !== 'INPUT') {
      e.preventDefault();
      this.enterSearchMode();
    }
    if (e.key === 'Enter') {
      const { onPressEnter } = this.props;
      const { value } = this.state;
      this.timeout = setTimeout(() => {
        onPressEnter(value);
      }, 0);
    }
  };

  onChange = (value) => {
    const { onSearch, onChange } = this.props;
    this.setState({ value });
    this.props.refine(value);
    if (onSearch) {
      onSearch(value);
    }
    if (onChange) {
      onChange(value);
    }

    return this.fetchOptions(value).then((options) =>
      this.setState({ options })
    );
  };

  enterSearchMode = () => {
    const { onVisibleChange } = this.props;
    onVisibleChange(true);
    this.setState({ searchMode: true }, () => {
      const { searchMode } = this.state;
      if (searchMode && this.inputRef) {
        this.inputRef.focus();
      }
    });
  };

  leaveSearchMode = () => {
    this.setState({
      searchMode: false,
      value: '',
    });
  };

  debouncePressEnter = () => {
    const { onPressEnter } = this.props;
    const { value } = this.state;
    onPressEnter(value);
  };

  render() {
    const { className, placeholder, open, ...rest } = this.props;
    const { searchMode, value } = this.state;

    delete rest.defaultOpen;
    const inputClass = `input ${searchMode ? 'show' : ''}`;

    return (
      <span
        className={`${className} headerSearch`}
        onClick={this.enterSearchMode}
        onKeyPress={this.enterSearchMode}
        role="button"
        onTransitionEnd={({ propertyName }) => {
          if (propertyName === 'width' && !searchMode) {
            const { onVisibleChange } = this.props;
            onVisibleChange(searchMode);
          }
        }}
      >
        <SearchOutlined key="Icon" />
        <AutoComplete
          key="AutoComplete"
          className={inputClass}
          dropdownClassName="certain-category-search-dropdown"
          options={this.state.options}
          placeholder="Try typing 'price'"
          value={value}
          onChange={this.onChange}
        >
          <Input
            ref={(node) => {
              this.inputRef = node;
            }}
            aria-label={placeholder}
            placeholder={placeholder}
            onKeyDown={this.onKeyDown}
            onBlur={this.leaveSearchMode}
          />
        </AutoComplete>
      </span>
    );
  }
}

export default connectAutoComplete(HeaderSearch);
