import React from 'react';
import PropTypes from 'prop-types';
import { InstantSearch, Index, Configure } from 'react-instantsearch-dom';
import { ParadigmAlgoliaSearchConfig, ALGOLIA_CLIENT } from '../../../config';

const { indexName } = ParadigmAlgoliaSearchConfig;

/**
 * Wraps any component and providers multi-index hits results as props.
 */
const WrapAutocomplete = ({ children, indices, searchParameters }) => (
  <InstantSearch searchClient={ALGOLIA_CLIENT} indexName={indexName}>
    <Configure {...searchParameters} />
    {children}
    {indices.map((index) => (
      <Index key={index} indexName={index} />
    ))}
  </InstantSearch>
);

WrapAutocomplete.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  /**
   * Array of index names from Algolia
   */
  indices: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Number of hits to return */
  hitsPerPage: PropTypes.number,
  searchParameters: PropTypes.object,
};

WrapAutocomplete.defaultProps = {
  hitsPerPage: 10,
};

export default WrapAutocomplete;
export { WrapAutocomplete };
