/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */

const uniqueKey = '_|_';

const isObject = (val) =>
  val === null
    ? false
    : Array.isArray(val)
    ? false
    : typeof val === 'function' || typeof val === 'object';

const isPrim = (val) => {
  const type = typeof val;
  return !!(
    type === 'number' ||
    type === 'string' ||
    type === 'boolean' ||
    type == 'null'
  );
};

const isArray = (val) => Array.isArray(val);

// Will parse an object or array into a flattened object
const parseObject = (obj, path) => {
  if (path == undefined) {
    path = '';
  }

  if (isObject(obj)) {
    const d = {};
    for (const i in obj) {
      // For values in object
      const newD = parseObject(obj[i], `${path + i}${uniqueKey}`); // index if array, value if object
      Object.assign(d, newD);
    }
    return d;
  }
  if (isPrim(obj)) {
    const d = {};
    const endPath = path.substr(0, path.length - 3);
    d[endPath] = obj;
    return d;
  }

  return {};
};

// Find the first array in an object, otherwise turn object into array
const arrayFrom = (json) => {
  const queue = [];
  let next = json;

  while (next !== undefined) {
    if (isArray(next)) {
      if (next.length > 0) {
        if (!isPrim(next[0])) return next;
      }
    }
    if (isObject(next)) {
      // queue = Object.keys(next).reduce((a, cv) => {
      //   a.push(next[cv]);
      //   return a;
      // }, []);
      for (const key in next) {
        queue.push(next[key]);
      }
    }
    next = queue.shift();
  }
  // nothing found, consider the whole object a row
  return [json];
};

// Applies a function against an accumulator and each key in the object (from left to right).
// const transform = (obj, fn, acc) => Object.keys(obj).reduce((a, k) => fn(a, obj[k], k, obj), acc);

// Returns all the elements of an array except the last one.
const initial = (arr) => arr.slice(0, -1);

// Returns all unique values of an array.
const uniqueElements = (arr) => [...new Set(arr)];

// Get size of arrays, objects or strings.
const size = (val) =>
  Array.isArray(val)
    ? val.length
    : val && typeof val === 'object'
    ? val.size || val.length || Object.keys(val).length
    : typeof val === 'string'
    ? new Blob([val]).size
    : 0;

const uniqueProperties = (obj) => {
  const objProperties = Object.keys(obj);
  const mappedProperties = objProperties.map((objP) => {
    const split = objP.split(uniqueKey);
    const targets = initial(split).join(uniqueKey);
    // return uniqueElements(targets);
    return targets;
  });
  return uniqueElements(mappedProperties);
};

// 1. Find the primary array to iterate over
// 2. For each item in the array, recursively flatten it into tabular object
// 3. Turn tabular object into a csv row (use a library for this)
const doCSV = (json) => {
  const inArray = arrayFrom(json);

  const outArray = [];
  for (const row in inArray) {
    outArray[outArray.length] = parseObject(inArray[row]);
  }
  const arraySize = size(outArray);
  // Now we check if the json had a bad parse
  // If array is less than 2, it may contain a huge object
  if (arraySize < 2) {
    // Check size of the resulting object
    const objSize = size(outArray[0]);
    if (objSize > 25) {
      const newObj = outArray[0];
      const uProp = uniqueProperties(newObj);
      const modifiedArray = uProp.reduce((a, b) => {
        // Filter items out of newObj
        const filtered = Object.keys(newObj)
          .filter((key) => key.includes(b))
          .reduce((obj, key) => {
            const nk = key.split(uniqueKey);
            const lastKey = nk[nk.length - 1];
            obj[lastKey] = newObj[key];
            return obj;
          }, {});
        const pushedObj = { target: b, ...filtered };
        a.push(pushedObj);
        return a;
      }, []);
      return doCSV(modifiedArray);
    }
  }

  return outArray;
};

export default doCSV;
