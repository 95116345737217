import _ from 'lodash';

function changeComponentById(layout, i, widgetType) {
  // First, we find the item in the layout
  const foundItem = layout.find((layoutItem) => layoutItem.i === i);

  if (_.isNil(foundItem)) {
    // Item not found, skip
    return layout;
  }
  // Item found, merge
  const mergedItem = _.merge(foundItem, { widgetType });
  return _.merge(layout, mergedItem);
}

function changeComponentInAllBreakpoints(layouts, i, widgetType) {
  return Object.keys(layouts).reduce((acc, currBreakpoint) => {
    // For every breakpoint, we change the component
    const newLayout = changeComponentById(
      layouts[currBreakpoint],
      i,
      widgetType
    );

    acc[currBreakpoint] = newLayout;
    return acc;
  }, {});
}

function filterWidgets(data, host, isAdmin) {
  return data.reduce((acc, currVal) => {
    // 1. Check for admin
    if (currVal.admin) {
      if (!isAdmin) {
        return acc;
      }
    }

    // 2. Check for environments
    if (!_.isEmpty(currVal.excludeHosts)) {
      const { excludeHosts } = currVal;
      if (_.includes(excludeHosts, host)) {
        return acc;
      }
    }

    // 3. Check visibility
    if (currVal.visible === false) {
      return acc;
    }
    acc.push(currVal);
    return acc;
  }, []);
}

export { changeComponentById, changeComponentInAllBreakpoints, filterWidgets };
