/* eslint-disable react/prefer-stateless-function */
import _ from 'lodash';
import URI from 'urijs';

export function extractHash(url) {
  try {
    const parts = URI.parse(url);

    return _.isNil(parts) ? '/' : parts.fragment;
  } catch (e) {
    return '/';
  }
}

export function extractAppLink(hit) {
  const appLink =
    hit.appLink || extractHash(hit.objectUrl) || hit.objectUrl || null;

  return appLink;
}
