import React, { useState } from 'react';
import { Typography } from 'antd';
import Deep from './Deep';
import ApiKeyInput from './ApiKeyInput';

const { Paragraph } = Typography;

export default function ApiKey({ scheme, ...rest }) {
  const { name, next, handleSubmitData } = rest;

  const [hasCredentials, setHasCredentials] = useState(false);

  const { isDeepIntegration } = scheme.data[0];

  const inputProps = {
    ...rest,
    scheme: scheme.data[0],
  };

  const deepProps = {
    name,
    next,
    hasCredentials,
    setHasCredentials,
    handleSubmitData,
  };

  return (
    <>
      {isDeepIntegration === 1 && <Deep {...deepProps} />}
      {isDeepIntegration !== 1 && (
        <Paragraph>
          Fetching {name} data requires a {name} subscription. When you have
          your API Key, enter the API key in the input below.
        </Paragraph>
      )}
      <br />
      {(hasCredentials || isDeepIntegration !== 1) && (
        <Paragraph>
          <ApiKeyInput {...inputProps} />
        </Paragraph>
      )}
    </>
  );
}
