/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'antd';
import { getView } from './api';
// import CustomSearchBox from '../../components/algolia/searchBox';
import CustomSearchBox from './SearchBox';
import Sidebar from './SearchSidebar';
import Content from './SearchContent';

export default function SearchPage(props) {
  const { hitsPerPage, onShowSizeChange } = props;

  const { screenWidth, breakpoint } = useSelector((state) => state.app);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: 'SCREEN_RESIZE', screenWidth });
  }, [dispatch, screenWidth]);

  const [collapsed, setCollapsed] = useState(true);
  const className = collapsed ? '' : 'sidebarOpen';
  const mobileOrDesktop = getView(breakpoint);
  return (
    <div className={`algoliaSearchPageWrapper ${className} searchPage`}>
      {mobileOrDesktop === 'MobileView' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className="searchBox"
        >
          <CustomSearchBox delay={100} />
          <Button
            type="primary"
            // className="sidebarToggle"
            onClick={() => setCollapsed(!collapsed)}
          >
            {collapsed ? 'Filter' : 'Hide'}
          </Button>
        </div>
      )}
      <div className="isoAlgoliaMainWrapper">
        <Sidebar collapsed={collapsed} setCollapsed={setCollapsed} />
        <Content
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          hitsPerPage={hitsPerPage}
          onShowSizeChange={onShowSizeChange}
        />
      </div>
    </div>
  );
}
