import React from 'react';
import { useSelector } from 'react-redux';
import { Input, Typography, Button, Form, message } from 'antd';

const { Paragraph } = Typography;

const key = 'forgotStatus';

const displayMessage = ({ isSaving, isFinished, isError }) => {
  if (isSaving) {
    message.loading({
      key,
      content: 'Hang tight...',
    });
  }

  if (isFinished) {
    message.success({
      key,
      content: 'Please check your e-mail for password reset instructions.',
    });
  }

  if (isError) {
    message.error({
      key,
      content: `We had trouble sending your request. Reason: ${
        isError && isError.message ? isError.message : 'Unknown'
      }`,
    });
  }
};

export function Forgot({ forgot }) {
  const { isSaving, isFinished, isError } = useSelector(
    (state) => state.authManagement
  );

  const [form] = Form.useForm();

  React.useEffect(() => {
    form.setFieldsValue({
      email: '',
    });
    displayMessage({ isSaving, isFinished, isError });
  }, [form, isSaving, isFinished, isError]);

  const onFinish = (values) => {
    const data = {
      action: 'sendResetPwd',
      value: values,
      notifierOptions: { preferredComm: 'email' },
    };
    return forgot(data);
  };

  return (
    <Form form={form} onFinish={onFinish} className="login-form">
      <h3>Forgot Password</h3>
      <Paragraph>
        Enter your email address below and we will send you password reset
        instructions.
      </Paragraph>
      <Form.Item
        label="E-mail"
        name="email"
        rules={[
          {
            type: 'email',
            message: 'The input is not a valid E-mail.',
          },
          {
            required: true,
            message: 'Please input your E-mail',
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Reset Password
        </Button>
      </Form.Item>
    </Form>
  );
}

export default Forgot;
