// Throttling is a straightforward reduction of the trigger rate.
// It will cause the event listener to ignore some portion of the events
// while still firing the listeners at a constant (but reduced) rate.
function throttled(delay, fn) {
  let lastCall = 0;
  return function (...args) {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    return fn(...args);
  };
}

// Unlike throttling, debouncing is a technique of keeping
// the trigger rate at exactly 0 until a period of calm, and
// then triggering the listener exactly once.
function debounced(delay, fn) {
  let timerId;
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }
    timerId = setTimeout(() => {
      fn(...args);
      timerId = null;
    }, delay);
  };
}

export default {
  throttled,
  debounced,
};
