/* eslint-disable react/display-name */
import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import IntegrationMultiForm from './IntegrationMultiForm';

import helpers from './_helpers';

export default function IntegrationCardExtra({
  id,
  provider,
  type,
  name,
  value,
  isEditable,
  active,
  multiValue,
  services,
}) {
  const { deleteKey } = helpers(services);

  const currentUser = useSelector((state) => state.users.data);
  const { screenWidth } = useSelector((state) => state.app);
  const isMobile = screenWidth < 391;

  const editProps = {
    id,
    provider,
    type,
    name,
    value,
    isEditable,
    active,
    multiValue,
  };

  const config = [
    {
      key: 'editKey',
      type: 'Edit',
      icon: <EditOutlined />,
      onClick: (e) => {
        e.stopPropagation();
        console.log(id, currentUser._id);
      },
      component: ({ action }) => (
        <IntegrationMultiForm {...editProps} action={action} />
      ),
    },
    {
      key: 'deleteKey',
      type: 'Delete',
      icon: <DeleteOutlined />,
      onClick: (e) => {
        e.stopPropagation();
        return deleteKey(id, currentUser._id);
      },
      component: ({ action }) => (
        <div
          style={{ marginLeft: '1em' }}
          onClick={action.onClick}
          onKeyDown={action.onClick}
          role="button"
          tabIndex="0"
        >
          {action.icon} {!isMobile ? action.type : null}
        </div>
      ),
    },
  ];
  return (
    <div style={{ display: 'flex' }}>
      {config.map(({ component: Component, ...rest }) => (
        <Component action={rest} />
      ))}
    </div>
  );
}
