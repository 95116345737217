/* eslint-disable no-template-curly-in-string */
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const key = 'loginStatus';

const validateMessages = {
  required: '${label} is required',
  types: {
    email: '${label} is not a valid email',
  },
};

const displayMessage = ({ isSaving, isFinished, isError }) => {
  if (isSaving) {
    message.loading({
      key,
      content: 'Hang tight...',
    });
  }

  if (isFinished) {
    message.success({
      key,
      content: 'Success! Welcome back, friend.',
    });
  }

  if (isError) {
    message.error({
      key,
      content: `We had trouble logging you in. Reason: ${
        isError && isError.message ? isError.message : 'Unknown'
      }`,
    });
  }
};

export const Login = ({ login, loggedIn, redirect }) => {
  const { isSaving, isFinished, isError } = useSelector((state) => state.users);
  const loading = useSelector((state) => state.loading.show);

  const [form] = Form.useForm();

  React.useEffect(() => {
    displayMessage({ isSaving, isFinished, isError });
  }, [form, isSaving, isFinished, isError]);

  if (loggedIn) {
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return <Redirect to="/" />;
  }

  const onFinish = (values) => login(values);

  return (
    <Form
      form={form}
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      validateMessages={validateMessages}
      onFinish={onFinish}
    >
      <h3>Login</h3>
      <Form.Item
        name="email"
        rules={[
          { type: 'email', required: true, message: 'Email is required.' },
        ]}
      >
        <Input
          placeholder="Email: satoshi@cryptosheets.com"
          prefix={<UserOutlined />}
          size="large"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
          size="large"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Link className="login-form-forgot" to="/forgot">
          Forgot Password?
        </Link>
      </Form.Item>

      <Form.Item>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          className="login-form-button"
        >
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Login;
