import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Form, Input, Checkbox, notification, Result } from 'antd';
import { LoadingOutlined, LockOutlined } from '@ant-design/icons';
import { useRequest } from '@umijs/hooks';
import { IS_LOCKED_DEFAULT } from '../config';
import client from '../feathers';

async function verifyEmail(token) {
  const authmanagement = client.service('authManagement');
  const data = {
    action: 'verifySignupLong',
    value: token,
  };
  return authmanagement.create(data);
}

function openNotification({ status, message, description }) {
  return notification[status]({ key: 'update-password', message, description });
}

function UpdatePassword({ user, login }) {
  const { email } = user;
  const onFinish = async (values) => {
    const authmanagement = client.service('authManagement');
    const { password } = values;
    const data = {
      action: 'passwordChange',
      value: {
        user: { email },
        oldPassword: IS_LOCKED_DEFAULT,
        password,
      },
    };

    return authmanagement
      .create(data)
      .then(() => login({ email, password }))
      .then(() =>
        openNotification({
          status: 'success',
          message: 'Success!',
          description: 'You are now free to start using Cryptosheets.',
        })
      )
      .catch((e) =>
        openNotification({
          status: 'warning',
          message: 'Unable to update password',
          description: 'Contact support@cryptosheets.com for assistance.',
        })
      );
  };

  return (
    <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={({ values, errorFields }) =>
        console.log({ values, errorFields })
      }
    >
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: 'Please input your Password!',
            validateTrigger: 'onFinish',
          },
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                'The two passwords that you entered do not match!'
              );
            },
          }),
        ]}
      >
        <Input.Password
          placeholder="Confirm Password"
          prefix={<LockOutlined className="site-form-item-icon" />}
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Remember me</Checkbox>
        </Form.Item>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Create Password
        </Button>
      </Form.Item>
    </Form>
  );
}

export default function Verify(props) {
  const { token } = props.match.params;
  const { loading, error, data: user } = useRequest(() => verifyEmail(token));

  if (loading) {
    return (
      <Result
        icon={<LoadingOutlined />}
        title="Hang tight, we are updating your profile..."
      />
    );
  }

  if (error) {
    return (
      <Result
        status="warning"
        title="We had trouble verifying your email. Contact support@cryptosheets.com for assistance."
      />
    );
  }

  if (user.isLocked) {
    // User likely came from partner landing page...ask for password
    return (
      <Result
        status="success"
        title="Success! Let's make it official."
        subTitle="Finalize your profile with a password."
        extra={<UpdatePassword user={user} login={props.login} />}
      />
    );
  }

  return (
    <Result
      status="success"
      title="Successfully Verified Email!"
      subTitle="You are now free to start using Cryptosheets."
      extra={[
        <Link to="/">
          <Button type="primary" key="console">
            Go Home
          </Button>
        </Link>,
      ]}
    />
  );
}
