import React from 'react';
import _ from 'lodash';
import { Typography, Divider } from 'antd';
import IntegratedPopover from './IntegratedPopover';
import List from './List';
import useProviders from '../../../../hooks/use-providers';

import './index.less';

const { Title, Paragraph } = Typography;

export default function AllIntegrations({ selectKey }) {
  const { loading, error, data } = useProviders();

  const integratedProps = {
    loading,
    error,
    data: _.filter(data, 'integrated'),
    selectKey,
  };
  const notIntegratedProps = {
    loading,
    error,
    data: _.filter(data, (o, i) => !o.integrated && i !== 79), // TODO: 0xTracker is a problem child
  };
  return (
    <div className="card-list">
      <Typography>
        <Title level={3}>
          <IntegratedPopover />
          Integrated
        </Title>
        <Paragraph>
          Integrated providers have completed a thorough review of integrated
          data, and have been meticulously implemented into our engine.
        </Paragraph>
        <List {...integratedProps} />
        <Divider />
        <List {...notIntegratedProps} />
      </Typography>
    </div>
  );
}
