import Papa from 'papaparse';
import doCSV from './flatten';

const parse = (response) => {
  const data = response;
  const readyJson = doCSV(data);
  // const readyJson = api.jsonFlatten(data);
  const unparse = Papa.unparse(readyJson);
  const parsed = Papa.parse(unparse, { dynamicTyping: true });

  return parsed.data;
};

export default parse;
