import React, { PureComponent } from 'react';
import Highlighter from 'react-highlight-words';
import _ from 'lodash';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Popconfirm, Table, message } from 'antd';
import { getCurrentFile } from '@cryptosheets/util';
import base64js from 'base64-js';

import { useRequest } from '@umijs/hooks';
import config from './config';
import styles from './style.less';
import feathers from '../../feathers';

const { isEqual } = _;
const { split } = String.prototype;

/**
 * The `Array#sort` comparator to produce a
 * [natural sort order](https://en.wikipedia.org/wiki/Natural_sort_order).
 *
 * @memberOf util
 * @param {*} value The value to compare.
 * @param {*} other The other value to compare.
 * @returns {number} Returns the sort order indicator for `value`.
 */
function compareNatural(value, other) {
  let index = -1;
  const valParts = split.call(value, '.');
  const valLength = valParts.length;
  const othParts = split.call(other, '.');
  const othLength = othParts.length;
  const length = Math.min(valLength, othLength);

  while (++index < length) {
    const valPart = valParts[index];
    const othPart = othParts[index];

    if (valPart > othPart && othPart !== 'prototype') {
      return 1;
    }
    if (valPart < othPart && valPart !== 'prototype') {
      return -1;
    }
  }
  return valLength > othLength ? 1 : valLength < othLength ? -1 : 0;
}

class TableForm extends PureComponent {
  static getDerivedStateFromProps(nextProps, preState) {
    if (isEqual(nextProps.value, preState.value)) {
      return null;
    }
    return {
      data: nextProps.value,
      value: nextProps.value,
    };
  }

  clickedCancel = false;

  index = 0;

  cacheOriginData = {};

  constructor(props) {
    super(props);
    this.state = {
      data: props.value,
      loading: false,
      value: props.value,
      searchText: '',
      searchedColumn: '',
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  getRowByKey(key, newData) {
    const { data = [] } = this.state;
    return (newData || data).filter((item) => item.key === key)[0];
  }

  toggleEditable = (e, key) => {
    e.preventDefault();
    const { data = [] } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      if (!target.editable) {
        this.cacheOriginData[key] = { ...target };
      }
      target.editable = !target.editable;
      this.setState({ data: newData });
    }
  };

  newMember = () => {
    const { data = [] } = this.state;
    const newData = data.map((item) => ({ ...item }));
    newData.unshift({
      key: `NEW_TEMP_ID_${this.index}`,
      name: '',
      version: '',
      editable: true,
      isNew: true,
    });
    this.index += 1;
    this.setState({ data: newData });
  };

  remove(key) {
    const { data = [] } = this.state;
    const { onDelete } = this.props;
    const newData = data.filter((item) => item.key !== key);
    this.setState({ data: newData });
    if (onDelete) {
      onDelete(this.props.service, { key });
    }
  }

  handleKeyPress(e, key) {
    if (e.key === 'Enter') {
      this.saveRow(e, key);
    }
  }

  handleFieldChange(e, fieldName, key) {
    const { data = [] } = this.state;
    const newData = [...data];
    const target = this.getRowByKey(key, newData);
    if (target) {
      target[fieldName] = e.target.value;

      this.setState({ data: newData });
    }
  }

  saveRow(e, key) {
    e.persist();
    this.setState({
      loading: true,
    });

    if (this.clickedCancel) {
      this.clickedCancel = false;
      return;
    }

    const target = this.getRowByKey(key) || {};

    if (!target.name || !target.version) {
      message.error('Missing required fields');
      e.target.focus();
      this.setState({
        loading: false,
      });
      return;
    }

    delete target.isNew;

    this.toggleEditable(e, key);

    const { onChange } = this.props;

    if (onChange) {
      onChange(this.props.service, target);
    }
    this.setState({
      loading: false,
    });
  }

  cancel(e, key) {
    this.clickedCancel = true;
    e.preventDefault();
    const { data = [] } = this.state;
    const newData = [...data];
    const sanitizedData = newData.map((item) => {
      if (item.key === key) {
        if (this.cacheOriginData[key]) {
          delete this.cacheOriginData[key];
          const returnObj = {
            ...item,
            ...this.cacheOriginData[key],
            editable: false,
          };
          return returnObj;
        }
      }
      return item;
    });

    this.setState({ data: sanitizedData });
    this.clickedCancel = false;
  }

  onError(error) {
    this.setState({ loading: false });
    message.error('Error: File size too large.');
  }

  async onSuccess(byteArray, record, fieldName) {
    this.setState({ loading: false });

    console.log('Received the full contents of the file.');
    const base64string = base64js.fromByteArray(byteArray);
    const fileLength = base64js.byteLength(base64string);

    this.handleFieldChange(
      { target: { value: base64string } },
      fieldName,
      record.key
    );

    message.success('File contents successfully loaded.');

    this.handleFieldChange(
      { target: { value: fileLength } },
      'fileLength',
      record.key
    );

    // return base64string;
  }

  render() {
    const { loading, data } = this.state;

    const columns = [
      {
        title: 'name',
        dataIndex: 'name',
        key: 'name',
        ...this.getColumnSearchProps('name'),
        sorter: (a, b) => compareNatural(a.name, b.name),
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                autoFocus
                onChange={(e) => this.handleFieldChange(e, 'name', record.key)}
                onKeyPress={(e) => this.handleKeyPress(e, record.key)}
                placeholder="name"
              />
            );
          }
          return text;
        },
      },
      {
        title: 'description',
        dataIndex: 'description',
        key: 'description',
        ...this.getColumnSearchProps('description'),
        sorter: (a, b) => compareNatural(a.name, b.name),
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                autoFocus
                onChange={(e) =>
                  this.handleFieldChange(e, 'description', record.key)
                }
                onKeyPress={(e) => this.handleKeyPress(e, record.key)}
                placeholder="description"
              />
            );
          }
          return text;
        },
      },
      {
        title: 'templateId',
        dataIndex: 'key',
        key: 'key',
        sorter: (a, b) => compareNatural(a.templateId, b.templateId),
        ...this.getColumnSearchProps('templateId'),
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={(e) => this.handleFieldChange(e, 'key', record.key)}
                onKeyPress={(e) => this.handleKeyPress(e, record.key)}
                placeholder="test"
              />
            );
          }
          return text;
        },
      },
      {
        title: 'version',
        dataIndex: 'version',
        key: 'version',
        ...this.getColumnSearchProps('version'),
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={(e) =>
                  this.handleFieldChange(e, 'version', record.key)
                }
                onKeyPress={(e) => this.handleKeyPress(e, record.key)}
                placeholder="version"
              />
            );
          }
          return text;
        },
      },
      {
        title: 'visible',
        dataIndex: 'visible',
        key: 'visible',
        ...this.getColumnSearchProps('visible'),
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={(e) =>
                  this.handleFieldChange(e, 'visible', record.key)
                }
                onKeyPress={(e) => this.handleKeyPress(e, record.key)}
                placeholder="visible"
              />
            );
          }
          return text;
        },
      },
      {
        title: 'tab',
        dataIndex: 'tab',
        key: 'tab',
        ...this.getColumnSearchProps('tab'),
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={(e) => this.handleFieldChange(e, 'tab', record.key)}
                onKeyPress={(e) => this.handleKeyPress(e, record.key)}
                placeholder="tab"
              />
            );
          }
          return text;
        },
      },
      {
        title: 'category',
        dataIndex: 'category',
        key: 'category',
        ...this.getColumnSearchProps('category'),
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={(e) =>
                  this.handleFieldChange(e, 'category', record.key)
                }
                onKeyPress={(e) => this.handleKeyPress(e, record.key)}
                placeholder="version"
              />
            );
          }
          return text;
        },
      },
      {
        title: 'type',
        dataIndex: 'type',
        key: 'type',
        ...this.getColumnSearchProps('type'),
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={(e) => this.handleFieldChange(e, 'type', record.key)}
                onKeyPress={(e) => this.handleKeyPress(e, record.key)}
                placeholder="version"
              />
            );
          }
          return text;
        },
      },
      {
        title: 'tags',
        dataIndex: 'tags',
        key: 'tags',
        ...this.getColumnSearchProps('tags'),
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={(e) => this.handleFieldChange(e, 'tags', record.key)}
                onKeyPress={(e) => this.handleKeyPress(e, record.key)}
                placeholder="version"
              />
            );
          }
          return text;
        },
      },
      {
        title: 'created_at',
        dataIndex: 'created_at',
        key: 'created_at',
        ...this.getColumnSearchProps('created_at'),
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={(e) =>
                  this.handleFieldChange(e, 'created_at', record.key)
                }
                onKeyPress={(e) => this.handleKeyPress(e, record.key)}
                placeholder="version"
              />
            );
          }
          return text;
        },
      },
      {
        title: 'updated_at',
        dataIndex: 'updated_at',
        key: 'updated_at',
        ...this.getColumnSearchProps('updated_at'),
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                onChange={(e) =>
                  this.handleFieldChange(e, 'updated_at', record.key)
                }
                onKeyPress={(e) => this.handleKeyPress(e, record.key)}
                placeholder="version"
              />
            );
          }
          return text;
        },
      },
      {
        title: 'action',
        key: 'action',
        fixed: 'right',
        width: 100,
        render: (text, record) => {
          const { loading } = this.state;
          if (!!record.editable && loading) {
            return null;
          }
          if (record.editable) {
            if (record.isNew) {
              return (
                <span>
                  <Button
                    type="link"
                    onClick={(e) => {
                      this.setState({ loading: true });
                      // const MAX_FILE_SIZE = 1003538;
                      const MAX_FILE_SIZE = 4500000;

                      getCurrentFile(
                        (byteArray) =>
                          this.onSuccess(byteArray, record, 'template'),
                        this.onError,
                        MAX_FILE_SIZE
                      );
                    }}
                  >
                    Load
                  </Button>
                  <Divider type="vertical" />
                  <Button
                    type="link"
                    onClick={(e) => this.saveRow(e, record.key)}
                  >
                    Add
                  </Button>
                  <Divider type="vertical" />
                  <Popconfirm
                    title="Are you sure you want to delete?"
                    onConfirm={() => this.remove(record.key)}
                  >
                    <Button type="link">Delete</Button>
                  </Popconfirm>
                </span>
              );
            }
            return (
              <span>
                <Button
                  onClick={(e) => {
                    this.setState({ loading: true });
                    // const MAX_FILE_SIZE = 1003538;
                    const MAX_FILE_SIZE = 4500000;

                    getCurrentFile(
                      (byteArray) =>
                        this.onSuccess(byteArray, record, 'template'),
                      this.onError,
                      MAX_FILE_SIZE
                    );
                  }}
                >
                  Load
                </Button>
                <Divider type="vertical" />
                <Button onClick={(e) => this.saveRow(e, record.key)}>
                  Save
                </Button>
                <Divider type="vertical" />
                <Button onClick={(e) => this.cancel(e, record.key)}>
                  Cancel
                </Button>
              </span>
            );
          }
          return (
            <span>
              <Button
                type="link"
                onClick={(e) => this.toggleEditable(e, record.key)}
              >
                Edit
              </Button>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => this.remove(record.key)}
              >
                <Button type="link">Delete</Button>
              </Popconfirm>
            </span>
          );
        },
      },
    ];

    return (
      <>
        <Button
          style={{ width: '100%', marginTop: 16, marginBottom: 8 }}
          type="dashed"
          onClick={this.newMember}
          icon={<PlusOutlined />}
        >
          Add new item
        </Button>
        <Table
          bordered
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={{ position: ['topLeft', 'bottomRight'], pageSize: 20 }}
          scroll={{ x: 1200 }}
          size="small"
          rowClassName={(record) => (record.editable ? styles.editable : '')}
        />
      </>
    );
  }
}

function WrapWithService({ service }) {
  const { methods } = config;
  const { query } = config[service];

  const { loading, error, data } = useRequest(() =>
    feathers
      .service(service)
      .find({ query })
      .then((response) => response.data)
  );

  if (loading) {
    return <div>loading...</div>;
  }

  if (error) {
    return <div>Error :(</div>;
  }

  const mappedData = data.map((d) => {
    const key = { key: d.templateId[0] };

    return _.merge(d, key);
  });

  if (_.isEmpty(mappedData)) {
    return <div>mapping</div>;
  }

  return <TableForm {...methods} service={service} value={mappedData} />;
}

export default WrapWithService;
