import React from 'react';
import EmptyWidget from '../panel/empty';
import options from '../constants';

function Dynamic({ widgetType = 'emptyWidget', ...rest }) {
  console.log(widgetType);
  let DynamicComponent = options.find((o) => o.widgetType === widgetType);
  if (DynamicComponent === undefined) {
    DynamicComponent = EmptyWidget;
  } else {
    DynamicComponent = DynamicComponent.component;
  }
  return <DynamicComponent {...rest} />;
}

export default Dynamic;
