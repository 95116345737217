/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Card } from 'antd';
import Hits from './Hits';

export default function DefaultList(props) {
  return (
    <Card title={props.activeKey}>
      <Hits {...props} pagination={false} />
    </Card>
  );
}
