import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Post from '../../components/post';
import Changelog from './components/Changelog';
import { useServices } from '../../hooks/use-services';

export default function Resources(props) {
  const [slug, setSlug] = useState('/resources/');
  const services = useServices();

  const resource = _.get(
    props,
    ['computedMatch', 'params', 'resource'],
    'resources'
  );

  useEffect(() => {
    async function getPosts() {
      if (resource !== 'resources') {
        setSlug(`/resources/${resource}/`);
      }
      await services['content/posts'].find({
        query: { slug },
      });
    }
    getPosts();
  }, [resource, services, slug]);

  if (slug === '/resources/changelog/') {
    return <Changelog />;
  }

  return <Post slug={slug} />;
}
