/* eslint-disable import/prefer-default-export */
export const PATHS = {
  ParadigmTest: '/paradigmTest',
  Contact: '/contact',
  Forgot: '/forgot',
  Home: '/',
  Login: '/login',
  Register: '/register',
  ResendVerify: '/resend',
  Search: '/search',
  Mosaic: '/mosaic',
  Verify: '/verify/:token',
  Reset: '/reset/:token',
};
