/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Card } from 'antd';

const { Meta } = Card;

const gridStyle = {
  textAlign: 'center',
};

const defaultImage =
  'https://res.cloudinary.com/cryptosheets/image/upload/v1564412059/Logomark_-_White.png';

const ProviderList = (props) => {
  const { hits } = props;
  return (
    <Card title="Providers">
      <div className="ais-Hits">
        {hits.map((hit) => {
          const displayedImage = hit.image || hit.thumbnailUrl || defaultImage;
          return (
            <Card.Grid
              id="providerCard"
              style={gridStyle}
              hoverable="true"
              className="providerCard"
              key={hit.providerId}
            >
              <Link to={`/browse/${hit.name}`}>
                <Avatar size={64} src={displayedImage} />
                <Meta title={hit.name} description={hit.description} />
              </Link>
            </Card.Grid>
          );
        })}
      </div>
    </Card>
  );
};

export default ProviderList;
