import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import resourceData from '../../components/widget/constants';

export function getActions(resourceName) {
  const widgetOptions = _.find(resourceData, {
    name: _.capitalize(resourceName),
  });
  const defaultActions = [
    {
      key: 'noAction',
      name: 'No Actions Available',
      icon: <QuestionCircleOutlined />,
      action: () => {},
    },
  ];
  return _.get(widgetOptions, 'actions', defaultActions);
}

export const getView = (breakpoint) => {
  let newView = 'MobileView';
  if (breakpoint === 'lg' || breakpoint === 'xl') {
    newView = 'DesktopView';
  } else if (breakpoint === 'md') {
    newView = 'TabView';
  }
  return newView;
};
