import React, { Component } from 'react';
import Iframe from 'react-iframe';
import { AutoComplete, Input } from 'antd';

const dataSource = [
  { label: 'Cryptosheets - Docs', value: 'https://docs.cryptosheets.com' },

  {
    label: 'Cryptosheets - Pricing',
    value: 'https://cryptosheets.com/pricing',
  },
  {
    label: 'Paradigm - API Console',
    value: 'https://docs.paradigmapi.com/widgets/console?endpoint=q',
  },

  {
    label: 'Charts - Daily Bitcoin Transactions',
    value:
      'https://embed.theblockcrypto.com/data/on-chain-metrics/bitcoin/transactions-on-the-bitcoin-network-daily/embed',
  },

  {
    label: 'Charts - GBTC Holdings of BTC',
    value:
      'https://embed.theblockcrypto.com/data/crypto-markets/grayscale/bitcoin-holdings-of-grayscale-bitcoin-trust/embed',
  },
  {
    label: 'Charts - CME (Net) Bitcoin Futures Positions by Trader Category',
    value:
      'https://embed.theblockcrypto.com/data/crypto-markets/futures/net-positions-of-cme-bitcoin-futures-by-trader-category-weekly/embed',
  },
  {
    label: 'Charts - BTC Change in Exchange Balances',
    value:
      'https://embed.theblockcrypto.com/data/on-chain-metrics/cryptocurrency-exchanges/change-in-usd-exchange-balances-daily-7dma/embed',
  },
  {
    label: 'Research - The Block',
    value: 'https://www.theblockcrypto.com',
  },
  {
    label: 'Analytics - IntoTheBlock',
    value: 'https://app.intotheblock.com/coin/BTC',
  },
  {
    label: 'Resources - Crypto Domains',
    value: 'https://cryptodomaingroup.com',
  },
];

export default class iFrameDisplay extends Component {
  state = {
    url: null,
  };

  handlePressEnter(e) {
    console.log(e.target.value);
    e.preventDefault();
    this.setState({ url: e.target.value });
  }

  setUrl(url) {
    console.log(url);
    this.setState({ url });
  }

  render() {
    const { widgetWidth, widgetHeight } = this.props;
    const { url } = this.state;
    return (
      <>
        {!url ? (
          <AutoComplete
            options={dataSource}
            placeholder="Type a url, or select from the drop down"
            filterOption
            onSelect={(url) => this.setUrl(url)}
          >
            <Input onPressEnter={(e) => this.handlePressEnter(e)} />
          </AutoComplete>
        ) : (
          <Iframe
            url={url}
            width={widgetWidth}
            height={widgetHeight}
            overflow="auto"
            id={url}
            //   className="myClassname"
            display="initial"
            position="relative"
          />
        )}
      </>
    );
  }
}
