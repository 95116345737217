import { useRequest } from '@umijs/hooks';
import client from '../feathers';

function fetchProviders() {
  return client
    .service('providers')
    .find({
      query: {
        visible: true,
        $limit: 1000,
      },
    })
    .then(({ data }) => data);
}

export default () => {
  const { loading, error, data } = useRequest(() => fetchProviders());

  return { loading, error, data };
};
