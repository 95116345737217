import React from 'react';
import { Switch, Tooltip } from 'antd';
import helpers from './_helpers';

export default function ToggleActive({ id, active, disabled, services }) {
  const { toggleKeyActive } = helpers(services);

  return (
    <Tooltip title="Toggle integration on/off">
      <Switch
        disabled={disabled}
        checkedChildren="Active"
        unCheckedChildren="Inactive"
        defaultChecked={active}
        onClick={(checked, event) => {
          // If you don't want click extra trigger collapse, you can prevent this:
          event.stopPropagation();
          toggleKeyActive(id, checked);
        }}
      />
    </Tooltip>
  );
}
