function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }

function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import React, { useState, useEffect } from 'react';
import unified from 'unified';
import markdownRenderer from 'remark-parse';
import raw from 'rehype-raw';
import remark2rehype from 'remark-rehype';
import rehype2react from 'rehype-react';
import defaultComponents from './defaultComponents';

function MarkdownConvert(_ref) {
  var {
    markdown,
    components = {}
  } = _ref;
  var [md, setMd] = useState(null);
  var [cmpt, setCmpt] = useState(_objectSpread(_objectSpread({}, defaultComponents), components));
  useEffect(() => {
    function compileMd() {
      return _compileMd.apply(this, arguments);
    }

    function _compileMd() {
      _compileMd = _asyncToGenerator(function* () {
        var compiled = unified().use(markdownRenderer) // Place any remark plugins here
        // .use(slug)
        // .use(github, { repository: 'rehypejs/rehype-react' })
        .use(remark2rehype, {
          allowDangerousHtml: true
        }) // .use(toc)
        .use(raw) // Place any rehype plugins here
        // .use(highlight)
        .use(rehype2react, {
          createElement: React.createElement,
          components: cmpt
        }).processSync(markdown).result;
        setMd(compiled);
      });
      return _compileMd.apply(this, arguments);
    }

    compileMd();
  }, [cmpt, markdown]);
  return md;
}

export default MarkdownConvert;