/* eslint-disable no-nested-ternary */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import _ from 'lodash';
import { Avatar, List } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import DropOption from './DropOption';
import Highlight from './Highlight';
import config from './config';
import { handleMenuClick } from './_helpers';

const Description = ({ item }) => (
  <>
    <span className="object-description">
      Type: {_.capitalize(item.objectType)} >{' '}
      {/* Type: {_.capitalize(item.objectType)} | Category:{' '} */}
      {_.capitalize(item.objectSubtype)} <br /> Source:{' '}
      {_.capitalize(item.source)}
    </span>
    <br />
  </>
);

function AlgoliaRow(props) {
  // Item is all of the data coming from the hit
  const history = useHistory();
  const { item } = props;

  const { image, objectType, parameter, thumbnailUrl, title } = item;

  const display = thumbnailUrl || image || null;
  const name = !_.isNil(title)
    ? 'title'
    : !_.isNil(parameter)
    ? 'parameter'
    : 'name';

  const menuData = {
    item,
    history,
  };

  const actions = _.has(config, objectType)
    ? config[objectType]
    : config.default;
  const dropOptionProps = {
    onMenuClick: (e) => handleMenuClick(e, actions, menuData),
    menuOptions: [...actions],
    buttonStyle: {
      buttonText: '',
      icon: <EllipsisOutlined />,
      type: 'link',
    },
  };
  return (
    <List.Item actions={[<DropOption {...dropOptionProps} />]}>
      <List.Item.Meta
        avatar={<Avatar src={display} shape="square" border="red" />}
        title={<Highlight hit={item} attribute={name} />}
        description={<Description {...props} />}
      />
    </List.Item>
  );
}

function areEqual(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */
  return _.isEqual(prevProps.item.objectID, nextProps.item.objectID);
}

// export default AlgoliaRow;
export default React.memo(AlgoliaRow, areEqual);
