import React from 'react';
import { Input, Form, Button, message } from 'antd';

export default function Reset({ currentUser, update }) {
  const { email } = currentUser;
  const initialValues = {
    email,
  };

  if (currentUser.profile) {
    initialValues.name = currentUser.profile.name;
  }

  const onFinish = (values) => {
    message.success('Account updated.');
    return update(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="baseView">
      <div className="left">
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          hideRequiredMark
          initialValues={initialValues}
        >
          <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: 'Please input your e-mail.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              { required: true, message: 'Please input your full name.' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="personalProfile"
            label="Personal profile"
            rules={[
              {
                required: false,
                message: 'Please enter a few words about yourself.',
              },
            ]}
          >
            <Input.TextArea
              placeholder="Brief introduction - what makes you tick?"
              rows={4}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Update Information
          </Button>
        </Form>
      </div>
    </div>
  );
}
