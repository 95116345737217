import React from 'react';
import { Card, Input } from 'antd';
import { connectHits, connectSearchBox } from 'react-instantsearch-dom';
import AlgoliaHits from '../../components/widget/components/AlgoliaHits';

const { Search } = Input;

const CustomSearch = ({ refine }) => (
  <Search
    placeholder="Search other charts..."
    className=""
    onChange={(e) => refine(e.target.value)}
  />
);

const ConnectedSearch = connectSearchBox(CustomSearch);

function AllResource({ hits, title }) {
  const algoliaHitsProps = {
    hits,
  };

  return (
    <Card
      className=""
      style={{ marginBottom: 24 }}
      title={<ConnectedSearch />}
      bordered={false}
      loading={false}
      bodyStyle={{ padding: 0 }}
    >
      <div style={{ padding: '1rem' }}>
        <AlgoliaHits {...algoliaHitsProps} />
      </div>
    </Card>
  );
}

const ConnectedAllResource = connectHits(AllResource);
export default ConnectedAllResource;
