import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button, Divider } from 'antd';
import SubscriptionItem from './SubscriptionItem';
import { STREAMS_URL } from '../../../../config';

const { Text } = Typography;

export default function Subscriptions() {
  const stripeSubscriptionItems = useSelector(
    (state) => state.stripeSubscription.data
  );
  const allPlans = useSelector((state) => state.stripePlan.queryResult.data);
  const freeForever = allPlans.find((plan) => plan.id === 'PLAN000A');

  let customer = null;
  let subscriptionItems;
  let content;

  if (!stripeSubscriptionItems) {
    // User does not have any subscriptions, so we default to free
    const defaultSubscriptionItem = { plan: freeForever };
    content = <SubscriptionItem subscription={defaultSubscriptionItem} />;
  } else {
    customer = stripeSubscriptionItems.customer;
    subscriptionItems = stripeSubscriptionItems.items.data;
    content = subscriptionItems.map((subscriptionItem) => (
      <SubscriptionItem subscription={subscriptionItem} />
    ));
  }
  return (
    <>
      <Text strong>Your Subscriptions</Text>
      <Text mark style={{ float: 'right' }}>
        <a
          href="https://cryptosheets.com/pricing#pricing-table"
          target="_blank"
          rel="noopener noreferrer"
        >
          Compare Plans
        </a>
      </Text>
      {content}
      <Divider />
      <Button
        block
        type="primary"
        href={`${STREAMS_URL}/stripe/portal/${customer}`}
        target="_blank"
        enabled={!!customer}
      >
        Manage Billing
      </Button>
    </>
  );
}
