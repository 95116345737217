import { actions } from './actions';

const initialState = {
  authToken: null, // authToken !== null does not mean it has been validated
  currentUser: null,
  keys: [],
  loading: false,
  status: 'initial',
  error: null,
  appName: 'Cryptosheets',
  environment: 'web',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_HOST:
      return {
        ...state,
        environment: action.host.environment,
      };
    case actions.SET_AUTH_TOKEN:
      return {
        ...state,
        authToken: action.authToken,
      };
    case actions.CLEAR_AUTH:
      return {
        ...state,
        authToken: null,
        currentUser: null,
      };
    case actions.AUTH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.AUTH_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.currentUser,
      };
    case actions.AUTH_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        status: 'loading',
      };
    case actions.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'success',
      };
    case actions.RESET_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error.response.data.message,
        status: 'error',
      };
    case actions.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        msg: 'Check your e-mail for reset token.',
        loading: false,
        status: 'success',
      };
    case actions.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: 'error',
      };
    case actions.SEND_FORM_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.SEND_FORM_SUCCESS:
      return {
        ...state,
        msg: 'Message sent. We will respond shortly.',
        loading: false,
        status: 'success',
      };
    case actions.SEND_FORM_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: 'error',
      };
    default:
      return state;
  }
};
