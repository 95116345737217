import React from 'react';
import { Input, Tooltip, Typography } from 'antd';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { encrypt } from '../../../integrations/_helpers';

const { Text } = Typography;

function ApiKeyInput({
  provider,
  scheme,
  submitData,
  validateStep,
  handleSubmitData,
}) {
  const initialValue =
    scheme.defaultValue !== 'placeholder' ? scheme.defaultValue : null;

  return (
    <div key={scheme.schemeId} style={{ marginBottom: '0.5rem' }}>
      <Text>
        {provider.name} {scheme.name}
      </Text>
      <Input.Password
        defaultValue={initialValue}
        onChange={(e) => {
          const newSubmitData = {
            ...submitData,
            type: scheme.type,
            in: scheme.in,
            name: scheme.name,
            value: encrypt(e.target.value),
          };
          // submitData.value = input;
          handleSubmitData(newSubmitData);
          validateStep(!e.target.value);
        }}
        placeholder={`Enter your ${provider.name} ${scheme.name}`}
        suffix={
          <Tooltip title="Extra information">
            <EyeInvisibleOutlined />
          </Tooltip>
        }
      />
    </div>
  );
}

export default ApiKeyInput;
