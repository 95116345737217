/* eslint-disable import/prefer-default-export */
export const actions = {
  WORKSPACE_TOGGLE_FIRST_TIME: 'WORKSPACE_TOGGLE_FIRST_TIME',
  WORKSPACE_FILTER_WIDGETS: 'WORKSPACE_FILTER_WIDGETS',
  WORKSPACE_ADD_ITEM: 'WORKSPACE_ADD_ITEM',
  WORKSPACE_UPDATE_ITEM: 'WORKSPACE_UPDATE_ITEM',
  WORKSPACE_REMOVE_ITEM: 'WORKSPACE_REMOVE_ITEM',
  WORKSPACE_ON_DRAG_STOP: 'WORKSPACE_ON_DRAG_STOP',
  WORKSPACE_ON_RESIZE: 'WORKSPACE_ON_RESIZE',
  WORKSPACE_ON_RESIZE_STOP: 'WORKSPACE_ON_RESIZE_STOP',
  WORKSPACE_GET_FROM_LS: 'WORKSPACE_GET_FROM_LS',
  WORKSPACE_SAVE_TO_LS: 'WORKSPACE_SAVE_TO_LS',
  WORKSPACE_CHANGE_BREAKPOINT: 'WORKSPACE_CHANGE_BREAKPOINT',
  WORKSPACE_SAVE_LAYOUT: 'WORKSPACE_SAVE_LAYOUT',
  WORKSPACE_RESET: 'WORKSPACE_RESET',
  toggleFirstTime: () => ({
    type: actions.WORKSPACE_TOGGLE_FIRST_TIME,
  }),
  workspaceFilterWidgets: (host, isAdmin) => ({
    type: actions.WORKSPACE_FILTER_WIDGETS,
    host,
    isAdmin,
  }),
  addItem: (item) => ({
    type: actions.WORKSPACE_ADD_ITEM,
    item,
  }),
  updateItem: (item) => ({
    type: actions.WORKSPACE_UPDATE_ITEM,
    item,
  }),
  removeItem: (i) => ({
    type: actions.WORKSPACE_REMOVE_ITEM,
    i,
  }),
  onDragStop: (layout, oldItem, newItem) => ({
    type: actions.WORKSPACE_ON_DRAG_STOP,
    layout,
    oldItem,
    newItem,
  }),
  onResize: (layout, oldItem, newItem, placeholder) => ({
    type: actions.WORKSPACE_ON_RESIZE,
    layout,
    oldItem,
    newItem,
    placeholder,
  }),
  onResizeStop: (layout, oldItem, newItem) => ({
    type: actions.WORKSPACE_ON_RESIZE_STOP,
    layout,
    oldItem,
    newItem,
  }),
  changeBreakpoint: (breakpoint) => ({
    type: actions.WORKSPACE_CHANGE_BREAKPOINT,
    breakpoint,
  }),
  saveLayout: (layout, currentBreakpoint) => ({
    type: actions.WORKSPACE_SAVE_LAYOUT,
    layout,
    currentBreakpoint,
  }),
  reset: () => ({
    type: actions.WORKSPACE_RESET,
  }),
};
