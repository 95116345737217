import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import { StripeProvider } from 'react-stripe-elements';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { bindWithDispatch } from 'feathers-redux';
import AppServicesContext from './AppServicesContext';
import AppCacheContext from './CacheContext';
import { STRIPE_PUBLISHABLE } from './config';
import configureStore, { history } from './store';
import createHistoryHashObserver from './createHistoryHashObserver';
import App from './App';
import { search } from './cache';
import { loadState, saveState } from './localStorage';
import { actions as rawServices } from './services/actions';

// Persisting state to local storage
// clearState();
// const store = configureStore();
const persistedState = loadState();
export const store = configureStore(persistedState);

// Bind service actions directly with dispatch
export const services = bindWithDispatch(store.dispatch, rawServices);

// We throttle the local storage updates to once every second
store.subscribe(
  _.throttle(() => {
    const stripeSubscription = store.getState().stripeSubscription.data;

    let refreshInterval = 30;
    try {
      if (!_.isNil(stripeSubscription)) {
        // We have subscription details
        refreshInterval = _.toNumber(
          _.get(stripeSubscription, 'plan.metadata.refreshInterval', 30)
        );
      }
    } catch (e) {
      // Do nothing
      console.log('Could not save refresh interval');
    }

    saveState({
      workspace: store.getState().workspace,
    });

    localStorage.setItem('refreshInterval', refreshInterval);
  }, 1000)
);

function noop() {}

if (process.env.NODE_ENV !== 'development') {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

createHistoryHashObserver(history);

ReactDOM.render(
  <StripeProvider apiKey={STRIPE_PUBLISHABLE}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppServicesContext services={services}>
          <AppCacheContext search={search}>
            <App host="sheets" services={services} />
          </AppCacheContext>
        </AppServicesContext>
      </ConnectedRouter>
    </Provider>
  </StripeProvider>,
  document.getElementById('root')
);
