/* eslint-disable react/no-multi-comp */
import React, { Component, useState } from 'react';
import { LockOutlined } from '@ant-design/icons';
import { Button, Modal, Input, Form } from 'antd';

function UpdatePasswordCreateForm(props) {
  const [loading, setLoading] = useState(false);
  const { visible, onCancel, onCreate, updatePassword } = props;

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setLoading(true);
    return updatePassword(values)
      .then(() => {
        setLoading(false);
        return onCreate();
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Modal
      visible={visible}
      title="Update Password"
      okText="Update"
      okButtonProps={{ type: 'submit', loading }}
      onCancel={onCancel}
      onOk={() => form.submit()}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="currentPassword"
          label="Current Password"
          rules={[
            {
              required: true,
              message: 'Please input current password.',
            },
          ]}
        >
          <Input
            type="password"
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="New Password"
          rules={[
            {
              required: true,
              message: 'Please input new password.',
            },
          ]}
        >
          <Input
            type="password"
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          rules={[
            {
              required: true,
              message: 'Password must be entered twice.',
            },
          ]}
        >
          <Input
            type="password"
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

class UpdatePassword extends Component {
  state = {
    visible: false,
  };

  showModal = () => {
    this.setState({ visible: true });
  };

  hideModal = () => {
    this.setState({ visible: false });
  };

  render() {
    const { updatePassword } = this.props;
    return (
      <div>
        <Button type="link" onClick={this.showModal}>
          Update
        </Button>
        <UpdatePasswordCreateForm
          visible={this.state.visible}
          onCancel={this.hideModal}
          onCreate={this.hideModal}
          updatePassword={updatePassword}
        />
      </div>
    );
  }
}

export default UpdatePassword;
