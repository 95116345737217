/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
import React from 'react';
import _ from 'lodash';
import { Avatar, Table, Typography } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import AlgoliaHighlight from '../../widget/components/AlgoliaHighlight';
import DropOption from '../../widget/components/DropOption';
import { getActions } from '../_helpers';

const { Text } = Typography;

const defaultImage =
  'https://res.cloudinary.com/cryptosheets/image/upload/v1564412059/Logomark_-_White.png';

const EndpointList = (props) => {
  const { hits } = props;

  const columns = [
    {
      title: '',
      dataIndex: '',
      key: 'icon',
      width: 10,
      render: (text, record, index) => {
        const image = _.get(record, 'provider.image', defaultImage);
        return <Avatar src={image} />;
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => {
        const providerName = _.get(record, 'provider.name', 'Cryptosheets');
        return (
          <>
            <AlgoliaHighlight hit={record} attribute="name" />
            <br />
            <Text type="secondary">{providerName}</Text>
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'actions',
      render: (text, record, index) => {
        const actions = getActions('endpoints');

        const dropOptionProps = {
          onMenuClick: (e) => console.log(e),
          menuOptions: [...actions],
          buttonStyle: {
            buttonText: '',
            icon: <EllipsisOutlined />,
            type: 'link',
          },
        };
        return <DropOption {...dropOptionProps} />;
      },
    },
  ];
  return (
    <Table
      pagination={false}
      showHeader={false}
      columns={columns}
      rowClassName="endpointListItem"
      rowKey="objectID"
      dataSource={hits}
    />
  );
};

export default EndpointList;
