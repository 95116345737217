import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useRequest } from '@umijs/hooks';
import { Button, Form, Input, Typography } from 'antd';
import {
  CheckOutlined,
  WarningOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { getData, updateEnums, actOnEachLine } from './_helpers';

const { TextArea } = Input;
const { Text } = Typography;

function Status({ data, loading, error }) {
  if (loading) {
    return (
      <>
        <LoadingOutlined /> LoadingOutlined...
      </>
    );
  }

  if (error) {
    return (
      <>
        <WarningOutlined /> Error
      </>
    );
  }

  if (_.isEmpty(data)) {
    // array does not exist, is not an array, or is empty
    // ⇒ do not attempt to process array
    return (
      <>
        <WarningOutlined /> Not Found
      </>
    );
  }

  return (
    <>
      <CheckOutlined /> Success: name: {data.name}, id: {data.parameterId}
    </>
  );
}

function Enums({ parameterId }) {
  const [form] = Form.useForm();
  const [enums, setEnums] = useState([]);

  const { loading, error, data, run } = useRequest(() => getData(parameterId), {
    debounceInterval: 500,
    onSuccess: (data, params) => {
      setEnums(data.enums.map((e) => e.value));
    },
  });

  useEffect(() => {
    run();
  }, [parameterId, run]);

  const statusProps = {
    loading,
    error,
    data,
  };

  const content = <Status {...statusProps} />;

  const onFinish = (formValues) => updateEnums(parameterId, enums);

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onFill = () => {
    form.setFieldsValue({
      enumerations: enums.join('\n'),
    });
  };

  return (
    <Form
      name="create-or-update-enumerations"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {content}

      <Form.Item label="Enums" name="enumerations">
        <TextArea
          onChange={(e) => actOnEachLine(e.target.value, setEnums)}
          rows={4}
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
        <Button type="link" htmlType="button" onClick={onFill}>
          Fill form
        </Button>
      </Form.Item>

      <Text>
        Current Enums:{' '}
        {enums.map((e) => (
          <div>{e}</div>
        ))}
      </Text>
    </Form>
  );
}

Enums.defaultProps = {};

export default Enums;
