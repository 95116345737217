import React, { Component } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Steps, Button } from 'antd';
import { useRequest } from '@umijs/hooks';
import Add from './Add';
import Test from './Test';
import Success from './Success';
import client from '../../../../../feathers';

import './Steps.css';

const { Step } = Steps;

const createSteps = (config) => [
  {
    title: 'Add Integration',
    content: <Add {...config} />,
  },
  {
    title: 'Test',
    content: <Test {...config} />,
  },
  {
    title: 'Finished!',
    content: <Success {...config} />,
  },
];

class CreateSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 0,
      provider: this.props.provider,
      isValidating: true,

      scheme: this.props.scheme,

      submitData: {
        type: this.props.scheme.type,
        in: null,
        name: null,
        value: null,
        isEditable: true,
        active: true,
        scheme: null,
        bearerFormat: null,
        multiValue: [],
        userId: this.props.userId,
        provider: this.props.provider.name,
        providerId: this.props.provider.providerId,
      },
    };
  }

  next = () => {
    const current = this.state.current + 1;
    this.setState({ current });
  };

  prev = () => {
    const current = this.state.current - 1;
    this.setState({ current });
  };

  validateStep = (validation) => this.setState({ isValidating: validation });

  testIntegration = () => {};

  handleSubmitData = (data) => {
    this.setState({ submitData: data });
  };

  render() {
    const { current, provider, isValidating, scheme, submitData } = this.state;

    const config = {
      current,
      provider,
      scheme,
      submitData,
      next: this.next,
      prev: this.prev,
      validateStep: this.validateStep,
      handleSubmitData: this.handleSubmitData,
      selectKey: this.props.selectKey,
      isMobile: this.props.isMobile,
    };

    const steps = createSteps(config);
    return (
      <div>
        <Steps size="small" current={current}>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[current].content}</div>
        <div className="steps-action">
          {current < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => this.next()}
              disabled={isValidating}
            >
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => this.props.close()}>
              Done
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: 8 }} onClick={() => this.prev()}>
              Previous
            </Button>
          )}
        </div>
      </div>
    );
  }
}

function Wrapper({ provider, selectKey, close }) {
  const userId = useSelector((state) => state.users.data._id);
  const screenWidth = useSelector((state) => state.app.screenWidth);

  const isMobile = screenWidth < 391;

  const { loading, error, data } = useRequest(() =>
    client
      .service('security-schemes')
      .find({ query: { providerId: provider.providerId } })
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error</div>;
  }

  const type = _.isEmpty(data.data)
    ? 'none'
    : data.data.length > 1
    ? 'multi'
    : data.data[0].type;

  const scheme = {
    type,
    data: data.data,
  };

  const stepsProps = {
    userId,
    provider,
    scheme,
    selectKey,
    close,
    isMobile,
  };

  return <CreateSteps {...stepsProps} />;
}

export default Wrapper;
