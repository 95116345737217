import React from 'react';
import {
  HomeOutlined,
  ApartmentOutlined,
  AreaChartOutlined,
  LayoutOutlined,
  FormOutlined,
  SlidersOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  KeyOutlined,
} from '@ant-design/icons';

export default [
  {
    icon: <HomeOutlined />,
    key: 'overview',
    label: 'Home',
    url: '/',
    active: true,
  },
  {
    icon: <AreaChartOutlined />,
    key: 'sub-res',
    label: 'Browse',
    url: '/browse',
    active: true,
  },
  {
    icon: <ApartmentOutlined />,
    key: 'uni',
    label: 'Uni Console',
    url: '/uni',
    active: true,
  },
  {
    icon: <LayoutOutlined />,
    key: 'templates',
    label: 'Templates',
    url: '/browse/templates',
    active: true,
    children: [
      {
        key: 'templatesQuick',
        label: 'Quick Start Templates',
        url: '/templates',
      },
    ],
  },
  {
    icon: <FormOutlined />,
    key: 'workspace',
    label: 'Workspace',
    url: '/workspace',
    active: true,
  },
  {
    icon: <SlidersOutlined />,
    key: 'parameters',
    label: 'Parameters',
    url: '/parameters',
    active: false,
  },
  {
    icon: 'formula',
    key: 'functions',
    label: 'Functions',
    url: '/functions',
    active: true,
    children: [
      {
        key: 'overview',
        label: 'Overview',
        url: '/functions',
      },
      {
        key: 'query',
        label: 'Query',
        url: '/functions/query',
      },
      {
        key: 'querya',
        label: 'Query A',
        url: '/functions/querya',
      },
      {
        key: 'parameters',
        label: 'Parameters',
        url: '/functions/parameters',
      },
      {
        key: 'streaming',
        label: 'Streaming',
        url: '/functions/streaming',
      },
      {
        key: 'core',
        label: 'Core',
        url: '/functions/core',
      },
      {
        key: 'specialty',
        label: 'Specialty',
        url: '/functions/specialty',
      },
      {
        key: 'utility',
        label: 'Utility',
        url: '/functions/utility',
      },
      {
        key: 'price',
        label: 'Price',
        url: '/functions/price',
      },
      {
        key: 'pricea',
        label: 'Price A',
        url: '/functions/pricea',
      },
      {
        key: 'ohlcv',
        label: 'OHLCV',
        url: '/functions/ohlcv',
      },
      {
        key: 'orderbooks',
        label: 'Orderbooks',
        url: '/functions/orderbooks',
      },
      {
        key: 'trades',
        label: 'Trades',
        url: '/functions/trades',
      },
      {
        key: 'ath',
        label: 'ATH',
        url: '/functions/ath',
      },
      {
        key: 'ta',
        label: 'TA',
        url: '/functions/ta',
      },
      {
        key: 'time',
        label: 'TIME',
        url: '/functions/time',
      },
      {
        key: 'technical-indicators',
        label: 'Indicators',
        url: 'https://docs.cryptosheets.com/functions/technical-indicators/',
        staticLink: true,
      },
      {
        key: 'symbols',
        label: 'Symbols',
        url: '/functions/symbols/',
      },
    ],
  },
  {
    icon: <QuestionCircleOutlined />,
    key: 'resources',
    label: 'Resources',
    url: '/resources',
    active: true,
    children: [
      {
        key: 'resources',
        label: 'Help',
        url: '/resources',
      },
      {
        key: 'faq',
        label: 'FAQ',
        url: '/resources/faq',
      },
      {
        key: 'guides',
        label: 'Guides',
        url: '/resources/guides',
      },
      {
        key: 'tutorials',
        label: 'Tutorials',
        url: '/resources/tutorials',
      },
      {
        key: 'troubleshooting',
        label: 'Troubleshooting',
        url: '/resources/troubleshooting',
      },
      {
        key: 'contact',
        label: 'Contact Us',
        url: '/contact',
      },
      {
        key: 'changelog',
        label: 'Changelog',
        url: '/resources/changelog',
      },
    ],
  },
  {
    icon: <SearchOutlined />,
    key: 'explorer',
    label: 'Smart Lookup',
    url: '/explorer',
    active: true,
    excludeHosts: ['web', 'sheets'],
  },
  {
    icon: <InfoCircleOutlined />,
    key: 'about',
    label: 'About',
    url: '/about',
    active: true,
  },
  {
    icon: <SettingOutlined />,
    key: 'setting',
    label: 'Settings',
    url: '/settings',
    active: true,
  },
  {
    icon: <KeyOutlined />,
    key: 'admin',
    label: 'Admin',
    url: '/admin',
    active: true,
    excludeHosts: [''],
    admin: true,
    children: [
      {
        icon: 'control',
        key: 'control',
        label: 'Template Engine',
        url: '/template-engine',
        active: true,
        excludeHosts: [''],
        admin: true,
      },
      {
        icon: 'search',
        key: 'navigate',
        label: 'Navigate',
        url: '/admin/navigate',
        active: true,
        excludeHosts: [''],
        admin: true,
      },
      {
        icon: 'search',
        key: 'enums',
        label: 'Easy Endpoint',
        url: '/admin/endpoint',
        active: true,
        excludeHosts: [''],
        admin: true,
      },
      {
        icon: 'search',
        key: 'trade',
        label: 'Trade',
        url: '/admin/trade',
        active: true,
        excludeHosts: [''],
        admin: true,
      },
      {
        icon: 'search',
        key: 'uni',
        label: 'Uni',
        url: '/admin/Uni',
        active: true,
        excludeHosts: [''],
        admin: true,
      },
    ],
  },
];
