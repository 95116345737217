import Loadable from 'react-loadable';
import * as actions from './actions';
import reducer from './reducer';
import { view as Loader } from '../loader';
// import view from './view';

const view = Loadable({
  loader: () => import('./view'),
  loading: Loader,
});

export { actions, reducer, view };
