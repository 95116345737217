/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
import React from 'react';
import _ from 'lodash';
import { List, Avatar, Tag, Card } from 'antd';
import { view as LongText } from '../../longText';
import AlgoliaHighlight from '../../widget/components/AlgoliaHighlight';

const { Meta } = Card;

const defaultImage =
  'https://res.cloudinary.com/cryptosheets/image/upload/v1564412059/Logomark_-_White.png';

const EndpointGrid = (props) => {
  const { hits } = props;
  // const data = listData(hits);
  return (
    <div className="endpointGrid">
      <List
        grid={{
          gutter: 16,
          xs: 1,
          sm: 2,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 3,
        }}
        dataSource={hits}
        renderItem={(item) => {
          const {
            description = 'No description.',
            objectID,
            premium,
            sample,
          } = item;

          const displayedImage =
            item.thumbnailUrl || _.get(item, 'provider.image', defaultImage);

          return (
            <List.Item key={objectID}>
              <Card
                actions={[
                  <Tag color={premium ? 'blue' : 'green'}>
                    {premium ? 'Premium' : 'Community'}
                  </Tag>,
                  <Tag color={sample ? 'geekblue' : 'red'}>
                    {sample ? 'Has Sample' : 'No Sample'}
                  </Tag>,
                ]}
              >
                <Meta
                  avatar={<Avatar src={displayedImage} />}
                  title={<AlgoliaHighlight hit={item} attribute="name" />}
                  description={
                    <LongText
                      content={!description ? 'No description.' : description}
                      limit={50}
                    />
                  }
                />
              </Card>
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default EndpointGrid;
