import { ReactComponent as Formula } from '../../assets/icons/formula_white.svg';
import { ReactComponent as Excel } from '../../assets/icons/excel_48x1.svg';
import { ReactComponent as Sheets } from '../../assets/icons/google_sheets.svg';

/**
 * Reference notes - for adding new icons
 * import React from 'react';
 * import { ReactComponent as Formula } from '../../assets/icons/formula_white.svg';
 * import { ReactComponent as Animated } from '../../assets/icons/animated-flow-lines.svg';
 * export const config = {
 *  formula: Formula,
 *  animated: Animated,
 * };
 *
 * icon name has to be capitalized
 * animated: Animated,
 */
export const config = {
  formula: Formula,
  excel: Excel,
  sheets: Sheets,
};
