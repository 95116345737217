import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

export default function LinkFix({ href, children }) {
  const r = new RegExp(/(?:^[a-z][a-z0-9+.-]*:|\/\/)/, 'i');

  const sanitizedChildren = _.isEmpty(children) ? children : children[0];

  if (r.test(href)) {
    // Link is external
    return (
      <a rel="nofollow" href={href}>
        {sanitizedChildren}
      </a>
    );
  }

  return (
    <Link to={href}>
      <a href={href}>{sanitizedChildren}</a>
    </Link>
  );
}
