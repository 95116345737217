import React, { useState } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch';
import {
  InstantSearch,
  Highlight,
  Configure,
  SearchBox,
  connectHits,
} from 'react-instantsearch-dom';
import { ParadigmAlgoliaSearchConfig } from '../../../config';

export const CustomHits = connectHits(({ hits }) => (
  <div className="hits">
    <SearchBox />
    {hits.map((hit) => (
      <div key={hit.objectID} className="hit">
        {hit.thumbnailUrl && (
          <div className="hit-picture">
            <img alt={hit.thumbnailUrl} src={hit.thumbnailUrl} />
          </div>
        )}
        <div className="hit-content">
          <div>
            <Highlight attribute="name" hit={hit} />
            <span> - ${hit.price}</span>
            <span> - {hit.name} stars</span>
          </div>
          <div className="hit-type">
            <Highlight attribute="type" hit={hit} />
          </div>
          <div className="hit-description">
            <Highlight attribute="description" hit={hit} />
          </div>
        </div>
      </div>
    ))}
  </div>
));

export const WrapWithHits = (props) => {
  const {
    searchParameters = {},
    appId,
    apiKey,
    indexName,
    hitsElement,
    initialSearchState,
    onSearchStateChange,
  } = props;

  const searchClient = algoliasearch(appId, apiKey);

  const hits = hitsElement || <CustomHits />;

  const [searchState, setSearchState] = useState(initialSearchState);

  const setNextSearchState = (nextSearchState) => {
    setSearchState(nextSearchState);
    onSearchStateChange(nextSearchState);
  };

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indexName}
      searchState={searchState}
      onSearchStateChange={setNextSearchState}
      createURL={() => ''}
    >
      <Configure {...searchParameters} />
      {hits}
    </InstantSearch>
  );
};

WrapWithHits.propTypes = {
  appId: PropTypes.string,
  apiKey: PropTypes.string,
  indexName: PropTypes.string,
  children: PropTypes.node,
  searchBox: PropTypes.bool,
  linkedStoryGroup: PropTypes.string,
  hasPlayground: PropTypes.bool,
  pagination: PropTypes.bool,
  searchParameters: PropTypes.object,
  hitsElement: PropTypes.element,
  initialSearchState: PropTypes.object,
  onSearchStateChange: PropTypes.func,
};

// defaultProps added so that they're displayed in the JSX addon
WrapWithHits.defaultProps = {
  ...ParadigmAlgoliaSearchConfig,
  initialSearchState: {},
  onSearchStateChange: () => {},
};

export default WrapWithHits;
