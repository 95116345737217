/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { actions as loadingActions } from '../../components/loading/index';
import parse from '../../util/parse';
import setData from '../../util/excel';

import { API_BASE_URL } from '../../config';

// Axios logic for handling timeouts and cancellations
const { CancelToken } = axios;
const source = CancelToken.source();

export const actions = {
  LOAD_ENDPOINT_REQUEST: 'LOAD_ENDPOINT_REQUEST',
  LOAD_ENDPOINT_SUCCESS: 'LOAD_ENDPOINT_SUCCESS',
  LOAD_ENDPOINT_ERROR: 'LOAD_ENDPOINT_ERROR',
  REQUEST_FETCH_CURRENT_ENDPOINT: 'REQUEST_FETCH_CURRENT_ENDPOINT',
  RESOLVE_FETCH_CURRENT_ENDPOINT: 'RESOLVE_FETCH_CURRENT_ENDPOINT',
  REJECT_FETCH_CURRENT_ENDPOINT: 'REJECT_FETCH_CURRENT_ENDPOINT',
  RESET_INITIAL_STATE: 'RESET_INITIAL_STATE',
  loadEndpointRequest: () => ({
    type: actions.LOAD_ENDPOINT_REQUEST,
  }),
  loadEndpointSuccess: (endpoint) => ({
    type: actions.LOAD_ENDPOINT_SUCCESS,
    endpoint,
  }),
  loadEndpointError: (error) => ({
    type: actions.LOAD_ENDPOINT_ERROR,
    error,
  }),
  loadEndpoint: (id) => (dispatch) => {
    dispatch(actions.loadEndpointRequest());
    dispatch(loadingActions.showLoading());
    return axios
      .get(`${API_BASE_URL}/endpoints/${id}`)
      .then(({ data }) => {
        dispatch(loadingActions.hideLoading());
        dispatch(actions.loadEndpointSuccess(data));
      })
      .catch((err) => {
        dispatch(loadingActions.hideLoading());
        dispatch(actions.loadEndpointError(err));
      });
  },
  requestFetchCurrentEndpoint: () => ({
    type: actions.REQUEST_FETCH_CURRENT_ENDPOINT,
  }),
  resolveFetchCurrentEndpoint: (payload) => ({
    type: actions.RESOLVE_FETCH_CURRENT_ENDPOINT,
    payload,
  }),
  rejectFetchCurrentEndpoint: (error) => ({
    type: actions.REJECT_FETCH_CURRENT_ENDPOINT,
    error,
  }),
  fetchCurrentEndpoint: (
    url,
    collectionName,
    host,
    keys = [],
    mainField = null,
    name = null,
    prettyName = null,
    currentPlan
  ) => (dispatch) => {
    const config = {
      method: 'post',
      url: `${API_BASE_URL}/endpoints/proxy`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.authToken}`,
        'x-url-string': url,
        'x-collectionName': collectionName,
        'Cache-Control': 'no-cache',
      },
      data: {
        keys,
        mainField,
        name,
        prettyName,
        currentPlan,
      },
      timeout: 5000,
      cancelToken: source.token,
    };
    dispatch(actions.requestFetchCurrentEndpoint());
    dispatch(loadingActions.showLoading());
    return axios(config)
      .then((response) => {
        const { data } = response;
        dispatch(loadingActions.hideLoading());
        if (mainField) {
          return dispatch(actions.resolveFetchCurrentEndpoint(data[mainField]));
        }
        return dispatch(actions.resolveFetchCurrentEndpoint(data));
      })
      .then((data) => {
        const { payload } = data;
        const parsed = parse(payload);
        if (host === 'sheets') {
          return window.google.script.run
            .withSuccessHandler((sheetData) =>
              console.log('success', sheetData)
            )
            .withFailureHandler((sheetError) =>
              window.google.script.run.failedData()
            )
            .setData(parsed);
        }
        if (host === 'excel') {
          return setData(parsed);
        }
        return data;
      })
      .catch((err) => {
        console.log(JSON.stringify(err, null, 2));
        if (err.message.includes('timeout')) {
          dispatch(actions.rejectFetchCurrentEndpoint(err.message));
          return dispatch(loadingActions.hideLoading());
        }
        const { message } = err.response.data;
        dispatch(actions.rejectFetchCurrentEndpoint(message));
        dispatch(loadingActions.hideLoading());
      });
  },
  resetInitialState: () => ({
    type: actions.RESET_INITIAL_STATE,
  }),
};
