function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  /* background: white; */\n  padding: 5px;\n  margin-top: 5px;\n  margin-bottom: 5px;\n  border-bottom: 1px solid #dedede;\n  /* box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2);\n  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2); */\n\n  .control-panel-header {\n    position: relative;\n    float: right;\n    overflow: hidden;\n    width: 100%;\n  }\n\n  .control-panel-scenario-name {\n    margin-bottom: 0;\n    max-width: 200px;\n  }\n\n  .ant-select-auto-complete {\n    margin-top: 5px;\n    width: 100%;\n  }\n\n  .ant-select-selector {\n    font-weight: 700;\n    color: rgb(93, 101, 119);\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
export var ControlPanelWrapper = styled.div(_templateObject());