import React from 'react';
import { connect } from 'react-redux';
import { useRequest } from '@umijs/hooks';
import { LineChartOutlined, UploadOutlined } from '@ant-design/icons';
import { Alert, message, Spin, Tabs, Tooltip } from 'antd';
import client from '../../feathers';
import { STREAMS_URL } from '../../config';
import { loadAuthToken } from '../../util/localStorage';
import TemplateTable from '../../components/crud/Templates';
import Error from './components/Error';
import DragUpload from './components/DragUpload';
import FormTable from './components/FormTable';

const { TabPane } = Tabs;

function onChange(info) {
  const { status } = info.file;

  if (status !== 'uploading') {
    // console.log(info.file, info.fileList);
  }
  if (status === 'done') {
    message.success(`${info.file.name} file uploaded successfully.`);
  } else if (status === 'error') {
    message.error(
      `${info.file.name} file upload failed. Reason: ${info.file.response.message}`
    );
  }
}

function fetchTemplates() {
  return client
    .service('templates')
    .find({
      query: { $select: ['templateId', 'name', 'author'], templateId: 84 },
    })
    .then(({ data }) => data);
}

const dragProps = {
  accept: '.xlsx, .csv, .xls',
  name: 'uri',
  multiple: true,
  method: 'post',
  headers: {
    Authorization: `Bearer ${loadAuthToken()}`,
  },
  action: `${STREAMS_URL}/templates`,
  data: {
    version: 1,
  },
};

function Templates({ screenWidth }) {
  const { loading, error } = useRequest(() => fetchTemplates());

  if (loading)
    return (
      <Spin tip="Loading...">
        <Alert
          message="Hang tight..."
          description="We are fetching your data..."
          type="info"
        />
      </Spin>
    );

  if (error) return <Error />;

  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <Tooltip title="Upload">
                <UploadOutlined />
                {screenWidth > 320 ? 'Upload' : null}
              </Tooltip>
            </span>
          }
          key="1"
        >
          <TemplateTable service="templates" />
        </TabPane>
        <TabPane
          tab={
            <span>
              <Tooltip title="Templates">
                <LineChartOutlined />
                {screenWidth > 320 ? 'Templates' : null}
              </Tooltip>
            </span>
          }
          key="2"
        >
          <FormTable />
        </TabPane>
      </Tabs>
      <DragUpload {...dragProps} onChange={onChange} />
    </>
  );
}

const mapStateToProps = (state) => ({
  screenWidth: state.app.screenWidth,
});

export default connect(mapStateToProps)(Templates);
