import React from 'react';
import _ from 'lodash';
import { Console } from '@cryptosheets/components';
import { useRequest } from '@umijs/hooks';
import { Alert, Spin } from 'antd';
import client from '../../../../feathers';
import { UniWrapper } from './index.style';

// async function getEndpoint(customFunction, data) {
//   if (data) {
//     const customFunctionData = data.find(
//       (func) => func.name === customFunction
//     );

//     if (customFunctionData) {
//       const { endpointid } = customFunctionData;

//       const params = {
//         query: {
//           $eager: '[provider, parameters.enums]',
//         },
//       };
//       const endpoint = await client.service(SERVICE).get(endpointid, params);

//       return endpoint;
//     }

//     return null;
//   }

//   return null;
// }

async function getEndpoint(endpointId) {
  const SERVICE = 'endpoints';
  const PARAMS = {
    query: {
      $eager: '[provider, parameters.enums]',
    },
  };
  const endpoint = await client.service(SERVICE).get(endpointId, PARAMS);

  return endpoint;
}

function UniConsole({ endpointId, customFunction, setCustomFunction }) {
  const { loading, error, data } = useRequest(() => getEndpoint(endpointId), {
    refreshDeps: [endpointId],
  });

  if (loading)
    return (
      <Spin tip="Loading...">
        <Alert
          message="Hang tight..."
          description="We are fetching your data..."
          type="info"
        />
      </Spin>
    );

  if (error) return <div>error</div>;

  if (_.isEmpty(data)) {
    return <div>no data</div>;
  }
  const { parameters } = data;

  return (
    <UniWrapper>
      <Console
        endpoint={data}
        isLoggedIn
        isAdmin
        host="web"
        initialParameters={parameters}
        handleNotLoggedIn={() => console.log('handle not logg')}
        screenWidth={900}
        scenarioId={null}
        customFunction={customFunction}
        setCustomFunction={setCustomFunction}
      />
    </UniWrapper>
  );
}

UniConsole.defaultProps = {};

export default UniConsole;
