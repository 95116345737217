function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: inline-block;\n  width: 13px;\n  height: 20px;\n  background: white;\n  margin-left: 8px;\n  margin-right: 8px;\n  position: relative;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  -webkit-font-smoothing: antialiased;\n  background: ", ";\n  border: ", ";\n  border-radius: 4px;\n  box-sizing: border-box;\n  margin: 2px 0 0 2px;\n  padding: 2px 4px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  -webkit-font-smoothing: antialiased;\n  border: 0;\n  box-sizing: border-box;\n  color: #67798d;\n  flex: 1 1 0%;\n  font-family: SFMono-Regular, Consolas, 'Liberation Mono', Menlo, Courier,\n    monospace;\n  font-size: 12px;\n  font-weight: 400;\n  height: 100%;\n  line-height: 32px;\n  margin: 0;\n  overflow: hidden;\n  padding: 4px 6px 4px 0;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  height: auto;\n  background-color: white;\n  margin-bottom: 1px;\n  justify-content: center;\n  border-top-left-radius: ", ";\n  border-top-right-radius: ", ";\n\n  .ant-form-item {\n    margin-bottom: 0;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
export var RequestParameter = styled.div(_templateObject(), props => props.index === 0 ? '5px' : null, props => props.index === 0 ? '5px' : null);
export var KeyWrapper = styled.div(_templateObject2());
export var Key = styled.span(_templateObject3(), props => props.required ? '#f2f5f8' : null, props => props.required ? '1px solid #d5deed' : null);
export var Pad = styled.div(_templateObject4());