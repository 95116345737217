import _ from 'lodash';
import { message } from 'antd';
import client from '../feathers';
import { loadAuthToken } from '../util/localStorage';

export async function fetchCustomerFromSessionId({
  sessionId,
  services,
  setFields,
}) {
  try {
    const sessionObject = await services['stripe/checkout']
      .get(sessionId)
      .catch((e) => console.log(e));

    const { customer } = sessionObject.value;

    const stripeCustomer = await services['stripe/customer'].get(customer);

    const { email } = stripeCustomer.value;

    return setFields([
      { name: ['email'], value: email },
      { name: ['stripeCustomerId'], value: customer },
    ]);
  } catch (e) {
    console.log(e);
    return [{ name: ['email'], value: null }];
  }
}

export default (feathersServices) => {
  function fetchUser(userId) {
    return feathersServices.users.get(userId);
  }

  function createUser(data) {
    message.info('Hang tight, we are creating your user...');
    return feathersServices.users
      .create(data)
      .then(() => {
        message.success('Success! Check your e-mail for verification.');
        return 'Done';
      })
      .catch((e) => {
        message.error('We had trouble creating a user.');
        return e;
      });
  }

  function fetchKeys(user) {
    return feathersServices.keys.find({ query: { userId: user._id } });
  }

  function fetchStripeCustomer(stripeCustomerId) {
    return feathersServices['stripe/customer'].get(stripeCustomerId);
  }

  function fetchStripeSubscription(stripeSubscriptionId) {
    if (!stripeSubscriptionId) {
      return;
    }
    return feathersServices['stripe/subscription'].get(stripeSubscriptionId);
  }

  function fetchStripeSubscriptionItem(stripeSubscriptionItemId) {
    if (!stripeSubscriptionItemId) {
      return;
    }
    return feathersServices['stripe/subscription-item'].get(
      stripeSubscriptionItemId
    );
  }

  function fetchPlans() {
    return feathersServices['stripe/plan'].find();
  }

  function realtimeSetup(user) {
    // Setting up real-time event messaging
    const {
      _id,
      // stripeCustomerId = null,
      // stripeSubscriptionId = null,
      // stripeSubscriptionItemId = null,
      // stripePlanId = null,
    } = user;

    const realtimeConfig = [
      {
        id: _id,
        serviceName: 'keys',
        redux: (data, entity) =>
          feathersServices[entity].find({ query: { userId: _id } }),
      },
      // {
      //   id: '',
      //   serviceName: 'changelog',
      //   redux: (data, entity) => feathersServices[entity].find({ query: {} }),
      // },
      // {
      //   id: _id,
      //   serviceName: 'users',
      //   redux: (data, entity) => feathersServices[entity].get(_id),
      // },
      // {
      //   id: _id,
      //   serviceName: 'quota',
      //   redux: (data, entity) => feathersServices[entity].get(_id),
      // },
      // {
      //   id: stripeCustomerId,
      //   serviceName: 'stripe/customer',
      //   redux: (data, entity, id) => feathersServices[entity].get(id),
      // },
      // {
      //   id: stripeSubscriptionId,
      //   serviceName: 'stripe/subscription',
      //   redux: (data, entity, id) => feathersServices[entity].get(id),
      // },
      // {
      //   id: stripeSubscriptionItemId,
      //   serviceName: 'stripe/subscription-item',
      //   redux: (data, entity, id) => {
      //     feathersServices[entity].get(id);
      //     feathersServices['stripe/subscription'].get(data.subscription);
      //   },
      // },
      // {
      //   id: stripePlanId,
      //   serviceName: 'stripe/plan',
      //   redux: (data, entity, id) => feathersServices[entity].get(id),
      // },
    ];

    const methods = ['created', 'patched', 'removed'];

    realtimeConfig.map((config) => {
      const { serviceName, redux, id } = config;
      const service = client.service(serviceName);

      console.log(`Now configuring ${serviceName}`);

      return methods.map((method) => {
        if (!id) {
          // Skip if no id on user profile
          return 'Skipping';
        }
        return service.on(method, (data) => {
          console.log(`received new data: `, method, serviceName);
          redux(data, serviceName, id);
        });
      });
    });
  }

  async function handleGoogleAuth() {
    const authToken = loadAuthToken();

    const googleToken = await window.google.script.run
      .withSuccessHandler((token) => token)
      .withFailureHandler((error) => console.log('failed good auth'))
      .getAuthToken(authToken);

    if (!_.isEqual(authToken, googleToken)) {
      console.log('not equal');
      return window.google.script.run
        .withSuccessHandler((loggedIn) => console.log('success', loggedIn))
        .withFailureHandler((error) => console.log('failed good auth'))
        .setAuthToken(authToken);
    }
  }

  function appSetup(user) {
    const {
      stripeCustomerId = null,
      stripeSubscriptionId = null,
      stripeSubscriptionItemId = null,
    } = user;

    // Hydrate our store with information
    fetchUser(user._id);
    fetchPlans();
    fetchStripeCustomer(stripeCustomerId);
    fetchStripeSubscription(stripeSubscriptionId);
    fetchStripeSubscriptionItem(stripeSubscriptionItemId);
    fetchKeys(user);
    realtimeSetup(user);

    if (window.google && window.google.script) {
      handleGoogleAuth().catch((e) =>
        console.log('Unable to do Google Auth. Check appSetup function.')
      );
    }
  }

  function appTeardown() {
    feathersServices.users.reset();
    feathersServices.keys.reset();
    feathersServices['stripe/plan'].reset();
    feathersServices['stripe/customer'].reset();
    feathersServices['stripe/subscription'].reset();
    feathersServices['stripe/subscription-item'].reset();
    localStorage.removeItem('authToken');
    localStorage.removeItem('csApiKey');
    localStorage.removeItem('refreshInterval');
  }
  return { appSetup, appTeardown, createUser, fetchCustomerFromSessionId };
};
