import React from 'react';
import { ExportButton } from '@cryptosheets/components';

const Demo = () => (
  <div>
    <ExportButton />
  </div>
);

export default Demo;
