import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, List, Typography, Skeleton } from 'antd';
import _ from 'lodash';
import Humanize from 'humanize-plus';
import UpgradeModal from './UpgradeModal';
import SubscriptionsDetail from './SubscriptionDetails';

const { Paragraph } = Typography;

function findPlanById(id, allPlans) {
  return allPlans.find((plan) => plan.id === id);
}

function DisplayMetadata({ plan }) {
  return (
    <>
      {Object.keys(plan.metadata).map((meta) => {
        let metaValue = plan.metadata[meta];
        if (meta === 'monthlyApiRequests') {
          metaValue = Humanize.formatNumber(metaValue);
        }

        return (
          <List.Item key={meta} actions={[metaValue]}>
            <List.Item.Meta description={_.startCase(meta)} />
          </List.Item>
        );
      })}
    </>
  );
}

export default function SubscriptionItem({ subscription }) {
  const allPlans = useSelector((state) => state.stripePlan.queryResult.data);
  const [newPlan, setNewPlan] = useState(subscription.plan);
  const [visible, setVisible] = useState(false);

  const buttonText = Humanize.formatNumber(newPlan.metadata.monthlyApiRequests);

  const subscriptionDetailProps = {
    onMenuClick: (e) => setNewPlan(findPlanById(e.key, allPlans)),
    buttonText,
  };

  const upgradeModalProps = {
    plan: newPlan,
    visible,
    setVisible,
    setNewPlan,
  };

  return (
    <>
      <List.Item
        actions={[<SubscriptionsDetail {...subscriptionDetailProps} />]}
      >
        <Skeleton avatar title={false} loading={false} active>
          <List.Item.Meta
            avatar={<Avatar icon={<InfoCircleOutlined />} />}
            title={newPlan.nickname}
            description={`${`$${parseInt(newPlan.amount, 10) / 100}`} ${
              newPlan.currency
            }/${newPlan.interval}`}
          />
        </Skeleton>
      </List.Item>
      <Paragraph>
        <DisplayMetadata plan={newPlan} />
      </Paragraph>
      <Button
        disabled={_.isEqual(newPlan.id, _.get(subscription, ['plan', 'id']))}
        block
        type="primary"
        onClick={() => setVisible(true)}
      >
        Change
      </Button>
      {newPlan && <UpgradeModal {...upgradeModalProps} />}
    </>
  );
}
