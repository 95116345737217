import React from 'react';
import { Layout } from 'antd';
import DescriptionList from './DescriptionList';

const { Content } = Layout;

export default function Overview({ style, template }) {
  return (
    <Content style={style}>
      <DescriptionList {...template} />
    </Content>
  );
}
