import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from 'antd';
import Overview from './Overview';

export default function ShowInfo({ template }) {
  const showInfoModal = useSelector(state => state.template.showInfoModal);
  const dispatch = useDispatch();

  const toggleShowInfo = () =>
    dispatch({
      type: 'TEMPLATES_TOGGLE_SHOW_INFO_MODAL',
      showInfoModal: !showInfoModal,
    });

  return (
    <Modal
      title="Template Info"
      visible={showInfoModal}
      onOk={() => toggleShowInfo()}
      onCancel={() => toggleShowInfo()}
    >
      <Overview template={template} />
    </Modal>
  );
}
