function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */\n  margin: 2.4em 1em;\n  box-shadow: 0 8px 20px rgba(143, 168, 191, 0.35);\n\n  .react-player {\n    position: absolute;\n    top: 0;\n    left: 0;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
export var VideoPlayerWrapper = styled.div(_templateObject());