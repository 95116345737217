import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Post from '../../components/post';
import { useServices } from '../../hooks/use-services';

export default function Functions(props) {
  const [slug, setSlug] = useState('/functions/');
  const services = useServices();

  const resource = _.get(
    props,
    ['computedMatch', 'params', 'function'],
    'functions'
  );

  useEffect(() => {
    async function getPosts() {
      if (resource !== 'functions') {
        setSlug(`/functions/${resource}/`);
      }
      await services['content/posts'].find({
        query: { slug },
      });
    }
    getPosts();
  }, [resource, services, slug]);

  return <Post slug={slug} />;
}
