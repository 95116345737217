import React from 'react';
import { connect } from 'react-redux';
// import Tour from './Tour';
import Uni from '../../components/uni';

import { actions as authActions } from '../../account/actions';

const UniConsole = (props) => (
  <>
    <Uni {...props} />
    {/* <Tour /> */}
  </>
);

const mapDispatchToProps = (dispatch, { services }) => ({
  // Auth actions for NotLoggedIn component
  login: (formValue) => {
    const { email, password } = formValue;
    // wait
    dispatch(authActions.login(email, password, services));
  },
  registerUser: (formValue) => {
    dispatch(authActions.registerUser(formValue, services));
  },
});

export default connect(null, mapDispatchToProps)(UniConsole);
