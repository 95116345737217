/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Iframe from 'react-iframe';

export class Template extends Component {
  render() {
    const { pageHeight, pageWidth } = this.props;
    const path = this.props.computedMatch.params[0];

    const { hash } = this.props.location;

    const url = `https://cryptosheets-docs.netlify.com/${path}?raw=true${hash}`;
    console.log({ path, url });
    return (
      <div style={{ backgroundColor: '#fff' }}>
        <Iframe url={url} width={pageWidth} height={pageHeight} />
      </div>
    );
  }
}

export default connect()(Template);
