import React, { Component } from 'react';
import { CodeOutlined } from '@ant-design/icons';
import { Avatar, PageHeader, Typography } from 'antd';
import { connect } from 'react-redux';

const { Paragraph } = Typography;

const InsideContent = () => (
  <div className="provider-insideContent">
    <Paragraph ellipsis={{ rows: 2, expandable: true }}>Description</Paragraph>
    <p className="provider-contentLink">
      <a href="www.google.com" rel="noopener noreferrer" target="_blank">
        <img
          src="https://gw.alipayobjects.com/zos/rmsportal/ohOEPSYdDTNnyMbGuyLb.svg"
          alt="doc"
        />
        Link 1
      </a>
    </p>
  </div>
);

const ExtraContent = () => (
  <Avatar
    style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}
    size={128}
    icon={<CodeOutlined />}
  />
);

export class Template extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitUrl: '',
    };
  }

  callback = (key) => {
    console.log(key);
  };

  render() {
    return (
      <>
        <PageHeader title="Reference" style={{ marginBottom: 24 }}>
          <div className="provider-wrap">
            <div className="provider-content">
              <InsideContent {...this.props} />
            </div>
            <div className="extraContent">
              <ExtraContent />
            </div>
          </div>
        </PageHeader>
      </>
    );
  }
}

// const mapStateToProps = state => {
//   return {
//   };
// };

// const mapDispatchToProps = (dispatch, props) => ({
//   // Auth actions for NotLoggedIn component
// });

export default connect()(Template);
