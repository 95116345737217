/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { actions as loadingActions } from '../../components/loading/index';

import { API_BASE_URL } from '../../config';

export const actions = {
  LOAD_PROVIDERS_REQUEST: 'index/App/LOAD_PROVIDERS_REQUEST',
  LOAD_PROVIDERS_SUCCESS: 'index/App/LOAD_PROVIDERS_SUCCESS',
  LOAD_PROVIDERS_ERROR: 'index/App/LOAD_PROVIDERS_ERROR',
  LOAD_PROVIDERS_ENDPOINTS_REQUEST:
    'index/App/LOAD_PROVIDERS_ENDPOINTS_REQUEST',
  LOAD_PROVIDERS_ENDPOINTS_SUCCESS:
    'index/App/LOAD_PROVIDERS_ENDPOINTS_SUCCESS',
  LOAD_PROVIDERS_ENDPOINTS_ERROR: 'index/App/LOAD_PROVIDERS_ENDPOINTS_ERROR',
  loadProvidersRequest: () => ({
    type: actions.LOAD_PROVIDERS_REQUEST,
  }),

  providersLoaded: (providers) => ({
    type: actions.LOAD_PROVIDERS_SUCCESS,
    providers,
  }),

  providersLoadingError: (error) => ({
    type: actions.LOAD_PROVIDERS_ERROR,
    error,
  }),

  loadProvidersEndpointsRequest: () => ({
    type: actions.LOAD_PROVIDERS_ENDPOINTS_REQUEST,
  }),

  loadProvidersEndpointsSuccess: (endpoints) => ({
    type: actions.LOAD_PROVIDERS_ENDPOINTS_SUCCESS,
    endpoints,
  }),

  loadProvidersEndpointsError: (error) => ({
    type: actions.LOAD_PROVIDERS_ENDPOINTS_ERROR,
    error,
  }),

  loadProviders: () => (dispatch, getState) => {
    dispatch(actions.loadProvidersRequest());
    dispatch(loadingActions.showLoading());
    return axios({
      url: `${API_BASE_URL}/providers?Category=Cryptocurrency`,
      method: 'GET',
    })
      .then((response) => {
        dispatch(loadingActions.hideLoading());
        dispatch(actions.providersLoaded(response.data));
      })
      .catch((err) => {
        dispatch(loadingActions.hideLoading());
        dispatch(actions.providersLoadingError(err));
      });
  },

  loadProvidersEndpoints: (id) => (dispatch, getState) => {
    dispatch(loadingActions.showLoading());
    dispatch(actions.loadProvidersEndpointsRequest());
    return axios({
      url: `${API_BASE_URL}/providers/${id}/endpoints`,
      method: 'GET',
    })
      .then((response) => {
        dispatch(loadingActions.hideLoading());
        dispatch(actions.loadProvidersEndpointsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(loadingActions.hideLoading());
        dispatch(actions.loadProvidersEndpointsError(err));
      });
  },
};
