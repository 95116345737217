import React from 'react';
import Interweave from 'interweave';
import moment from 'moment';
import _ from 'lodash';
import { useRequest } from '@umijs/hooks';
import { Alert, Avatar, Card, Spin, Typography } from 'antd';
import { Link } from 'react-router-dom';
import NotFound from './NotFound';
import Error from './Error';
import client from '../../../feathers';

const { Paragraph } = Typography;

async function filterTemplates(search) {
  const query = {
    visible: true,
    $select: ['name', 'version', 'description', 'category', 'type', 'thumb'],
  };
  if (!_.isEmpty(search)) {
    query.name = {
      $like: `%${search}%`,
    };
  }

  try {
    const response = await client.service('templates').find({
      query,
    });

    return response.data;
  } catch (e) {
    return e;
  }
}

export default function AllTemplates({ search }) {
  const { loading, error, data } = useRequest(() => filterTemplates(search), {
    refreshDeps: [search],
  });

  if (loading)
    return (
      <Spin tip="Loading...">
        <Alert
          message="Hang tight..."
          description="We are fetching your template..."
          type="info"
        />
      </Spin>
    );
  if (error) return <Error />;

  const template = data;

  if (_.isEmpty(template)) {
    // array does not exist, is not an array, or is empty
    // ⇒ do not attempt to process array
    return <NotFound resource="template" />;
  }

  return (
    <>
      {data.map((item) => (
        <Card.Grid
          id="providerCard"
          className="projectGrid"
          key={item.templateId}
        >
          <Card bodyStyle={{ padding: 0 }} bordered={false}>
            <Card.Meta
              title={
                <div className="cardTitle">
                  <Avatar size="small" src={item.thumb} />
                  <Link to={`/templates/${item.templateId}`}>
                    {_.startCase(item.name)}
                  </Link>
                </div>
              }
              description={
                <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                  <Interweave content={item.description} />
                </Paragraph>
              }
            />
            <div className="projectItemContent">
              <Link to={`/templates/${item.templateId}`}>View</Link>
              {item.created_at && (
                <span className="datetime" title={item.created_at}>
                  {moment(item.created_at).fromNow()}
                </span>
              )}
            </div>
          </Card>
        </Card.Grid>
      ))}
    </>
  );
}
