/* eslint-disable no-case-declarations */
import _ from 'lodash';
import moment from 'moment';
import uuidv4 from 'uuid/v4';
import options from '../../components/widget/constants';
import { filterWidgets } from './api';
import { actions } from './actions';

const filteredWidgets = filterWidgets(options, 'web', false);

// The initial state of the App
export const initialState = {
  lastUpdate: '2019-12-22',
  updated: '2019-12-21',
  firstTime: true,
  currentBreakpoint: 'lg',
  items: { lg: [] },
  filteredWidgets,
};

/* eslint-disable default-case, no-param-reassign */
export default function reducer(state = initialState, action) {
  const prevState = _.cloneDeep(state);
  // Cache buster
  if (
    _.isNil(state.updated) ||
    moment(state.updated).isBefore(state.lastUpdate)
  ) {
    // We have to merge new properties and update the updated time
    const updated = moment().toDate();

    return _.merge(initialState, { updated });
  }

  switch (action.type) {
    case actions.WORKSPACE_TOGGLE_FIRST_TIME:
      return {
        ...prevState,
        firstTime: !prevState.firstTime,
      };

    case actions.WORKSPACE_FILTER_WIDGETS:
      return {
        ...prevState,
        filteredWidgets: filterWidgets(
          prevState.filteredWidgets,
          action.host,
          action.isAdmin
        ),
      };

    case actions.WORKSPACE_ADD_ITEM:
      const items = _.cloneDeep(prevState.items);
      const highestYItem = _.maxBy(
        items[prevState.currentBreakpoint],
        (item) => item.y
      ) || { y: 0 };
      const defaultItem = {
        i: `n${uuidv4()}`,
        x:
          (prevState.items[prevState.currentBreakpoint].length * 2) %
          (prevState.cols || 12),
        y: highestYItem.y, // puts it at the bottom
        w: 2,
        h: 2,
        widgetType: _.get(action.item, 'widgetType', 'emptyWidget'),
        widgetData: _.get(action.item, 'widgetData', {}),
      };
      return {
        ...prevState,
        items: {
          ...prevState.items,
          [prevState.currentBreakpoint]: [
            ...(prevState.items[prevState.currentBreakpoint] || []),
            defaultItem,
          ],
        },
      };

    case actions.WORKSPACE_UPDATE_ITEM:
      return {
        ...prevState,
        items: {
          ...prevState.items,
          [prevState.currentBreakpoint]: _.map(
            prevState.items[prevState.currentBreakpoint],
            (item) => {
              if (item.i === action.item.i) {
                return action.item;
              }
              return item;
            }
          ),
        },
      };

    case actions.WORKSPACE_REMOVE_ITEM:
      return {
        ...prevState,
        items: {
          ...prevState.items,
          [prevState.currentBreakpoint]: prevState.items[
            prevState.currentBreakpoint
          ].filter(({ i }) => i !== action.i),
        },
      };

    case actions.WORKSPACE_ON_DRAG_STOP:
      const dragItems = [...prevState.items[prevState.currentBreakpoint]];
      return {
        ...prevState,
        items: {
          [prevState.currentBreakpoint]: _.map(dragItems, (item) =>
            item.i === action.newItem.i ? _.merge(item, action.newItem) : item
          ),
        },
      };

    case actions.WORKSPACE_ON_RESIZE:
      const { layout, oldItem, newItem, placeholder } = action;
      console.log({ layout, oldItem, newItem, placeholder });
      return {
        ...prevState,
      };

    case actions.WORKSPACE_ON_RESIZE_STOP:
      const resizeItems = [...prevState.items[prevState.currentBreakpoint]];
      return {
        ...prevState,
        items: {
          [prevState.currentBreakpoint]: _.map(resizeItems, (item) =>
            item.i === action.newItem.i ? _.merge(item, action.newItem) : item
          ),
        },
      };

    case actions.WORKSPACE_CHANGE_BREAKPOINT:
      return {
        ...prevState,
        currentBreakpoint: action.breakpoint,
        items: {
          ...prevState.items,
          [action.breakpoint]:
            prevState.items[action.breakpoint] ||
            prevState.items[prevState.currentBreakpoint] ||
            [],
        },
      };

    case actions.WORKSPACE_SAVE_LAYOUT:
      // return _.merge(state, { layouts: action.layouts });
      return {
        ...prevState,
        layouts: action.layouts,
      };

    case actions.WORKSPACE_RESET:
      return initialState;

    default:
      return state;
  }
}
