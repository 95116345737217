import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, PageHeader } from 'antd';
import Content from './Content';
import ExtraContent from './ExtraContent';

function Page({ title, description, children }) {
  const contentProps = {
    description,
  };
  return (
    <>
      <Row gutter={24}>
        <Col xl={16} lg={16} md={24} sm={24} xs={24}>
          <PageHeader title={title} style={{ marginBottom: 24 }}>
            <div className="provider-wrap">
              <div className="provider-content">
                <Content {...contentProps} />
              </div>
              <div className="extraContent">
                <ExtraContent />
              </div>
            </div>
          </PageHeader>
          {children}
        </Col>
      </Row>
    </>
  );
}

Page.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  loading: PropTypes.bool,
  inner: PropTypes.bool,
};

export default Page;
