// These actions are App specific, such as media queries and environments
/* eslint-disable import/prefer-default-export */
import reduxifyServices from 'feathers-redux';
import client from '../feathers';

// Create Redux actions and reducers for Feathers services
const rawServices = reduxifyServices(client, [
  'authManagement',
  'users',
  'keys',
  'contact',
  'providers',
  'changelog',
  'quota',
  'content/posts',
  'integrations/cryptocompare',
  'integrations/cryptocompare/setup',
  'integrations/paradigm/setup',
  'integrations/coinmetrics/setup',
  'integrations/nomics/setup',
  'integrations/cointracking/setup',
  'integrations/glassnode/setup',
  'stripe/checkout',
  'stripe/customer',
  'stripe/plan',
  'stripe/subscription',
  'stripe/subscription-item',
]);

export const actions = {
  ...rawServices,
};
