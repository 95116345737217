function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  transition: all 0.5s ease;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  /* transition: all 0.5s ease; */\n\n  #request-button-dropdown .ant-btn {\n    padding: 0;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
import { Row } from 'antd';
export var RequestPanelWrapper = styled.div(_templateObject());
export var StyledRow = styled(Row)(_templateObject2());