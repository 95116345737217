/* eslint-disable react/prefer-stateless-function */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';
import SearchPage from './SearchPage';
import { view as WrapWithHits } from '../../components/algolia/wrap-with-hits';
import { searchIndices } from './config';
import AlgoliaComponentWrapper from './algoliaComponent.style';

import './View.less';

const { TabPane } = Tabs;

function genTabs(hitsPerPage, dispatch) {
  return _.map(searchIndices, (value) => {
    const searchConfig = {
      ...value.algolia,
      searchParameters: {
        ...value.algolia.searchParameters,
        hitsPerPage,
      },
    };

    return (
      <TabPane tab={value.key} key={value.key}>
        <WrapWithHits
          {...searchConfig}
          hitsElement={
            <SearchPage
              hitsPerPage={hitsPerPage}
              onShowSizeChange={(hitsPerPage) =>
                dispatch({ type: 'BROWSE_SET_HITS_PER_PAGE', hitsPerPage })
              }
            />
          }
        />
      </TabPane>
    );
  });
}

function Browse(props) {
  const { activeKey, hitsPerPage } = useSelector((state) => state.browse);
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState(genTabs(hitsPerPage, dispatch));

  useEffect(() => {
    setTabs(genTabs(hitsPerPage, dispatch));
  }, [hitsPerPage, dispatch]);

  return (
    <AlgoliaComponentWrapper>
      <Tabs
        activeKey={activeKey}
        onChange={(key) => dispatch({ type: 'UPDATE_ACTIVE_TAB', key })}
      >
        {tabs}
      </Tabs>
    </AlgoliaComponentWrapper>
  );
}

export default Browse;
