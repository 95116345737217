import React from 'react';
import _ from 'lodash';
import { Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

const { Option } = Select;

function onBlur() {
  // console.log('blur');
}

function onFocus() {
  // console.log('focus');
}

function onSearch(val) {
  // console.log('search:', val);
}

export default function WidgetSelect({ i }) {
  const { currentBreakpoint, items } = useSelector((state) => state.workspace);
  const currentItems = items[currentBreakpoint];
  const widget = _.find(currentItems, (item) => item.i === i);
  const filteredWidgets = useSelector(
    (state) => state.workspace.filteredWidgets
  );
  const dispatch = useDispatch();

  const CHANGE_COMPONENT = (value) => {
    const newItem = _.merge({}, widget, { widgetType: value });
    return dispatch({ type: 'WORKSPACE_UPDATE_ITEM', item: newItem });
  };

  return (
    <Select
      showSearch
      style={{ width: 200 }}
      placeholder="Select a widget"
      optionFilterProp="children"
      onChange={(value) => CHANGE_COMPONENT(value)}
      onFocus={onFocus}
      onBlur={onBlur}
      onSearch={onSearch}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {filteredWidgets.map((option) => (
        <Option key={option.widgetType} value={option.widgetType}>
          {option.name}
        </Option>
      ))}
    </Select>
  );
}
