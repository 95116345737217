import React from 'react';
import _ from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Avatar, Collapse, Tooltip, Typography, Input } from 'antd';
import IntegrationCard from './IntegrationCard';
import ToggleActive from './ToggleActive';
import helpers from './_helpers';
import { LOGO_BLACK } from '../../../../config';

import './apiKeys.css';

const { Panel } = Collapse;
const { Text, Paragraph, Title } = Typography;

export default function View({ services }) {
  const { truncate } = helpers(services);

  const { screenWidth } = useSelector((state) => state.app);
  const currentUser = useSelector((state) => state.users.data);
  const keys = useSelector(
    (state) => state.keys.queryResult.data,
    shallowEqual
  );

  const primaryKey = _.get(currentUser, ['primaryKey'], null);

  const isMobile = screenWidth < 391;

  return (
    <>
      <Collapse>
        <Panel
          header={
            <Text ellipsis>
              <Avatar src={LOGO_BLACK} size={isMobile ? 20 : 32} />
              {isMobile ? truncate('Cryptosheets') : 'Cryptosheets'}
            </Text>
          }
          key="1"
          extra={[
            <Tooltip title="Cryptosheets api key is sent with every request.">
              <InfoCircleOutlined /> <ToggleActive active disabled />
            </Tooltip>,
          ]}
        >
          <Paragraph>
            <Title level={4}>API Key</Title>
            <Input.Password style={{ width: 200 }} value={primaryKey} />
          </Paragraph>
        </Panel>
      </Collapse>
      {keys.map((key) => (
        <IntegrationCard {...key} />
      ))}
    </>
  );
}
