// This reducer is App specific, such as media queries and environments
import { createSelector } from 'reselect';
import { actions } from './actions';

const normalize = (value, compare = '') => {
  const v = value.toLowerCase().trim();
  const c = compare.toLowerCase().trim();
  return c.includes(v);
};

// The initial state of the App
export const initialState = {
  isCacheFinished: false,
  error: null,
  status: 'initial',
  screenWidth: typeof window === 'object' ? window.innerWidth : null,
  breakpoint: 'xs',
  globalSearchValue: '',
  globalSearch: [
    {
      title: 'Providers',
      children: [],
    },
  ],
  endpoints: [],
  providers: [],
};

const providersSelector = (state) => state.app.providers;
const endpointsSelector = (state) => state.app.endpoints;
const globalSearchTerm = (state, value) => value;

export const providerSearch = createSelector(
  [providersSelector, globalSearchTerm],
  (providers, value) => {
    const pSearch = providers.filter((p) => {
      const { title } = p;
      return normalize(value, title);
    });
    return pSearch.slice(0, 3);
  }
);
export const endpointSearch = createSelector(
  [endpointsSelector, globalSearchTerm],
  (endpoints, value) => {
    const eSearch = endpoints.filter((p) => {
      const { title } = p;
      return normalize(value, title);
    });
    return eSearch.slice(0, 3);
  }
);

export const breakpoints = {
  xs: 320, // Google and Excel
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
};

const getBreakpoint = (screenWidth) =>
  Object.keys(breakpoints).find((key) => breakpoints[key] >= screenWidth) ||
  'xl';

/* eslint-disable default-case, no-param-reassign */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.IS_CACHE_FINISHED:
      return {
        ...state,
        isCacheFinished: action.payload,
      };
    case actions.INITIAL_GLOBAL_SEARCH_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actions.INITIAL_GLOBAL_SEARCH_SUCCESS:
      return {
        ...state,
        providers: action.globalSearch[0].children,
        endpoints: action.globalSearch[1].children,
      };
    case actions.INITIAL_GLOBAL_SEARCH_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.SEND_TO_SHEET_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actions.SEND_TO_SHEET_SUCCESS:
      return {
        ...state,
      };
    case actions.SEND_TO_SHEET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.UPDATE_GLOBAL_SEARCH:
      return {
        ...state,
        globalSearchValue: action.globalSearchValue,
        globalSearch: action.globalSearch,
      };
    case actions.SCREEN_RESIZE:
      return {
        ...state,
        screenWidth: action.screenWidth,
        breakpoint: getBreakpoint(action.screenWidth),
      };
    case actions.RESET_INITIAL_STATE:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
