import React, { useRef, useState } from 'react';
import { useRequest } from '@umijs/hooks';
import moment from 'moment';
import _ from 'lodash';
import { Button, Empty, Input, Avatar, Card, Typography } from 'antd';
import { Link } from 'react-router-dom';

import helpers from '../../../util/helpers';

const { Paragraph } = Typography;
const { Search } = Input;
const { strings } = helpers;
const { prettify } = strings;

function filterEndpoints(endpoints, search) {
  search = search.toUpperCase();
  if (search) {
    return endpoints.filter((endpoint) =>
      endpoint.name.toUpperCase().includes(search)
    );
  }
  return endpoints;
}

function getData({ pageSize = 10, offset, dataSource }) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        total: dataSource.length,
        list: dataSource.slice(offset, offset + pageSize),
      });
    }, 1);
  });
}

export default function AllEndpoints({
  endpoints,
  provider: { name: providerName },
  image,
}) {
  const [filteredEndpoints, setFilteredEndpoints] = useState(endpoints);
  const containerRef = useRef(null);

  function onChange(event) {
    const { value } = event.target;
    setFilteredEndpoints(filterEndpoints(endpoints, value));
  }

  const all = useRequest(
    (result) =>
      getData({
        pageSize: 10,
        offset: result?.list?.length || 0,
        dataSource: filteredEndpoints,
      }),
    {
      loadMore: true,
      refreshDeps: [filteredEndpoints],
      ref: containerRef,
      isNoMore: (result) =>
        result ? result.list.length >= result.total : false,
    }
  );

  const { data, loading, loadingMore, loadMore, noMore } = all;

  const renderFooter = () => (
    <>
      {!noMore && (
        <Button onClick={loadMore} loading={loadingMore}>
          {loadingMore ? 'Loading more' : 'Click to load more'}
        </Button>
      )}
      {noMore && <span>No more data</span>}
      <span style={{ float: 'right', fontSize: 12 }}>total: {data.total}</span>
    </>
  );

  return (
    <div ref={containerRef} style={{ height: 600, overflowY: 'auto' }}>
      <Card
        className="projectList"
        style={{ marginBottom: 24 }}
        title={
          <Search
            placeholder={`Search Data from ${providerName}`}
            className="isoSearchNotes"
            onChange={onChange}
          />
        }
        bordered={false}
        loading={false}
        bodyStyle={{ padding: 0 }}
      >
        {!_.isEmpty(data) ? (
          data.list.map(({ endpointId, name, description, created_at }) => (
            <Card.Grid
              id="providerCard"
              className="projectGrid"
              key={endpointId}
              style={{ height: 200 }}
            >
              <Card bodyStyle={{ padding: 0 }} bordered={false}>
                <Card.Meta
                  title={
                    <div className="cardTitle">
                      <Avatar size="small" src={image} />
                      <Link to={`/browse/${providerName}/${name}`}>
                        {prettify(name)}
                      </Link>
                    </div>
                  }
                  description={
                    <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                      {description}
                    </Paragraph>
                  }
                />
                <div className="projectItemContent">
                  <Link to={`/browse/${providerName}/${name}`}>View</Link>
                  {created_at && (
                    <span className="datetime" title={created_at}>
                      {moment(created_at).fromNow()}
                    </span>
                  )}
                </div>
              </Card>
            </Card.Grid>
          ))
        ) : (
          <Empty
            description={<span>No data. Try expanding your search.</span>}
          />
        )}
      </Card>
      {!loading && renderFooter()}
    </div>
  );
}
