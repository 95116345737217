/* eslint-disable react/sort-comp */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { Select, Typography } from 'antd';

const { Option } = Select;
const { Text } = Typography;

class WidgetSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: undefined,
    };
  }

  handleChange = value => {
    this.setState({ value });
  };

  handleSelect = widgetType => {
    const { item, searchLocation, addItem, updateItem } = this.props;
    console.log(item);
    switch (searchLocation) {
      case 'widgetPanel':
        return updateItem(item, { widgetType });
      default:
        return addItem({ widgetType });
    }
  };

  render() {
    const sortedOptions = _.sortBy(this.props.filteredWidgets, 'name');
    const selectOptions = _.map(sortedOptions, d => (
      <Option key={d.widgetType} value={d.widgetType}>
        <Text>{d.name}</Text>
        <br />
        <Text type="secondary">{d.description}</Text>
      </Option>
    ));
    return (
      <Select
        showSearch
        placeholder="Search and add widgets"
        value={this.state.searchValue}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        style={{ minWidth: 200 }}
        defaultActiveFirstOption={false}
        notFoundContent={null}
        filterOption={(input, option) =>
          option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {selectOptions}
      </Select>
    );
  }
}

export default function WrapWidgetSelect(props) {
  const { filteredWidgets } = useSelector(state => state.workspace);
  const dispatch = useDispatch();

  const ADD_ITEM = item => dispatch({ type: 'WORKSPACE_ADD_ITEM', item });
  const UPDATE_ITEM = (item, data) => {
    const newItem = _.merge({}, item, data);
    return dispatch({ type: 'WORKSPACE_UPDATE_ITEM', item: newItem });
  };

  const widgetSelectProps = {
    filteredWidgets,
    addItem: ADD_ITEM,
    updateItem: UPDATE_ITEM,
    ...props,
  };
  return <WidgetSelect {...widgetSelectProps} />;
}
