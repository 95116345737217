/* eslint-disable class-methods-use-this */
import React, { useState } from 'react';
import _ from 'lodash';
import { BookOutlined, ReadOutlined } from '@ant-design/icons';
import {
  Alert,
  Button,
  Spin,
  PageHeader,
  Row,
  Col,
  Tooltip,
  Typography,
} from 'antd';
import { connect } from 'react-redux';
import { useRequest } from '@umijs/hooks';
import { useHotkeys } from '@cryptosheets/hooks';
import { Console, YoutubeButton } from '@cryptosheets/components';
import { unicornCase } from '@cryptosheets/util';
import URI from 'urijs';
import client from '../../feathers';
import helpers from '../../util/helpers';

import { actions as endpointActions } from './actions';
import { actions as authActions } from '../../account/actions';
import { getPlan } from '../account/reducer';

// import Admin from './Admin';
import NotLoggedIn from './UserNotLoggedIn';
import ErrorBoundary from './ErrorBoundary';
import NotFound from './components/NotFound';
import Admin from './Admin';

const { Paragraph } = Typography;
const { strings } = helpers;
const { prettify } = strings;

function fetchEndpoint(endpointName, providerName) {
  const query = {
    $eager: '[provider, parameters.enums]',
    $joinRelation: 'provider',
    [`refBROWSEextCETSendpoints.name`]: {
      $like: endpointName,
    },
    [`provider.name`]: {
      $like: providerName,
    },
  };
  return client
    .service('endpoints')
    .find({
      query,
    })
    .then(({ data }) => data)
    .catch((e) => console.log(e));
}

const HeaderActions = ({ endpoint }) => {
  const { endpointId, name, apiDocumentation } = endpoint;

  const providerName = _.get(endpoint, ['provider', 'name'], 'provider');
  const endpointDocumentation = `https://docs.cryptosheets.com/providers/${unicornCase(
    providerName
  )}/${unicornCase(name)}/`;

  const youtubeProps = {
    key: 'youtube-documentation',
    endpointId,
    buttonStyle: {
      shape: 'circle',
    },
  };

  return [
    // endpoint.endpointId,
    <Tooltip key="name-documentation" title={`View ${name} documentation`}>
      <Button
        shape="circle"
        href={endpointDocumentation}
        target="_blank"
        icon={<ReadOutlined />}
      />
    </Tooltip>,
    <Tooltip
      key="provider-documentation"
      title={`View ${providerName} documentation`}
    >
      <Button
        shape="circle"
        href={apiDocumentation}
        target="_blank"
        icon={<BookOutlined />}
      />
    </Tooltip>,
    <YoutubeButton {...youtubeProps} />,
  ];
};

const InsideContent = (props) => {
  const { endpoint } = props;
  const { description } = endpoint;
  return (
    <div className="provider-insideContent">
      <Paragraph ellipsis={{ rows: 2, expandable: true }}>
        {description}
      </Paragraph>
    </div>
  );
};

function Endpoint(props) {
  const {
    computedMatch,
    loggedIn,
    isAdmin,
    location,
    screenWidth,
    host,
  } = props;

  const {
    endpoint: endpointName,
    provider: providerName,
  } = computedMatch.params;

  const [modalVisible, setModalVisible] = useState(false);
  const [customFunction, setCustomFunction] = useState('QUERYA');
  const [adminVisible, setAdminVisible] = useState(false);
  useHotkeys('ctrl+alt+e', () => setAdminVisible(!adminVisible));

  const { loading, error, data } = useRequest(() =>
    fetchEndpoint(endpointName, providerName)
  );

  if (loading)
    return (
      <Spin tip="Loading...">
        <Alert
          message="Hang tight..."
          description="We are fetching your data..."
          type="info"
        />
      </Spin>
    );
  if (error) return <p>Error :(</p>;

  const [endpoint] = data;

  if (!endpoint) {
    return <NotFound />;
  }

  const { name } = endpoint;

  const PageTitle = () => <span>{prettify(name)} </span>;

  const { scenarioId } = URI.parseQuery(location.search);

  return (
    <>
      <PageHeader
        title={<PageTitle />}
        style={{ marginBottom: 1, padding: 5 }}
        extra={<HeaderActions endpoint={endpoint} />}
      >
        <div className="provider-wrap">
          <div className="provider-content">
            <InsideContent endpoint={endpoint} />
          </div>
          {/* <div className="extraContent">
            <ExtraContent />
          </div> */}
        </div>
      </PageHeader>
      <div>
        {endpoint && (
          <Console
            endpoint={endpoint}
            isLoggedIn={loggedIn}
            isAdmin={isAdmin}
            host={host}
            initialParameters={endpoint.parameters}
            handleNotLoggedIn={() => setModalVisible(true)}
            screenWidth={screenWidth}
            scenarioId={scenarioId}
            customFunction={customFunction}
            setCustomFunction={setCustomFunction}
          />
        )}
        {!loggedIn && (
          <NotLoggedIn
            visible={modalVisible}
            handleOk={() => setModalVisible(false)}
            handleCancel={() => setModalVisible(false)}
            redirect={location.pathname}
            {...props}
          />
        )}
        {adminVisible && (
          <Admin
            isAdmin={isAdmin}
            endpoint={endpoint}
            visible={adminVisible}
            setVisible={setAdminVisible}
          />
        )}
      </div>
    </>
  );
}

function EndpointWrapper(props) {
  return (
    <Row gutter={24}>
      <Col xl={16} lg={16} md={24} sm={24} xs={24}>
        <ErrorBoundary>
          <Endpoint {...props} />
        </ErrorBoundary>
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  const planId = state.account.dailyPlanId;
  return {
    loggedIn: state.users.data !== null,
    isAdmin: state.users.data && state.users.data.role === 'admin',
    data: state.endpoint.data,
    host: state.auth.environment,
    keys: state.account.keys,
    status: state.endpoint.status,
    error: state.endpoint.error,
    currentPlan: getPlan(state, planId),
    screenWidth: state.app.screenWidth,
  };
};

const mapDispatchToProps = (dispatch, { services }) => ({
  // Auth actions for NotLoggedIn component
  login: (formValue) => {
    const { email, password } = formValue;
    // wait
    dispatch(authActions.login(email, password, services));
  },
  registerUser: (formValue) => {
    dispatch(authActions.registerUser(formValue, services));
  },
  // Endpoint actions
  load: (id) => {
    dispatch(endpointActions.loadEndpoint(id));
  },
  fetchCurrentEndpoint: (
    url,
    collectionName,
    host,
    keys,
    mainField,
    name,
    prettyName,
    currentPlan
  ) =>
    dispatch(
      endpointActions.fetchCurrentEndpoint(
        url,
        collectionName,
        host,
        keys,
        mainField,
        name,
        prettyName,
        currentPlan
      )
    ),
  resetInitialState: () => dispatch(endpointActions.resetInitialState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EndpointWrapper);
