import React from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Tooltip } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import { openInfoWidget } from '../../../components/widget/components/api';
import DropOption from '../../../components/widget/components/DropOption';
import { getActions, getPrimaryAndSecondaryAction } from '../../api';

const actions = getActions('templates');

const PrimaryAction = ({ action, template, toggleShowInfo, host }) => (
  <Tooltip title={action.name}>
    <Button
      icon={action.icon}
      type="primary"
      onClick={() => action.action({ item: template, toggleShowInfo, host })}
    >
      {action.name}
    </Button>
  </Tooltip>
);

const SecondaryAction = ({ action, template, toggleShowInfo, host }) => (
  <Tooltip title={action.name}>
    <Button
      icon={action.icon}
      onClick={() => action.action({ item: template, toggleShowInfo })}
    >
      {/* {action.name} */}
    </Button>
  </Tooltip>
);

export default function ActionPanel({ template, ...rest }) {
  const host = useSelector((state) => state.auth.environment);
  const showInfoModal = useSelector((state) => state.template.showInfoModal);
  const dispatch = useDispatch();

  const primaryAndSecondaryActions = getPrimaryAndSecondaryAction(
    'templates',
    host
  );

  const toggleShowInfo = () =>
    dispatch({
      type: 'TEMPLATES_TOGGLE_SHOW_INFO_MODAL',
      showInfoModal: !showInfoModal,
    });

  const filterActions = _.partition(
    actions,
    (action) => action.excludeHosts && action.excludeHosts.includes(host)
  );

  const disabledActions = filterActions[0];
  const activeActions = filterActions[1];

  const buttonProps = {
    template,
    toggleShowInfo,
    host,
  };

  const openInfoProps = { item: template, toggleShowInfo, ...rest };

  const dropOptionProps = {
    onMenuClick: (e) => openInfoWidget(e, actions, openInfoProps),
    menuOptions: [],
    buttonStyle: {
      buttonText: '',
      icon: <EllipsisOutlined />,
    },
  };

  return _.reduce(
    activeActions,
    (acc, action, index) => {
      const { primaryAction, secondaryAction } = primaryAndSecondaryActions;

      if (action.key === primaryAction) {
        acc.unshift(<PrimaryAction {...buttonProps} action={action} />);
        return acc;
      }
      if (action.key === secondaryAction) {
        acc.push(<SecondaryAction {...buttonProps} action={action} />);
        return acc;
      }
      dropOptionProps.menuOptions.push(action);

      if (index === activeActions.length - 1) {
        dropOptionProps.menuOptions = [
          ...dropOptionProps.menuOptions,
          ...disabledActions,
        ];
        acc.push(<DropOption {...dropOptionProps} />);
      }

      return acc;
    },
    []
  );
}
