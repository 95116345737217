import React from 'react';
import _ from 'lodash';
import { Descriptions, Typography, Tag } from 'antd';
import { linkify } from '@cryptosheets/util';
import { sanitizeTemplateData } from '../api';

const { Title, Paragraph, Text } = Typography;

export default function DescriptionList(props) {
  const { description, tags, ...rest } = props;

  const splitTags = _.split(tags, ',');

  const sanitizedTemplateData = sanitizeTemplateData(rest);

  return (
    <Typography>
      <div>
        {_.map(splitTags, (tag) => (
          <Tag color="green">{tag}</Tag>
        ))}
      </div>
      <Title level={4}>Description</Title>
      <Paragraph>{description}</Paragraph>
      <Descriptions title="Info" column={1} bordered>
        {_.map(sanitizedTemplateData, (value, key) => {
          function createMarkup() {
            return { __html: _.isString(value) ? linkify(value) : value };
          }
          return (
            <>
              <Descriptions.Item label={_.startCase(key)}>
                <Text>
                  <div dangerouslySetInnerHTML={createMarkup()} />
                </Text>
              </Descriptions.Item>
            </>
          );
        })}
      </Descriptions>
    </Typography>
  );
}
