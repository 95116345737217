import React, { useEffect, useState } from 'react';
import URI from 'urijs';

import Post from '../../../components/post';

const defaultSlug = `/templates/documentation/`;

function Documentation({ url }) {
  const [slug, setSlug] = useState(null);

  console.log(url);

  useEffect(() => {
    async function getSlug() {
      try {
        const path = new URI(url).path();
        if (!path) {
          setSlug(defaultSlug);
        } else {
          setSlug(path);
        }
      } catch (e) {
        setSlug(defaultSlug);
      }
    }
    getSlug();
  }, [url]);

  return <Post slug={slug} />;
}

export default Documentation;
