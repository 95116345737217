import React, { Component } from 'react';
import { Modal, Button } from 'antd';
import Login from '../../../account/login';
import Register from '../../../account/register';

export default class NotLoggedIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginOrRegister: 'register',
    };
  }

  setLoginOrRegister(component) {
    this.setState({ loginOrRegister: component });
  }

  render() {
    const { loginOrRegister } = this.state;
    let content;
    if (loginOrRegister === 'register') {
      content = (
        <>
          <Register {...this.props} />
          Or{' '}
          <Button onClick={() => this.setLoginOrRegister('login')} type="link">
            Log in!
          </Button>
        </>
      );
    } else {
      content = (
        <div
          style={{
            '.modal-account-content .login-form h3': {
              height: '20px',
              lineHeight: '20px',
              padding: '0 0 35px 0',
              textAlign: 'center',
              font:
                '20px "microsoft yahei",Helvetica,Tahoma,Arial,"Microsoft jhengHei",sans-serif',
            },
            '.modal-account-content .login-form-forgot': { cssFloat: 'right' },
            '.modal-account-content .login-form-button': { width: '100%' },
          }}
        >
          <div className="modal-account-content">
            <Login {...this.props} />
          </div>
          Or{' '}
          <Button
            onClick={() => this.setLoginOrRegister('register')}
            type="link"
          >
            Register!
          </Button>
        </div>
      );
    }
    return (
      <>
        <Modal
          title="Login or Register to pull data:"
          style={{ top: 20 }}
          visible={this.props.visible}
          onOk={this.props.handleOk}
          onCancel={this.props.handleCancel}
        >
          {content}
        </Modal>
      </>
    );
  }
}
