import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import Humanize from 'humanize-plus';
import DropOption from './DropOption';

export default function SubscriptionDetails(props) {
  const allPlans = useSelector((state) => state.stripePlan.queryResult.data);
  const menuOptions = allPlans
    .reduce((acc, plan) => {
      if (!_.isEmpty(plan.metadata)) {
        acc.push({
          key: plan.id,
          name: `${Humanize.formatNumber(
            plan.metadata.monthlyApiRequests,
            0
          )} (${plan.nickname || plan.id})`,
        });
      }
      return acc;
    }, [])
    .sort((a, b) => parseFloat(a.name) - parseFloat(b.name));
  const dropOptionProps = {
    onMenuClick: props.onMenuClick,
    menuOptions,
    buttonStyle: {
      buttonText: props.buttonText,
      type: 'ghost',
    },
  };
  return <DropOption {...dropOptionProps} />;
}
