/* eslint-disable no-nested-ternary */
import React from 'react';
import { Divider } from 'antd';
import Subscriptions from './Subscriptions';
import Quota from './Quota';

import './billing.css';

function Billing() {
  return (
    <>
      <div className="quotaView">
        <Quota />
      </div>
      <Divider />
      <div className="billingView">
        <Subscriptions />
      </div>
    </>
  );
}

export default Billing;
