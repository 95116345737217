import React from 'react';
import Post from '../../../components/post';

export default function Usage() {
  return (
    <article className="markdown api-container article-start">
      <Post slug="/templates/how-to-use-cryptosheets-templates/" />
    </article>
  );
}
