/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { connectHits } from 'react-instantsearch-dom';
import { Card } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import Dynamic from './components/dynamic';
import WidgetPanel from './panel/widgetPanel';

function Widget(props) {
  const { i, width, height, hits, widgetOptions } = props;

  const { items, currentBreakpoint } = useSelector((state) => state.workspace);
  const widget = _.find(items[currentBreakpoint], (item) => item.i === i);

  const title = _.startCase(widget.widgetType);

  const scrollbarStyle = {
    width,
    height: height - 26,
  };

  const componentProps = {
    i,
    hits,
    widgetOptions,
    widgetWidth: width,
    widgetHeight: height,
    ...widget,
  };

  return (
    <Card
      className="panelContainer"
      title={title}
      bordered={false}
      extra={<WidgetPanel {...componentProps} />}
      loading={false}
      bodyStyle={{ padding: 0, flex: 1 }}
    >
      <Scrollbars style={{ ...scrollbarStyle }}>
        <Dynamic {...componentProps} />
      </Scrollbars>
    </Card>
  );
}

const ConnectedWidget = connectHits(Widget);

export default ConnectedWidget;
