import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, Result, Skeleton, Typography } from 'antd';
import _ from 'lodash';
import { useRequest } from '@umijs/hooks';
import { Markdown } from '@cryptosheets/components';
import client from '../../feathers';
import Container from './Container';
import Footer from './Footer';
import LinkFix from './LinkFix';

const { Title } = Typography;

const components = { a: LinkFix };

async function findPost(slug) {
  const { data } = await client
    .service('content/posts')
    .find({ query: { slug } })
    .catch((e) => ({
      data: [],
    }));

  return data;
}

export default function Post({ slug }) {
  console.log(slug);
  const { data, error, loading } = useRequest(() => findPost(slug), {
    refreshDeps: [slug],
  });

  if (loading) {
    return <Skeleton active />;
  }

  if (error) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/">
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    );
  }

  const [record] = data;

  if (_.isNil(record)) {
    return (
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/">
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    );
  }

  const { title, content } = record;

  console.log(title);

  return (
    <Container>
      <Title>{title}</Title>
      <Markdown markdown={content} components={components} />
      <Divider />
      <Footer />
    </Container>
  );
}
