import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import config from './typeConfig';

import './exception.css';

export class Exception extends Component {
  static defaultProps = {
    backText: 'Back to home',
    redirect: '/',
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      type = '404',
      actions,
      redirect,
      backText,
      img,
      title,
      desc,
    } = this.props;
    const pageType = type in config ? type : '404';
    return (
      <div className="exception">
        <div className="imgBlock">
          <div
            className="imgEle"
            style={{ backgroundImage: `url(${img || config[pageType].img})` }}
          />
        </div>
        <div className="content">
          <h1>{title || config[pageType].title}</h1>
          <div className="desc">{desc || config[pageType].desc}</div>
          <div className="actions">
            {actions || (
              <Link to={redirect}>
                <Button type="primary">{backText}</Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Exception;
