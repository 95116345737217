import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { FileExcelOutlined } from '@ant-design/icons';
import { Card, List, Tooltip } from 'antd';
import { actions as endpointActions } from '../../../pages/endpoint/actions';

import './section.css';

class Section extends React.Component {
  renderActivities = (item) => (
    <List.Item key={item.id}>
      <List.Item.Meta
        title={
          <span>
            {item.prettyName}: {item.collectionName}
          </span>
        }
        description={
          <span className="dateTime" title={item.updatedAt}>
            {moment(item.updatedAt).fromNow()}
          </span>
        }
      />
      <div>
        <Tooltip title="Send to sheet">
          <FileExcelOutlined
            onClick={() =>
              this.props.sendToSheetByUrl(
                item.urlString,
                item.collectionName,
                this.props.host,
                this.props.keys,
                null,
                item.name,
                item.prettyName,
                this.props.currentPlan
              )
            }
          />
        </Tooltip>
      </div>
    </List.Item>
  );

  render() {
    const { sectionData } = this.props;
    return (
      <Card
        bodyStyle={{ padding: 0 }}
        bordered={false}
        className="activeCard"
        title="Recent Requests"
      >
        <List
          renderItem={(item) => this.renderActivities(item)}
          dataSource={sectionData}
          className="activitiesList"
          size="large"
        />
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  host: state.auth.environment,
  keys: state.account.keys,
});

const mapDispatchToProps = (dispatch, props) => ({
  sendToSheetByUrl: (
    url,
    collectionName,
    host,
    keys = [],
    mainField = null,
    name = null,
    prettyName = null,
    currentPlan
  ) => {
    // wait
    dispatch(
      endpointActions.fetchCurrentEndpoint(
        url,
        collectionName,
        host,
        keys,
        mainField,
        name,
        prettyName,
        currentPlan
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Section);
