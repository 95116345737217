/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { List } from 'antd';
import { Stats } from 'react-instantsearch-dom';
import Pagination from './AlgoliaPagination';
import Row from './AlgoliaRow';

import './AlgoliaHits.less';

export default class BasicList extends Component {
  render() {
    const { hits, grid, pagination = true, widgetOptions } = this.props;
    return (
      <div className="algolia-hits">
        <Stats />
        <br />
        <List
          grid={
            grid
              ? {
                  gutter: 0,
                  xs: 1,
                  sm: 2,
                  md: 2,
                  lg: 3,
                  xl: 3,
                  xxl: 3,
                }
              : false
          }
          rowKey="id"
          dataSource={hits}
          renderItem={(item) => (
            <Row widgetOptions={widgetOptions} item={item} />
          )}
        />
        {pagination ? <Pagination /> : null}
      </div>
    );
  }
}
