import React from 'react';
import _ from 'lodash';
import { useRequest } from '@umijs/hooks';
import { BookOutlined, ReadOutlined } from '@ant-design/icons';
import {
  Alert,
  Spin,
  Avatar,
  PageHeader,
  Col,
  Tooltip,
  Typography,
  Row,
} from 'antd';
import client from '../../feathers';
import AllEndpoints from './components/AllEndpoints';
import NotFound from './components/NotFound';
import Error from './components/Error';

import './provider.css';

const { Paragraph, Text } = Typography;

const defaultImage =
  'https://res.cloudinary.com/cryptosheets/image/upload/v1564412059/Logomark_-_White.png';

const Content = (props) => {
  const { provider } = props;
  const { description } = provider;
  return (
    <div className="provider-insideContent">
      <Paragraph ellipsis={{ rows: 5, expandable: true }}>
        {description}
      </Paragraph>
      <Paragraph className="provider-contentLink">
        <a href={provider.link} rel="noopener noreferrer" target="_blank">
          <Avatar icon={<BookOutlined />} />

          <Text
            style={{ marginLeft: 12, color: '#1890FF' }}
            disabled={!provider.link}
            strong
          >
            Documentation
          </Text>
        </a>
      </Paragraph>
      <p className="provider-contentLink">
        <a href={provider.content} rel="noopener noreferrer" target="_blank">
          <Avatar icon={<ReadOutlined />} />
          <Tooltip
            title={
              provider.content
                ? provider.content
                : 'No tutorials yet for this provider.'
            }
          >
            <Text
              style={{ marginLeft: 12, color: '#1890FF' }}
              disabled={!provider.content}
              strong
            >
              Tutorials
            </Text>
          </Tooltip>
        </a>
      </p>
    </div>
  );
};

const ExtraContent = (props) => {
  const { image } = props;
  const src = image || defaultImage;
  return <Avatar size={128} src={src} />;
};

function fetchProvider(providerName) {
  const params = {
    query: {
      visible: true,
      'refBROWSEextCETSproviders.name': providerName,
      $eager: '[endpoints]',
      $modifyEager: {
        endpoints: {
          visible: 1,
        },
      },
    },
    provider: undefined,
  };
  return client
    .service('providers')
    .find(_.omit(params, 'provider'))
    .then(({ data }) => data);
}

function Provider(props) {
  const { widgetData } = props;
  const providerName = widgetData.name || 'test';

  const { loading, error, data } = useRequest(() =>
    fetchProvider(providerName)
  );

  if (loading)
    return (
      <Spin tip="Loading...">
        <Alert
          message="Hang tight..."
          description="We are fetching your symbols..."
          type="info"
        />
      </Spin>
    );
  if (error) return <Error />;

  const provider = data[0];
  if (_.isEmpty(provider)) {
    // array does not exist, is not an array, or is empty
    // ⇒ do not attempt to process array
    return <NotFound />;
  }
  const { name, image, endpoints } = provider;

  const allEndpointsProps = {
    endpoints,
    provider,
    image,
  };

  return (
    <>
      <Row gutter={24}>
        <Col xl={16} lg={16} md={24} sm={24} xs={24}>
          <PageHeader title={name} style={{ marginBottom: 24 }}>
            <div className="provider-wrap">
              <div className="provider-content">
                <Content provider={provider} />
              </div>
              <div className="extraContent">
                <ExtraContent {...provider} />
              </div>
            </div>
          </PageHeader>
          <AllEndpoints {...allEndpointsProps} />
        </Col>
      </Row>
    </>
  );
}

Provider.defaultProps = {
  widgetData: {
    name: 'Cryptosheets',
  },
};

// This function wraps the provider component and gets the provider name from the window.url
function WrapWithRoute({
  computedMatch,
  widgetData = { name: 'Cryptosheets' },
  ...rest
}) {
  const name = computedMatch.params.provider;

  widgetData.name = name;

  return <Provider {...rest} widgetData={widgetData} />;
}

export { Provider };
export default WrapWithRoute;
