import React from 'react';
import {
  QuestionCircleOutlined,
  // FileExcelOutlined,
  // TwitterOutlined,
  // BookOutlined,
  // FileAddOutlined,
} from '@ant-design/icons';
import URI from 'urijs';
import { common } from '../../../widget/constants';

const defaultCommon = common.slice(1);

export default {
  scenario: [
    ...defaultCommon,
    {
      key: 'viewScenario',
      name: 'View Scenario',
      icon: <QuestionCircleOutlined />,
      action: ({ item, history }) => {
        const { objectUrl } = item;

        const { fragment } = URI.parse(objectUrl);

        return history.push(fragment);
      },
      excludeHosts: [],
    },
  ],
  default: [
    {
      key: 'noAction',
      name: 'No Actions Available',
      icon: <QuestionCircleOutlined />,
      action: () => {},
    },
  ],
};
