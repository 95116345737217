function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  margin: 0 0 0 16px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  font-size: 10px;\n  color: #7d7d7d;\n\n  /* {\n    margin: 0 0 0 16px;\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    font-size: 10px;\n    color: #7d7d7d;\n} */\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
export var HeaderNoteWrapper = styled.span(_templateObject());