import client from '../../../feathers';

function updateTreeData(list, key, children) {
  return list.map((node) => {
    if (node.key === key) {
      return { ...node, children };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }

    return node;
  });
}

async function getProviders() {
  const SERVICE = 'providers';
  const QUERY = {
    $limit: -1,
    $select: ['name'],
    visible: true,
    $sort: { name: 1 },
  };

  const params = {
    query: QUERY,
  };
  const response = await client.service(SERVICE).find(params);

  return response;
}

export async function updateProviders(key, setTreeData) {
  let functionType = 'QUERYA';

  try {
    const [fType] = key.split('-');
    functionType = fType;
  } catch (e) {
    // Do nothing
  }

  const providers = await getProviders().then((res) =>
    res.map((provider) => {
      const { name, providerId } = provider;
      return {
        title: name,
        key: `${functionType}-PROVIDER-${providerId}`,
        children: [],
      };
    })
  );
  setTreeData((origin) => {
    const newTree = updateTreeData(origin, key, providers);
    return newTree;
  });
}

async function getEndpoints(providerId) {
  const SERVICE = 'endpoints';
  const QUERY = {
    providerId,
    visible: true,
    $limit: -1,
    $select: ['name'],
    $sort: { name: 1 },
  };

  const params = {
    query: QUERY,
  };
  const response = await client.service(SERVICE).find(params);

  return response;
}

export async function updateEndpoints(key, setTreeData) {
  const [functionType, , providerId] = key.split('-');

  const endpoints = await getEndpoints(providerId).then((res) =>
    res.map((endpoint) => {
      const { name, endpointId } = endpoint;
      return {
        title: name,
        key: `${functionType}-ENDPOINT-${endpointId}`,
        isLeaf: true,
      };
    })
  );
  setTreeData((origin) => {
    const newTree = updateTreeData(origin, key, endpoints);
    return newTree;
  });
}
