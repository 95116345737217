import { asyncBatchProcess, getType } from '@cryptosheets/util';
import client from '../../../feathers';

export function getData(parameterId) {
  const SERVICE = 'parameters';

  const params = {
    query: {
      $eager: 'enums',
    },
  };

  return client.service(SERVICE).get(parameterId, params);
}

export function updateParamsToEnums(parameterId, enumId) {
  const SERVICE = 'params-to-enums';

  const data = {
    parameterId,
    enumId,
  };

  return client.service(SERVICE).create(data);
}

export async function updateEnum(enumeration, { parameterId }) {
  const SERVICE = 'enums';

  const data = {
    type: getType(enumeration),
    value: enumeration,
  };

  const enumObj = await client.service(SERVICE).create(data);
  const { enumId } = enumObj;

  return updateParamsToEnums(parameterId, enumId);
}

export async function updateEnums(parameterId, enums) {
  await asyncBatchProcess(enums, updateEnum, 5, { parameterId });

  await client.service('cache/clear/all').find({ query: {} });
}

export function actOnEachLine(value, func) {
  const lines = value.replace(/\r\n/g, '\n').split('\n');

  return func(lines);
}
