import _ from 'lodash';

export function customFunctionsToTreeData(customFunctions) {
  const types = [];
  const treeData = [];

  _.reduce(
    customFunctions,
    (result, customFunction) => {
      // Step 1: Get all function types.
      const { name, functionType } = customFunction;
      const properType = _.startCase(functionType);

      let child = {
        title: name,
        key: name,
        isLeaf: true,
      };

      if (name === 'QUERYA') {
        child = {
          ...child,
          key: `${name}-PROVIDERS`,
          isLeaf: false,
          children: [],
        };
      }

      if (name === 'QUERY') {
        child = {
          ...child,
          key: `${name}-PROVIDERS`,
          isLeaf: false,
          children: [],
        };
      }

      if (!types.includes(functionType)) {
        types.push(functionType);

        treeData.push({
          title: properType,
          key: `${properType}-CATEGORY`,
          children: [],
        });
      }

      const recordIndex = _.findIndex(treeData, (o) => o.title === properType);
      treeData[recordIndex].children.push(child);

      return result;
    },
    []
  );

  return _.orderBy(treeData, 'title');
}
