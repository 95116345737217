import React from 'react';
import _ from 'lodash';
import { QuestionCircleOutlined } from '@ant-design/icons';
import resourceData from '../widget/constants';

export function getActions(resourceName) {
  const widgetOptions = _.find(resourceData, {
    name: _.capitalize(resourceName),
  });
  const defaultActions = [
    {
      key: 'noAction',
      name: 'No Actions Available',
      icon: <QuestionCircleOutlined />,
      action: () => {},
    },
  ];
  return _.get(widgetOptions, 'actions', defaultActions);
}
