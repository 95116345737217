import React from 'react';

export const ServicesContext = React.createContext();

export default function AppServices({ children, services }) {
  return (
    <ServicesContext.Provider value={services}>
      {children}
    </ServicesContext.Provider>
  );
}
