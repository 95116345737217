function getExcel(callback) {
  try {
    return window.Excel.run(callback);
  } catch (e) {
    console.log('Unable to get excel instance, Error: ', e);
  }
}

export default {
  run: (callback) => getExcel(callback),
};
