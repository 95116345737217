/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Typography, Tabs, Row, Col } from 'antd';
import Overview from './Overview';
import Usage from './Usage';
import TemplateFrame from './TemplateFrame';

import './Resource.css';
import Documentation from './Documentation';

const { TabPane } = Tabs;
const { Paragraph, Title } = Typography;

function callback(key) {
  console.log(key);
}

const outerTabs = [
  {
    tab: 'Info',
    key: 1,
    content: ({ template, screenWidth }) => {
      const fileList = [
        {
          uid: template.templateId,
          name: template.name,
          status: 'done',
          url: template.thumb,
          preview: template.thumb,
          addedCategory: 'test',
        },
      ];
      const templateFrameProps = {
        template,
        fileList,
        previewImage: template.thumb,
      };
      return (
        <div className="content-window">
          {screenWidth < 768 ? (
            <Tabs defaultActiveKey="1" onChange={callback}>
              <TabPane tab="Resource" key={1}>
                <Title level={4}>Description</Title>
                <Paragraph>{template.description}</Paragraph>
                <TemplateFrame {...templateFrameProps} />
              </TabPane>
              <TabPane tab="Overview" key={2}>
                <Overview template={template} />
              </TabPane>
            </Tabs>
          ) : screenWidth < 1035 ? (
            <Row gutter={8}>
              <Col span={12}>
                <TemplateFrame {...templateFrameProps} />
              </Col>
              <Col span={12}>
                <Overview template={template} />
              </Col>
            </Row>
          ) : (
            <TemplateFrame {...templateFrameProps} />
          )}
        </div>
      );
    },
  },
  {
    tab: 'Documentation',
    key: 2,
    content: ({ template }) => {
      const documentationUrl = _.get(template, ['documentationUrl'], null);
      return (
        <div className="">
          <Documentation url={documentationUrl} />
        </div>
      );
    },
  },
  {
    tab: 'Usage',
    key: 3,
    content: ({ template }) => (
      <div className="">
        <Usage />
      </div>
    ),
  },
];

export default function Content(props) {
  const screenWidth = useSelector((state) => state.app.screenWidth);
  const { template } = props;

  return (
    <div className="card-container">
      <Tabs type="card" defaultActiveKey="1" onChange={callback}>
        {outerTabs.map((tab) => {
          const { content, ...rest } = tab;
          return (
            <TabPane {...rest}>{content({ template, screenWidth })}</TabPane>
          );
        })}
      </Tabs>
    </div>
  );
}
