/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  QuestionCircleOutlined,
  FileExcelOutlined,
  TwitterOutlined,
  BookOutlined,
  FileAddOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import AlgoliaHits from './components/AlgoliaHits';
import EmptyWidget from './panel/empty';
import SymbolSummary from './components/symbolSummary';
import iFrameDisplay from './components/iFrameDisplay';
import WidgetInfo from './components/WidgetInfo';
import TradingView from './components/tradingView';
import Explorer from './components/explorer';
import { Provider } from '../../pages/provider/view';

import TemplateSummary from './components/templateSummary';
import ExcelChart from './components/excelChart';
import {
  insertTemplateIntoWorkbookById,
  openNewWorkbookByTemplateId,
  handleSendToSheet,
} from './components/api';

export const common = [
  {
    key: 'showInfo',
    name: 'Show Info',
    icon: <QuestionCircleOutlined />,
    action: (actionProps) => {
      if (actionProps.addItem) {
        return actionProps.addItem(
          actionProps.item,
          actionProps.widgetOptions.infoComponent || 'widget'
        );
      }

      if (actionProps.toggleShowInfo) {
        actionProps.toggleShowInfo();
      }
    },
  },
  {
    key: 'sendToWorksheet',
    name: 'Send to Worksheet',
    icon: <FileExcelOutlined />,
    action: (actionProps) => {
      const { item } = actionProps;

      return handleSendToSheet(item);
    },
    // excludeHosts: ['web'],
  },
  {
    key: 'shareToTwitter',
    name: 'Share',
    icon: <TwitterOutlined />,
    action: (actionProps) => {
      const canUseDOM = !!(
        typeof window !== 'undefined' &&
        window.document &&
        window.document.createElement
      );

      const { item } = actionProps;

      const defaultUrl = 'https://app.cryptosheets.com';

      // const shareUrl = _.isNil(item.objectUrl) ? defaultUrl : item.objectUrl;

      const shareUrl = _.has(item, 'objectUrl')
        ? item.objectUrl
        : canUseDOM
        ? window.location.href
        : defaultUrl;

      const twitterShareLink = `https://twitter.com/intent/tweet?url=${_.replace(
        shareUrl,
        '#',
        '%23'
      )}`;
      return window.open(twitterShareLink);
    },
  },
];

export const researchActions = [
  {
    key: 'openResearchItem',
    name: 'Open Research Item',
    icon: <BookOutlined />,
    action: (actionProps) => {
      const { objectUrl } = actionProps.item;

      return window.open(objectUrl);
    },
  },
];

export const templateActions = [
  {
    key: 'insertIntoCurrent',
    name: 'Insert into Current Workbook',
    icon: <FileAddOutlined />,
    action: (actionProps) => {
      const templateId =
        _.get(actionProps, ['item', 'metadigmID']) ||
        _.get(actionProps, ['item', 'templateId']) ||
        3;

      return insertTemplateIntoWorkbookById([templateId]);
    },
    excludeHosts: ['web', 'sheets'],
  },
  {
    key: 'openNewWorkbookByTemplateId',
    name: 'Open New Workbook',
    icon: <FileExcelOutlined />,
    action: (actionProps) => {
      const templateId =
        _.get(actionProps, ['item', 'metadigmID']) ||
        _.get(actionProps, ['item', 'templateId']) ||
        3;

      const host = _.get(actionProps, ['host'], 'excel');

      return openNewWorkbookByTemplateId([templateId], host);
    },
    excludeHosts: [],
  },
];

// Connected specifies if component is connected to Algolia
const options = [
  {
    widgetType: 'parameters',
    name: 'Parameters',
    description: 'Get a detailed list of all parameters.',
    connected: true,
    component: AlgoliaHits,
    indexName: 'paradigm-parameters',
    actions: [...common],
  },
  {
    widgetType: 'content',
    name: 'Content',
    description:
      'All global content including APIs, Content, Templates, Widgets and more.',
    connected: true,
    component: AlgoliaHits,
    indexName: 'CRYPTOSHEETS_SEARCH',
    actions: [...common],
  },
  {
    widgetType: 'apis',
    name: 'API',
    description: 'All API endpoints from all providers.',
    connected: true,
    component: AlgoliaHits,
    indexName: 'CRYPTOSHEETS_SEARCH',
    searchParameters: {
      filters: 'objectType:api',
    },
    actions: [...common],
  },
  {
    widgetType: 'widget',
    name: 'Widget',
    connected: false,
    visible: false,
    component: WidgetInfo,
    actions: [...common],
  },
  {
    widgetType: 'emptyWidget',
    name: 'Empty Widget',
    connected: false,
    visible: false,
    component: EmptyWidget,
    actions: [...common],
  },
  {
    widgetType: 'symbols',
    name: 'Symbols',
    description: 'Get detailed list of all symbols.',
    connected: true,
    component: AlgoliaHits,
    indexName: 'paradigm-symbols',
    actions: [...common],
  },
  {
    widgetType: 'symbolSummary',
    name: 'Symbol Summary',
    connected: true,
    visible: false,
    component: SymbolSummary,
    actions: [...common],
  },
  {
    widgetType: 'tradingView',
    name: 'Trading View',
    description: 'Open a live TradingView panel.',
    connected: true,
    component: TradingView,
    actions: [...common],
  },
  {
    widgetType: 'explorer',
    name: 'Explorer',
    connected: true,
    visible: false,
    excludeHosts: ['web', 'sheets'],
    component: Explorer,
    actions: [...common],
  },
  {
    widgetType: 'web',
    name: 'Web',
    description: 'Open content from the web.',
    connected: true,
    visible: true,
    component: iFrameDisplay,
    actions: [...common],
  },
  {
    widgetType: 'providers',
    name: 'Providers',
    description: 'Discover all data providers in the Cryptosheets ecosystem.',
    connected: true,
    component: AlgoliaHits,
    infoComponent: 'provider',
    indexName: 'CRYPTOSHEETS_SEARCH',
    searchParameters: {
      filters: 'objectSubtype:provider',
    },
    actions: [...common],
  },
  {
    widgetType: 'provider',
    name: 'Provider',
    connected: true,
    visible: false,
    component: Provider,
    // indexName: 'CRYPTOSHEETS_SEARCH',
    // searchParameters: {
    //   filters: 'objectSubtype:provider',
    // },
    actions: [...common],
  },
  {
    widgetType: 'excelChart',
    name: 'Quick Start Templates',
    description: 'Quick start custom function templates for core data.',
    connected: true,
    component: ExcelChart,
    actions: [...common],
  },
  // {
  //   widgetType: 'templates',
  //   name: 'Templates',
  //   connected: true,
  //   excludeHosts: ['web', 'sheets'],
  //   component: Templates,
  //   actions: [...common],
  // },
  {
    widgetType: 'templates',
    name: 'Templates',
    description: 'Charts, models, dashboards, calculators and more.',
    connected: true,
    visible: true,
    component: AlgoliaHits,
    indexName: 'CRYPTOSHEETS_SEARCH',
    searchParameters: {
      filters: 'objectType:template',
    },
    actions: [...common, ...templateActions],
    primaryAction: 'openNewWorkbookByTemplateId',
    secondaryAction: 'shareToTwitter',
    defaultAction: 'showInfo',
  },
  {
    widgetType: 'templateSummary',
    name: 'Template Summary',
    connected: true,
    component: TemplateSummary,
    excludeHosts: ['web', 'sheets'],
    visible: false,
    actions: [...common],
  },
  {
    widgetType: 'cluster',
    name: 'Scenario Clusters',
    description: 'Crypto market scenarios & data clusters.',
    connected: true,
    visible: true,
    component: AlgoliaHits,
    indexName: 'CRYPTOSHEETS_SEARCH',
    searchParameters: {
      filters: 'objectType:scenario',
    },
    actions: [...common],
  },
  {
    widgetType: 'explorer',
    name: 'Explorer',
    description: 'Cryptosheets Smart Lookup.',
    connected: true,
    visible: true,
    // component: AlgoliaHits,
    // indexName: 'CRYPTOSHEETS_SEARCH',
    // searchParameters: {
    //   filters: 'objectType:cluster',
    // },
    actions: [...common],
  },
  {
    widgetType: 'research',
    name: 'Research',
    description: 'Institutional crypto research.',
    connected: true,
    visible: true,
    component: AlgoliaHits,
    indexName: 'CRYPTOSHEETS_SEARCH',
    searchParameters: {
      filters: 'objectSubtype:research',
    },
    actions: [...common, ...researchActions],
  },
  {
    widgetType: 'charts',
    name: 'Chart Widgets',
    description: 'Charts, graphs & widgets.',
    connected: true,
    visible: true,
    component: AlgoliaHits,
    indexName: 'CRYPTOSHEETS_SEARCH',
    searchParameters: {
      filters: 'objectType:widget',
    },
    actions: [...common],
  },
];

export default options;
