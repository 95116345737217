/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Card, Space } from 'antd';
import AlgoliaHighlight from '../../widget/components/AlgoliaHighlight';
import { extractAppLink } from './_helpers';

import './ScenarioGrid.less';

const { Meta } = Card;

const gridStyle = {
  textAlign: 'center',
  whiteSpace: 'none',
};

const defaultImage =
  'https://res.cloudinary.com/cryptosheets/image/upload/v1564412059/Logomark_-_White.png';

const ScenarioGrid = (props) => {
  const { hits } = props;
  return (
    <Card title="Scenarios">
      <div className="ais-Hits">
        {hits.map((hit) => {
          const displayedImage = hit.image || hit.thumbnailUrl || defaultImage;
          return (
            <Card.Grid
              id="providerCard"
              style={gridStyle}
              hoverable="true"
              className="providerCard"
              key={hit.objectID}
            >
              <Link to={extractAppLink(hit)}>
                <Space direction="vertical">
                  <Avatar shape="square" size={128} src={displayedImage} />
                </Space>
                <Space direction="vertical">
                  <Meta
                    title={<AlgoliaHighlight hit={hit} attribute="title" />}
                    description={hit.description}
                  />
                </Space>
              </Link>
            </Card.Grid>
          );
        })}
      </div>
    </Card>
  );
};

export default ScenarioGrid;
