import CryptoJS from 'crypto-js';
import { CLIENT_TO_STREAM } from '../../../../config';
import { useServices } from '../../../../hooks/use-services';

export function encrypt(data) {
  try {
    const cipherText = CryptoJS.AES.encrypt(data, CLIENT_TO_STREAM).toString();

    return cipherText;
  } catch (e) {
    console.log(e);
    return 'Error encrypting data.';
  }
}

export function decrypt(cipherText) {
  try {
    const bytes = CryptoJS.AES.decrypt(cipherText, CLIENT_TO_STREAM);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);

    return originalText;
  } catch (e) {
    console.log(e);
    return 'Error decrypting data.';
  }
}

export default () => {
  const feathersServices = useServices();

  function truncate(str, len = 17) {
    return str.length > len ? `${str.substring(0, len - 3)}...` : str;
  }

  function updateKey(id, data) {
    return feathersServices.keys.patch(id, data);
  }

  function deleteKey(id, userId) {
    return feathersServices.keys.remove(id);
  }

  function toggleKeyActive(id, checked) {
    return feathersServices.keys.patch(id, { active: !!checked });
  }

  return {
    truncate,
    updateKey,
    deleteKey,
    toggleKeyActive,
  };
};
