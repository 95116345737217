/* eslint-disable func-names */
async function verifyExcel() {
  try {
    await window.Office.onReady();
  } catch (error) {
    throw new Error('Office failed to initialize');
  }
}

const { Excel } = window;

function errorHandler(err) {
  console.log(err);
}

async function getActiveCellAddress() {
  await Excel.run(async (context) => {
    const myWorkbook = context.workbook;
    const activeCell = myWorkbook.getActiveCell();
    activeCell.load('address');

    await context.sync();

    console.log(`The active cell is ${activeCell.address}`);
    return activeCell.address;
  });
}

async function getActiveCellValues() {
  await Excel.run(async (context) => {
    const myWorkbook = context.workbook;
    const activeCell = myWorkbook.getActiveCell();
    activeCell.load('values');

    await context.sync();

    console.log(`The active cell values are ${activeCell.values}`);
    return activeCell.values;
  });
}

async function onSelectionChange(event) {
  await Excel.run(async (context) => {
    console.log(`The selected range has changed to: ${event.address}`);
    return getActiveCellValues();
  });
}

async function registerSelectionChangedHandler(cb) {
  await window.Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    sheet.onSelectionChanged.add(cb);
    await context.sync();
  });
}

async function selectRange() {
  await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    const range = sheet.getRange('B7:C7');
    range.select();

    await context.sync();
  });
}

function sendTokenToCustomFunction(key, token) {
  return window.OfficeRuntime.storage.setItem(key, token).then(
    function () {
      console.log(`Success: Item with key '${key}' saved to Storage.`);
    },
    function (error) {
      console.log(
        `Error: Unable to save item with key '${key}' to Storage. ${error}`
      );
    }
  );
}

function getTokenFromCustomFunction(key) {
  console.log('Running get token function');
  return window.OfficeRuntime.storage.getItem(key).then(
    function (result) {
      return result;
    },
    function (error) {
      console.log(
        `Error: Unable to read item with key '${key}' from Storage. ${error}`
      );
    }
  );
}

/** Default helper for invoking an action and handling errors. */
async function tryCatch(callback) {
  try {
    await verifyExcel();
    await callback();
  } catch (error) {
    // Note: In a production add-in, you'd want to notify the user through your add-in's UI.
    console.error(error);
    return error;
  }
}

export default {
  errorHandler,
  getActiveCellAddress,
  getActiveCellValues,
  onSelectionChange,
  registerSelectionChangedHandler,
  selectRange,
  tryCatch,
  sendTokenToCustomFunction,
  getTokenFromCustomFunction,
};
