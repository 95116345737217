// const _ = require('lodash');
// const moment = require('moment');
import _ from 'lodash';
import moment from 'moment';

function prettyDate(date) {
  return moment(date).format('L');
}

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

function sanitizeTemplateData(data) {
  // Start with dates
  return _.reduce(
    data,
    function (result, value, key) {
      const dateFields = ['created_at', 'updated_at'];
      if (dateFields.includes(key)) {
        _.merge(result, { [key]: prettyDate(value) });
        return result;
      }
      if (key === 'fileLength') {
        _.merge(result, { [key]: formatBytes(value) });
        return result;
      }
      _.merge(result, { [key]: value });
      return result;
    },
    {}
  );
}

export { sanitizeTemplateData };
