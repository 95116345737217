import React from 'react';
import _ from 'lodash';
import { Input, message } from 'antd';
import feathers from '../../feathers';

export default {
  methods: {
    onChange: (service, target) => {
      const { editable, templateId, key, ...rest } = target;

      if (_.startsWith(key, 'NEW')) {
        // This is a create item new object
        return feathers
          .service(service)
          .create(rest)
          .then(() => message.success('Successfully created item'))
          .catch((e) =>
            message.success(`Failed to create item. Reason: ${e.message}`)
          );
      }

      return feathers
        .service(service)
        .patch(key, rest)
        .then(() => message.success('Successfully updated item'))
        .catch((e) =>
          message.success(`Failed to update item. Reason: ${e.message}`)
        );
    },
    onDelete: (service, target) => {
      const { key } = target;

      if (_.startsWith(key, 'NEW')) {
        return;
      }

      return feathers
        .service(service)
        .remove(key)
        .then(() => message.success('Successfully deleted item'))
        .catch((e) =>
          message.success(`Failed to delete item. Reason: ${e.message}`)
        );
    },
  },
  templates: {
    columns: [
      {
        title: 'templateId',
        dataIndex: 'templateId',
        key: 'templateId',
        width: '20%',
        render: (text, record) => {
          if (record.editable) {
            return (
              <Input
                value={text}
                autoFocus
                onChange={(e) => this.handleFieldChange(e, 'name', record.key)}
                onKeyPress={(e) => this.handleKeyPress(e, record.key)}
                placeholder="templateId"
              />
            );
          }
          return text;
        },
      },
    ],
    query: {
      $select: [
        'templateId',
        'name',
        'description',
        'version',
        'tab',
        'visible',
        'category',
        'type',
        'created_at',
        'updated_at',
        'tags',
      ],
      $limit: 400,
      $sort: {
        created_at: 0,
      },
    },
  },
};
