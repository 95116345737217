import React, { useState } from 'react';
import _ from 'lodash';
import { useRequest } from '@umijs/hooks';
import { useHotkeys } from '@cryptosheets/hooks';
import { Alert, Spin } from 'antd';
import { MosaicWindow } from 'react-mosaic-component';
import { WrappedMosaic } from './index.style';
import client from '../../../feathers';

import 'react-mosaic-component/react-mosaic-component.css';

import { WrappedExplorer } from './Explorer';
import UniConsole from './UniConsole';

async function getCustomFunctions() {
  const SERVICE = 'custom-functions';
  const QUERY = {
    $limit: -1,
    $eager: '[parameters]',
    visible: true,
    $sort: { name: 1 },
  };

  const params = {
    query: QUERY,
  };
  const response = await client.service(SERVICE).find(params);

  return response;
}

function Uni() {
  const [visible, setVisible] = useState(false);
  const [customFunction, setCustomFunction] = useState('OHLCV');
  const [endpointId, setEndpointId] = useState(4556);
  const [currentNode, setCurrentNode] = useState({
    direction: 'column',
    first: 'Explorer',
    second: 'Console',
    // splitPercentage: 40,
  });
  useHotkeys('ctrl+alt+e', () => setVisible(!visible));

  const { loading, error, data } = useRequest(() => getCustomFunctions());

  if (loading)
    return (
      <Spin tip="Loading...">
        <Alert
          message="Hang tight..."
          description="We are fetching your data..."
          type="info"
        />
      </Spin>
    );

  if (error) return <div>error</div>;

  if (_.isEmpty(data)) {
    return <div>no data</div>;
  }

  const explorerProps = {
    customFunctions: data,
    setCustomFunction,
    setEndpointId,
  };

  const uniConsoleProps = {
    endpointId,
    setEndpointId,
    customFunction,
    setCustomFunction,
    allCustomFunctions: data,
  };

  const TILE_MAP = {
    Explorer: <WrappedExplorer {...explorerProps} />,
    Console: <UniConsole {...uniConsoleProps} />,
  };

  return (
    <WrappedMosaic
      renderTile={(id, path) => (
        <MosaicWindow
          path={path}
          createNode={() => 'new'}
          title={id}
          toolbarControls={[null, null, null, null]}
        >
          {TILE_MAP[id]}
        </MosaicWindow>
      )}
      value={currentNode}
      onChange={setCurrentNode}
    />
  );
}

Uni.defaultProps = {};

export default Uni;
