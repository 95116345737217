import _ from 'lodash';
import { message } from 'antd';
import { useServices } from '../../../../hooks/use-services';

export default () => {
  const feathersServices = useServices();

  const stripeCustomer = feathersServices['stripe/customer'];
  const stripeSubscription = feathersServices['stripe/subscription'];
  const { users } = feathersServices;

  function fetchUser(userId) {
    return users.get(userId);
  }

  async function fetchOrCreateStripeCustomer(email, token) {
    // Let's see if we have the user in Stripe
    try {
      const stripeUser = await stripeCustomer.find({ query: { email } });

      const { data } = stripeUser;

      if (_.isEmpty(data)) {
        // User does not exist...let's create
        return stripeCustomer.create({ email, source: token.id });
      }

      // We have a user object, let's return it
      return data[0];
    } catch (e) {
      console.log(e);
    }
  }

  function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  function fetchQuota(userId) {
    const { quota } = feathersServices;
    return quota.get(userId);
  }

  function fetchSubscriptions(stripeCustomerId) {
    return stripeSubscription.find({
      query: { customer: stripeCustomerId },
    });
  }

  function fetchCurrentSubscription(subscriptionId) {
    // if (!subscriptionId) {
    //   throw new Error('No subscription id');
    // }
    return feathersServices['stripe/subscription'].get(subscriptionId);
  }

  function createSubscription(stripeCustomerId, planId) {
    const body = {
      customer: stripeCustomerId,
      items: [{ plan: planId }],
    };

    return stripeSubscription
      .create(body)
      .then((res) => {
        console.log(res);
        return fetchSubscriptions(stripeCustomerId);
      })
      .catch((e) => console.log('error creating sub', e));
  }

  async function createOrUpdateSubscription(
    token,
    currentUser,
    newPlanId,
    currentSubscription,
    currentSubscriptionItem
  ) {
    const sId = _.get(currentUser, ['stripeCustomerId'], null);
    const stripeSubscriptionItem = feathersServices['stripe/subscription-item'];

    try {
      let stripeCustomerId;
      if (!sId) {
        const stripeUser = await fetchOrCreateStripeCustomer(
          currentUser.email,
          token
        );
        stripeCustomerId = stripeUser.id;
      } else {
        stripeCustomerId = sId;
        await stripeCustomer.update(stripeCustomerId, { source: token.id });
      }

      // Gold scenario. Customer already has an existing item. Easy update
      if (currentSubscriptionItem) {
        const updateBody = {
          plan: newPlanId,
        };
        return stripeSubscriptionItem
          .update(currentSubscriptionItem.id, updateBody)
          .then(() =>
            message.success('Successfully created/updated subscription')
          );
      }

      // Next, check if user has subscription
      if (currentSubscription) {
        // User already has an existing subscription..this gets complicated.
        // We need to see if they already have the subscription item we want to add

        const createItemBody = {
          subscription: currentSubscription.id,
          plan: newPlanId,
        };
        return stripeSubscriptionItem
          .create(createItemBody)
          .then(() =>
            message.success('Successfully created/updated subscription')
          )
          .catch((e) => console.log(e));
      }

      // User does not currently have a subscription...let's create it
      if (stripeCustomerId) {
        const createBody = {
          customer: stripeCustomerId,
          items: [
            {
              plan: newPlanId,
            },
          ],
        };
        return stripeSubscription
          .create(createBody)
          .then(() =>
            message.success('Successfully created/updated subscription')
          );
      }

      message.success('Successfully created/updated subscription');
    } catch (e) {
      console.log(e);
      message.error(
        'Unable to update profile. Please contact support@cryptosheets.com.'
      );
    }
  }

  function fetchPlans() {
    return feathersServices['stripe/plan'].find();
  }

  function createCustomer(email, token) {
    return stripeCustomer.create({ email, source: token });
  }

  async function createCustomerAndUpdateUser(currentUser, token) {
    try {
      const newUser = await createCustomer(currentUser.email);

      await users.patch(currentUser._id, {
        stripeCustomerId: newUser.value.id,
      });
    } catch (e) {
      console.log(e);
      message.error('Error creating customer');
    }
  }

  function fetchStripeCustomer(stripeCustomerId, email) {
    return stripeCustomer.get(stripeCustomerId);
  }

  function downgradePlanToFree(subscriptionId, planId) {
    const body = {
      items: [
        {
          id: planId,
          plan: 'PLAN000A',
        },
      ],
    };

    return stripeSubscription
      .patch(subscriptionId, body)
      .then(() => message.success('Successfully downgraded profile'))
      .catch(() =>
        message.error(
          'Unable to downgrade profile. Please contact support@cryptosheets.com.'
        )
      );
  }
  return {
    feathersServices,
    fetchUser,
    fetchQuota,
    formatNumber,
    createCustomerAndUpdateUser,
    createSubscription,
    createOrUpdateSubscription,
    fetchStripeCustomer,
    fetchSubscriptions,
    fetchCurrentSubscription,
    fetchPlans,
    downgradePlanToFree,
  };
};
