import React from 'react';
import { Modal } from 'antd';
import AdminEndpoint from '../admin/endpoint';

export default function Admin({ isAdmin, endpoint, visible, setVisible }) {
  if (!isAdmin) {
    return null;
  }

  if (!endpoint) {
    return null;
  }

  const { endpointId } = endpoint;

  return (
    <div>
      <Modal
        title="Update Endpoint"
        visible={visible}
        width="100%"
        onOk={() => setVisible(!visible)}
        onCancel={() => setVisible(!visible)}
      >
        <AdminEndpoint endpointId={endpointId} />
      </Modal>
    </div>
  );
}
