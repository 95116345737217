import { TabsWrapper, TabPane } from './components/ExcelGoogleTabs';
import InlineCode from './components/InlineCode';
import Linkify from './components/Linkify';
import Nav from './components/Nav';
import TypographyParagraph from './components/TypographyParagraph';
import TypographyText from './components/TypographyText';
import LinkFix from './components/LinkFix';
import DownloadTemplate from './components/DownloadTemplate';
import VideoPlayer from './components/VideoPlayer';
export default {
  a: LinkFix,
  'inline-code': InlineCode,
  'tab-pane': TabPane,
  'tabs-wrapper': TabsWrapper,
  // 'table-assets': TableAssets,
  // 'table-exchanges': TableExchanges,
  // 'table-providers': TableProviders,
  nav: Nav,
  linkify: Linkify,
  'typography-paragraph': TypographyParagraph,
  'typography-text': TypographyText,
  'download-template': DownloadTemplate,
  'video-player': VideoPlayer
};