import React, { useState } from 'react';
import _ from 'lodash';
import ReactResizeDetector from 'react-resize-detector';
import { Input, Tree } from 'antd';
import { customFunctionsToTreeData } from '../_.helpers';
import { updateProviders, updateEndpoints } from './_helpers';

const { Search } = Input;

const childrenTypes = {
  PROVIDERS: updateProviders,
  PROVIDER: updateEndpoints,
};

const getParentKey = (key, tree) => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (!_.isEmpty(node.children)) {
      if (node.children.some((item) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  console.log(parentKey);
  return parentKey;
};

function searchTree(tree, value, key = 'id', reverse = false) {
  const stack = [...tree];
  const results = [];
  while (stack.length) {
    const node = stack[reverse ? 'pop' : 'shift']();
    const match = node[key].match(value);
    if (match) results.push(node);
    node.children && stack.push(...node.children);
  }
  return results;
}

const Explorer = ({
  customFunctions,
  setEndpointId,
  setCustomFunction,
  height = 700,
}) => {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [treeData, setTreeData] = useState(
    customFunctionsToTreeData(customFunctions)
  );

  const onExpand = (eKeys) => {
    setExpandedKeys(eKeys);
    setAutoExpandParent(false);
  };

  const onSelect = async (selectedKeys) => {
    const [key] = selectedKeys;

    if (key) {
      const [customFunction, childType, id] = key.split('-');

      setCustomFunction(customFunction);

      switch (childType) {
        case 'ENDPOINT':
          setEndpointId(id);
          break;

        case 'CATEGORY':
          break;
        default:
          const { endpointid } = customFunctions.find(
            (func) => func.name === customFunction
          );
          setEndpointId(endpointid);
          break;
      }
    }
  };

  const onLoadData = async ({ key, children }) =>
    new Promise((resolve) => {
      if (!_.isEmpty(children)) {
        resolve();
        return;
      }

      const [, childType] = key.split('-');

      if (childType) {
        const set = childrenTypes[childType](key, setTreeData);
        if (set) {
          resolve();
        }
      }
    });

  const onSearch = (e) => {
    const { value } = e.target;
    const temp = value.replace(/[.,-/#!$%^&*;:{}=\-_`~()"'+@<>?\\[\]]/g, '');

    const eKeys = treeData
      .map((item) => {
        if (item.key.indexOf(value) > -1) {
          return getParentKey(item.key, treeData);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);

    setExpandedKeys(eKeys);
    setSearchValue(temp);
    setAutoExpandParent(true);
  };

  const loop = (data) => {
    if (!searchValue || searchValue === '') {
      return treeData;
    }
    const dynamicRegex = `.*(${searchValue.toUpperCase()}){1}.*`;
    return searchTree(treeData, dynamicRegex, 'title');
  };

  return (
    <>
      <Search
        style={{ marginBottom: 8 }}
        placeholder="Search"
        onChange={onSearch}
      />
      <Tree
        showLine
        treeData={loop(treeData)}
        loadData={onLoadData}
        onSelect={onSelect}
        expandedKeys={expandedKeys}
        onExpand={onExpand}
        autoExpandParent={autoExpandParent}
        height={height}
      />
    </>
  );
};

export const WrappedExplorer = (props) => (
  <ReactResizeDetector handleWidth handleHeight>
    {({ width, height }) => <Explorer {...props} height={height} />}
  </ReactResizeDetector>
);
export default Explorer;
