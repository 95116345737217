import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { view as CustomIcon } from '../custom-icon';
import data from './data';
import { LOGO_ALTERNATE_WHITE, LOGOMARK_WHITE } from '../../config';

import './sidebar.css';

function filterSidePanel(data, host, isAdmin) {
  return data.reduce((acc, currVal) => {
    // 1. Check for admin
    if (currVal.admin) {
      if (!isAdmin) {
        return acc;
      }
    }

    // 2. Check for environments
    if (!_.isEmpty(currVal.excludeHosts)) {
      const { excludeHosts } = currVal;
      if (_.includes(excludeHosts, host)) {
        return acc;
      }
    }
    acc.push(currVal);
    return acc;
  }, []);
}

const { SubMenu } = Menu;
const SubMenuItem = (item) => (
  <SubMenu
    id="sidebarItem"
    key={item.key}
    title={
      <span>
        {item.icon === 'formula' ? <CustomIcon type="formula" /> : item.icon}
        <span>{item.label}</span>
      </span>
    }
  >
    {item.children.map((subItem) => (
      <Menu.Item key={subItem.key}>
        {subItem.staticLink ? (
          <a href={subItem.url} target="_blank" rel="noopener noreferrer">
            {subItem.label}
          </a>
        ) : (
          <Link to={subItem.url}>{subItem.label}</Link>
        )}
      </Menu.Item>
    ))}
  </SubMenu>
);

const MenuItem = (item) => (
  <Menu.Item id="sidebarItem" key={item.key}>
    <Link to={item.url}>
      {item.icon === 'formula' ? <CustomIcon type="formula" /> : item.icon}

      <span>{item.label}</span>
    </Link>
  </Menu.Item>
);

const Sidebar = ({ collapsed, setCollapsed, host, isAdmin }) => {
  const logo = collapsed ? LOGOMARK_WHITE : LOGO_ALTERNATE_WHITE;
  const [menuItems] = useState(data);
  const [current, setCurrent] = useState('overview');
  const hidden = collapsed ? '' : '';
  const hiddenStyle = {
    visibility: `${hidden}`,
  };

  return (
    <div className="ant-layout-sider-children" style={hiddenStyle}>
      {collapsed ? (
        <div className="logo">
          <MenuUnfoldOutlined style={hiddenStyle} onClick={setCollapsed} />
        </div>
      ) : (
        <div className="logo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
          <MenuFoldOutlined style={hiddenStyle} onClick={setCollapsed} />
        </div>
      )}
      <Menu
        theme="dark"
        onClick={(e) => setCurrent(e.key)}
        style={{ padding: '16px 0', width: '100%' }}
        selectedKeys={[current]}
        mode="inline"
        inlineCollapsed={collapsed}
      >
        {filterSidePanel(menuItems, host, isAdmin).map((item) => {
          if (item.children instanceof Array && item.active) {
            return SubMenuItem(item);
          }
          return item.active && MenuItem(item);
        })}
      </Menu>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  host: state.auth.environment,
  isAdmin: state.users.data && state.users.data.role === 'admin',
});

export default connect(mapStateToProps)(Sidebar);
