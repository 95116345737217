import { actions } from './actions';

// The initial state of the App
export const initialState = {
  loading: false,
  error: false,
  status: 'initial',
  showInfoModal: false,
};

/* eslint-disable default-case, no-param-reassign */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.TEMPLATES_TOGGLE_SHOW_INFO_MODAL:
      return {
        ...state,
        showInfoModal: !state.showInfoModal,
      };
    default:
      return state;
  }
}
