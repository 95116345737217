/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { Pagination } from 'antd';
import { connectPagination } from 'react-instantsearch-dom';

const AlgoliaPagination = (props) => {
  // console.log({ defaultCurrent, pageSize, nbPages });
  const {
    defaultCurrent = 1,
    nbPages = 1,
    refine,
    hitsPerPage = 10,
    onShowSizeChange,
  } = props;
  return (
    <Pagination
      defaultCurrent={defaultCurrent}
      total={hitsPerPage * nbPages}
      onChange={refine}
      pageSize={hitsPerPage}
      pageSizeOptions={[10, 20, 50, 100]}
      onShowSizeChange={(current, size) => onShowSizeChange(size)}
    />
  );
};

const CustomPagination = connectPagination(AlgoliaPagination);

export default CustomPagination;
