import React from 'react';
import _ from 'lodash';
import Page from '../../components/page/view';
import AllResource from './AllResource';
import { view as WrapWithHits } from '../../components/algolia/wrap-with-hits';
import { config } from './config';

export function AllContent(props) {
  console.log(props);
  const contentType = _.get(
    props,
    ['computedMatch', 'params', 'contentType'],
    'default'
  );

  const { pageProps } = config[contentType] || config.default;
  const { indexProps } = config[contentType] || config.default;

  return (
    <Page {...pageProps}>
      <WrapWithHits
        {...indexProps}
        hitsElement={<AllResource {...pageProps} />}
      />
    </Page>
  );
}

export default AllContent;
