/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Menu, Dropdown, Typography } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useIsMobile } from '../hooks/use-is-mobile';
import { actions as authActions } from './actions';

import './account.css';

import AccountRoute from './accountRoute';
import Login from './login';
import Register from './register';
import Forgot from './forgot';
import Contact from './contact';
import Mosaic from './mosaic';
import Reset from './reset';
import Verify from './verify';
import ResendVerify from './resend-verify';

const { Paragraph } = Typography;
const logo =
  'https://res.cloudinary.com/cryptosheets/image/upload/v1564412059/logomark_white.svg';

const menu = (
  <Menu>
    <Menu.Item key="0">
      <Link to="/">Home</Link>
    </Menu.Item>
    <Menu.Item key="1">
      <a
        href="https://docs.cryptosheets.com/introduction"
        target="_blank"
        rel="noopener noreferrer"
      >
        Help
      </a>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="3" disabled>
      <Link to="/contact">Contact</Link>
    </Menu.Item>
  </Menu>
);

const NavActions = (props) => {
  const isMobile = useIsMobile();
  if (isMobile) {
    return (
      <Dropdown overlay={menu}>
        <Button
          shape="circle"
          icon={<MenuOutlined />}
          className="account-header-dropdown"
          onClick={(e) => e.preventDefault()}
        />
      </Dropdown>
    );
  }

  return (
    <nav>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <a
            href="https://docs.cryptosheets.com/introduction"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help
          </a>
        </li>
        <li>
          <Link to="/contact">Contact</Link>
        </li>
      </ul>
    </nav>
  );
};

const AccountPage = (props) => {
  const { match } = props;
  const { path } = match;
  return (
    <>
      <div className="account-header">
        <div className="account-header-wrapper">
          <header>
            <Link to="/">
              <img src={logo} alt="cryptosheets main logo" />
              <h2 style={{ color: 'white' }}>Cryptosheets</h2>
            </Link>
            <div className="account-nav-wrapper">
              <NavActions />
            </div>
          </header>
        </div>
      </div>
      <div className="account-content">
        <AccountRoute exact path="/login" component={Login} {...props} />
        <AccountRoute exact path="/register" component={Register} {...props} />
        <AccountRoute exact path="/forgot" component={Forgot} {...props} />
        <AccountRoute exact path="/contact" component={Contact} {...props} />
        <AccountRoute exact path="/mosaic" component={Mosaic} {...props} />
        <AccountRoute
          exact
          path="/resend"
          component={ResendVerify}
          {...props}
        />
        <AccountRoute path="/reset/:token" component={Reset} {...props} />
        <AccountRoute path="/verify/:token" component={Verify} {...props} />
        {path === '/login' ? (
          <Paragraph>
            <Link to="/register">Register</Link>
          </Paragraph>
        ) : path === '/register' ? (
          <Paragraph>
            <Link to="/login">Login</Link>
          </Paragraph>
        ) : (
          <Paragraph>
            <div style={{ display: 'flex' }}>
              <Link to="/login" style={{ flexGrow: 1 }}>
                Login
              </Link>
              <Link to="/register">Register</Link>
            </div>
          </Paragraph>
        )}
      </div>

      <div className="footer">All rights reserved © CryptoSheets, Inc 2019</div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedIn: state.users.data !== null,
  status: state.auth.status,
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch, { services }) => {
  const { authManagement, contact } = services;

  return {
    login: (formValue) => {
      const { email, password } = formValue;
      // wait
      dispatch(authActions.login(email, password, services));
    },
    registerUser: (formValue) => {
      dispatch(authActions.registerUser(formValue, services));
    },
    getSessionDetails: (sessionId) =>
      services[`stripe/checkout`].get(sessionId).catch((e) => console.log(e)),
    forgot: (data) => authManagement.create(data).catch((e) => console.log(e)),
    resendVerificationEmail: (data) =>
      authManagement.create(data).catch((e) => console.log(e)),
    reset: (formValue, token) => {
      dispatch(authActions.resetPassword(formValue, token));
    },
    sendForm: (data) => contact.create(data).catch((e) => console.log(e)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
