import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

export default function NotFound({ resource = 'resource' }) {
  return (
    <Result
      status="404"
      title="404"
      subTitle={`Sorry, we could not find the ${resource} you requested.`}
      extra={
        <Link to="/">
          <Button type="primary">Back Home</Button>
        </Link>
      }
    />
  );
}
