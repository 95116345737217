/* eslint-disable class-methods-use-this */
import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Intercom from 'react-intercom';
import {
  sendTokenToCustomFunction,
  getTokenFromCustomFunction,
} from '@cryptosheets/util';
import moesif from 'moesif-browser-js';
import Heap from 'react-heap';
import options from './components/widget/constants';
import feathers from './feathers';
import { PATHS } from './constants';
import { view as Account } from './account';
import { view as Loader } from './components/loader';
import { view as Browse } from './pages/browse';
import { view as Home } from './pages';

import { actions as appActions } from './appGlobal/actions';
import { actions as authActions } from './account/actions';
import { actions as providerActions } from './pages/provider/actions';
import { actions as workspaceActions } from './pages/workspace/actions';
import helpers from './account/_helpers';

import { INTERCOM_APP_ID, MOESIF_APP_ID } from './config';
import { loadAuthToken, loadRefreshInterval } from './util/localStorage';
// import { cacheSetup } from './cache';

import './App.less';

const pages = {
  [PATHS.ParadigmTest]: {
    component: Loader,
    path: '/paradigmTest',
  },
  [PATHS.Forgot]: {
    component: Account,
    path: '/forgot',
  },
  [PATHS.Home]: {
    component: Home,
    path: '/',
  },
  [PATHS.Login]: {
    component: Account,
    path: '/login',
  },
  [PATHS.Register]: {
    component: Account,
    path: '/register',
  },
  [PATHS.Contact]: {
    component: Account,
    path: '/contact',
  },
  [PATHS.ResendVerify]: {
    component: Account,
    path: '/resend',
  },
  [PATHS.Mosaic]: {
    component: Account,
    path: '/mosaic',
  },
  [PATHS.Reset]: {
    component: Account,
    path: '/reset/:token',
  },
  [PATHS.Search]: {
    component: Browse,
    path: '/search',
  },
  [PATHS.Verify]: {
    component: Account,
    path: '/verify/:token',
  },
};

async function checkLocalAndSendToFunctions() {
  const authToken = loadAuthToken();
  const refreshInterval = loadRefreshInterval();
  const tokenExists = await getTokenFromCustomFunction('authToken');
  const refreshIntervalExists = await getTokenFromCustomFunction(
    'refreshInterval'
  );

  if (!_.isEqual(authToken, tokenExists)) {
    sendTokenToCustomFunction('authToken', authToken);
  }
  if (!_.isEqual(refreshInterval, refreshIntervalExists)) {
    sendTokenToCustomFunction('refreshInterval', refreshInterval);
  }
}

class App extends React.Component {
  async componentDidMount() {
    const {
      isAdmin,
      setHost,
      setFilteredWidgets,
      authSuccess,
      services,
    } = this.props;

    const { appSetup } = helpers(services);

    // cacheSetup().then(() => this.props.isCacheFinished(true));

    moesif.init({
      applicationId: MOESIF_APP_ID,
      // add other option here.
    });

    const host = await this.determineHost();

    feathers
      .reAuthenticate()
      .then(({ user }) => {
        const { _id, ...userData } = user;
        authSuccess(user);
        appSetup(user);
        moesif.identifyUser(_id, userData);
      })
      .catch((e) => console.log('Auth error', e));

    setHost(host);
    setFilteredWidgets(options, host, isAdmin);
    if (window.OfficeRuntime && window.OfficeRuntime.storage) {
      this.customFunctionsHeartbeat = setInterval(
        checkLocalAndSendToFunctions,
        5000
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.customFunctionsHeartbeat);
  }

  async determineHost() {
    try {
      const { host } = window.location;
      if (host.includes('google')) {
        return 'sheets';
      }

      const data = await window.Office.onReady();

      if (data.host === 'Excel') {
        return 'excel';
      }
      return 'web';
    } catch (e) {
      return 'web';
    }
  }

  render() {
    const { currentUser, appName, environment, services } = this.props;

    let user;
    if (window && currentUser && currentUser.email) {
      user = {
        app_id: INTERCOM_APP_ID,
        email: currentUser.email,
        id: currentUser._id,
        username: currentUser.username,
        role: currentUser.role,
        appName,
        environment,
      };
    }

    return (
      <>
        <Switch>
          {Object.keys(pages).map((path) => (
            <Route
              exact
              path={path}
              // component={pages[path].component}
              render={(props) => {
                const Component = pages[path].component;
                return <Component {...props} services={services} />;
              }}
              key={path}
            />
          ))}
          <Route render={(props) => <Home {...props} services={services} />} />
        </Switch>
        <Heap
          appId="1997928135"
          userId={user && user.id ? user.id : 'anonymous'}
          userData={user}
        />
        <Intercom appID={INTERCOM_APP_ID} {...user} />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.users.data !== null,
  currentUser: state.users.data,
  isAdmin: state.users.data && state.users.data.role === 'admin',
  appName: state.auth.appName,
  environment: state.auth.environment,
});

const mapDispatchToProps = (dispatch, props) => ({
  isCacheFinished: (payload) => dispatch(appActions.isCacheFinished(payload)),
  authSuccess: (user) => dispatch(authActions.authSuccess(user)),
  setHost: (host) => {
    dispatch(authActions.setHost(host));
  },
  setFilteredWidgets: (data, host, isAdmin) => {
    dispatch(workspaceActions.workspaceFilterWidgets(data, host, isAdmin));
  },
  loadProviders: () => {
    dispatch(providerActions.loadProviders());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
