/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useHotkeys } from '@cryptosheets/hooks';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Avatar, Badge, Card } from 'antd';
import AlgoliaHighlight from '../../widget/components/AlgoliaHighlight';

const { Meta } = Card;
const { Ribbon } = Badge;

const gridStyle = {
  textAlign: 'center',
};

const defaultImage =
  'https://res.cloudinary.com/cryptosheets/image/upload/v1564412059/Logomark_-_White.png';

const ProviderGrid = (props) => {
  const user = useSelector((state) => state.users.data);
  const [adminVisible, setAdminVisible] = useState(false);
  useHotkeys('alt+e', () => setAdminVisible(!adminVisible));

  const isAdmin = user?.role === 'admin';

  const visibility = isAdmin && adminVisible ? 'visible' : 'hidden';

  const { hits } = props;
  return (
    <Card title="Providers">
      <div className="ais-Hits">
        {hits.map((hit) => {
          const displayedImage = hit.image || hit.thumbnailUrl || defaultImage;

          return (
            <Card.Grid
              id="providerCard"
              style={gridStyle}
              hoverable="true"
              className="providerCard"
              key={hit.objectID}
            >
              <Link to={`/browse/${hit.name}`}>
                <Ribbon style={{ visibility }} text={hit.providerId}>
                  <Avatar size={128} src={displayedImage} />
                </Ribbon>
                <Meta
                  title={<AlgoliaHighlight hit={hit} attribute="name" />}
                  description={hit.description}
                />
              </Link>
            </Card.Grid>
          );
        })}
      </div>
    </Card>
  );
};

export default ProviderGrid;
