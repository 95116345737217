import React from 'react';
import { view as Exception403 } from '../../pages/exception/403';
import { useIsAdmin } from '../../hooks/use-is-admin';

export default function View({ children }) {
  const isAdmin = useIsAdmin();

  if (!isAdmin) {
    return <Exception403 />;
  }

  return <>{children}</>;
}
