/* eslint-disable import/prefer-default-export */
export const PATHS = {
  Browse: '/browse',
  BrowseProviders: '/browse/providers',
  BrowseEndpoints: '/browse/endpoints',
  BrowseTemplates: '/browse/templates',
  Endpoint: '/browse/:provider/:endpoint',
  Workspace: '/workspace',
  HelpCenter: '/helpCenter',
  Overview: '/',
  Provider: '/browse/:provider',
  Settings: '/settings',
  Topo: '/topo',
  Parameters: '/parameters',
  Data: '/data',
  Reference: '/reference',
  AllContent: '/content/:contentType',
  Content: '/content/:contentType/:objectID',
  Tools: '/tools',
  About: '/about',
  Paradigm: '/paradigm',
  Analysis: '/analysis',
  Test: '/test',
  Explorer: '/explorer',
  UniConsole: '/uni',

  // Admin
  EasyEndpoint: '/admin/endpoint',
  Enums: '/admin/enums',
  Navigate: '/admin/navigate',
  Trade: '/admin/trade',
  Uni: '/admin/uni',
  // Documentation paths
  Docs: '/docs/*',
  Templates: '/templates',
  TemplateEngine: '/template-engine',
  Template: '/templates/:templateId',
  Resources: '/resources',
  Resource: '/resources/:resource',
  Functions: '/functions',
  Function: '/functions/:function',
  Scenario: '/scenario/:scenarioId',
};
