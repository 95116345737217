import React from 'react';
import { connect } from 'react-redux';
import {
  DownOutlined,
  PoweroffOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Dropdown, Menu, Button } from 'antd';
import { Link } from 'react-router-dom';
import { actions as appActions } from '../../appGlobal/actions';
import { ALGOLIA_INDICES } from '../../config';

import './header.css';
import { view as WrapAutocomplete } from '../algolia/wrap-autocomplete';
import { view as HeaderSearch } from '../algolia/header-search';

const onClick = (props, clear) => {
  const { key } = props;
  if (key === '2') {
    clear();
  }
};

const menu = (clear) => (
  <Menu onClick={(props) => onClick(props, clear)}>
    <Menu.Item key="1">
      <Link to="/settings">
        <SettingOutlined />
        &nbsp;Preferences
      </Link>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="2">
      <PoweroffOutlined />
      Sign out
    </Menu.Item>
  </Menu>
);

export class Header extends React.Component {
  render() {
    const { clear, loggedIn, currentUser } = this.props;

    const autocompleteProps = {
      indices: ALGOLIA_INDICES,
      searchParameters: {
        hitsPerPage: 1,
      },
    };

    // Offset the height of sidebar
    const width = 'calc(100% - 29px)';
    return (
      <div
        className="header-wrapper header-fixed"
        style={{ padding: 0, width }}
      >
        <div className="header-user-info">
          <WrapAutocomplete {...autocompleteProps}>
            <HeaderSearch />
          </WrapAutocomplete>
          {loggedIn ? (
            <Dropdown overlay={menu(clear)} placement="bottomRight">
              <span className="header-dropdown-link">
                <UserOutlined />
                <span className="name">{currentUser.email}</span>
                <DownOutlined />
              </span>
            </Dropdown>
          ) : (
            <>
              <Link to="/login">
                <Button type="link">Log in</Button>
              </Link>
              <Link to="/register">
                <Button type="primary"> Sign up</Button>
              </Link>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  globalSearchValue: state.app.globalSearchValue,
  providers: state.app.providers,
});

const mapDispatchToProps = (dispatch, props) => ({
  // Auth actions for NotLoggedIn component
  updateGlobalSearch: (value) => {
    dispatch(
      appActions.updateGlobalSearch(
        value,
        props.providerSearch,
        props.endpointSearch
      )
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
