import React, { useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Form, Input, Button } from 'antd';
import { useServices } from '../hooks/use-services';
import { fetchCustomerFromSessionId } from './_helpers';

function SessionRegisterForm({ onChange, fields, registerUser }) {
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    return registerUser(values);
  };
  return (
    <Form
      name="register_with_session"
      className="login-form"
      fields={fields}
      onFieldsChange={(changedFields, allFields) => {
        onChange(allFields);
      }}
      onFinish={onFinish}
    >
      <h3>Finish Registration</h3>

      <Form.Item
        name="email"
        rules={[
          { type: 'email', required: true, message: 'Email is required.' },
        ]}
      >
        <Input
          placeholder="Email: satoshi@cryptosheets.com"
          prefix={<UserOutlined />}
          size="large"
        />
      </Form.Item>

      <Form.Item
        name="fullname"
        rules={[
          {
            required: true,
            message: 'Please input your name.',
          },
        ]}
      >
        <Input
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Full Name"
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
          size="large"
        />
      </Form.Item>

      <Form.Item
        name="confirm"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                'The two passwords that you entered do not match!'
              );
            },
          }),
        ]}
      >
        <Input
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Confirm Password"
          size="large"
        />
      </Form.Item>

      <Form.Item name="stripeCustomerId">
        <Input
          style={{ display: 'none' }}
          disabled
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Encrypted stripe details"
          size="large"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button">
          Register
        </Button>
      </Form.Item>
    </Form>
  );
}

function RegisterWithSession({ sessionId, registerUser }) {
  const services = useServices();

  const [fields, setFields] = useState([{ name: ['email'], value: null }]);

  React.useEffect(() => {
    fetchCustomerFromSessionId({ sessionId, services, setFields });
  }, [services, sessionId]);

  return (
    <SessionRegisterForm
      registerUser={registerUser}
      fields={fields}
      onChange={(newFields) => {
        setFields(newFields);
      }}
    />
  );
}

export default RegisterWithSession;
