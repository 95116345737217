import { createSelector } from 'reselect';
import { actions } from './actions';
import helpers from '../../util/helpers';

const { arrays } = helpers;
const { reducedFilter } = arrays;

const stripePlansSelector = (state) => state.account.stripePlans;
const planId = (state, plan) => plan;

export const getPlan = createSelector(
  [stripePlansSelector, planId],
  (allPlans, currentPlanId) => {
    const defaultPlan = {
      id: 'PLAN000A',
      monthlyCost: 0,
      monthlyApiRequests: 1000,
      requestConcurrency: '1 per sec',
      stripePlans: ['prod_FZMZiKd88c2JZ5'],
      support: false,
      plan: 'FREE_FOREVER',
    };
    const newValue = reducedFilter(
      allPlans,
      Object.keys(defaultPlan),
      (item) => item.id === currentPlanId
    )[0];

    return newValue === undefined ? defaultPlan : newValue;
  }
);

// The initial state of the App
export const initialState = {
  loading: false,
  error: false,
  status: 'initial',
  msg: null,
  profile: [],
  stripePlans: [],
  currentPlan: null,
  modalVisible: false,
};

/* eslint-disable default-case, no-param-reassign */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.ACCOUNT_TOGGLE_MODAL_VISIBLE:
      return {
        ...state,
        modalVisible: !!state.modalVisible,
      };
    case actions.ACCOUNT_CURRENT_PLAN:
      return {
        ...state,
      };
    case actions.LOAD_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.LOAD_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.profile,
        loading: false,
      };
    case actions.LOAD_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        status: 'loading',
      };
    case actions.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.profile,
        loading: false,
        status: 'success',
      };
    case actions.UPDATE_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: 'error',
      };
    case actions.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        msg: 'Check your e-mail for reset token.',
        loading: false,
        status: 'success',
      };
    case actions.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: 'error',
      };
    case actions.UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        msg: action.msg,
        loading: false,
        status: 'success',
      };
    case actions.UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: 'error',
      };
    case actions.DOWNGRADE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.DOWNGRADE_PROFILE_SUCCESS:
      return {
        ...state,
        msg: 'Successfully downgraded.',
        loading: false,
        status: 'success',
      };
    case actions.DOWNGRADE_PROFILE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: 'error',
      };
    case actions.ADD_KEY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        status: 'loading',
      };
    case actions.ADD_KEY_SUCCESS:
      return {
        ...state,
        ...action.profile,
        loading: false,
        status: 'success',
      };
    case actions.ADD_KEY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: 'error',
      };
    case actions.DELETE_KEY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        status: 'loading',
      };
    case actions.DELETE_KEY_SUCCESS:
      return {
        ...state,
        ...action.profile,
        loading: false,
        status: 'success',
      };
    case actions.DELETE_KEY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: 'error',
      };
    case actions.LOAD_STRIPE_PLANS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.LOAD_STRIPE_PLANS_SUCCESS:
      return {
        ...state,
        stripePlans: action.payload,
        loading: false,
        status: 'success',
      };
    case actions.LOAD_STRIPE_PLANS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: 'error',
      };
    case actions.CREATE_STRIPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.CREATE_STRIPE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'success',
      };
    case actions.CREATE_STRIPE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: 'error',
      };
    case actions.UPDATE_STRIPE_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.UPDATE_STRIPE_SUCCESS:
      return {
        ...state,
        loading: false,
        status: 'success',
      };
    case actions.UPDATE_STRIPE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
        status: 'error',
      };
    case actions.RESET_INITIAL_STATE:
      return {
        ...state,
        loading: false,
        error: false,
        status: 'initial',
        msg: null,
      };
    default:
      return state;
  }
}
