import React from 'react';
import { Avatar, PageHeader, Statistic, Descriptions } from 'antd';
import _ from 'lodash';
import Interweave from 'interweave';
import DropOption from './DropOption';
import {
  insertTemplateIntoWorkbookById,
  openNewWorkbookByTemplateId,
} from './api';

const handleMenuClick = (record, e) => {
  if (e.key === '1') {
    insertTemplateIntoWorkbookById(record.templateId);
  } else if (e.key === '2') {
    openNewWorkbookByTemplateId(record.templateId);
  }
};

const renderContent = (descriptionItems) => {
  const { column, ...rest } = descriptionItems;
  return (
    <Descriptions size="small" column={column || 2}>
      {Object.keys(rest).map((key) => (
        <Descriptions.Item label={_.startCase(key)}>
          {rest[key]}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

const extraContent = (
  <div
    style={{
      display: 'flex',
      width: 'max-content',
      justifyContent: 'flex-end',
    }}
  >
    <Statistic
      title="Status"
      value="Pending"
      style={{
        marginRight: 32,
      }}
    />
    <Statistic title="Price" prefix="$" value={568.08} />
  </div>
);

const Content = ({ children, extra }) => (
  <div className="content">
    <div className="main">{children}</div>
  </div>
);

function TemplateSummary({ widgetData, widgetWidth }) {
  console.log(widgetData);
  const { name, description, thumb, ...rest } = widgetData;
  return (
    <div>
      <PageHeader
        title={name}
        subTitle={<Interweave content={description} />}
        extra={[
          <DropOption
            buttonStyle={{
              type: 'primary',
              icon: 'download',
              buttonText: 'Load',
            }}
            onMenuClick={(e) => handleMenuClick(widgetData, e)}
            menuOptions={[
              { key: '1', name: 'Insert into Current' },
              { key: '2', name: 'New Workbook' },
            ]}
          />,
        ]}
      >
        <Content extra={extraContent}>{renderContent(rest)}</Content>
        <Avatar shape="square" src={thumb} size={widgetWidth - 100} />
      </PageHeader>
    </div>
  );
}

export default TemplateSummary;
