import { createSelector } from 'reselect';
import { actions } from './actions';

const normalize = (value, compare) => {
  const v = value.toLowerCase().trim();
  const c = compare.toLowerCase().trim();
  return c.includes(v);
};

const providersSelector = (state) => state.provider.providers;
const providerId = (state, id) => id;
const globalSearchTerm = (state, value) => value;

export const getProvider = createSelector(
  [providersSelector, providerId],
  (providers, provider) => {
    const p = providers.filter((pr) => pr.id === provider);
    return p[0];
  }
);

export const providerSearch = createSelector(
  [providersSelector, globalSearchTerm],
  (providers, value) => {
    const pSearch = providers
      .filter((p) => {
        const { API } = p;
        return normalize(value, API);
      })
      .map((p, i) => {
        const doc = {
          title: p.API,
          count: p.numberOfEndpoints,
        };
        return doc;
      });
    return pSearch;
  }
);

// The initial state of the App
export const initialState = {
  loading: false,
  error: false,
  status: 'initial',
  currentUser: false,
  providers: [],
  endpoints: [],
};

/* eslint-disable default-case, no-param-reassign */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_PROVIDERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.LOAD_PROVIDERS_SUCCESS:
      return {
        ...state,
        providers: action.providers,
        loading: false,
      };
    case actions.LOAD_PROVIDERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.LOAD_PROVIDERS_ENDPOINTS_REQUEST:
      return {
        ...state,
        loading: true,
        status: 'loading',
        error: null,
      };
    case actions.LOAD_PROVIDERS_ENDPOINTS_SUCCESS:
      return {
        ...state,
        endpoints: action.endpoints,
        status: 'success',
        loading: false,
      };
    case actions.LOAD_PROVIDERS_ENDPOINTS_ERROR:
      return {
        ...state,
        loading: false,
        status: 'error',
        error: action.error,
      };
    default:
      return state;
  }
}
