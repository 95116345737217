import React from 'react';
import { Route } from 'react-router-dom';

const AccountRoute = ({ exact, path, component: Component, ...rest }) => (
  <Route
    exact={exact}
    path={path}
    render={(props) => <Component {...props} {...rest} />}
  />
);

export default AccountRoute;
