import React from 'react';
import { Collapse } from 'antd';
import { useRequest } from '@umijs/hooks';
import Links from './Links';
import ParametersTable from './ParametersTable';
import client from '../../../feathers';

const { Panel } = Collapse;

function fetchEndpoint(endpointId) {
  const query = {
    $eager: '[provider, parameters.enums]',
  };
  return client
    .service('endpoints')
    .get(endpointId, { query })

    .catch((e) => console.log(e));
}

export default function EasyEndpoint({ endpointId = 1411 }) {
  const { data: endpoint, error, loading } = useRequest(() =>
    fetchEndpoint(endpointId)
  );

  if (loading) {
    return <div>loading</div>;
  }

  if (error) {
    return <div>error</div>;
  }

  const { parameters } = endpoint;

  return (
    <div>
      <Links endpoint={endpoint} />
      <Collapse>
        <Panel header="Endpoint" key="1">
          <p>test</p>
        </Panel>
        <Panel header="Parameters" key="2">
          <ParametersTable parameters={parameters} />
        </Panel>
        <Panel header="Other" key="3" disabled>
          <p>test</p>
        </Panel>
      </Collapse>
    </div>
  );
}
