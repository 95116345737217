import React from 'react';
import { useRequest } from '@umijs/hooks';
import _ from 'lodash';
// import { useRequest } from '@umijs/hooks';
import { Button, Form, Input, InputNumber, Typography } from 'antd';
import {
  CheckOutlined,
  InfoOutlined,
  WarningOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import client from '../../../feathers';

const { Title, Paragraph } = Typography;

const initialValues = {
  userId: '2efdfa43-d509-4f98-bab9-06ab36a4cbd9',
  exchangeAccountId: '40051',
  fromSymbol: 'BTC',
  toSymbol: 'USD',
  amount: 1,
};

const formItems = [
  {
    name: 'userId',
    label: 'User Id',
    component: <Input />,
  },
  {
    name: 'exchangeAccountId',
    label: 'Exchange Account Id',
    component: <Input />,
  },
  {
    name: 'fromSymbol',
    label: 'From Symbol',
    component: <Input />,
  },
  {
    name: 'toSymbol',
    label: 'To Symbol',
    component: <Input />,
  },
  {
    name: 'amount',
    label: 'Amount',
    component: <InputNumber />,
  },
];

function createTrade(values) {
  const { userId, exchangeAccountId, ...data } = values;
  const service = 'trading';
  const query = {
    userId,
    exchangeAccountId,
  };
  const params = {
    query,
  };

  return client.service(service).create(data, params);
}

function Status({ data, loading, error }) {
  console.log({ data, loading, error });
  if (loading) {
    return (
      <>
        <LoadingOutlined /> LoadingOutlined...
      </>
    );
  }

  if (error) {
    return (
      <>
        <WarningOutlined /> Error:{' '}
        {_.get(error, ['data', 'error'], 'General Error')}
      </>
    );
  }

  if (_.isEmpty(data)) {
    // array does not exist, is not an array, or is empty
    // ⇒ do not attempt to process array
    return (
      <>
        <InfoOutlined /> Ready to trade
      </>
    );
  }

  return (
    <>
      <CheckOutlined /> Success:
      {_.get(data, 'id', 'Id will appear when integrated')}
    </>
  );
}

function Trade() {
  const { loading, error, data, run } = useRequest(
    (values) => createTrade(values),
    {
      manual: true,
    }
  );

  const statusProps = {
    loading,
    error,
    data,
  };

  const content = <Status {...statusProps} />;

  const onFinish = (values) => {
    console.log('Success:', values);
    return run(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      <Title>Trade</Title>
      <Paragraph>
        This endpoint creates and queues a trade operation. The trade operation
        will attempt to trade the specified amount of fromSymbol to toSymbol at
        the currently available rate on the exchange. The trading engine will
        execute the trade as a series of limit orders in order to control
        slippage and monitor spread.
      </Paragraph>
      <Form
        name="create-trade"
        initialValues={initialValues}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {formItems.map(({ label, name, component }) => (
          <Form.Item label={label} name={name}>
            {component}
          </Form.Item>
        ))}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      {content}
    </div>
  );
}

Trade.defaultProps = {};

export default Trade;
