import React from 'react';
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  LoadingOutlined,
} from '@ant-design/icons';
import { notification } from 'antd';

export function onStatusChange(status, resource = 'resource', message) {
  const constants = {
    loading: {
      key: 'status',
      message: 'Loading...',
      description: message || 'We are fetching your resource.',
      icon: <LoadingOutlined twoToneColor="#108ee9" />,
    },
    success: {
      key: 'status',
      message: 'Success',
      description: message || `Your ${resource} has been delivered.`,
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    },
    error: {
      key: 'status',
      message: 'Error',
      description:
        message ||
        'We had trouble fetching your data. Error details in the Error tab below.',
      icon: <CloseCircleTwoTone twoToneColor="#eb2f96" />,
    },
  };

  if (status === 'initial') {
    return;
  }

  notification.open(constants[status]);
}
