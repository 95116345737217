import { actions } from './actions';

const initState = {
  activeKey: 'All',
  newKey: 'tester',
  hitsPerPage: 10,
  searchState: {},
  searchValue: '',
  view: 'grid',
};
export default (state = initState, action) => {
  switch (action.type) {
    case actions.CHANGE_VIEW:
      return {
        ...state,
        view: action.view,
      };
    case actions.BROWSE_SET_SEARCH:
      return {
        ...state,
        searchValue: action.searchValue,
      };
    case actions.BROWSE_SET_HITS_PER_PAGE:
      return {
        ...state,
        hitsPerPage: action.hitsPerPage,
      };
    case actions.UPDATE_SEARCH_STATE:
      return {
        ...state,
        searchState: action.searchState,
      };
    case actions.UPDATE_ACTIVE_TAB:
      return {
        ...state,
        activeKey: action.key,
      };
    default:
      return state;
  }
};
