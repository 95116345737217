import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { FormOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import { Alert, Form, Input, Checkbox, Button, Typography } from 'antd';
import URI from 'urijs';
import RegisterWithSession from './registerWithSession';

const { Text } = Typography;

export default class Register extends Component {
  formRef = React.createRef();

  state = {
    confirmDirty: false,
    autoCompleteResult: [],
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.formRef.current.submit();
  };

  onFinish = (values) => {
    const { registerUser } = this.props;

    return registerUser(values);
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    console.log(this.formRef);
    if (value && value !== this.formRef.current.getFieldValue('password')) {
      callback('Two passwords that you enter must match.');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    if (value && this.state.confirmDirty) {
      this.formRef.current.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  render() {
    const { loggedIn, error, redirect, registerUser } = this.props;
    if (loggedIn) {
      if (redirect) {
        return <Redirect to={redirect} />;
      }
      return <Redirect to="/" />;
    }
    const { search } = this.props.location;

    const { session_id } = URI.parseQuery(search);

    if (session_id) {
      return (
        <RegisterWithSession
          sessionId={session_id}
          registerUser={registerUser}
        />
      );
    }

    return (
      <Form
        ref={this.formRef}
        onSubmit={this.handleSubmit}
        onFinish={this.onFinish}
        className="login-form"
      >
        <h3>Register</h3>
        <Form.Item
          name="fullname"
          rules={[
            {
              required: true,
              message: 'Please input your name.',
            },
          ]}
        >
          <Input
            prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Full Name"
          />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not a valid E-mail.',
            },
            {
              required: true,
              message: 'Please input your E-mail',
            },
          ]}
        >
          <Input
            prefix={<FormOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password.',
            },
            {
              min: 7,
              message: 'Password must be at least 7 characters long.',
            },
            {
              validator: this.validateToNextPassword,
            },
          ]}
          hasFeedback
        >
          <Input
            type="password"
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Password"
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          rules={[
            {
              required: true,
              message: 'Please confirm your password.',
            },
            {
              validator: this.compareToFirstPassword,
            },
          ]}
          hasFeedback
        >
          <Input
            type="password"
            prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Confirm Password"
            onBlur={this.handleConfirmBlur}
          />
        </Form.Item>
        <Form.Item
          name="agreement"
          rules={[
            {
              required: true,
              message: 'Terms of service agreement is required for signup.',
            },
          ]}
          valuePropName="checked"
        >
          <Checkbox>
            I have read the{' '}
            <a
              href="https://cryptosheets.com/tos"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Text strong>terms of service</Text>
            </a>
          </Checkbox>
        </Form.Item>
        {error && <Alert message={`Error: ${error}`} type="error" />}
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Register
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
