/* eslint-disable import/prefer-default-export */
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { message } from 'antd';
import { push } from 'connected-react-router';
import { parseFullName } from '@cryptosheets/util';
import feathers from '../feathers';
import helpers from './_helpers';
import { actions as loadingActions } from '../components/loading/index';

import { STREAMS_URL } from '../config';
import { saveAuthToken } from '../util/localStorage';

const hostDetails = {
  web: {
    appName: 'Cryptosheets',
    environment: 'web',
  },
  excel: {
    appName: 'Cryptosheets',
    environment: 'excel',
  },
  sheets: {
    appName: 'Cryptosheets',
    environment: 'sheets',
  },
};

export const actions = {
  SET_HOST: 'SET_HOST',
  setHost: (host) => ({
    type: actions.SET_HOST,
    host: hostDetails[host],
  }),
  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  CLEAR_AUTH: 'CLEAR_AUTH',
  AUTH_REQUEST: 'AUTH_REQUEST',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_ERROR: 'AUTH_ERROR',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  SEND_FORM_REQUEST: 'SEND_FORM_REQUEST',
  SEND_FORM_SUCCESS: 'SEND_FORM_SUCCESS',
  SEND_FORM_ERROR: 'SEND_FORM_ERROR',
  FETCH_CONSOLIDATED_USER_REQUEST: 'FETCH_CONSOLIDATED_USER_REQUEST',
  FETCH_CONSOLIDATED_USER_SUCCESS: 'FETCH_CONSOLIDATED_USER_SUCCESS',
  FETCH_CONSOLIDATED_USER_ERROR: 'FETCH_CONSOLIDATED_USER_ERROR',
  clearAuthCreds: (services) => (dispatch, getState) => {
    const { appTeardown } = helpers(services);
    dispatch(actions.clearAuth());
    appTeardown();
    dispatch(push('/login'));
  },
  setAuthToken: (authToken) => ({
    type: actions.SET_AUTH_TOKEN,
    authToken,
  }),
  clearAuth: () => ({
    type: actions.CLEAR_AUTH,
  }),
  authRequest: () => ({
    type: actions.AUTH_REQUEST,
  }),
  authSuccess: (currentUser) => ({
    type: actions.AUTH_SUCCESS,
    currentUser,
  }),
  authError: (error) => ({
    type: actions.AUTH_ERROR,
    error,
  }),
  resetPasswordRequest: () => ({
    type: actions.RESET_PASSWORD_REQUEST,
  }),
  resetPasswordSuccess: () => ({
    type: actions.RESET_PASSWORD_SUCCESS,
  }),
  resetPasswordError: (error) => ({
    type: actions.RESET_PASSWORD_ERROR,
    error,
  }),
  storeAuthInfo: (authToken, dispatch) => {
    const decodedToken = jwtDecode(authToken);
    dispatch(actions.setAuthToken(authToken));
    dispatch(actions.authSuccess(decodedToken.user));
    saveAuthToken(authToken);
  },
  login: (email, password, services) => (dispatch, getState) => {
    dispatch(loadingActions.showLoading());
    dispatch(actions.authRequest());
    return feathers
      .authenticate({
        strategy: 'local',
        email,
        password,
      })
      .then((res) => {
        const { appSetup } = helpers(services);
        const { user } = res;
        appSetup(user);

        return user;
      })
      .then((user) => {
        dispatch(loadingActions.hideLoading());
        dispatch(actions.authSuccess(user));
      })
      .catch((e) => {
        dispatch(loadingActions.hideLoading());
        message.error({
          content: `Unable to login. Reason: ${e.message}`,
          duration: 3,
        });
        actions.authError(e);
      });
  },
  refreshAuthToken: () => (dispatch) => {
    dispatch(actions.authRequest());
    return feathers.reAuthenticate().catch((e) => console.log(e));
  },
  resetPassword: (values, token) => (dispatch) => {
    dispatch(actions.resetPasswordRequest());
    const config = {
      method: 'post',
      url: `${STREAMS_URL}/authManagement`,
      data: {
        action: 'resetPwdLong',
        value: {
          token,
          password: values.password,
        },
      },
    };
    return axios(config)
      .then(() => {
        message.success('Successfully changed password');
        dispatch(actions.resetPasswordSuccess());
      })
      .catch((err) => {
        message.error('Unable to reset password');
        dispatch(actions.resetPasswordError(err));
      });
  },
  registerUser: (user, services) => (dispatch, getState) => {
    const { createUser } = helpers(services);
    const { email, password, fullname } = user;
    const { firstName, lastName } = parseFullName(fullname);
    const data = {
      ...user,
      profile: {
        name: fullname,
        firstName,
        lastName,
      },
    };
    return createUser(data)
      .then(() => {
        // Success, we will now log the user in
        dispatch(actions.login(email, password, services));
      })
      .catch((error) => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          dispatch(actions.authError(error.response.data.message));
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  },
};
