import React, { useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactResizeDetector from 'react-resize-detector';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Console } from '@cryptosheets/components';
import { useRequest } from '@umijs/hooks';
import { Alert, Spin } from 'antd';
import client from '../../../feathers';
import NotLoggedIn from './UserNotLoggedIn';

async function getEndpoint(endpointId) {
  const SERVICE = 'endpoints';
  const PARAMS = {
    query: {
      $eager: '[provider, parameters.enums]',
    },
  };
  const endpoint = await client.service(SERVICE).get(endpointId, PARAMS);

  return endpoint;
}

function UniConsole(props) {
  console.log(props);
  const { height, endpointId, customFunction, setCustomFunction } = props;
  const [modalVisible, setModalVisible] = useState(false);
  const host = useSelector((state) => state.auth.environment);
  const isLoggedIn = useSelector((state) => state.users.data !== null);
  const { loading, error, data } = useRequest(() => getEndpoint(endpointId), {
    refreshDeps: [endpointId],
  });

  if (loading)
    return (
      <Spin tip="Loading...">
        <Alert
          message="Hang tight..."
          description="We are fetching your data..."
          type="info"
        />
      </Spin>
    );

  if (error) return <div>error</div>;

  if (_.isEmpty(data)) {
    return <div>no data</div>;
  }
  const { parameters } = data;

  console.log(height);

  return (
    <>
      <Console
        endpoint={data}
        isLoggedIn={isLoggedIn}
        isAdmin
        host={host}
        initialParameters={parameters}
        handleNotLoggedIn={() => setModalVisible(true)}
        screenWidth={900}
        scenarioId={null}
        customFunction={customFunction}
        setCustomFunction={setCustomFunction}
      />
      {!isLoggedIn && (
        <NotLoggedIn
          visible={modalVisible}
          handleOk={() => setModalVisible(false)}
          handleCancel={() => setModalVisible(false)}
          {...props}
        />
      )}
    </>
  );
}

UniConsole.defaultProps = {};

export const WrappedUniConsole = (props) => (
  <ReactResizeDetector handleWidth handleHeight>
    {({ width, height }) => (
      <Scrollbars height={height}>
        <UniConsole {...props} height={height} />
      </Scrollbars>
    )}
  </ReactResizeDetector>
);
export default UniConsole;
