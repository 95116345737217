import React from 'react';
import { Result } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const config = {
  isSaving: {
    icon: <LoadingOutlined />,
    status: null,
    title: 'Updating your subscription...',
    subTitle: 'Please wait while we update your Cryptosheets subscription.',
  },
  isFinished: {
    icon: null,
    status: 'success',
    title: 'Updating your subscription...',
    subTitle: 'Please wait while we update your Cryptosheets subscription.',
  },
  isError: {
    icon: null,
    status: 'error',
    title: 'Updating your subscription...',
    subTitle: 'Please wait while we update your Cryptosheets subscription.',
  },
};

export default function CardFormStatus({ status }) {
  const statusConfig = config[status];
  return (
    <Result
      icon={statusConfig.icon}
      status={statusConfig.status}
      title={statusConfig.title}
      subTitle={statusConfig.subTitle}
    />
  );
}
