import feathers from '@feathersjs/client';
import { storageConfig } from './config';
import transportConfig from './transport-config';

export default (
  storage = 'localStorage',
  transport = 'rest',
  server = 'streams'
) => {
  if (transport === 'google') {
    return {};
  }

  const client = feathers();
  const getTimeout = (user) => {
    const defaultTimeout = 30000;
    const alternateTimeout = 60000;

    if (!user) {
      return defaultTimeout;
    }

    if (user.role === 'admin') {
      return alternateTimeout;
    }

    if (user.plan !== 'free' && user.plan !== 'FREE_FOREVER') {
      return alternateTimeout;
    }

    return defaultTimeout;
  };

  client.configure(transportConfig(client, transport, server));
  client.configure(feathers.authentication(storageConfig[storage]));

  client.hooks({
    before: {
      all: [
        async function (context) {
          const auth = context.app.get('authentication');

          if (context.path !== 'authentication' && auth) {
            const { user } = await auth;

            context.service.timeout = getTimeout(user);
          }

          return context;
        },
      ],
    },
    error: {
      all: [
        async function (context) {
          const { error } = context;

          if (error && error.code === 408) {
            error.message =
              'The dataset is too large or took too long to return. Upgrade your plan to make larger requests.';
          }

          return context;
        },
      ],
    },
  });

  return client;
};
