import React from 'react';
import { view as Exception } from '../../../components/exception';

export default () => (
  <Exception
    type="403"
    // desc={{ id: 'exception-404.description.404' }}
    // backText={{ id: 'exception-404.exception.back' }}
  />
);
