import styled from 'styled-components';
import { Mosaic } from 'react-mosaic-component';
import { Col } from 'antd';

export const ColumnWrapper = styled(Col)`
  /* height: 80vh; */
  /* height: ${(props) => (props.isMobile ? '10vh' : '80vh')}; */
  overflow: auto;
  /* padding: 1rem; */
  padding: ${(props) => (props.isMobile ? '0.5rem' : '1rem')};
`;

export const WrappedMosaic = styled(Mosaic)`
  position: inherit;
  margin-top: 60px;

  .mosaic-root {
    top: 100px;
  }
`;
