import React from 'react';
import TradingViewWidget from 'react-tradingview-widget';

export default function TradingView({ widgetWidth, widgetHeight }) {
  return (
    <div>
      <TradingViewWidget
        symbol="BTCUSDT"
        width={widgetWidth}
        height={widgetHeight}
        onChange={(e) => console.log(e)}
      />
    </div>
  );
}
