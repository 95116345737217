import arrays from './arrays';
import math from './math';
import objects from './objects';
import strings from './strings';
import utils from './utils';

export default {
  arrays,
  math,
  objects,
  strings,
  utils,
};
