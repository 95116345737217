import React, { useState } from 'react';
import { Markdown } from '@cryptosheets/components';
import { Typography } from 'antd';
import ApiKeyInput from './ApiKeyInput';
import Deep from './Deep';

const { Paragraph } = Typography;

export default function Http({
  next,
  provider,
  scheme,
  submitData,
  validateStep,
  handleSubmitData,
}) {
  const [hasCredentials, setHasCredentials] = useState(false);

  const { name } = provider;
  const [securityScheme] = scheme.data;
  const { description, isDeepIntegration } = securityScheme;

  const inputProps = {
    provider,
    scheme: securityScheme,
    submitData,
    validateStep,
    handleSubmitData,
  };

  const deepProps = {
    name,
    next,
    hasCredentials,
    setHasCredentials,
    handleSubmitData,
  };

  return (
    <>
      {isDeepIntegration === 1 && <Deep {...deepProps} />}
      {isDeepIntegration !== 1 && (
        <Paragraph>
          <Markdown markdown={description} />
        </Paragraph>
      )}
      <br />
      {(hasCredentials || isDeepIntegration !== 1) && (
        <Paragraph>
          <ApiKeyInput {...inputProps} />
        </Paragraph>
      )}
    </>
  );
}
