import React from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Popover, Typography } from 'antd';
import styled from 'styled-components';

const StyledPopover = styled(Popover)`
  max-width: 320px;
`;

const { Paragraph } = Typography;

function PopoverTitle() {
  return (
    <>
      <CheckCircleTwoTone twoToneColor="#52c41a" /> Integrated Provider
    </>
  );
}

function Content() {
  return (
    <Paragraph>
      Integrated providers have completed a thorough review of integrated data,
      and have been meticulously implemented into our engine.
      <br />
      <Link to="/contact">Contact us for more info.</Link>
    </Paragraph>
  );
}

export default function IntegratedPopover() {
  return (
    <StyledPopover content={<Content />} title={<PopoverTitle />}>
      <CheckCircleTwoTone twoToneColor="#52c41a" />{' '}
    </StyledPopover>
  );
}
