import React from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Result, Button, Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Paragraph, Text } = Typography;

export default function NotFound() {
  return (
    <Result
      status="error"
      title="Error."
      subTitle="Please check and make sure that the link you followed is correct. A couple of pieces could cause the following issue:"
      extra={[
        <Link to="/">
          <Button type="primary">Go Home</Button>
        </Link>,
      ]}
    >
      <div className="desc">
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16,
            }}
          >
            The content you submitted has the following error:
          </Text>
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined style={{ color: 'red' }} /> Our server is
          currently experiencing some technical difficulties. Please try again
          in a few minutes.
        </Paragraph>
      </div>
    </Result>
  );
}
