import React, { useState } from 'react';
import _ from 'lodash';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Button, Tooltip } from 'antd';
import { useServices } from '../../../../../hooks/use-services';
import helpers from '../_helpers';

function ProviderActivateButton({ provider, show }) {
  const dispatch = useDispatch();
  const services = useServices(dispatch);

  const { requestProvider } = helpers(services);

  const [isRequested, setIsRequested] = useState(false);
  const { integrated } = provider;
  const requestedProviders = useSelector(
    (state) => state.users.data.requestedProviders
  );

  // We need to check if the key already exists in the users Integrations
  const keys = useSelector((state) => state.keys.queryResult.data);

  const isIntegrated = _.find(keys, (key) => key.provider === provider.name);

  return (
    <Tooltip
      title={
        isIntegrated
          ? "Provider already integrated. Check 'Your Integrations' tab"
          : null
      }
    >
      <Button
        disabled={
          isRequested ||
          isIntegrated ||
          _.includes(requestedProviders, provider.name)
        }
        type="primary"
        ghost={!integrated}
        onClick={
          integrated
            ? () => show()
            : () => requestProvider(provider.name, setIsRequested)
        }
      >
        {integrated ? 'Activate' : 'Request'}
      </Button>
    </Tooltip>
  );
}

export default connect()(ProviderActivateButton);
