import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Button, Typography, Form } from 'antd';

const { Title, Paragraph } = Typography;

function Navigate(props) {
  const [form] = Form.useForm();

  const onFinish = ({ route }) => {
    console.log('submit', route);
    return props.history.push(route);
  };

  return (
    <div>
      <Title>Navigate</Title>
      <Paragraph>
        Type in relative route to navigate to page within Cryptosheets
      </Paragraph>
      <Form form={form} layout="inline" onFinish={onFinish}>
        <Form.Item
          label="Route"
          name="route"
          rules={[
            {
              required: true,
              message: 'Please input route',
            },
          ]}
        >
          <Input placeholder="/templates/102" />
        </Form.Item>

        <Form.Item>
          <Button onClick={() => form.resetFields()}>Reset</Button>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Navigate
          </Button>
        </Form.Item>
      </Form>
      <Title level={4}>Examples</Title>
      <ul>
        <li>
          <Link to="/browse">/browse</Link>
        </li>
        <li>
          <Link to="/templates">/templates</Link>
        </li>
        <li>
          <Link to="/browse/Lunarcrush/Assets">/browse/Lunarcrush/Assets</Link>
        </li>
      </ul>
    </div>
  );
}

export default Navigate;
