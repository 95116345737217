module.exports = [{
  name: "_limit",
  description: "_limit will return only the number of results you specify.",
  in: 'query',
  required: false,
  examples: {
    default: {
      value: 100
    },
    customFunction: {
      value: 10
    }
  },
  schema: {
    type: "number"
  }
}, {
  description: "_path will query specific fields in a response using [JMESPath](https://jmespath.org/) query syntax.",
  in: 'query',
  required: false,
  name: "_path",
  schema: {
    type: "string"
  },
  examples: {
    default: '[].coins' // default: {
    //   value: '',
    // },
    // customFunction: {
    //   value: '[].coins',
    // },

  }
}, {
  description: "_paths will pull all possible paths in a JSON response, to be subsequently used in _path.",
  in: 'query',
  required: false,
  name: "_paths",
  schema: {
    type: "boolean"
  },
  examples: {
    default: {
      value: ''
    },
    customFunction: {
      value: 'TRUE'
    }
  }
}, {
  name: '_renameHeaders',
  description: '_renameHeaders will rename headers based on specified native to alias mappings.',
  required: false,
  schema: {
    type: "string"
  },
  examples: {
    customFunction: {
      value: 'coin_name:name'
    }
  }
}, {
  description: "_fields will return only the fields (or column names) you specify.",
  in: 'query',
  required: false,
  name: "_fields",
  schema: {
    type: "string"
  },
  examples: {
    default: {
      value: ''
    },
    customFunction: {
      value: 'hashrate,hashrate_auto'
    }
  }
}, {
  description: "_formatDates will attempt to format a date series based on momentjs formatting.",
  in: 'query',
  required: false,
  name: "_formatDates",
  schema: {
    type: "string"
  },
  examples: {
    default: {
      value: 'MM DD YYYY',
      summary: 'date with empty space separator'
    },
    customFunction: {
      value: 'MM DD YYYY'
    },
    'MM-DD-YYYY': {
      value: 'MM-DD-YYYY',
      summary: 'date with - dash separator'
    },
    YYYYDDMM: {
      value: 'YYYYDDMM',
      summary: 'date with year first and no separator'
    },
    'MM/DD/YYYY': {
      value: 'MM/DD/YYYY',
      summary: 'date with forward slash / separator'
    },
    'MM-DD-YYYY HH:MM:SS': {
      value: 'MM-DD-YYYY HH:MM:SS',
      summary: 'date with month day year hour minute and second'
    },
    'MM-DD-YYYY hh:mm:ss.000': {
      value: 'MM-DD-YYYY hh:mm:ss.000',
      summary: 'date with month day year hour minute second and millisecond'
    },
    YYMMDD: {
      value: 'YYMMDD',
      summary: 'year month day with no space common syntax for derivatives like options or futures'
    },
    L: {
      value: 'L',
      summary: 'Month numeral, day of month, year'
    },
    LL: {
      value: 'LL',
      summary: 'Month name, day of month, year ex 4-Sep-86'
    },
    LLL: {
      value: 'LLL',
      summary: 'Month name, day of month, year, time'
    },
    LTS: {
      value: 'LTS',
      summary: 'Time with seconds ex 8:30:25 PM'
    },
    X: {
      value: 'X',
      summary: 'Unix Timestamp'
    },
    x: {
      value: 'x',
      summary: 'Unix Millisecond Timestamp'
    }
  }
}, {
  description: "_count will return the count of rows per the specified column header.",
  in: 'query',
  required: false,
  name: "_count",
  schema: {
    type: "string"
  },
  examples: {
    default: {
      value: 'MM DD YYYY'
    },
    customFunction: {
      value: 'MM DD YYYY'
    }
  }
}, {
  description: "_orderBy will order a column ascending or descending (prefixed with a '-')",
  in: 'query',
  required: false,
  name: "_orderBy",
  schema: {
    type: "string"
  },
  examples: {
    customFunction: {
      value: 'coin_name,coin_algo'
    }
  }
}, {
  name: '_output',
  description: '_output will take any response and morph it into the desired output. placeholders include: flatten, csv, and 2dArray',
  in: 'query',
  required: false,
  style: 'form',
  explode: true,
  isInternal: true,
  schema: {
    type: 'string'
  }
}, {
  description: "_showHeaders is a toggle to show or hide property values in a data response. In table formats, this typically involves hiding the first row.",
  in: 'query',
  required: false,
  name: "_showHeaders",
  schema: {
    type: "boolean"
  },
  examples: {
    default: {
      value: true
    },
    customFunction: {
      value: true
    }
  }
}, {
  name: '_distinct',
  description: 'Creates a duplicate-free version of the data, using SameValueZero for equality comparisons, in which only the first occurrence of each element is kept. The order of result values is determined by the order they occur in the initial data.',
  in: 'query',
  required: false,
  schema: {
    type: "string"
  },
  examples: {
    customFunction: {
      value: 'algo_name'
    }
  }
}];