const STREAMS_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://cryptosheets-stream-prod.azurewebsites.net'
    : 'http://localhost:9001';

const TASKS_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://tasks-prod.azurewebsites.net'
    : 'http://localhost:9003';

const localStoragePlaceholder = {
  authToken: '',
  getItem: async () => {
    const authToken = await '';
    return authToken;
  },
  setItem: () => {},
  removeItem: () => {},
};

const officePlaceholder = {
  authToken: '',
  getItem: async () => {
    const authToken = await '';
    return authToken;
  },
  setItem: () => {},
  removeItem: () => {},
};

const storageConfig = {
  office: {
    storage:
      typeof window !== 'undefined' && window.OfficeRuntime
        ? window.OfficeRuntime.storage
        : officePlaceholder,
    storageKey: 'authToken',
  },
  localStorage: {
    storage:
      typeof window === 'undefined'
        ? localStoragePlaceholder
        : window.localStorage,
    storageKey: 'authToken',
  },
};

export { STREAMS_URL, TASKS_URL, storageConfig };
