/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { message, Alert } from 'antd';
import { CardElement, injectStripe } from 'react-stripe-elements';
import CardFormStatus from './CardFormStatus';
import helpers from './_helpers';

const createOptions = (fontSize, padding) => ({
  style: {
    base: {
      fontSize,
      color: '#424770',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, monospace',
      '::placeholder': {
        color: '#aab7c4',
      },
      padding,
    },
    invalid: {
      color: '#9e2146',
    },
  },
});

function handleChange(change) {
  const { error } = change;
  if (error) {
    message.error(error.message);
    // this.setState({ error: error.message, loading: false });
  }
}

function handleSubmit(props) {
  const {
    stripe,
    newPlanId,
    setVisible,
    currentUser,
    currentSubscription,
    currentSubscriptionItem,
    createOrUpdateSubscription,
  } = props;

  if (stripe) {
    stripe
      .createToken({ email: currentUser.email })
      .then((payload) => {
        const { token, error } = payload;
        if (error) {
          return message.error(error.message);
        }

        return createOrUpdateSubscription(
          token,
          currentUser,
          newPlanId,
          currentSubscription,
          currentSubscriptionItem
        );
      })
      .then(() => {
        // setNewPlan(null);
        setVisible(false);
      })
      .catch((e) => {
        console.error(e);
      });
  } else {
    console.log("Stripe.js hasn't loaded yet.");
  }
}

function _CardForm({ fontSize, stripe, newPlanId, setVisible }) {
  const { createOrUpdateSubscription } = helpers();

  const currentUser = useSelector((state) => state.users.data, shallowEqual);
  const currentSubscription = useSelector(
    (state) => state.stripeSubscription.data
  );
  const currentSubscriptionItem = useSelector(
    (state) => state.stripeSubscriptionItem.data
  );

  const {
    isSaving: stripeSubscriptionSaving,
    isError: stripeSubscriptionError,
  } = useSelector((state) => state.stripeSubscription);
  const {
    isSaving: stripeSubscriptionItemSaving,
    isError: stripeSubscriptionItemError,
  } = useSelector((state) => state.stripeSubscriptionItem);
  const {
    isSaving: stripeCustomerSaving,
    isError: stripeCustomerError,
  } = useSelector((state) => state.stripeCustomer);

  const isSaving =
    stripeSubscriptionSaving ||
    stripeSubscriptionItemSaving ||
    stripeCustomerSaving;

  const isError =
    stripeSubscriptionError ||
    stripeSubscriptionItemError ||
    stripeCustomerError;

  const submitProps = {
    stripe,
    newPlanId,
    setVisible,
    currentUser,
    currentSubscription,
    currentSubscriptionItem,
    createOrUpdateSubscription,
  };

  return (
    <>
      {isSaving ? (
        <CardFormStatus
          status={isSaving ? 'isSaving' : isError ? 'isError' : 'isFinished'}
        />
      ) : (
        <form
          className="checkoutForm"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(submitProps);
          }}
        >
          <label>
            Card details
            <CardElement onChange={handleChange} {...createOptions(fontSize)} />
          </label>
          <button>Pay</button>
          {isError ? (
            <Alert
              message="We had trouble updating your subscription. Contact support@cryptosheets.com"
              banner
              closable
            />
          ) : null}
        </form>
      )}
    </>
  );
}

const CardForm = injectStripe(_CardForm);

export default CardForm;
