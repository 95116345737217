/* eslint-disable no-nested-ternary */
/* eslint-disable func-names */
import React, { Component } from 'react';
import axios from 'axios';
import { Button, List, Empty } from 'antd';
import helpers from '../../../util/excel/helpers';

const { tryCatch, registerSelectionChangedHandler } = helpers;

export default class Explorer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: null,
      searchResults: [],
      error: null,
    };
  }

  componentDidMount() {
    return this.verifyOffice();
  }

  getCellValue = async () => {
    let searchValue;

    if (window && window.Excel) {
      await window.Excel.run(async (context) => {
        const myWorkbook = context.workbook;
        const activeCell = myWorkbook.getActiveCell();
        activeCell.load('values');

        await context.sync();

        console.log(`The active cell values are ${activeCell.values}`);
        searchValue = activeCell.values;
      });
      await this.setState({ searchValue: searchValue[0][0] });
      return this.fetchResults(this.state.searchValue);
    }
  };

  fetchResults = (searchValue) => {
    const config = {
      method: 'get',
      url: `https://api.paradigmapi.com/v1/paradigm/search?subscription-key=59b910a23d4d4d21b9094ad5c7ffdc2f&term=${searchValue}`,
    };
    return axios(config)
      .then((res) => {
        const { result } = res.data;
        console.log(result);
        this.setState({ searchResults: result });
      })
      .catch((err) => console.log(err));
  };

  verifyOffice = async () => {
    try {
      if (window && window.Excel) {
        await tryCatch(registerSelectionChangedHandler(this.getCellValue));
      }
    } catch (error) {
      this.setState({ error: JSON.stringify(error) });
    }
  };

  render() {
    const { searchValue, searchResults, error } = this.state;
    return (
      <div style={{ margin: 10 }}>
        {error && <p>{error}</p>}
        {!window.Excel ? (
          <Empty description="The Cryptosheets Explorer is an advanced feature for users of Microsoft Excel">
            <Button
              type="primary"
              href="https://appsource.microsoft.com/en-us/product/office/WA104381695"
              rel="noopener noreferrer"
              target="_blank"
            >
              Get Cryptosheets for Excel
            </Button>
          </Empty>
        ) : searchValue && searchResults.length ? (
          <List
            itemLayout="horizontal"
            dataSource={searchResults}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={<a href={item.url}>{item.term}</a>}
                  description={
                    <span>
                      <div
                        style={{
                          backgroundColor: 'transparent',
                          color: 'darkgray',
                          fontSize: '11px',
                        }}
                      >
                        {item.urlThumb ? (
                          <img src={item.urlThumb} alt={item.term} />
                        ) : null}
                        <br />
                        Source: {item.source}
                      </div>{' '}
                      <div
                        style={{
                          // backgroundColor: '#f3f3f3',
                          backgroundColor: 'white',
                          color: 'gray',
                          fontSize: '11px',
                          padding: '3px',
                          boxShadow: 'rgba(0,0,0,.25) 0 1px 1px 0px',
                        }}
                      >
                        {item.description}{' '}
                      </div>
                      <div
                        style={{
                          backgroundColor: 'transparent',
                          color: 'darkgray',
                          fontSize: '11px',
                        }}
                      >
                        Category: {item.category} | Type: {item.type}
                        <br />
                        Get Data: {item.ideas}
                      </div>{' '}
                    </span>
                  }
                />
              </List.Item>
            )}
          />
        ) : searchValue && !searchResults.length ? (
          <Empty description="No results. Try broadening your search or using a fuzzy keyword search by adding a '~' to the beginning of your search term (example: ~NVTAdj90 instead of just NVTAdj90)." />
        ) : (
          // <Empty
          //   description="Cryptosheets Explorer automatically provides relevant data as you type in and edit your spreadsheet.
          //   Simply navigate to a cell, and any data that correlates to its text (for example “bitcoin market cap” or “ethereum price”) will generate relevant data from Cryptosheets that you can explore or insert from this task pane."
          // />
          <Empty
            description="Cryptosheets Explorer automatically provides relevant data as you type in and edit your spreadsheet.
          Simply navigate to a cell, and any data that correlates to its text (for example “bitcoin market cap” or “ethereum price”) will generate relevant data from Cryptosheets that you can explore or insert from this task pane. TRY IT NOW --> type bitcoin into any cell then click back into that cell..."
          >
            <br />
            <Button
              type="primary"
              href="https://intercom.help/cryptosheets/en/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn More
            </Button>
          </Empty>
        )}
      </div>
    );
  }
}
