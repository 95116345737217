import React from 'react';
import { Spin } from 'antd';

import './loader.css';

const Loader = (props) => (
  <div className="loader-wrapper">
    <div className="loader-content">
      <Spin tip="Loading..." />
    </div>
  </div>
);

export default Loader;
