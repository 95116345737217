import React from 'react';
import Page from '../../components/page/view';
import AllResource from './AllResource';
import { view as WrapWithHits } from '../../components/algolia/wrap-with-hits';

export function Charts(props) {
  const indexProps = {
    searchParameters: {
      filters: 'objectType:widget',
    },
    indexName: 'CRYPTOSHEETS_SEARCH',
  };

  const pageProps = {
    title: 'Charts',
    description:
      'Learn how to leverage the Cryptosheets Charting Engine to visualize complex data.',
  };
  return (
    <Page {...pageProps}>
      <WrapWithHits
        {...indexProps}
        hitsElement={<AllResource {...pageProps} />}
      />
    </Page>
  );
}

export default Charts;
