import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import Button from 'antd/lib/button';
// import CustomSearchBox from '../../components/algolia/searchBox';
import CustomSearchBox from './SearchBox';
import RefinementList from './RefinementList';
import { getView } from './api';
import { searchIndices } from './config';

const ClearRefinements = ({ items, refine }) => (
  <Button type="danger" onClick={() => refine(items)} disabled={!items.length}>
    Clear all refinements
  </Button>
);

const CustomClearRefinements = connectCurrentRefinements(ClearRefinements);

function Sidebar({ collapsed, setCollapsed }) {
  const { breakpoint } = useSelector((state) => state.app);
  const { activeKey } = useSelector((state) => state.browse);

  const mobileOrDesktop = getView(breakpoint);

  const foundIndex = searchIndices.find((index) => index.key === activeKey);

  if (_.isEmpty(foundIndex)) {
    return <div>Loading...</div>;
  }

  const { refinementLists } = foundIndex;

  return (
    <div className="sidebarWrapper isoAlgoliaSidebar">
      {mobileOrDesktop === 'DesktopView' && (
        <div style={{ marginBottom: 15 }}>
          <CustomSearchBox delay={100} />
        </div>
      )}

      {refinementLists.map((refinement, index) => (
        <RefinementList {...refinement} key={`${foundIndex.key}-${index}`} />
      ))}

      <CustomClearRefinements />
    </div>
  );
}

export default Sidebar;
