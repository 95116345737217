import React from 'react';
import { Typography } from 'antd';

const { Paragraph } = Typography;

export default function Content({ description }) {
  return (
    <div className="provider-insideContent">
      <Paragraph ellipsis={{ rows: 5, expandable: true }}>
        {description}
      </Paragraph>
      {/* <Paragraph className="provider-contentLink">
        <a href={provider.link} rel="noopener noreferrer" target="_blank">
          <Avatar icon="book" />

          <Text
            style={{ marginLeft: 12, color: '#1890FF' }}
            disabled={!provider.link}
            strong
          >
            Documentation
          </Text>
        </a>
      </Paragraph>
      <p className="provider-contentLink">
        <a href={provider.content} rel="noopener noreferrer" target="_blank">
          <Avatar icon="read" />
          <Tooltip
            title={
              provider.content
                ? provider.content
                : 'No tutorials yet for this provider.'
            }
          >
            <Text
              style={{ marginLeft: 12, color: '#1890FF' }}
              disabled={!provider.content}
              strong
            >
              Tutorials
            </Text>
          </Tooltip>
        </a>
      </p> */}
    </div>
  );
}
