import React from 'react';
import { Button, Empty, Typography } from 'antd';

import functionsQuery from '../../../util/excel/functions/functionsQuery';
import functionsParameters from '../../../util/excel/functions/functionsParameters';
import functionsOHLCV from '../../../util/excel/functions/functionsOHLCV';
import functionsTrades from '../../../util/excel/functions/functionsTrades';
import functionsOrderbooks from '../../../util/excel/functions/functionsOrderbooks';
import functionsExrate from '../../../util/excel/functions/functionsExrate';
import functionsPrice from '../../../util/excel/functions/functionsPrice';
import functionsAddress from '../../../util/excel/functions/functionsAddress';
import functionsMetrics from '../../../util/excel/functions/functionsMetrics';
import functionsTa from '../../../util/excel/functions/functionsTa';

const { Title, Paragraph } = Typography;

const {
  tryCatch: tryCatchQuery,
  createReport: createReportQuery,
} = functionsQuery;
const {
  tryCatch: tryCatchParameters,
  createReport: createReportParameters,
} = functionsParameters;
const {
  tryCatch: tryCatchOHLCV,
  createReport: createReportOHLCV,
} = functionsOHLCV;
const {
  tryCatch: tryCatchTrades,
  createReport: createReportTrades,
} = functionsTrades;
const {
  tryCatch: tryCatchOrderbooks,
  createReport: createReportOrderbooks,
} = functionsOrderbooks;
const {
  tryCatch: tryCatchExrate,
  createReport: createReportExrate,
} = functionsExrate;
const {
  tryCatch: tryCatchPrice,
  createReport: createReportPrice,
} = functionsPrice;
const {
  tryCatch: tryCatchAddress,
  createReport: createReportAddress,
} = functionsAddress;
const {
  tryCatch: tryCatchMetrics,
  createReport: createReportMetrics,
} = functionsMetrics;
const { tryCatch: tryCatchTa, createReport: createReportTa } = functionsTa;

function WidgetContainer({ children }) {
  return <div style={{ margin: '5px' }}>{children}</div>;
}

function Widget() {
  return (
    <WidgetContainer>
      {!window.Excel ? (
        <Empty description="The Report Generation is an advanced feature for users of Microsoft Excel">
          <Button
            type="primary"
            href="https://appsource.microsoft.com/en-us/product/office/WA104381695"
            rel="noopener noreferrer"
            target="_blank"
          >
            Get Excel
          </Button>
        </Empty>
      ) : (
        <Typography>
          <Title level={4}>
            <Title
              level={4}
              style={{
                borderBottom: '1px solid black',
              }}
            >
              CS.QUERY()
            </Title>
            <Paragraph style={{ color: 'darkgray', fontSize: '12px' }}>
              Description:
              <br />
              1) This example will create a new sheet, put a =CS.PARAMETERS()
              function into a cell and populate data based off of the formula
              inputs.
              <br />
              <br />
              2) It will also put a =CS.QUERY() function into another cell
              referencing the same inputs as the =CS.PARAMETERS() function and
              populate data based off of the formula inputs.
            </Paragraph>
            <img
              style={{
                boxShadow: 'rgba(0,0,0,.25) 0 5px 5px 0px',
                padding: '0px',
                width: '100%',
              }}
              src="https://res.cloudinary.com/cryptosheets/image/upload/v1572912126/cryptosheets-docs/templates/functions/functionsQuery.png"
              alt="t"
            />
            <br />
            <br />
            <Title level={4} style={{ color: '#01723a' }}>
              {' '}
              <Button
                style={{ backgroundColor: '#01723a' }}
                onClick={() => tryCatchQuery(createReportQuery)}
              >
                <span style={{ color: 'white' }}>Load the template</span>
              </Button>
            </Title>
            <p style={{ color: 'green', fontSize: '11px' }}>
              <a
                href="https://app.cryptosheets.com/#/functions/query"
                rel="noopener noreferrer"
                target="_blank"
              >
                Read more here
              </a>
            </p>
            <br />
            <Paragraph />
          </Title>
          <Title level={4}>
            <Title
              level={4}
              style={{
                borderBottom: '1px solid black',
              }}
            >
              CS.PARAMETERS()
            </Title>
            <Paragraph style={{ color: 'darkgray', fontSize: '12px' }}>
              Description:
              <br />
              This example will create a new sheet, put several =CS.PARAMETERS()
              functions into cells and populate data based off of the formula
              inputs. You can change the input values for provider and endpoint
              to adjust the return values.
            </Paragraph>
            <img
              style={{
                boxShadow: 'rgba(0,0,0,.25) 0 5px 5px 0px',
                padding: '0px',
                width: '100%',
              }}
              src="https://res.cloudinary.com/cryptosheets/image/upload/v1572911658/cryptosheets-docs/templates/functions/functionsParameters.png"
              alt="t"
            />
            <br />
            <br />
            <Title level={4} style={{ color: '#01723a' }}>
              {' '}
              <Button
                style={{ backgroundColor: '#01723a' }}
                onClick={() => tryCatchParameters(createReportParameters)}
              >
                <span style={{ color: 'white' }}>Load the template</span>
              </Button>
            </Title>
            <p style={{ color: 'green', fontSize: '11px' }}>
              <a
                href="https://app.cryptosheets.com/#/functions/parameters"
                rel="noopener noreferrer"
                target="_blank"
              >
                Read more here
              </a>
            </p>
            <br />
          </Title>
          <Title
            level={4}
            // style={{
            //   padding: '4px',
            //   backgroundColor: '#E6E6E6',
            // }}
          >
            <Title
              level={4}
              style={{
                borderBottom: '1px solid black',
              }}
            >
              CS.OHLCV()
            </Title>
            <Paragraph style={{ color: 'darkgray', fontSize: '12px' }}>
              Description:
              <br />
              This example will create a new sheet, put a =CS.OHLCV() function
              into a cell and populate data based off of the formula inputs. You
              can change the input values for base, quote, exchange, type,
              period, timeStart, timeEnd and more to adjust the return values.
            </Paragraph>
            <img
              style={{
                boxShadow: 'rgba(0,0,0,.25) 0 5px 5px 0px',
                padding: '0px',
                width: '100%',
              }}
              src="https://res.cloudinary.com/cryptosheets/image/upload/v1572912065/cryptosheets-docs/templates/functions/functionsOHLCV.png"
              alt="t"
            />
            <br />
            <br />
            <Title level={4} style={{ color: '#01723a' }}>
              {' '}
              <Button
                style={{ backgroundColor: '#01723a' }}
                onClick={() => tryCatchOHLCV(createReportOHLCV)}
              >
                <span style={{ color: 'white' }}>Load the template</span>
              </Button>
            </Title>
            <p style={{ color: 'green', fontSize: '11px' }}>
              <a
                href="https://app.cryptosheets.com/#/functions/ohlcv"
                rel="noopener noreferrer"
                target="_blank"
              >
                Read more here
              </a>
            </p>
            <br />
          </Title>
          <Title
            level={4}
            style={{
              borderBottom: '1px solid black',
            }}
          >
            CS.TRADES()
          </Title>
          <Paragraph style={{ color: 'darkgray', fontSize: '12px' }}>
            Description:
            <br />
            This example will create a new sheet, put a =CS.TRADES() function
            into a cell and populate data based off of the formula inputs. You
            can change the input values for base, quote, exchange, type and more
            to adjust the return values.
          </Paragraph>
          <img
            style={{
              boxShadow: 'rgba(0,0,0,.25) 0 5px 5px 0px',
              padding: '0px',
              width: '100%',
            }}
            src="https://res.cloudinary.com/cryptosheets/image/upload/v1572922715/cryptosheets-docs/templates/functions/functionsTrades.png"
            alt="trades"
          />
          <br />
          <br />
          <Title level={4} style={{ color: '#01723a' }}>
            {' '}
            <Button
              style={{ backgroundColor: '#01723a' }}
              onClick={() => tryCatchTrades(createReportTrades)}
            >
              <span style={{ color: 'white' }}>Load the template</span>
            </Button>
          </Title>
          <p style={{ color: 'green', fontSize: '11px' }}>
            <a
              href="https://app.cryptosheets.com/#/functions/trades"
              rel="noopener noreferrer"
              target="_blank"
            >
              Read more here
            </a>
          </p>
          <br />
          <Paragraph />
          <Title
            level={4}
            style={{
              borderBottom: '1px solid black',
            }}
          >
            CS.ORDERBOOKS()
          </Title>
          <Paragraph style={{ color: 'darkgray', fontSize: '12px' }}>
            Description:
            <br />
            This example will create a new sheet, put a =CS.ORDERBOOKS()
            function into a cell and populate data based off of the formula
            inputs. You can change the input values for base, quote, exchange,
            type, startDate, endDate, limitLevels and more to adjust the return
            values.
          </Paragraph>
          <img
            style={{
              boxShadow: 'rgba(0,0,0,.25) 0 5px 5px 0px',
              padding: '0px',
              width: '100%',
            }}
            src="https://res.cloudinary.com/cryptosheets/image/upload/v1572923375/cryptosheets-docs/templates/functions/functionsOrderbooks.png"
            alt="orderbooks"
          />
          <br />
          <br />
          <Title level={4} style={{ color: '#01723a' }}>
            {' '}
            <Button
              style={{ backgroundColor: '#01723a' }}
              onClick={() => tryCatchOrderbooks(createReportOrderbooks)}
            >
              <span style={{ color: 'white' }}>Load the template</span>
            </Button>
          </Title>
          <p style={{ color: 'green', fontSize: '11px' }}>
            <a
              href="https://app.cryptosheets.com/#/functions/orderbooks"
              rel="noopener noreferrer"
              target="_blank"
            >
              Read more here
            </a>
          </p>
          <br />
          <Paragraph />
          <Title
            level={4}
            style={{
              borderBottom: '1px solid black',
            }}
          >
            CS.EXRATE()
          </Title>
          <Paragraph style={{ color: 'darkgray', fontSize: '12px' }}>
            Description:
            <br />
            1) This example will create a new sheet, put a =CS.EXRATE() function
            into a cell and populate data based off of the formula inputs. You
            can change the input values for base, quote and refresh interval to
            adjust the return values.
            <br />
            <br />
            2) *Note: this function produces real time streaming data that may
            destabilize your worksheet, please use accordingly
          </Paragraph>
          <img
            style={{
              boxShadow: 'rgba(0,0,0,.25) 0 5px 5px 0px',
              padding: '0px',
              width: '100%',
            }}
            src="https://res.cloudinary.com/cryptosheets/image/upload/v1572924351/cryptosheets-docs/templates/functions/functionsExrate.png"
            alt="t"
          />
          <br />
          <br />
          <Title level={4} style={{ color: '#01723a' }}>
            {' '}
            <Button
              style={{ backgroundColor: '#01723a' }}
              onClick={() => tryCatchExrate(createReportExrate)}
            >
              <span style={{ color: 'white' }}>Load the template</span>
            </Button>
          </Title>
          <p style={{ color: 'green', fontSize: '11px' }}>
            <a
              href="https://app.cryptosheets.com/#/functions/exrate"
              rel="noopener noreferrer"
              target="_blank"
            >
              Read more here
            </a>
          </p>
          <br />
          <Paragraph />
          <Title
            level={4}
            style={{
              borderBottom: '1px solid black',
            }}
          >
            CS.PRICE()
          </Title>
          <Paragraph style={{ color: 'darkgray', fontSize: '12px' }}>
            Description:
            <br />
            This example will create a new sheet, put several =CS.PRICE()
            functions into a range of cells and populate data based off of the
            formula inputs. You can change the input values for base/quote to
            adjust the return values.
          </Paragraph>
          <img
            style={{
              boxShadow: 'rgba(0,0,0,.25) 0 5px 5px 0px',
              padding: '0px',
              width: '100%',
            }}
            src="https://res.cloudinary.com/cryptosheets/image/upload/v1572950942/cryptosheets-docs/templates/functions/functionsPrice.png"
            alt="t"
          />
          <br />
          <br />
          <Title level={4} style={{ color: '#01723a' }}>
            {' '}
            <Button
              style={{ backgroundColor: '#01723a' }}
              onClick={() => tryCatchPrice(createReportPrice)}
            >
              <span style={{ color: 'white' }}>Load the template</span>
            </Button>
          </Title>
          <p style={{ color: 'green', fontSize: '11px' }}>
            <a
              href="https://app.cryptosheets.com/#/functions/price"
              rel="noopener noreferrer"
              target="_blank"
            >
              Read more here
            </a>
          </p>
          <br />
          <Paragraph />
          <Title
            level={4}
            style={{
              borderBottom: '1px solid black',
            }}
          >
            CS.ADDRESS()
          </Title>
          <Paragraph style={{ color: 'darkgray', fontSize: '12px' }}>
            Description:
            <br />
            This example will create a new sheet, put a =CS.ADDRESS() function
            into a cell and populate data based off of the formula inputs. You
            can change the input values for address and name to adjust the
            return values.
          </Paragraph>
          <img
            style={{
              boxShadow: 'rgba(0,0,0,.25) 0 5px 5px 0px',
              padding: '0px',
              width: '100%',
            }}
            src="https://res.cloudinary.com/cryptosheets/image/upload/v1572952613/cryptosheets-docs/templates/functions/functionsAddress.png"
            alt="t"
          />
          <br />
          <br />
          <Title level={4} style={{ color: '#01723a' }}>
            {' '}
            <Button
              style={{ backgroundColor: '#01723a' }}
              onClick={() => tryCatchAddress(createReportAddress)}
            >
              <span style={{ color: 'white' }}>Load the template</span>
            </Button>
          </Title>
          <p style={{ color: 'green', fontSize: '11px' }}>
            <a
              href="https://app.cryptosheets.com/#/functions/address"
              rel="noopener noreferrer"
              target="_blank"
            >
              Read more here
            </a>
          </p>
          <br />
          <Paragraph />
          <Title
            level={4}
            style={{
              borderBottom: '1px solid black',
            }}
          >
            CS.METRICS()
          </Title>
          <Paragraph style={{ color: 'darkgray', fontSize: '12px' }}>
            Description:
            <br />
            This example will create a new sheet, put a =CS.METRICS() function
            into the cell you select and populate data based off of the formula
            inputs.
          </Paragraph>
          <img
            style={{
              boxShadow: 'rgba(0,0,0,.25) 0 5px 5px 0px',
              padding: '0px',
              width: '100%',
            }}
            src="https://res.cloudinary.com/cryptosheets/image/upload/v1572912065/cryptosheets-docs/templates/functions/functionsOHLCV.png"
            alt="t"
          />
          <br />
          <br />
          <Title level={4} style={{ color: '#01723a' }}>
            {' '}
            <Button
              style={{ backgroundColor: '#01723a' }}
              onClick={() => tryCatchMetrics(createReportMetrics)}
            >
              <span style={{ color: 'white' }}>Load the template</span>
            </Button>
          </Title>
          <p style={{ color: 'green', fontSize: '11px' }}>
            <a
              href="https://app.cryptosheets.com/#/functions/metrics"
              rel="noopener noreferrer"
              target="_blank"
            >
              Read more here
            </a>
          </p>
          <br />
          <Paragraph />
          <Title
            level={4}
            style={{
              borderBottom: '1px solid black',
            }}
          >
            CS.TA()
          </Title>
          <Paragraph style={{ color: 'darkgray', fontSize: '12px' }}>
            Description:
            <br />
            This example will create a new sheet, put a =CS.TA() function into
            the cell you select and populate data based off of the formula
            inputs. Note: CS.TA does not require any parameter inputs by
            default.
          </Paragraph>
          <img
            style={{
              boxShadow: 'rgba(0,0,0,.25) 0 5px 5px 0px',
              padding: '0px',
              width: '100%',
            }}
            src="https://res.cloudinary.com/cryptosheets/image/upload/v1572912065/cryptosheets-docs/templates/functions/functionsTa.png"
            alt="t"
          />
          <br />
          <br />
          <Title level={4} style={{ color: '#01723a' }}>
            {' '}
            <Button
              style={{ backgroundColor: '#01723a' }}
              onClick={() => tryCatchTa(createReportTa)}
            >
              <span style={{ color: 'white' }}>Load the template</span>
            </Button>
          </Title>
          <p style={{ color: 'green', fontSize: '11px' }}>
            <a
              href="https://app.cryptosheets.com/#/functions/ta"
              rel="noopener noreferrer"
              target="_blank"
            >
              Read more here
            </a>
          </p>
          <br />
          <Paragraph />
          {/* <Title level={4}>
            The Block Genesis<br></br>
            <img src="https://res.cloudinary.com/cryptosheets/image/upload/v1568717117/logos/theblock-genesis_372x135_sox1lp.png" />
            <Title level={3} style={{ color: '#00bdbb' }}>
              Cryptocurrency traded volume dries up in August
            </Title>
          </Title>
          <Paragraph>
          <img src="https://res.cloudinary.com/cryptosheets/image/upload/e_shadow:100/v1568715490/charts/the%20block/crypto-traded-volume-vs-btc-price_trw2oa.jpg" />
            Load data from charts in the Genesis research article you're reading
            directly into your worksheet.{' '}    
          </Paragraph>
          <Title level={4}>Try it out:</Title>
          <Button onClick={() => tryCatch(createReport)}>Load the template</Button>
          <Title level={4}>QUICK TAKE</Title>
          <Paragraph style={{ color: 'gray', fontSize: '10px'}}>by Larry Cermak SEPTEMBER 3, 2019, 7:01AM EDT · 2 MIN READ</Paragraph>
          <Paragraph>
            1. Traded
            volume on spot cryptocurrency exchanges hit a 5-month low of $55
            billion in August<br></br><br></br>
            2. August seems to be the first month in the last three years when
            there is a significant discrepancy between volume and price<br></br><br></br>
            3. Coinbase, bitflyer, and Bitstamp have grown the fastest in 2019;
            Bitfinex and Bittrex the slowest<br></br><br></br>
            4. Based on both the falling spot and derivatives volume, the market
            doesn’t seem to be in the best shape at the moment.
          </Paragraph> */}
        </Typography>
      )}
    </WidgetContainer>
    //   <WidgetContainer>
    //   {!Excel ? (
    //     <Empty description="The Report Generation is an advanced feature for users of Microsoft Excel">
    //       <Button
    //         type="primary"
    //         href="https://appsource.microsoft.com/en-us/product/office/WA104381695"
    //         rel="noopener noreferrer"
    //         target="_blank"
    //       >
    //         Get Excel
    //       </Button>
    //     </Empty>
    //   ) : (
    //     <Typography>
    //       <Title level={4}>
    //         The Block Genesis<br></br>
    //         <img src="https://res.cloudinary.com/cryptosheets/image/upload/v1568717117/logos/theblock-genesis_372x135_sox1lp.png" />
    //         <Title level={3} style={{ color: '#00bdbb' }}>
    //           Cryptocurrency traded volume dries up in August
    //         </Title>
    //       </Title>
    //       <Paragraph>
    //       <img src="https://res.cloudinary.com/cryptosheets/image/upload/e_shadow:100/v1568715490/charts/the%20block/crypto-traded-volume-vs-btc-price_trw2oa.jpg" />
    //         Load data from charts in the Genesis research article you're reading
    //         directly into your worksheet.{' '}
    //       </Paragraph>
    //       <Title level={4}>Try it out:</Title>
    //       <Button onClick={() => tryCatch2(createReport2)}>Load the template</Button>
    //       <Title level={4}>QUICK TAKE</Title>
    //       <Paragraph style={{ color: 'gray', fontSize: '10px'}}>by Larry Cermak SEPTEMBER 3, 2019, 7:01AM EDT · 2 MIN READ</Paragraph>
    //       <Paragraph>
    //         1. Traded
    //         volume on spot cryptocurrency exchanges hit a 5-month low of $55
    //         billion in August<br></br><br></br>
    //         2. August seems to be the first month in the last three years when
    //         there is a significant discrepancy between volume and price<br></br><br></br>
    //         3. Coinbase, bitflyer, and Bitstamp have grown the fastest in 2019;
    //         Bitfinex and Bittrex the slowest<br></br><br></br>
    //         4. Based on both the falling spot and derivatives volume, the market
    //         doesn’t seem to be in the best shape at the moment.
    //       </Paragraph>
    //     </Typography>
    //   )}
    // </WidgetContainer>
  );
}

export default Widget;
