import React from 'react';
import { connectSortBy } from 'react-instantsearch-dom';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';

const SortBy = (props) => {
  const { items, refine } = props;
  const menu = (
    <Menu
      selectable
      onSelect={(item) => {
        refine(item.key);
      }}
    >
      {items.map((item) => (
        <Menu.Item key={item.value}>
          <UserOutlined />
          <span style={{ fontWeight: item.isRefined ? 'bold' : '' }}>
            {item.label}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div className="ais-SortBy">
      <Dropdown overlay={menu}>
        <Button>
          Sort <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default connectSortBy(SortBy);
