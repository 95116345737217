import { createSelector } from 'reselect';
import { actions } from './actions';

const parametersSelector = (state) => state.endpoint.currentEndpoint;

export const getParams = createSelector(
  [parametersSelector],
  (currentEndpoint) => {
    const { parameters } = currentEndpoint;
    return parameters
      ? parameters.reduce((acc, param) => {
          Object.assign(acc, {
            [param.name]: {
              value: param.default,
            },
          });
          return acc;
        }, {})
      : [];
  }
);

export const initialParams = createSelector(
  [parametersSelector],
  (currentEndpoint) => {
    const { parameters } = currentEndpoint;
    return parameters
      ? parameters.reduce((acc, param) => {
          acc.push({
            name: param.name,
            in: param.in,
            defaultValue: param.default,
            disabled: !param.required,
            value: '',
            required: param.required,
            error: null,
          });
          return acc;
        }, [])
      : [];
  }
);

// The initial state of the App
export const initialState = {
  error: null,
  status: 'initial',
  currentEndpoint: [],
  data: [],
};

/* eslint-disable default-case, no-param-reassign */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOAD_ENDPOINT_REQUEST:
      return {
        ...state,
        error: null,
      };
    case actions.LOAD_ENDPOINT_SUCCESS:
      return {
        ...state,
        currentEndpoint: action.endpoint,
      };
    case actions.LOAD_ENDPOINT_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case actions.REQUEST_FETCH_CURRENT_ENDPOINT:
      return {
        ...state,
        status: 'loading',
        data: [],
        error: null,
      };
    case actions.RESOLVE_FETCH_CURRENT_ENDPOINT:
      return {
        ...state,
        status: 'success',
        data: action.payload,
      };
    case actions.REJECT_FETCH_CURRENT_ENDPOINT:
      return {
        ...state,
        status: 'error',
        error: action.error,
      };
    case actions.RESET_INITIAL_STATE:
      return {
        ...state,
        error: null,
        status: 'initial',
        currentEndpoint: [],
        data: [],
      };
    default:
      return state;
  }
}
