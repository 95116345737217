function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background-color: #014020;\n  border: thin solid #d3eadc;\n\n  .ant-btn-link {\n    color: white;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  height: 600px;\n  background-color: white;\n\n  .jsoneditor {\n    border: thin solid #d3eadc;\n  }\n\n  .jsoneditor-menu {\n    background-color: #01723a;\n  }\n\n  .jsoneditor-contextmenu .jsoneditor-menu {\n    color: #ffffff;\n    background-color: white;\n  }\n\n  .jsoneditor-modal .pico-modal-header {\n    background: #01723a;\n  }\n\n  .jsoneditor-menu {\n    background-color: #01723a;\n    border-bottom: 1px solid #01723a;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
export var OutputWrapper = styled.div(_templateObject());
export var OutputMenuWrapper = styled.div(_templateObject2());