import React from 'react';
import { Typography } from 'antd';
import { view as Change } from '../../../components/changelog';

const { Paragraph, Text, Title } = Typography;

export default function Changelog(props) {
  return (
    <div style={{ background: '#fff', padding: '42px 24px 50px' }}>
      <Typography className="markdown">
        <Title>Changelog</Title>
        <Title level={3}>
          Stay up to date with new features, datasets and platform announcements
          by...
        </Title>
        <Paragraph>
          <ol>
            <li>
              Signing up for our{' '}
              <a href="https://app.cryptosheets.com">
                <Text mark>mailing list</Text>
              </a>
            </li>
            <li>
              Visiting our{' '}
              <a href="https://intercom.help/cryptosheets/en/">
                <Text mark>Help Center</Text>
              </a>{' '}
              with tutorials, FAQs and examples
            </li>
            <li>
              Follow us on{' '}
              <a href="https://twitter.com/Cryptosheets_RT">
                <Text mark>Twitter</Text>
              </a>
              and{' '}
              <a href="https://medium.com/cryptosheets">
                <Text mark>Medium</Text>
              </a>
            </li>
            <li>
              Click each update item below to see more information about it or{' '}
              <a href="https://app.cryptosheets.com/#/resources/changelog">
                <Text mark>view this in a browser</Text>
              </a>
            </li>
          </ol>
        </Paragraph>
      </Typography>
      <Change />
    </div>
  );
}
