import _ from 'lodash';

const mapValues = (obj, fn) =>
  Object.keys(obj).reduce((acc, k) => {
    acc[k] = fn(obj[k], k, obj);
    return acc;
  }, {});

function toString(o) {
  Object.keys(o).forEach((k) => {
    if (_.isNil(o[k])) {
      o[k] = '';
    }

    o[k] = `${o[k]}`;
  });

  return o;
}

export default {
  toString,
  mapValues,
};
