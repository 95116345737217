import React from 'react';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Avatar, Card, List, Typography } from 'antd';
import moment from 'moment';
import CreateIntegration from '../CreateIntegration';

import './index.less';

const { Paragraph } = Typography;

function ProviderListItem({ provider, selectKey }) {
  const { name, updatedAt, integrated, image, description } = provider;

  const createIntegrationProps = {
    provider,
    selectKey,
  };
  return (
    <List.Item className="settings-provider-list-item">
      <Card hoverable>
        <Card.Meta
          avatar={<Avatar size={56} src={image} />}
          title={
            <span>
              {name}{' '}
              {integrated ? (
                <CheckCircleTwoTone twoToneColor="#52c41a" />
              ) : null}
            </span>
          }
          description={
            <Paragraph
              className="item"
              ellipsis={{
                rows: 1,
              }}
            >
              {description}
            </Paragraph>
          }
        />
        <div className="cardItemContent">
          <span>{moment(updatedAt).fromNow()}</span>
          <div className="avatarList" style={{ float: 'right' }}>
            <CreateIntegration {...createIntegrationProps} />
          </div>
        </div>
      </Card>
    </List.Item>
  );
}

export default function ProviderList({ loading, data, selectKey, showModal }) {
  return (
    <div className="settings-provider-list">
      <List
        rowKey="providerId"
        loading={loading}
        grid={{
          gutter: 24,
          xl: 4,
          lg: 3,
          md: 3,
          sm: 2,
          xs: 1,
        }}
        dataSource={data}
        renderItem={(provider) => (
          <ProviderListItem
            provider={provider}
            showModal={showModal}
            selectKey={selectKey}
          />
        )}
      />
    </div>
  );
}
