import React from 'react';
import _ from 'lodash';
import { useRequest } from '@umijs/hooks';
import { useSelector } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Avatar, Collapse, Input, Typography } from 'antd';
import { Link } from 'react-router-dom';
import ToggleActive from './ToggleActive';
import IntegrationCardExtra from './IntegrationCardExtra';
import helpers, { decrypt } from './_helpers';
import { LOGO_BLACK } from '../../../../config';
import client from '../../../../feathers';

const { Panel } = Collapse;
const { Text, Paragraph, Title } = Typography;

const customPanelStyle = {
  border: 0,
};

function fetchProviderImage(providerName) {
  return client
    .service('providers')
    .find({
      query: {
        visible: true,
        name: providerName,
        $select: ['image'],
      },
    })
    .then(({ data }) => data[0]);
}

export default function IntegrationCard({
  _id,
  provider,
  type,
  value,
  name,
  isEditable,
  active,
  multiValue,
  services,
}) {
  const { truncate } = helpers(services);

  const { screenWidth } = useSelector((state) => state.app);
  const { loading, error, data } = useRequest(
    () => fetchProviderImage(provider),
    { refreshDeps: [provider] }
  );

  if (loading) {
    return <div>loading</div>;
  }

  if (error) {
    return <div>error</div>;
  }

  const image = _.get(data, ['image'], LOGO_BLACK);

  const isMobile = screenWidth < 391;

  const integrationCardExtraProps = {
    id: _id,
    provider,
    type,
    value,
    name,
    isEditable,
    active,
    multiValue,
  };

  return (
    <div style={{ marginTop: '10px' }}>
      <Collapse>
        <Panel
          header={
            <Text strong>
              <Avatar src={image} size={isMobile ? 20 : 32} />
              {isMobile ? truncate(provider) : provider}
            </Text>
          }
          key={_id}
          extra={[<ToggleActive id={_id} active={active} />]}
        >
          <Title level={4}>Your {provider} Integration</Title>
          <Paragraph>
            <Collapse defaultActiveKey="1">
              <Panel
                header={_.startCase(type)}
                key={1}
                showArrow={false}
                extra={<IntegrationCardExtra {...integrationCardExtraProps} />}
              >
                {type === 'multi' ? (
                  multiValue.map(({ name, value }) => (
                    <div style={{ marginBottom: '1em' }}>
                      <Input.Password
                        addonBefore={name}
                        style={{ maxWidth: 250 }}
                        value={decrypt(value)}
                      />
                      <br />
                    </div>
                  ))
                ) : (
                  <Input.Password
                    style={{ maxWidth: 250 }}
                    value={decrypt(value)}
                  />
                )}
              </Panel>
            </Collapse>
          </Paragraph>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => (
              <InfoCircleOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            <Panel style={customPanelStyle} header="View Docs">
              <Title level={4}>Usage</Title>
              <Paragraph>
                {provider} is a deep integrated provider. The security scheme
                has been fully integrated to:
                <ul>
                  <li>
                    <Link to={`/browse/${provider}`}>Website Requests</Link>
                  </li>
                  <li>
                    <Link to="/functions">Custom Functions</Link>
                  </li>
                </ul>
              </Paragraph>
            </Panel>
          </Collapse>
        </Panel>
      </Collapse>
    </div>
  );
}
