/* eslint-disable react/display-name */
/* eslint-disable no-nested-ternary */
import React from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { connectHits } from 'react-instantsearch-dom';
import {
  DefaultList,
  EndpointList,
  EndpointGrid,
  ProviderList,
  ProviderGrid,
  TemplateGrid,
  ScenarioGrid,
} from '../../components/hits';
import resourceData from '../../components/widget/constants';

function determineComponent(activeKey, selector, hits) {
  const hitProps = {
    activeKey,
    hits,
    widgetOptions: _.find(resourceData, { name: activeKey }),
  };
  switch (selector) {
    case 'Endpointslist':
      return <EndpointList hits={hits} />;
    case 'Endpointsgrid':
      return <EndpointGrid hits={hits} />;
    case 'Providersgrid':
      return <ProviderGrid hits={hits} />;
    case 'Providerslist':
      return <ProviderList hits={hits} />;
    case 'Templateslist':
      return <DefaultList {...hitProps} />;
    case 'Templatesgrid':
      hitProps.grid = true;
      return <TemplateGrid hits={hits} />;
    case 'Scenariosgrid':
      hitProps.grid = true;
      return <ScenarioGrid hits={hits} />;
    default:
      return <DefaultList {...hitProps} />;
  }
}

const MHit = React.memo(function Hit(props) {
  const { activeKey, view } = useSelector((state) => state.browse);

  const selector = _.join([activeKey, view], '');
  const { hits } = props;

  return (
    <div className="browseHits">
      {determineComponent(activeKey, selector, hits)}
    </div>
  );
});

const Hits = connectHits(MHit);

export default Hits;
