import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import nodefetch from 'node-fetch';
import { STREAMS_URL, TASKS_URL } from './config';

const { rest } = feathers;

export default (client, transport, server) => {
  const url = server === 'tasks' ? TASKS_URL : STREAMS_URL;
  // Socket config
  const socket = io(url, {
    transports: ['websocket'],
    upgrade: false,
  });

  // Rest config
  const fetch = typeof window === 'undefined' ? nodefetch : window.fetch;
  const restClient = rest(url);

  switch (transport) {
    case 'rest':
      return restClient.fetch(fetch);
    case 'socket':
      return feathers.socketio(socket, {
        timeout: 30000,
      });
    default:
      return feathers.socketio(socket, {
        timeout: 30000,
      });
  }
};
