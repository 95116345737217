import React from 'react';
import { useSelector } from 'react-redux';
import { useRequest } from '@umijs/hooks';
import { Alert, Input, Spin, PageHeader, Col, Card, Row } from 'antd';
import ActionPanel from './components/ActionPanel';
import AllTemplates from './components/AllTemplates';
import Resource from './components/Resource';
import Overview from './components/Overview';
import ShowInfo from './components/ShowInfo';
import client from '../../feathers';
import NotFound from './components/NotFound';
import Error from './components/Error';

import './template.css';

const { Search } = Input;

function fetchTemplate(templateId) {
  return client
    .service('templates')
    .get(templateId, {
      query: {
        visible: true,
        $select: [
          'name',
          'created_at',
          'updated_at',
          'version',
          'description',
          'category',
          'type',
          'thumb',
          'fileLength',
          'tags',
          'documentationUrl',
          'googleUrl',
        ],
      },
    })
    .then((result) => result);
}

function PageHeaderWithIcons({ googleUrl, name }) {
  return (
    <Row>
      <Col>{name}</Col>
    </Row>
  );
}

function Template(props) {
  const screenWidth = useSelector((state) => state.app.screenWidth);
  const host = useSelector((state) => state.auth.environment);
  const [search, setSearch] = React.useState('');

  function onChange(event) {
    setSearch(event.target.value);
  }

  const { widgetData } = props;
  const templateId = widgetData.templateId || 3;

  const { loading, error, data } = useRequest(() => fetchTemplate(templateId), {
    refreshDeps: [templateId],
  });

  if (loading)
    return (
      <Spin tip="Loading...">
        <Alert
          message="Hang tight..."
          description="We are fetching your template..."
          type="info"
        />
      </Spin>
    );

  if (error) {
    if (error.code === 404) {
      return <NotFound />;
    }

    return <Error />;
  }

  const template = data;

  const { name, googleUrl } = template;

  const pageHeaderProps = {
    name,
    googleUrl,
  };

  const overviewProps = {
    template,
    style: {
      background: '#fff',
      minHeight: '100vh',
      padding: '20px 25px 20px 20px',
      border: '1px solid #dedede',
    },
  };

  return (
    <div className="template-page">
      <Row gutter={8}>
        <Col xl={16} lg={16} md={24} sm={24} xs={24}>
          <PageHeader
            title={<PageHeaderWithIcons {...pageHeaderProps} />}
            style={{ marginBottom: 24 }}
            extra={<ActionPanel template={template} host={host} />}
          >
            <div className="template-wrap">
              <div className="template-content">
                <Resource template={template} />
              </div>
            </div>
          </PageHeader>
          <Card
            className="template-list"
            style={{ marginBottom: 24 }}
            title={
              <Search
                placeholder="Search other templates..."
                className=""
                value={search}
                onChange={onChange}
              />
            }
            bordered={false}
            loading={false}
            bodyStyle={{ padding: 0 }}
          >
            <AllTemplates search={search} />
          </Card>
        </Col>
        {screenWidth >= 1035 ? (
          <Col xl={8} lg={8} md={24} sm={24} xs={24}>
            <Overview {...overviewProps} />
          </Col>
        ) : null}
        <ShowInfo {...overviewProps} />
      </Row>
    </div>
  );
}

Template.defaultProps = {
  widgetData: {
    templateId: 3,
  },
};

// This function wraps the provider component and gets the provider name from the window.url
function WrapWithRoute({
  computedMatch,
  widgetData = { templateId: 3 },
  ...rest
}) {
  const { templateId } = computedMatch.params;

  widgetData.templateId = templateId;

  return <Template {...rest} widgetData={widgetData} />;
}

export { Template };
export default WrapWithRoute;
