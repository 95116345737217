import React from 'react';
import { useModal } from 'sunflower-antd';
import { Modal } from 'antd';
import Activate from './ActivateButton';
import Steps from './Steps';

function CreateIntegration(props) {
  const { provider } = props;
  const { modalProps, show, close } = useModal({
    defaultVisible: false,
  });
  const activateProps = {
    provider,
    show,
  };
  const stepsProps = {
    ...props,
    close,
  };
  return (
    <div>
      <Modal
        {...modalProps}
        style={{ top: 1 }}
        title={`Connect to ${provider.name}`}
        okText="Submit"
        width={600}
        footer={null}
      >
        <Steps {...stepsProps} />
      </Modal>
      <Activate {...activateProps} />
    </div>
  );
}

export default CreateIntegration;
