// $('#create-report').click(() => tryCatch(createReport));
import Excel from './get-excel';

async function hideGridlines() {
  await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.getActiveWorksheet();
    sheet.showGridlines = false;

    await context.sync();
  });
}

/** Load sample data into a new worksheet and create a chart */
async function createReport() {
  await Excel.run(async (context) => {
    const sheet = context.workbook.worksheets.add();

    try {
      await writeSheetData(sheet);
      sheet.activate();
      await context.sync();
      await hideGridlines();
    } catch (error) {
      // Try to activate the new sheet regardless, to show
      // how far the processing got before failing
      sheet.activate();
      await context.sync();

      // Then re-throw the original error, for appropriate error-handling
      // (in this snippet, simply showing a notification)
      throw error;
    }
  });

  console.log('Success!', 'Report generation completed.');
}

async function writeSheetData(sheet) {
  // Set the report title in the worksheet
  const titleCell = sheet.getCell(0, 0);
  titleCell.values = [['Custom Functions - Examples']];
  titleCell.format.font.name = 'Arial';
  titleCell.format.font.size = 18;

  // Set the report title #2 in the worksheet
  const titleCell2 = sheet.getCell(0, 0);
  titleCell.values = [['CS.ADDRESS() Example']];
  titleCell.format.font.name = 'Arial';
  titleCell.format.font.size = 18;

  // Create an array containing sample data
  const headerNames = ['arguments', 'values', 'possibleValues', 'function'];
  const data = [
    // ['try changing the values below', '', '', '=FORMULATEXT(D4)'],
    ['Hint: try changing the values below', '', '', '=FORMULATEXT(R[1]C[0])'],
    ['REQUIRED', '', '', '=CS.ADDRESS(B5)'],
    [
      'address',
      '0x742d35cc6634c0532925a3b844bc454e4438f44e',
      '(any valid address for applicable chains)',
      '',
    ],
    ['name', '(name of wallet)', '', ''],
    ['', '', '', ''],
    ['', '', '', ''],
  ];

  // Create an array containing sample data
  const headerNames2 = ['arguments', 'values', 'possible value', 'function'];
  const data2 = [
    [
      'Hint: try using =TRANSPOSE() to change the output for multiple addresses',
      '',
      '',
      '=FORMULATEXT(R[1]C[0])',
    ],
    ['REQUIRED', '', '', '=TRANSPOSE(CS.ADDRESS(R[1]C[-2]))'],
    [
      'address list (combined)',
      '=TEXTJOIN(",","true",R[1]C[0]:R[10]C[0])',
      '(any valid address for applicable chains)',
      '',
    ],
    ['address list', '0x742d35cc6634c0532925a3b844bc454e4438f44e', '', ''],
    ['', '0xf87a7ec94884f44d9de33d36b73f42c7c0dd38b1', '', ''],
    ['', 'LaizKtS5DUhPuP1nTQcc83MS7HwK6vk85z', '', ''],
    ['', '3MkUNScqf21EcfWq6T4x2MFgBeSTqhB5t6', '', ''],
    ['', '33Kja69SQVc8kozpoP7Qw6HFtGxHkiWzTz', '', ''],
    ['', '0xb62ef4c58f3997424b0cceab28811633201706bc', '', ''],
  ];

  // Create an array containing sample data
  // const headerNames = ['symbol', 'price', 'pctChg', 'volume', 'volatility'];
  // const data = [
  //   ['BTC', 5000, 7000, 6544, 4377],
  //   ['ETH', 400, 323, 276, 651],
  //   ['XRP', 12000, 8766, 8456, 9812],
  //   ['BCH', 1550, 1088, 692, 853],
  //   ['LTC', 225, 600, 923, 544],
  //   ['Tim Rice', 0, 0, 0, 0]
  // ];

  // Write the sample data to the specified range in the worksheet
  // and bold the header row
  const headerRow = titleCell
    .getOffsetRange(1, 0)
    .getResizedRange(0, headerNames.length - 1);
  headerRow.values = [headerNames];
  headerRow.getRow(0).format.font.bold = true;
  headerRow.format.fill.color = '#4473c5';
  headerRow.format.font.color = 'white';

  const dataRange = headerRow
    .getOffsetRange(1, 0)
    .getResizedRange(data.length - 1, 0);
  dataRange.values = data;

  titleCell.getResizedRange(0, headerNames.length - 1).merge();
  dataRange.format.autofitColumns();

  const columnRanges = headerNames.map((header, index) =>
    dataRange.getColumn(index).load('format/columnWidth')
  );
  await sheet.context.sync();

  // Write the #2 sample data to the specified range in the worksheet
  // and bold the header row
  const headerRow2 = titleCell2
    .getOffsetRange(20, 0)
    .getResizedRange(0, headerNames2.length - 1);
  headerRow2.values = [headerNames2];
  headerRow2.getRow(0).format.font.bold = true;
  headerRow2.format.fill.color = '#4473c5';
  headerRow2.format.font.color = 'white';

  const dataRange2 = headerRow2
    .getOffsetRange(1, 0)
    .getResizedRange(data2.length - 1, 0);
  dataRange2.values = data2;

  titleCell2.getResizedRange(0, headerNames2.length - 1).merge();
  dataRange2.format.autofitColumns();

  await sheet.context.sync();

  // For the header (product name) column, make it a minimum of 100px;
  // const firstColumn = columnRanges.shift();
  // if (firstColumn.format.columnWidth < 100) {
  //   console.log('Expanding the first column to 100px');
  //   firstColumn.format.columnWidth = 100;
  //   firstColumn.format.font.bold = true;
  //   firstColumn.format.fill.color = 'white';
  //   firstColumn.format.border.color = 'red';
  // }
  const secondColumn = columnRanges.shift(0);
  if (secondColumn.format.columnWidth < 100) {
    console.log('Expanding the second column to 100px');
    secondColumn.format.columnWidth = 100;
    secondColumn.format.font.bold = true;
    secondColumn.format.fill.color = 'white';
    secondColumn.format.border.color = 'red';
    secondColumn.format.fill.color = 'blue';
    secondColumn.format.border.color = 'red';
  }

  // For the remainder, make them identical or a minimum of 60px
  let minColumnWidth = 60;
  columnRanges.forEach((column, index) => {
    console.log(
      `Column #${index + 1}: auto-fitted width = ${column.format.columnWidth}`
    );
    minColumnWidth = Math.max(minColumnWidth, column.format.columnWidth);
  });
  console.log(`Setting data columns to a width of ${minColumnWidth} pixels`);
  dataRange
    .getOffsetRange(0, 1)
    .getResizedRange(0, -1).format.columnWidth = minColumnWidth;

  // Add a new chart
  const chart = sheet.charts.add(
    Excel.ChartType.columnClustered,
    dataRange,
    Excel.ChartSeriesBy.columns
  );

  // Set the properties and format the chart
  const chartTopRow = dataRange.getLastRow().getOffsetRange(200, 0);
  chart.setPosition(chartTopRow, chartTopRow.getOffsetRange(140, 0));
  chart.title.text = 'Crypto Dashboard';
  chart.legend.position = 'Right';
  chart.legend.format.fill.setSolidColor('white');
  chart.dataLabels.format.font.size = 9;
  chart.dataLabels.format.font.color = 'black';

  const { points } = chart.series.getItemAt(0);
  points.getItemAt(0).format.fill.setSolidColor('pink');
  points.getItemAt(1).format.fill.setSolidColor('indigo');
}

/** Default helper for invoking an action and handling errors. */
async function tryCatch(callback) {
  try {
    await callback();
  } catch (error) {
    // Note: In a production add-in, you'd want to notify the user through your add-in's UI.
    console.error(error);
  }
}

export default {
  tryCatch,
  createReport,
};
