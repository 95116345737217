import React from 'react';
import { Button, Result } from 'antd';

import './Success.css';

export default function CreateIntegrationStepsSuccess(props) {
  const { provider, selectKey, isMobile } = props;
  return (
    <div className={`integration-success ${isMobile ? 'is-mobile' : null}`}>
      <Result
        status="success"
        title={`Successfully integrated ${provider.name}`}
        subTitle="Your integration is located in Settings -> Your Integrations."
        extra={[
          <Button
            type="primary"
            key="console"
            onClick={() => selectKey('integrations')}
          >
            Go to Integrations
          </Button>,
        ]}
      />
    </div>
  );
}
