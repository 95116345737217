import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import client from '../../feathers';
import ActionPanel from './ActionPanel';

import styles from './style.less';

class Stream extends Component {
  clickedCancel = false;

  index = 0;

  cacheOriginData = {};

  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      loading: false,
      service: props.service,
      columns: props.columns,
    };
  }

  componentDidMount = () => {
    const { columns, loading } = this.state;

    const actions = {
      title: 'action',
      key: 'action',
      fixed: 'right',
      width: 100,
      render: (text, record) => (
        <ActionPanel
          record={record}
          loading={loading}
          create={this.create}
          patch={this.patch}
          remove={this.remove}
          cancel={this.cancel}
          saveRow={this.saveRow}
          toggleEditable={this.toggleEditable}
        />
      ),
    };

    this.setState({
      columns: columns
        .map((column) => {
          const render = (text, record) => {
            if (record.editable) {
              return (
                <Input
                  value={text}
                  autoFocus
                  onChange={(e) =>
                    this.handleFieldChange(e, column.title, record.key)
                  }
                  //   onKeyPress={(e) => this.handleKeyPress(e, record.key)}
                  placeholder="Enter a new value"
                />
              );
            }
            return text;
          };
          return {
            ...column,
            render,
          };
        })
        .concat(actions),
    });
  };

  create = async (data) => {
    const { service } = this.props;

    await client.service(service).create(data);
  };

  patch = async (id, data) => {
    const { service } = this.props;

    await client.service(service).patch(id, data);
  };

  remove = async (id) => {
    const { service } = this.props;

    await client.service(service).patch(id);
  };

  newRecord = () => {
    const { data = [] } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const [record] = data;
    newData.push({
      ...record,
      key: `NEW_TEMP_ID_${this.index}`,
      editable: true,
      isNew: true,
    });

    this.index += 1;
    this.setState({ data: newData });
  };

  saveRow = async (e, key, action) => {
    const { identifier } = this.props;
    e.persist();
    this.setState({
      loading: true,
    });

    if (this.clickedCancel) {
      this.clickedCancel = false;
      return;
    }

    const target = this.getRowByKey(key) || {};

    const { key: tableIdentifier, isNew, editable, ...payload } = target;

    delete payload[identifier];

    await action(payload);

    this.toggleEditable(e, key);

    this.setState({
      loading: false,
    });
  };

  handleFieldChange = (e, fieldName, key) => {
    const { data = [] } = this.state;
    const newData = [...data];
    const target = this.getRowByKey(key, newData);
    if (target) {
      target[fieldName] = e.target.value;

      this.setState({ data: newData });
    }
  };

  cancel = (e, key) => {
    this.clickedCancel = true;
    e.preventDefault();
    const { data = [] } = this.state;
    const newData = [...data];
    const sanitizedData = newData.map((item) => {
      if (item.key === key) {
        if (this.cacheOriginData[key]) {
          delete this.cacheOriginData[key];
          const returnObj = {
            ...item,
            ...this.cacheOriginData[key],
            editable: false,
          };
          return returnObj;
        }
      }
      return item;
    });

    this.setState({ data: sanitizedData });
    this.clickedCancel = false;
  };

  getRowByKey(key, newData) {
    console.log(key);
    const { data = [] } = this.state;
    return (newData || data).filter((item) => item.key === key)[0];
  }

  toggleEditable = (e, key) => {
    e.preventDefault();
    const { data = [] } = this.state;
    const newData = data.map((item) => ({ ...item }));
    const target = this.getRowByKey(key, newData);
    if (target) {
      if (!target.editable) {
        this.cacheOriginData[key] = { ...target };
      }
      target.editable = !target.editable;
      this.setState({ data: newData });
    }
  };

  render() {
    const { columns, data, loading } = this.state;

    // const expandComponent = this.props;

    return (
      <>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          pagination={false}
          size="small"
          rowClassName={(record) => (record.editable ? styles.editable : '')}
          expandable={this.props.expandable}
        />
        <Button
          style={{ width: '100%', marginTop: 16, marginBottom: 8 }}
          type="dashed"
          onClick={this.newRecord}
          icon={<PlusOutlined />}
        >
          Add new item
        </Button>
      </>
    );
  }
}

Stream.propTypes = {
  identifier: PropTypes.string,
  service: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
};

export default Stream;
