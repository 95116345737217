import placeData from './placeData';

const setData = async (response) => {
  await window.Excel.run(async (context) => {
    // const range = await getRange();
    const myWorkbook = context.workbook;
    const activeCell = myWorkbook.getActiveCell();
    activeCell.load(['address', 'rowIndex', 'columnIndex', 'worksheet']);

    return context.sync().then(() => {
      const { rowIndex, columnIndex, worksheet } = activeCell;
      const path = { row: rowIndex, col: columnIndex, worksheet };
      placeData(response, path);
    });
  });
};

export default setData;
