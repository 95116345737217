import React, { useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { Form, Modal, Input, message } from 'antd';
import helpers, { encrypt, decrypt } from './_helpers';

const CustomizedForm = ({ form, onChange, onFinish, fields }) => (
  <Form
    form={form}
    name="global_state"
    layout="inline"
    fields={fields}
    onFieldsChange={(changedFields, allFields) => {
      onChange(allFields);
    }}
    onFinish={onFinish}
  >
    {fields.map((field) => (
      <Form.Item
        name={field.name}
        label={_.startCase(field.name)}
        rules={[{ required: true, message: `${field.name} is required!` }]}
      >
        <Input.Password />
      </Form.Item>
    ))}
  </Form>
);

export default function IntegrationMultiForm(props) {
  const {
    action,
    id,
    type,
    isEditable,
    name,
    value,
    multiValue,
    services,
  } = props;
  const newKeyFields =
    type === 'multi'
      ? multiValue.map((mv) => {
          const { name, value } = mv;
          return {
            name: [name],
            value: decrypt(value),
          };
        })
      : [{ name, value: decrypt(value) }];
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [fields, setFields] = useState(newKeyFields);

  const { updateKey } = helpers(services);

  const { screenWidth } = useSelector((state) => state.app);
  const isMobile = screenWidth < 391;

  const onFinish = (values) => {
    const keyProperties = Object.keys(values);
    if (keyProperties.length > 1) {
      // Multi update
      const prepareUpdatedKeys = keyProperties.map((key) => ({
        name: key,
        // value: values[key],
        value: encrypt(values[key]),
      }));

      const patchObject = _.merge([], multiValue, prepareUpdatedKeys);

      return updateKey(id, { multiValue: patchObject }).then(() => {
        message.success('Success! Integration has been updated.');
        setModalVisible(false);
      });
    }
    // Simple update
    return updateKey(id, {
      name: keyProperties[0],
      value: encrypt(values[keyProperties[0]]),
    }).then(() => {
      message.success('Success! Integration has been updated.');
      setModalVisible(false);
    });
  };
  return (
    <div>
      <Modal
        title="Edit Integration"
        okText="Submit"
        visible={modalVisible}
        onOk={() => form.submit()}
        onCancel={() => setModalVisible(false)}
        okButtonProps={{ type: 'submit' }}
        width={600}
      >
        <CustomizedForm
          form={form}
          fields={fields}
          onChange={(newFields) => {
            setFields(newFields);
          }}
          onFinish={onFinish}
        />
      </Modal>
      {isEditable ? (
        <div
          style={{ marginLeft: '1em' }}
          onClick={() => setModalVisible(true)}
          onKeyDown={() => setModalVisible(true)}
          role="button"
          tabIndex="0"
        >
          {action.icon} {!isMobile ? action.type : null}
        </div>
      ) : null}
    </div>
  );
}
