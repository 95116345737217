/* eslint-disable react/sort-comp */
import React from 'react';
import { NumberOutlined } from '@ant-design/icons';
import { Button, Tooltip, Empty } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { WidthProvider, Responsive } from 'react-grid-layout';
import _ from 'lodash';
import EmptyWorkspace from './components/EmptyWorkspace';
import WidgetSelect from './components/WidgetSelect';
import WidgetContainer from '../../components/widget/WidgetContainer';

import './react-grid-layout.css';
import './react-resizable.css';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

/**
 * This layout demonstrates how to use a grid with a dynamic number of elements.
 */
export default function Workspace(props) {
  const { currentBreakpoint, firstTime, items } = useSelector(
    (state) => state.workspace
  );
  const dispatch = useDispatch();

  const WORKSPACE_TOGGLE_FIRST_TIME = () => {
    dispatch({ type: 'WORKSPACE_TOGGLE_FIRST_TIME' });
  };
  const WORKSPACE_CHANGE_BREAKPOINT = (breakpoint) => {
    dispatch({ type: 'WORKSPACE_CHANGE_BREAKPOINT', breakpoint });
  };
  const WORKSPACE_SAVE_LAYOUT = (layout, allLayouts) => {};
  const WORKSPACE_RESET = () => {
    dispatch({ type: 'WORKSPACE_RESET' });
  };
  const WORKSPACE_ON_RESIZE_STOP = (layout, oldItem, newItem, placeholder) => {
    dispatch({
      type: 'WORKSPACE_ON_RESIZE_STOP',
      layout,
      oldItem,
      newItem,
      placeholder,
    });
  };
  const WORKSPACE_ON_DRAG_STOP = (layout, oldItem, newItem, placeholder) => {
    dispatch({
      type: 'WORKSPACE_ON_DRAG_STOP',
      layout,
      oldItem,
      newItem,
      placeholder,
    });
  };

  const createItem = (item) => {
    const widgetProps = {
      i: item.i,
      widgetType: item.widgetType,
    };
    const i = item.add ? '+' : item.i;
    return (
      <div key={i} data-grid={item}>
        <WidgetContainer {...widgetProps} />
      </div>
    );
  };

  return (
    <div>
      <div className="workspaceSearchContainer">
        <div style={{ margin: 'auto', display: 'flex', flexDirection: 'row' }}>
          <WidgetSelect />
          <div style={{ margin: 'auto' }}>
            <Button type="link" onClick={WORKSPACE_RESET}>
              Reset
            </Button>
            <Tooltip title="Number of widgets">
              <NumberOutlined /> {items.length}
            </Tooltip>
          </div>
        </div>
      </div>

      {_.isEmpty(items[currentBreakpoint]) ? (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No widgets. Click the 'Search' dropdown to get started."
        />
      ) : (
        <ResponsiveReactGridLayout
          {...props}
          draggableHandle=".ant-card-head"
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 1 }}
          onLayoutChange={WORKSPACE_SAVE_LAYOUT}
          onBreakpointChange={WORKSPACE_CHANGE_BREAKPOINT}
          onDragStop={WORKSPACE_ON_DRAG_STOP}
          onResizeStop={WORKSPACE_ON_RESIZE_STOP}
        >
          {_.map(items[currentBreakpoint], (item) => createItem(item))}
        </ResponsiveReactGridLayout>
      )}

      {firstTime && (
        <EmptyWorkspace toggleFirstTime={WORKSPACE_TOGGLE_FIRST_TIME} />
      )}
    </div>
  );
}
