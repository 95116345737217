import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Empty, Button } from 'antd';
import ReactGA from 'react-ga';
import { view as CustomIcon } from '../../components/custom-icon';

import { view as SectionData } from '../../components/card/section';

import './overview.css';
import { getPlan } from '../account/reducer';

const OverviewComponent = (props) => {
  const { account, host } = props;
  return (
    <>
      <Empty
        // image="https://gw.alipayobjects.com/mdn/miniapp_social/afts/img/A*pevERLJC9v0AAAAAAAAAAABjAQAAAQ/original"
        imageStyle={{
          height: 60,
        }}
        description={
          <span>
            {/* <span style={{color: 'green' text}}>Welcome to CryptoSheets!</span> <br />
            <br /> */}
            <span style={{ color: 'green' }}>Welcome to Cryptosheets!</span>
            <br />
            <br />
            <span style={{ fontSize: '2em' }}>Get started by... </span>
            <br />
            <br />
            <span style={{ fontWeight: 'bold' }}>1.</span> Searching for{' '}
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="browse">data</Link>
            </span>{' '}
            <br />
            <span style={{ fontWeight: 'bold' }}>2.</span> Exploring the{' '}
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="resources/guides">menu on the left</Link>
            </span>{' '}
            <br />
            <span style={{ fontWeight: 'bold' }}>3.</span> Type{' '}
            <span style={{ fontWeight: 'bold' }}>" / "</span> for quick search{' '}
            <br />
            <span style={{ fontWeight: 'bold' }}>4.</span> Type{' '}
            <span style={{ fontWeight: 'bold' }}>
              {host === 'sheets'
                ? `=CSPRICE("BTC","USD")`
                : `=CS.PRICE("BTC","USD")`}
            </span>{' '}
            <br />
            <span style={{ fontWeight: 'bold' }}>5.</span> Take a quick{' '}
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="resources/guides">guided tour</Link>
            </span>{' '}
            <br />
            <span style={{ fontWeight: 'bold' }}>6.</span> Browsing our{' '}
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="resources/tutorials">tutorials</Link>
            </span>{' '}
            <br />
            <span style={{ fontWeight: 'bold' }}>7.</span> Trying our{' '}
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="/templates/260">templates</Link>
            </span>{' '}
            <br />
            <span style={{ fontWeight: 'bold' }}>8.</span> See the{' '}
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              <Link to="resources/changelog">latest updates </Link>
            </span>{' '}
            <br />
            <span style={{ fontWeight: 'bold' }}>9.</span> Visiting the{' '}
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              <a href="https://intercom.help/cryptosheets/en/" target="_blank">
                help center{' '}
              </a>
            </span>{' '}
            <br />
            <span style={{ fontWeight: 'bold' }}>10.</span> Visiting the{' '}
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              <a href="https://docs.cryptosheets.com/" target="_blank">
                docs portal{' '}
              </a>
            </span>{' '}
            <br />
            <br />
            {/* No endpoints have been saved */}
          </span>
        }
      >
        {host === 'web' ? (
          <Button.Group size="large">
            <Button
              href="https://appsource.microsoft.com/en-us/product/office/WA104381695?src=medium&mktcmpid=cmc"
              onClick={() =>
                ReactGA.event({
                  category: 'Get Excel',
                  action: 'User pressed Get Excel button on home page',
                })
              }
            >
              <CustomIcon type="excel" />
              Get for Excel
            </Button>
            <Button
              href="https://gsuite.google.com/marketplace/app/cryptosheets/898843532532"
              onClick={() =>
                ReactGA.event({
                  category: 'Get Sheets',
                  action: 'User pressed Get Sheets button on home page',
                })
              }
            >
              <CustomIcon type="sheets" />
              Get for Sheets
            </Button>
          </Button.Group>
        ) : (
          <Link to="browse">
            <Button type="primary">Browse Data</Button>
          </Link>
        )}
      </Empty>

      {account && account.last10Requests && (
        <div className="overview" style={{ marginTop: 20 }}>
          <SectionData sectionData={account.last10Requests} {...props} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const planId = state.account.dailyPlanId;
  return {
    data: state.endpoint.data,
    host: state.auth.environment,
    error: state.endpoint.error,
    status: state.endpoint.status,
    currentPlan: getPlan(state, planId),
  };
};

export default connect(mapStateToProps)(OverviewComponent);
