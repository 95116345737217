import { unicornCase } from '@cryptosheets/util';
import { message } from 'antd';
import { useServices } from '../../../../hooks/use-services';

export default () => {
  const feathersServices = useServices();

  function createIntegration(providerName, userId, submitData, validate, next) {
    console.log({ submitData });
    validate(true);
    const sanitizedProvider = unicornCase(providerName);

    const service = feathersServices[`integrations/${sanitizedProvider}/setup`];

    if (!service) {
      message.error('Provider is not yet integrated.');
      return null;
    }

    const messageConfig = {
      info: {
        key: 'createIntegration',
        content: 'Hang tight, we are creating your integration...',
      },
      success: {
        key: 'createIntegration',
        content: "Success! Check the 'Your Integrations' tab.",
      },
      error: {
        key: 'createIntegration',
        content:
          'We had trouble creating your integration. Contact support@cryptosheets.com for help.',
      },
    };
    message.info(messageConfig.info);
    return service
      .create({
        ...submitData,
        provider: providerName,
      })
      .then((res) => {
        message.success(messageConfig.success);
        feathersServices.keys.find({ query: { userId } });
        validate(false);
        next();
        return res;
      })
      .catch((e) => {
        message.error(messageConfig.error);
        return null;
      });
  }

  function requestProvider(provider, setIsRequested) {
    const messageConfig = {
      info: {
        key: 'createIntegration',
        content: 'Hang tight, we are sending your message...',
      },
      success: {
        key: 'createIntegration',
        content: 'Thank you for the feedback!',
      },
      error: {
        key: 'createIntegration',
        content:
          'We had trouble sending your message. Contact support@cryptosheets.com directly.',
      },
    };

    const data = {
      action: 'requestProvider',
      body: provider,
    };

    const service = feathersServices.contact;

    if (!service) {
      return message.error('Contact is not yet integrated.');
    }

    message.success(messageConfig.success);
    service.create(data);
    return setIsRequested(true);
  }

  function createKey(data) {
    return feathersServices.keys.patch(data);
  }
  return { createIntegration, requestProvider, createKey };
};
