import React from 'react';
import { Button, Checkbox, Divider, Typography } from 'antd';

const { Paragraph } = Typography;

export default function Deep({
  name,
  next,
  hasCredentials,
  setHasCredentials,
  handleSubmitData,
}) {
  return (
    <>
      <Paragraph>
        {name} is a deeply integrated provider. We can automatically create an
        integration on your behalf.
      </Paragraph>
      <br />
      <Paragraph>
        <Button
          type="primary"
          disabled={hasCredentials}
          onClick={() => {
            // We want this to skip most of the form and auto-integrate
            handleSubmitData(null);
            return next();
          }}
        >
          Create Integration
        </Button>
        <Divider />
        <Paragraph>
          <p style={{ marginBottom: '20px' }}>
            <Checkbox
              checked={hasCredentials}
              onChange={() => setHasCredentials(!hasCredentials)}
            >
              I have my own credentials
            </Checkbox>
          </p>
        </Paragraph>
      </Paragraph>
    </>
  );
}
