import React, { Component } from 'react';
import { List } from 'antd';
import UpdatePassword from './updatePassword';

export default class Security extends Component {
  componentWillUnmount() {
    const { resetInitialState } = this.props;
    resetInitialState();
  }

  getData = () => [
    {
      title: 'Account Password',
      description: <>Update your account password here.</>,
      actions: [<UpdatePassword {...this.props} />],
    },
  ];

  render() {
    const data = this.getData();
    return (
      <>
        <List
          itemLayout="horizontal"
          dataSource={data}
          renderItem={(item) => (
            <List.Item actions={item.actions}>
              <List.Item.Meta
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </>
    );
  }
}
