import _ from 'lodash';
import {
  insertSheets,
  newWorkbookFromEncodedString,
  tryCatch,
} from '@cryptosheets/util';
import client from '../../feathers';

async function insertTemplateIntoWorkbookById(id) {
  return client
    .service('templates')
    .get(id[0])
    .then(({ template, tab }) => {
      if (_.isNil(tab)) {
        return tryCatch(insertSheets(template));
      }
      return tryCatch(insertSheets(template, [tab]));
    })
    .catch((e) => console.log(e));
}

async function openNewWorkbookByTemplateId(id) {
  return client
    .service('templates')
    .get(id[0])
    .then(({ template, tab }) => {
      if (_.isNil(tab)) {
        return tryCatch(newWorkbookFromEncodedString(template));
      }
      return tryCatch(newWorkbookFromEncodedString(template, [tab]));
    })
    .catch((e) => console.log(e));
}

export { insertTemplateIntoWorkbookById, openNewWorkbookByTemplateId };
