import React, { PureComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import ReactResizeDetector from 'react-resize-detector';
import { view as Header } from '../components/header';
import { view as Breadcrumb } from '../components/breadcrumb';
import { view as Exception404 } from './exception/404';
import { view as Browse } from './browse';
import { view as Endpoint } from './endpoint';
import { view as Overview } from './overview';
import { view as Parameters } from './parameters';
import { view as Provider } from './provider';
import { view as Scenario } from './scenario';
import { view as Reference } from './reference';
import { view as Sidebar } from '../components/sidebar';
import { view as Settings } from './account';
import { view as Test } from './testing';
import { view as Docs } from './docs';
import { view as Resources } from './resources';
import { view as Workspace } from './workspace';
import { view as Charts } from './charts';
import { view as About } from './about';
import { view as Paradigm } from './paradigm';
import { view as AllContent } from './allContent';
import { view as OneContent } from './content';
import { view as Explorer } from './explorer';
import { view as UniConsole } from './uni-console';
import { view as Template } from './template';
import { view as Templates } from './templates';
import { view as Functions } from './functions';
import { view as TemplateEngine } from './template-engine';

import { actions as loginActions } from '../account/actions';
import { actions as providerActions } from './provider/actions';
import { PATHS } from './constants';
import PageRoute from './pageRoute';
import { providerSearch, endpointSearch } from '../appGlobal/reducer';

// Admin routes
import Navigate from './admin/navigate';
import EasyEndpoint from './admin/endpoint';
import Enums from './admin/enums';
import Trade from './admin/trade';
import Uni from './admin/uni';

import './home.css';

const { Content } = Layout;

const pages = {
  [PATHS.Overview]: {
    component: Overview,
    path: '/',
  },
  [PATHS.Browse]: {
    component: Browse,
    path: '/browse',
  },
  [PATHS.BrowseProviders]: {
    component: Browse,
    path: '/browse/providers',
  },
  [PATHS.BrowseEndpoints]: {
    component: Browse,
    path: '/browse/endpoints',
  },
  [PATHS.BrowseTemplates]: {
    component: Browse,
    path: '/browse/templates',
  },
  [PATHS.Endpoint]: {
    component: Endpoint,
    path: '/browse/:provider/:endpoint',
  },
  [PATHS.Workspace]: {
    component: Workspace,
    path: '/workspace',
  },
  [PATHS.Settings]: {
    component: Settings,
    path: '/settings',
  },
  [PATHS.Provider]: {
    component: Provider,
    path: '/browse/:provider',
  },
  [PATHS.Parameters]: {
    component: Parameters,
    path: '/parameters',
  },
  [PATHS.Reference]: {
    component: Reference,
    path: '/reference',
  },
  [PATHS.Test]: {
    component: Test,
    path: '/test',
  },
  [PATHS.Resources]: {
    component: Resources,
    path: '/resources',
  },
  [PATHS.Resource]: {
    component: Resources,
    path: '/resources/:resource',
  },
  [PATHS.Docs]: {
    component: Docs,
    path: '/docs/*',
  },
  [PATHS.Charts]: {
    component: Charts,
    path: '/charts',
  },
  [PATHS.AllContent]: {
    component: AllContent,
    path: '/content/:contentType',
  },
  [PATHS.Content]: {
    component: OneContent,
    path: '/content/:contentType/:objectID',
  },
  [PATHS.About]: {
    component: About,
    path: '/about',
  },
  [PATHS.Paradigm]: {
    component: Paradigm,
    path: '/paradigm',
  },
  [PATHS.Explorer]: {
    component: Explorer,
    path: '/explorer',
  },
  [PATHS.UniConsole]: {
    component: UniConsole,
    path: '/uni',
  },
  [PATHS.Templates]: {
    component: Templates,
    path: '/templates',
  },
  [PATHS.Template]: {
    component: Template,
    path: '/templates/:templateId',
  },
  [PATHS.Functions]: {
    component: Functions,
    path: '/functions',
  },
  [PATHS.Function]: {
    component: Functions,
    path: '/functions/:function',
  },
  [PATHS.Function]: {
    component: Functions,
    path: '/functions/:query',
  },
  [PATHS.Function]: {
    component: Functions,
    path: '/functions/:streaming',
  },
  [PATHS.Scenario]: {
    component: Scenario,
    path: '/scenario/:scenarioId',
  },
  [PATHS.TemplateEngine]: {
    component: TemplateEngine,
    path: '/template-engine',
    isAdmin: true,
  },
  [PATHS.EasyEndpoint]: {
    component: EasyEndpoint,
    path: '/admin/endpoint',
    isAdmin: true,
  },
  [PATHS.Enums]: {
    component: Enums,
    path: '/admin/enums',
    isAdmin: true,
  },
  [PATHS.Navigate]: {
    component: Navigate,
    path: '/admin/navigate',
    isAdmin: true,
  },
  [PATHS.Trade]: {
    component: Trade,
    path: '/admin/trade',
    isAdmin: true,
  },
  [PATHS.Uni]: {
    component: Uni,
    path: '/admin/uni',
    isAdmin: true,
  },
};

class HomePage extends PureComponent {
  state = {
    collapsed: true,
  };

  setCollapsed = () => {
    this.setState({ collapsed: !this.state.collapsed });
  };

  render() {
    const {
      loc,
      clear,
      loggedIn,
      currentUser,
      providerSearch,
      endpointSearch,
      globalSearch,
    } = this.props;

    const { collapsed } = this.state;
    const sidebarWidth = collapsed ? 30 : 256;
    const sidebarStyle = {
      flex: `0 0 ${sidebarWidth}px`,
      width: `${sidebarWidth}px`,
      zIndex: 10,
    };
    return (
      <div className="ant-layout ant-layout-has-sider">
        <div
          style={sidebarStyle}
          className="ant-layout-sider ant-layout-sider-dark"
        >
          <Sidebar collapsed={collapsed} setCollapsed={this.setCollapsed} />
        </div>
        <div className="content-wrapper ant-layout">
          <ReactResizeDetector handleWidth handleHeight id="paramsTable">
            {({ width, height }) => (
              <Scrollbars style={{ width, height }}>
                <div className="header ant-layout-header">
                  <Header
                    collapsed={collapsed}
                    sidebarWidth={sidebarWidth}
                    setCollapsed={this.setCollapsed}
                    clear={clear}
                    loggedIn={loggedIn}
                    currentUser={currentUser}
                    globalSearch={globalSearch}
                    providerSearch={providerSearch}
                    endpointSearch={endpointSearch}
                  />
                </div>
                <Content style={{ margin: '10px', paddingBottom: '80px' }}>
                  <Breadcrumb style={{ margin: '16px 0' }} loc={loc} />
                  <Switch>
                    {Object.keys(pages).map((path) => (
                      <PageRoute
                        key={path}
                        exact
                        path={path}
                        component={pages[path].component}
                        isAdmin={pages[path].isAdmin}
                        pageWidth={width}
                        pageHeight={height}
                        {...this.props}
                      />
                    ))}
                    <Route component={Exception404} />
                  </Switch>
                </Content>
              </Scrollbars>
            )}
          </ReactResizeDetector>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const { globalSearchValue } = state.app;
  return {
    loc: state.router.location,
    loggedIn: state.users.data !== null,
    currentUser: state.users.data,
    providers: state.provider.providers,
    providerSearch: providerSearch(state, globalSearchValue),
    endpointSearch: endpointSearch(state, globalSearchValue),
    globalSearch: state.app.globalSearch,
    account: state.account,
    dailyPlanId: state.account.dailyPlanId,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  clear: () => {
    dispatch(loginActions.clearAuthCreds(props.services));
  },
  loadEndpoints: (id) => {
    dispatch(providerActions.loadProvidersEndpoints(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
