function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  background: #fafafa;\n  margin-right: 5px;\n  padding: 2px 2px 2px 2px;\n  box-shadow: 0 5px 10px -10px rgba(0, 0, 0, 0.25);\n  /* box-shadow: 0 2px 6px 0 hsla(0, 0%, 0%, 0.2); */\n  border: 1px solid #dddeee;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  background-color: white;\n  height: 600px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n  -ms-flex-pack: center;\n  justify-content: center;\n  padding: 12px 0;\n  opacity: 0.5;\n  -webkit-transition: opacity 0.3s;\n  transition: opacity 0.3s;\n  border-top: 1px dashed #f0f0f0;\n\n  &:hover {\n    opacity: 1;\n  }\n\n  .ant-btn-icon-only:hover {\n    .anticon:hover {\n      color: hsl(150, 98%, 5%);\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import styled from 'styled-components';
export var CodeBoxActions = styled.div(_templateObject());
export var EditorWrapper = styled.div(_templateObject2());
export var ResourceWrapper = styled.div(_templateObject3());