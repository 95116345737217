import React from 'react';
import { Route } from 'react-router-dom';
import { view as Protected } from '../components/protected';

const PageRoute = ({ component: Component, isAdmin = false, ...rest }) => {
  if (isAdmin) {
    return (
      <Protected>
        <Route
          {...rest}
          render={(props) => <Component {...props} {...rest} />}
        />
      </Protected>
    );
  }
  return (
    <Route {...rest} render={(props) => <Component {...props} {...rest} />} />
  );
};

export default PageRoute;
