/* eslint-disable import/prefer-default-export */
import { useRef, useEffect } from 'react';
import _ from 'lodash';
import {
  insertSheets,
  fetchUrlAndSanitizeParams,
  newWorkbookFromEncodedString,
  parseThenSendToSheet,
} from '@cryptosheets/util';
import { message } from 'antd';
import URI from 'urijs';
import { onStatusChange } from '../../_helpers/status-change';
import feathers from '../../../feathers';

function fetchTemplates() {
  return feathers
    .service('templates')
    .find({
      query: {
        visible: true,
        $sort: {
          created_at: 1,
        },
        // $limit: 100,
        $select: [
          'name',
          'templateId',
          'description',
          'thumb',
          'created_at',
          'updated_at',
          'version',
          'fileLength',
        ],
      },
    })
    .then(({ data }) => data);
}

function fetchTemplateById(id) {
  return feathers
    .service('templates')
    .get(id, {
      query: {},
    })
    .then(({ data }) => data);
}

async function insertTemplateIntoWorkbookById(id) {
  onStatusChange('loading', 'template');
  return feathers
    .service('templates')
    .get(id[0])
    .then(({ template, tab }) => {
      if (_.isNil(tab)) {
        return insertSheets(template);
      }
      return insertSheets(template, [tab]);
    })
    .then(() => onStatusChange('success', 'template'))
    .catch((e) => onStatusChange('error', 'template', e.message));
}

async function openNewWorkbookByTemplateId(id, host = 'excel') {
  onStatusChange('loading', 'template');
  return feathers
    .service('templates')
    .get(id[0])
    .then(({ template, tab }) => {
      if (_.isNil(tab)) {
        return newWorkbookFromEncodedString(template, null, host);
      }
      return newWorkbookFromEncodedString(template, [tab], host);
    })
    .then(() => onStatusChange('success', 'template'))
    .catch((e) => onStatusChange('error', 'template', e.message));
}

const handleMenuClick = (record, e) => {
  if (e.key === '1') {
    insertTemplateIntoWorkbookById(record.templateId);
  } else if (e.key === '2') {
    openNewWorkbookByTemplateId(record.templateId);
  }
};

function proxyThenSendToSheet(url, data) {
  message.loading({ content: 'Fetching resource...', key: 'sendToSheet' });
  return feathers
    .service('api/query')
    .find({
      query: {
        url,
        providerId: 1211,
        provider: 'Paradigm',
        endpoint: 'q',
        ...data,
      },
    })
    .then(parseThenSendToSheet)
    .then(() =>
      message.success({
        content: 'Success! Check your worksheet',
        key: 'sendToSheet',
      })
    )
    .catch(() =>
      message.error({
        content: 'Send to sheet failed',
        key: 'sendToSheet',
      })
    );
}

const buildUrl = (url) => {
  if (!url) {
    return 'https://docs.paradigmapi.com/widgets/top100_twtr_followers';
  }
  const uri = new URI(url);
  const segment = uri.segment();
  const widget = segment[segment.length - 1];
  const widgetUrl = `https://api.paradigmapi.com/q/?e=widget&m=${widget}`;
  return widgetUrl;
};

const buildParams = (url) => {
  if (!url) {
    return 'https://docs.paradigmapi.com/widgets/top100_twtr_followers';
  }
  const uri = new URI(url);
  const segment = uri.segment();
  const widget = segment[segment.length - 1];
  const params = {
    e: 'widget',
    m: widget,
  };
  return params;
};

function getParamsByUrl(url) {
  const result = URI.parse(url);
  const { query } = result;

  return URI.parseQuery(query);
}

async function fetchScenarioData(endpoint, parameters) {
  const { name, provider } = endpoint;

  const query = _.reduce(
    parameters,
    (result, param) => {
      const { scenarioParam, value } = param;

      result[scenarioParam] = value;
      return result;
    },
    {}
  );

  query.provider = provider.name;
  query.endpoint = name;

  const params = {
    query,
  };

  const service = feathers.service('api/query');

  const response = await service.find(params);

  return response;
}

const getMessageStatus = (status, msg) => {
  const key = 'status';
  const config = {
    success: () => message.success({ content: 'Success!', key }),
    loading: () => message.loading({ content: 'Loading...', key }),
    error: () => message.error({ content: `Error: ${msg}`, key }),
  };

  return config[status]();
};

async function fetchScenario(objectUrl) {
  try {
    const { fragment } = URI.parse(objectUrl);
    const { query } = URI.parse(fragment);
    const { scenarioId } = URI.parseQuery(query);

    const params = {
      query: {
        $eager: '[endpoint.provider, parameters]',
      },
    };

    getMessageStatus('loading');
    const scenario = await feathers
      .service('scenarios')
      .get(scenarioId, params);

    const { endpoint, parameters } = scenario;

    const data = await fetchScenarioData(endpoint, parameters);

    await parseThenSendToSheet(data);

    return getMessageStatus('success');
  } catch (e) {
    return getMessageStatus('error', e.message);
  }
}

function handleSendToSheet(widgetData) {
  let sheetData;
  if (widgetData) {
    const { title, source, objectSubtype, objectUrl } = widgetData;
    sheetData = { title, source, objectSubtype, objectUrl };
  }
  const contentType = _.get(widgetData, ['objectType'], 'default');

  switch (contentType) {
    case 'widget':
      return proxyThenSendToSheet(
        buildUrl(widgetData.objectUrl),
        buildParams(widgetData.objectUrl)
      );
    case 'scenario':
      return fetchScenario(sheetData.objectUrl);
    case 'Scenario':
      return fetchUrlAndSanitizeParams(
        widgetData.objectUrl,
        getParamsByUrl(widgetData.objectUrl)
      ).then(parseThenSendToSheet);
    default:
      return parseThenSendToSheet(sheetData);
  }
}

function openInfoWidget(e, actions, props) {
  const findAction = actions.find((action) => action.key === e.key);

  try {
    return findAction.action(props);
  } catch (error) {
    console.log(error);
    message.error(`Could not complete action. Reason: ${error.message}`);
  }
}

function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

export {
  fetchTemplates,
  fetchTemplateById,
  insertTemplateIntoWorkbookById,
  openNewWorkbookByTemplateId,
  handleMenuClick,
  handleSendToSheet,
  openInfoWidget,
  useTraceUpdate,
};
