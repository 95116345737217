import React from 'react';
import { Button, Divider, Popconfirm } from 'antd';

export default function ActionPanel({
  record,
  loading,
  create,
  patch,
  remove,
  cancel,
  saveRow,
  toggleEditable,
}) {
  if (!!record.editable && loading) {
    return null;
  }
  if (record.editable) {
    if (record.isNew) {
      return (
        <span>
          <Button type="link" onClick={(e) => saveRow(e, record.key, create)}>
            Add
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure you want to delete?"
            onConfirm={() => remove(record.key)}
          >
            <Button type="link">Delete</Button>
          </Popconfirm>
        </span>
      );
    }
    return (
      <span>
        <Divider type="vertical" />
        <Button onClick={(e) => saveRow(e, record.key, create)}>Save</Button>
        <Divider type="vertical" />
        <Button onClick={(e) => cancel(e, record.key)}>Cancel</Button>
      </span>
    );
  }
  return (
    <span>
      <Button type="link" onClick={(e) => toggleEditable(e, record.key)}>
        Edit
      </Button>
      <Divider type="vertical" />
      <Popconfirm
        title="Are you sure you want to delete?"
        onConfirm={() => remove(record.key)}
      >
        <Button type="link">Delete</Button>
      </Popconfirm>
    </span>
  );
}
