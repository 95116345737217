import { applyMiddleware, createStore, combineReducers, compose } from 'redux';
import { createHashHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import resetEnhancer from './enhancer/reset';
import { loadAuthToken } from './util/localStorage';
import helpers from './util/helpers';
import { actions as appActions } from './appGlobal/actions';
import { actions as accountActions } from './pages/account/actions';
import { actions as serviceActions } from './services/actions';

// // Import reducers
import { reducer as appReducer } from './appGlobal';
import { reducer as authReducer } from './account';
import { reducer as browseReducer } from './pages/browse';
import { reducer as endpointReducer } from './pages/endpoint';
import { reducer as loadingReducer } from './components/loading';
import { reducer as accountReducer } from './pages/account';
import { reducer as providerReducer } from './pages/provider';
import { reducer as templateReducer } from './pages/template';
import { reducer as workspaceReducer } from './pages/workspace';

const { utils } = helpers;
const { debounced } = utils;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rawServices = serviceActions;

const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer,
    auth: authReducer,
    account: accountReducer,
    browse: browseReducer,
    endpoint: endpointReducer,
    loading: loadingReducer,
    provider: providerReducer,
    template: templateReducer,
    workspace: workspaceReducer,
    // Feathers services
    authManagement: rawServices.authManagement.reducer,
    users: rawServices.users.reducer,
    keys: rawServices.keys.reducer,
    contact: rawServices.contact.reducer,
    providers: rawServices.providers.reducer,
    changelog: rawServices.changelog.reducer,
    posts: rawServices['content/posts'].reducer,
    quota: rawServices.quota.reducer,
    stripeCheckout: rawServices['stripe/checkout'].reducer,
    stripeCustomer: rawServices['stripe/customer'].reducer,
    stripeSubscription: rawServices['stripe/subscription'].reducer,
    stripeSubscriptionItem: rawServices['stripe/subscription-item'].reducer,
    stripePlan: rawServices['stripe/plan'].reducer,
    integrationsCryptocompare:
      rawServices['integrations/cryptocompare'].reducer,
    integrationsCryptocompareSetup:
      rawServices['integrations/cryptocompare/setup'].reducer,
    integrationsParadigmSetup:
      rawServices['integrations/paradigm/setup'].reducer,
    integrationsCointrackingSetup:
      rawServices['integrations/cointracking/setup'].reducer,
  });

export const history = createHashHistory();
const middlewares = [thunk, promise, routerMiddleware(history)];

const storeEnhancers = composeEnhancers(
  resetEnhancer,
  applyMiddleware(...middlewares)
);

export default function configureStore(preloadedState) {
  const store = createStore(reducer(history), preloadedState, storeEnhancers);
  store._reducers = reducer;

  // Hydrate the authToken from localStorage if it exist
  const authToken = loadAuthToken();
  if (authToken) {
    store.dispatch(accountActions.loadStripePlans());
  }

  // Add event listener for screen resize
  const resizeHandler = () =>
    store.dispatch(appActions.screenResize(window.innerWidth));
  const tHandler = debounced(100, resizeHandler);
  window.addEventListener('resize', tHandler);

  return store;
}
