/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { connectSearchBox } from 'react-instantsearch-dom';

import './AlgoliaSearch.css';

class SearchBox extends Component {
  static defaultProps = {
    defaultActiveFirstOption: false,
    className: '',
    placeholder: '',
    dataSource: [],
    defaultOpen: false,
    onPressEnter: () => {},
    onSearch: () => {},
    onChange: () => {},
    onVisibleChange: () => {},
  };

  static getDerivedStateFromProps(props) {
    if ('open' in props) {
      return {
        searchMode: props.open,
      };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      searchMode: props.defaultOpen,
      value: this.props.currentRefinement,
    };
  }

  onChange = (value) => {
    const { onSearch, onChange } = this.props;
    this.setState({ value });
    this.props.refine(value);
    if (onSearch) {
      onSearch(value);
    }
    if (onChange) {
      onChange(value);
    }
  };

  enterSearchMode = () => {
    const { onVisibleChange } = this.props;
    onVisibleChange(true);
    this.setState({ searchMode: true }, () => {
      const { searchMode } = this.state;
      if (searchMode && this.inputRef) {
        this.inputRef.focus();
      }
    });
  };

  leaveSearchMode = () => {
    this.setState({
      searchMode: false,
      value: '',
    });
  };

  render() {
    const {
      className,
      dataSource,
      placeholder,
      open,
      hits,
      currentRefinement,
      refine,
      ...rest
    } = this.props;
    const { searchMode } = this.state;
    delete rest.defaultOpen;
    const inputClass = `input ${searchMode ? 'show' : ''}`;
    return (
      <span
        className={`${className} headerSearch`}
        onClick={this.enterSearchMode}
        onKeyPress={this.enterSearchMode}
        role="button"
        onTransitionEnd={({ propertyName }) => {
          if (propertyName === 'width' && !searchMode) {
            const { onVisibleChange } = this.props;
            onVisibleChange(searchMode);
          }
        }}
      >
        <SearchOutlined />
        <Input
          ref={(node) => {
            this.inputRef = node;
          }}
          key="AlgoliaSearch"
          className={inputClass}
          size="small"
          value={currentRefinement}
          onChange={(event) => refine(event.currentTarget.value)}
          onBlur={this.leaveSearchMode}
        />
      </span>
    );
  }
}

const CustomSearchBox = connectSearchBox(SearchBox);

export default CustomSearchBox;
