import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { PlusOutlined } from '@ant-design/icons';
import { Upload, Modal } from 'antd';
import client from '../../../feathers';

import './TemplateFrame.css';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

/**
 * Creates link source and filename from template
 * @param  {string} xlsx
 * @return {void}
 */
function downloadExcel(template) {
  const friendlyTemplate = window.decodeURI(template.template);

  const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${friendlyTemplate}`;
  const downloadLink = document.createElement('a');
  const fileName = `${_.snakeCase(template.name)}`;

  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

class TemplateFrame extends React.Component {
  state = {
    previewVisible: false,
    previewImage: this.props.previewImage,
    fileList: this.props.fileList,
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.preview || file.url,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  handleDownload = async file => {
    const { host } = this.props;
    const response = await client.service('templates').get(file.uid);

    if (host === 'web') {
      return downloadExcel(response);
    }
  };

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        <PlusOutlined />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    return (
      <div id="template-frame" className="clearfix">
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          onDownload={this.handleDownload}
          showUploadList={{ showRemoveIcon: false }}
        >
          {fileList.length >= 0 ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  host: state.auth.environment,
});

export default connect(mapStateToProps)(TemplateFrame);
