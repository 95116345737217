export const searchIndices = [
  {
    key: `All`,
    hitComp: `PageHit`,
    algolia: {
      indexName: 'CRYPTOSHEETS_SEARCH',
      searchParameters: {
        hitsPerPage: 10,
      },
    },
    defaultRefinement: 'CRYPTOSHEETS_SEARCH',
    items: [
      { value: 'CRYPTOSHEETS_SEARCH', label: 'Newest' },
      { value: 'CRYPTOSHEETS_SEARCH_ALPHABETICAL', label: 'Alphabetical' },
    ],
    refinementLists: [
      { displayName: 'Type', attributeName: 'objectType' },
      {
        displayName: 'Category',
        attributeName: 'objectSubtype',
        searchable: true,
        placeholder: 'Search subtypes...',
      },
      {
        displayName: 'Source',
        attributeName: 'source',
        searchable: true,
        placeholder: 'Search source...',
      },
    ],
  },
  {
    key: `Endpoints`,
    algolia: {
      indexName: 'ENDPOINTS',
      searchParameters: {
        hitsPerPage: 10,
      },
    },
    defaultRefinement: 'ENDPOINTS',
    // Use for the SortBy component
    items: [
      { value: 'ENDPOINTS', label: 'Newest' },
      { value: 'ENDPOINTS_NAME_ALPHABETICAL', label: 'Alphabetical' },
    ],
    // Use for Refinement List
    refinementLists: [
      {
        displayName: 'Provider',
        attributeName: 'provider.name',
        searchable: true,
        placeholder: 'Search provider...',
      },
      { displayName: 'Type', attributeName: 'shortcodes.type' },
      {
        displayName: 'Sub Category',
        attributeName: 'shortcodes.subCategory',
      },
    ],
  },
  {
    key: `Providers`,
    hitComp: `PageHit`,
    algolia: {
      indexName: 'PROVIDERS',
      searchParameters: {
        hitsPerPage: 10,
      },
    },
    defaultRefinement: 'PROVIDERS',
    items: [
      { value: 'PROVIDERS', label: 'Newest' },
      { value: 'PROVIDERS_ALPHABETICAL', label: 'Alphabetical' },
    ],
    refinementLists: [
      { displayName: 'Type', attributeName: 'shortcodes.type' },
      {
        displayName: 'Sub-Category',
        attributeName: 'shortcodes.subCategory',
      },
    ],
  },
  {
    key: `Templates`,
    hitComp: `PageHit`,
    algolia: {
      indexName: 'CRYPTOSHEETS_SEARCH',
      searchParameters: {
        filters: 'objectType:template',
        hitsPerPage: 10,
      },
    },
    defaultRefinement: 'CRYPTOSHEETS_SEARCH',
    items: [
      { value: 'CRYPTOSHEETS_SEARCH', label: 'Newest' },
      { value: 'CRYPTOSHEETS_SEARCH_ALPHABETICAL', label: 'Alphabetical' },
    ],
    refinementLists: [
      {
        displayName: 'Category',
        attributeName: 'dimType',
        searchable: true,
        placeholder: 'Search category...',
      },
      {
        displayName: 'Type',
        attributeName: 'objectSubtype',
        searchable: true,
        placeholder: 'Search category...',
      },
    ],
  },
  {
    key: `Scenarios`,
    hitComp: `PageHit`,
    algolia: {
      indexName: 'CRYPTOSHEETS_SEARCH',
      searchParameters: {
        filters: 'objectType:scenario',
        hitsPerPage: 10,
      },
    },
    defaultRefinement: 'CRYPTOSHEETS_SEARCH',
    items: [
      { value: 'CRYPTOSHEETS_SEARCH', label: 'Newest' },
      { value: 'CRYPTOSHEETS_SEARCH_ALPHABETICAL', label: 'Alphabetical' },
    ],
    refinementLists: [
      {
        displayName: 'Source',
        attributeName: 'source',
        searchable: true,
        placeholder: 'Search source...',
      },
      {
        displayName: 'Category',
        attributeName: 'dimType',
        searchable: true,
        placeholder: 'Search category...',
      },
      {
        displayName: 'Type',
        attributeName: 'dimType2',
        searchable: true,
        placeholder: 'Search type...',
      },
      {
        displayName: 'Sub Type',
        attributeName: 'objectSubtype',
        searchable: true,
        placeholder: 'Search sub type...',
      },
    ],
  },
];
