import React from 'react';

import './markdown.less';

function Container({ children }) {
  return (
    <div className="main-container">
      <article className="markdown api-container article-start">
        {children}
      </article>
    </div>
  );
}

export default Container;
