import React, { Component } from 'react';
import _ from 'lodash';
import { parseFullName } from '@cryptosheets/util';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { view as Exception403 } from '../exception/403';
import { actions as accountActions } from './actions';
import { getPlan } from './reducer';
import { view as Integrations } from './components/integrations';
import AllIntegrations from './components/allIntegrations';
import { view as Billing } from './components/billing';
import BaseView from './components/base';
import Security from './components/security';

import './account.less';

const { Item } = Menu;

class Settings extends Component {
  constructor(props) {
    super(props);
    const menuMap = {
      base: 'Basic Settings',
      security: 'Security',
      billing: 'Billing',
      integrations: 'Your Integrations',
      allIntegrations: 'All Integrations',
    };
    this.state = {
      mode: 'inline',
      menuMap,
      selectKey: this.props.selectKey,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  getMenu = () => {
    const { menuMap } = this.state;
    return Object.keys(menuMap).map((item) => (
      <Item key={item}>{menuMap[item]}</Item>
    ));
  };

  getRightTitle = () => {
    const { selectKey, menuMap } = this.state;
    return menuMap[selectKey];
  };

  selectKey = (key) => {
    this.setState({ selectKey: key });
  };

  resize = () => {
    if (!this.main) {
      return;
    }
    requestAnimationFrame(() => {
      if (!this.main) {
        return;
      }
      let mode = 'inline';
      const { offsetWidth } = this.main;
      if (this.main.offsetWidth < 641 && offsetWidth > 400) {
        mode = 'horizontal';
      }
      if (window.innerWidth < 768 && offsetWidth > 400) {
        mode = 'horizontal';
      }
      this.setState({
        mode,
      });
    });
  };

  renderChildren = () => {
    const { selectKey } = this.state;
    const { currentUser } = this.props;
    switch (selectKey) {
      case 'base':
        return <BaseView {...this.props} key={currentUser.email} />;
      case 'billing':
        return <Billing {...this.props} />;
      case 'security':
        return <Security {...this.props} />;
      case 'integrations':
        return <Integrations {...this.props} />;
      case 'allIntegrations':
        return <AllIntegrations selectKey={this.selectKey} />;
      default:
        break;
    }

    return null;
  };

  render() {
    const { loggedIn } = this.props;

    if (!loggedIn) {
      return <Exception403 />;
    }

    const { mode, selectKey } = this.state;
    return (
      <div
        className="main"
        ref={(ref) => {
          if (ref) {
            this.main = ref;
          }
        }}
      >
        <div className="leftMenu">
          <Menu
            mode={mode}
            selectedKeys={[selectKey]}
            onClick={({ key }) => this.selectKey(key)}
          >
            {this.getMenu()}
          </Menu>
        </div>
        <div className="right">
          <div className="title">{this.getRightTitle()}</div>
          {this.renderChildren()}
        </div>
      </div>
    );
  }
}

Settings.defaultProps = {
  selectKey: 'base',
};

const mapStateToProps = (state) => {
  const planId = state.account.dailyPlanId;
  return {
    apiKeys: _.get(state, ['auth', 'keys'], []),
    primaryKey: _.get(state, ['user', 'primaryKey'], null),
    loggedIn: state.users.data !== null,
    currentUser: state.users.data,
    error: state.account.error,
    status: state.account.status,
    plan: _.get(state, ['user', 'data', 'plan'], null),
    modalStatus: state.auth.status,
    currentPlan: getPlan(state, planId),
    stripePlans: state.account.stripePlans,
  };
};

const mapDispatchToProps = (dispatch, props) => ({
  update: (values) => {
    if (values.name) {
      const { firstName, lastName } = parseFullName(values.name);
      values = {
        ...values,
        profile: {
          name: values.name,
          firstName,
          lastName,
        },
      };
    }
    dispatch(accountActions.updateProfile(values));
  },
  loadStripePlans: () => dispatch(accountActions.loadStripePlans()),
  createStripe: (dailyPlanId, payload) =>
    dispatch(accountActions.createStripe(dailyPlanId, payload)),
  updateStripe: (payload) => dispatch(accountActions.updateStripe(payload)),
  updatePassword: (values) => dispatch(accountActions.updatePassword(values)),
  resetInitialState: () => dispatch(accountActions.resetInitialState()),
  addApiKey: (keys) => dispatch(accountActions.addKey(keys)),
  deleteApiKey: (keys) => dispatch(accountActions.deleteKey(keys)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
