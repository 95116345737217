import React from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import resourceData from '../components/widget/constants';

export function getActions(resourceName) {
  const widgetOptions = _.find(resourceData, {
    name: _.capitalize(resourceName),
  });
  const defaultActions = [
    {
      key: 'noAction',
      name: 'No Actions Available',
      icon: <QuestionCircleOutlined />,
      action: () => {},
    },
  ];
  return _.get(widgetOptions, 'actions', defaultActions);
}

export function getDefaultIfExcludedHost(
  resourceName,
  actionKey,
  host,
  defaultAction = 'showInfo'
) {
  const actions = getActions(resourceName);

  const foundAction = actions.find((action) => action.key === actionKey);

  if (foundAction) {
    if (foundAction.excludeHosts && foundAction.excludeHosts.includes(host)) {
      return foundAction.defaultAction || defaultAction;
    }
    return actionKey;
  }
  return defaultAction;
}

export function getPrimaryAndSecondaryAction(
  resourceName,
  host = 'web',
  defaultAction = 'showInfo'
) {
  const widgetOptions = _.find(resourceData, {
    name: _.capitalize(resourceName),
  });

  return {
    primaryAction: getDefaultIfExcludedHost(
      'templates',
      _.get(widgetOptions, 'primaryAction', 'showInfo'),
      host,
      defaultAction
    ),
    secondaryAction: getDefaultIfExcludedHost(
      'templates',
      _.get(widgetOptions, 'secondaryAction', 'showInfo'),
      host,
      defaultAction
    ),
  };
}
