import React from 'react';
import { Descriptions } from 'antd';

class SymbolSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      widgetData: {},
    };
  }

  componentDidMount() {
    this.setState({
      widgetData: this.props.widgetData,
    });
  }

  render() {
    const {
      category,
      classification,
      name,
      symbol,
      tagline,
    } = this.state.widgetData;
    return (
      <Descriptions bordered title={name} border size="small">
        <Descriptions.Item label="Name">{name}</Descriptions.Item>
        <Descriptions.Item label="Symbol">{symbol}</Descriptions.Item>
        <Descriptions.Item label="Category">{category}</Descriptions.Item>
        <Descriptions.Item label="Classification">
          {classification}
        </Descriptions.Item>
        <Descriptions.Item label="Tagline">{tagline}</Descriptions.Item>
      </Descriptions>
    );
  }
}

SymbolSummary.defaultProps = {
  widgetData: {
    category: 'currency,peer to peer',
    classification: 'general cryptoasset',
    name: 'Bitcoin',
    objectID: '000',
    symbol: 'BTC',
    tagline: 'Peer to peer electronic cash',
  },
};

export default SymbolSummary;
