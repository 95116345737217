/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactResizeDetector from 'react-resize-detector';
import { view as Widget } from '.';
import { view as WrapWithHits } from '../algolia/wrap-with-hits';
import options from './constants';

import './widget.css';

function WidgetContainer({ i, widgetType }) {
  const widgetOptions = options.find(opt => opt.widgetType === widgetType);
  return (
    <ReactResizeDetector handleWidth handleHeight>
      {({ width, height }) => {
        const indexProps = {
          searchParameters: _.isNil(widgetOptions)
            ? {}
            : widgetOptions.searchParameters,
          indexName: _.isNil(widgetOptions)
            ? 'CRYPTOSHEETS_SEARCH'
            : widgetOptions.indexName,
          style: { height, width },
        };
        const widgetProps = {
          i,
          height,
          width,
          widgetType,
          widgetOptions,
        };
        return (
          <WrapWithHits
            {...indexProps}
            hitsElement={<Widget {...widgetProps} />}
          ></WrapWithHits>
        );
      }}
    </ReactResizeDetector>
  );
}

WidgetContainer.propTypes = {
  i: PropTypes.string,
  widgetType: PropTypes.string,
};

export default WidgetContainer;
