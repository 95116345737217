import React from 'react';
import { Typography } from 'antd';
import { Link } from 'react-router-dom';

const { Paragraph } = Typography;

export default function Footer() {
  return (
    <Paragraph>
      <a href={window.location.href} rel="noopener noreferrer" target="_blank">
        Read this in browser
      </a>{' '}
      | <Link to="/contact">Contact Us</Link>
    </Paragraph>
  );
}
