import styled from 'styled-components';

const AlgoliaComponentWrapper = styled.section`
  .sidebarWrapper {
    width: 340px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin: 0 30px 0 0;
    -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    -moz-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    -ms-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  @media only screen and (max-width: 767px) {
    .sidebarWrapper {
      width: 100%;
      margin-left: calc(-100% + -30px);
      margin-right: 30px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .sidebarWrapper {
      width: 270px;
    }
  }

  .ais-SearchBox .ais-SearchBox-input {
    padding: 0 36px 0 46px;
    border: 1px solid #e9e9e9;
    transition: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-transition: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    -webkit-box-shadow: 'none';
    -moz-box-shadow: 'none';
    box-shadow: 'none';
  }

  .isoAlgoliaSidebarItem {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 25px;
    margin-bottom: 15px;
    background-color: #ffffff;
    border: 1px solid #e9e9e9;
    transition: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-transition: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
  }

  .isoAlgoliaSidebarItem .isoAlgoliaSidebarTitle {
    font-size: 14px;
    font-weight: 700;
    color: #323332;
    line-height: 1.3;
    margin: 0 0 20px;
    display: flex;
  }

  .isoAlgoliaSidebarItem.isoInline {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .isoAlgoliaSidebarItem.isoInline .isoAlgoliaSidebarTitle {
    margin-bottom: 0;
  }

  .ais-RefinementList label {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #595959;
  }

  .ais-RefinementList {
    .ais-RefinementList-searchBox {
      margin-bottom: 3px;
      .ais-SearchBox {
        margin-bottom: 10px;
        .ais-SearchBox-form {
          width: 100%;
          height: 100%;
          display: inline-block;
          position: relative;
          max-width: 300px;
          width: 100%;
          height: 32px;
          white-space: nowrap;
          box-sizing: border-box;
          font-size: 14px;

          input {
            padding: 0px 36px 0px 46px;
            border: 1px solid rgb(233, 233, 233);
            border-radius: 3px;
            box-shadow: none;
            width: 100%;
            height: 100%;
          }

          input:focus {
            outline: none;
          }

          .ais-SearchBox-submit {
            right: inherit;
            left: 0px;
            position: absolute;
            top: 0;
            right: inherit;
            left: 0;
            margin: 0;
            border: 0;
            border-radius: 3px 0 0 3px;
            background-color: hsla(0, 0%, 100%, 0);
            padding: 0;
            width: 32px;
            height: 100%;
            vertical-align: middle;
            text-align: center;
            font-size: inherit;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            .ais-SearchBox-submitIcon {
              /* width: 14px; */
              /* height: 14px; */
              vertical-align: middle;
              fill: #bfc7d8;
            }
          }

          .ais-SearchBox-reset {
            /* display: none; */
            position: absolute;
            top: 6px;
            right: 6px;
            margin: 0;
            border: 0;
            background: none;
            cursor: pointer;
            padding: 0;
            font-size: inherit;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            fill: #bfc7d8;
          }
        }
      }
    }
  }

  .ais-RefinementList-item {
    color: #3369e7;
    margin-top: 6px;
  }

  .ais-RefinementList .ais-RefinementList-count {
    margin: 0 0 0 auto;
    border-radius: 31px;
    background-color: rgba(39, 81, 175, 0.1);
    color: #3369e7;
    font-size: 12px;
    padding: 2px 10px;
  }

  .ais-RefinementList .ais-RefinementList-checkbox {
    display: inline-block;
    border-radius: 3.5px;
    width: 16px;
    height: 16px;
    background: #fff
      url("data:image/svg+xml;utf8,<svg viewBox='0 0 10 9' xmlns='http://www.w3.org/2000/svg'><path d='M1 4.88l2.378 2.435L9.046 1.6' stroke-width='1.6' stroke='%23FFF' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/></svg>")
      no-repeat 50%/10px;
    box-shadow: inset 0 0 0 1px #d4d8e3;
    outline: none;
    vertical-align: middle;
    margin-right: 8px;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
  }

  .ais-RefinementList-item--selected * {
    font-weight: 600;
  }

  .ais-RefinementList-item label {
    cursor: pointer;
  }

  /* Content Wrapper Styling */
  .contentWrapper {
    width: calc(100% - 370px);
    display: flex;
    flex-direction: column;
  }

  @media only screen and (max-width: 767px) {
    .contentWrapper {
      width: 100%;
      margin-right: 0;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1199px) {
    .contentWrapper {
      width: calc(100% - 300px);
    }
  }

  .ant-card-grid:hover {
    color: white;
    cursor: pointer;
  }

  .ais-Hits {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  .ais-Hits.list .ais-Hits {
    flex-direction: column;
  }

  .topbarWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 27px;
    flex-wrap: wrap;
  }

  .topbarWrapper .ais-Stats {
    font-size: 14px;
    font-weight: 500;
    color: #323332;
    opacity: 0.6;
    font-stretch: normal;
  }

  @media only screen and (max-width: 991px) {
    .topbarWrapper .ais-Stats {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .ais-SortBy {
    margin-left: auto;
    margin-right: 20px;
  }

  @media only screen and (max-width: 991px) {
    .ais-SortBy {
      margin: 0 auto 0 0;
    }
  }
`;

export default AlgoliaComponentWrapper;
