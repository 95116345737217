import React from 'react';
import _ from 'lodash';
import { Button } from 'antd';
import { unicornCase } from '@cryptosheets/util';

function Links({ endpoint }) {
  const { endpointId, name, apiDocumentation } = endpoint;

  const providerName = _.get(endpoint, ['provider', 'name'], 'provider');
  const endpointDocumentation = `https://docs.cryptosheets.com/providers/${unicornCase(
    providerName
  )}/${unicornCase(name)}/`;

  return [
    endpointId,
    <Button type="link" href={endpointDocumentation} target="_blank">
      CS Docs
    </Button>,
    <Button type="link" href={apiDocumentation} target="_blank">
      API Docs
    </Button>,
  ];
}

export default Links;
